import C from '../constants/constants';
import $ from '../../libs/reduxOperations';

export const initialState = {
  comments: {},
  preparedComment: {},
  commentPending: false,
};

export default function commentsReducer(state = initialState, action) {
  switch(action.type) {
    case C.CREATE_COMMENT_START:
    case C.UPDATE_COMMENT_START:
    case C.DELETE_COMMENT_START:
      return {
        ...state,
        commentPending: true,
      };
    case C.CREATE_COMMENT_SUCCESS:
    case C.UPDATE_COMMENT_SUCCESS:
    case C.ASSIGN_TASKS_SUCCESS:
    case C.FETCH_TASKS_SUCCESS:
    case C.CREATE_TASK_SUCCESS:
    case C.FETCH_OBJECT_SUCCESS:
    case C.CREATE_BLOCK_SUCCESS:
      return {
        ...state,
        commentPending: false,
        comments: $.addOrUpdateObjectWithObjects(state.comments, action.comments),
        preparedComment: {},
      };
    case C.DELETE_COMMENT_SUCCESS:
      return {
        ...state,
        comments: $.removeObjectFromObject(state.comments, action.comment),
      };
    case C.CREATE_COMMENT_FAIL:
    case C.DELETE_COMMENT_FAIL:
    case C.UPDATE_COMMENT_FAIL:
      return {
        ...state,
        commentPending: false,
      };
    case C.PREPARE_COMMENT:
      return {
        ...state,
        preparedComment: action.comment,
      };
    case C.FETCH_TASK_SUCCESS:
      return {
        ...state,
        comments: $.addOrUpdateObjectWithObjects(state.comments, action.comments),
      };

    default:
      return state;
  }
}
