import { setting, department, role, tagGroup, objectType, project } from './headers';

setting.define({
  departments: [department],
  roles: [role],
  company_tag_group: tagGroup,
  company_type_tag_group: tagGroup,
  company_industry_tag_group: tagGroup,
  contact_log_type_tag_group: tagGroup,
  address_type_tag_group: tagGroup,
  person_title_tag_group: tagGroup,
  people_tag_group: tagGroup,
  task_tag_group: tagGroup,
  file_tag_group: tagGroup,
  people_object_type: objectType,
  task_object_types: [objectType],
  project,
});

export default setting;
