// @flow

import React, { Component } from 'react';
import s from './LoaderOverlay.module.scss';
import Loader from './Loader';

type Props = {
  solid?: ?boolean,
  theme: string,
  showLoader: boolean,
};

class LoaderOverlay extends Component<Props> {
  render() {
    const { solid, theme, showLoader } = this.props;
    return (
      <div className={`${s.loading} ${solid ? s.solid : ''} ${showLoader ? s.showLoader : ''}`}>
        <div className={s.loader}>
          <div className={s.logotype}>
            { solid && theme === 'dark' && <img alt="Jetty" src="https://s3.eu-central-1.amazonaws.com/jetty2-dev/web/logotype-new.png" /> }
            { solid && theme === 'light' && <img alt="Jetty" src="https://s3-eu-west-1.amazonaws.com/j2-files/Jetty-logo.png" /> }
          </div>
          <Loader />
        </div>
      </div>
    );
  }
}

export default LoaderOverlay;
