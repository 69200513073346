// @flow

import React from 'react';

import Title from './Title';
import TextScroll from './TextScroll';
import s from './InputPreview.module.scss';

type Props = {
  title?: string,
  value?: ?string,
  smallTitle?: boolean,
  noTitle?: boolean,
  isNested?: boolean,
  icon?: string,
  isFixed?: boolean,
  edit?: boolean,
  valueSet?: boolean,
};

const InputPreview = ({
  title,
  value,
  smallTitle,
  noTitle,
  isNested,
  icon,
  isFixed,
  edit,
  valueSet = true,
}: Props) => (
  <div className={`${s.wrapper} ${isNested ? s.isNested : ''} ${isFixed ? s.isFixed : ''} ${value ? s.hasValue : ''} ${title ? s.hasTitle : ''} ${valueSet ? s.valueSet : ''}`}>
    {(!isNested && !noTitle) && title &&
      <div className={`${s.titleWrapper} ${title ? s.hasTitle : ''}`}>
        {(smallTitle ? <div className={`${s.title} ${s.small}`}>{title}</div> : <Title h3 edit={edit}>{title}</Title>)}
      </div>
    }
    <div className={s.value}>
      { icon && <span className={s.icon}><i className={`fa fa-${icon}`} /></span>}
      {value && value !== 'undefined' && <TextScroll>{value}</TextScroll>}
    </div>
  </div>
);

export default InputPreview;
