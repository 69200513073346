import { normalize } from 'normalizr';
import { PURGE } from 'redux-persist';
import C from '../constants/constants';
import E from '../constants/economy';
import requestManager from '../../libs/requestManager';
import { getIds } from './actionUtils';
import setting from '../entities/Setting';
import project from '../entities/Project';
import menuEntity from '../entities/MenuItem';
import currency from '../entities/Currency';
import processFile from '../../libs/fileUtils';

const flattenSettings = normalizedSetting => ({
  departments: normalizedSetting.entities.department,
  roles: normalizedSetting.entities.role,
  tagGroups: normalizedSetting.entities.tag_group,
  tags: normalizedSetting.entities.tag,
  setting: normalizedSetting.entities.setting,
  project: normalizedSetting.entities.project,
  jobRoles: normalizedSetting.entities.job_role,
});

const flattenMenuItems = normalizedMenuItem => ({
  menuItems: normalizedMenuItem.entities.menu_items,
});

const flattenCurrencies = normalizedCurrency => ({
  currencies: normalizedCurrency.entities.currency,
});

export function setTheme(theme) {
  return (dispatch) => {
    dispatch({ type: C.SET_THEME, theme });
  };
}

export function activateListMode() {
  return (dispatch) => {
    dispatch({ type: C.ACTIVATE_LIST_MODE });
  };
}

export function activateCalendarMode() {
  return (dispatch) => {
    dispatch({ type: C.ACTIVATE_CALENDAR_MODE });
  };
}

export function fetchProjectSettings() {
  return (dispatch, getState) => {
    dispatch({ type: C.FETCH_PROJECT_SETTINGS_START });
    const ids = getIds(getState);
    requestManager
      .getProjectSettings(ids.clientId, ids.projectId).then((data) => {
        const nd = normalize(data.data, setting);
        const reduxEntities = flattenSettings(nd);
        dispatch({
          type: C.FETCH_PROJECT_SETTINGS_SUCCESS,
          ...reduxEntities,
        });
      })
      .catch(error => dispatch({ type: C.FETCH_PROJECT_SETTINGS_FAIL, error }));
  };
}

export function fetchProject() {
  return (dispatch, getState) => {
    dispatch({ type: C.FETCH_PROJECT_START });
    const ids = getIds(getState);
    requestManager
      .getProject(ids.clientId, ids.projectId).then((data) => {
        const nd = normalize(data.data, project);
        const reduxEntities = flattenSettings(nd);
        dispatch({
          type: C.FETCH_PROJECT_SUCCESS,
          ...reduxEntities,
        });
      })
      .catch(error => dispatch({ type: C.FETCH_PROJECT_FAIL, error }));
  };
}

export function clearAllFilters(listType) {
  return (dispatch) => {
    dispatch({ type: C.CLEAR_ALL_FILTERS, listType });
  };
}

export function setFilterAvailability(available, listType) {
  return (dispatch) => {
    dispatch({ type: C.SET_FILTER_AVAILABILITY, available, listType });
  };
}

export function setFilterDepartments(department, listType) {
  return (dispatch) => {
    dispatch({ type: C.SET_FILTER_DEPARTMENTS, department, listType });
  };
}

export function setFilterStatus(status, listType) {
  return (dispatch) => {
    dispatch({ type: C.SET_FILTER_STATUS, status, listType });
  };
}

export function toggleFilterPublished(listType) {
  return (dispatch) => {
    dispatch({ type: C.TOGGLE_FILTER_PUBLISHED, listType });
  };
}

export function setFilterDates(schedule, listType) {
  return (dispatch) => {
    dispatch({ type: C.SET_FILTER_DATES, schedule, listType });
  };
}

export function setFilterTag(tag, listType) {
  return (dispatch) => {
    dispatch({ type: C.SET_FILTER_TAG, tag, listType });
  };
}

export function setFilterTitleTag(tag, listType) {
  return (dispatch) => {
    dispatch({ type: C.SET_FILTER_TITLE_TAG, tag, listType });
  };
}

export function setFilterRoles(role, listType) {
  return (dispatch) => {
    dispatch({ type: C.SET_FILTER_ROLES, role, listType });
  };
}

export function setFilterCategory(category, listType) {
  return (dispatch) => {
    dispatch({ type: C.SET_FILTER_CATEGORY, category, listType });
  };
}

export function setFilterTasks(myTasks, listType) {
  return (dispatch) => {
    dispatch({ type: C.SET_FILTER_TASKS, myTasks, listType });
  };
}

export function setFilterExactMatch(exactMatch, listType) {
  return (dispatch) => {
    dispatch({ type: C.SET_FILTER_EXACT, exactMatch, listType });
  };
}

export function setFilterExactTitlesMatch(exactMatch, listType) {
  return (dispatch) => {
    dispatch({ type: C.SET_FILTER_TITLES_EXACT, exactMatch, listType });
  };
}

export function setFilterUser(user, listType) {
  return (dispatch) => {
    dispatch({ type: C.SET_FILTER_USERS, user, listType });
  };
}

export function setFilterOrderBy(orderBy, listType) {
  return (dispatch) => {
    dispatch({ type: C.SET_FILTER_ORDER_BY, orderBy, listType });
  };
}

export function setFilterPrimaryCategory(primaryCategory, listType) {
  return dispatch => dispatch({ type: C.SET_FILTER_PRIMARY_CATEGORY, primaryCategory, listType });
}

export function setFilterSecondaryCategory(secondaryCategory, listType) {
  return dispatch => dispatch({ type: C.SET_FILTER_SECONDARY_CATEGORY, secondaryCategory, listType });
}

export function setFilterTertiaryCategory(tertiaryCategory, listType) {
  return dispatch => dispatch({ type: C.SET_FILTER_TERTIARY_CATEGORY, tertiaryCategory, listType });
}

export function setFilterObject(object, listType) {
  return dispatch => dispatch({ type: C.SET_FILTER_OBJECT, object, listType });
}

export function setFilterConnectedTo(connectedTo, listType) {
  return dispatch => dispatch({ type: C.SET_FILTER_CONNECTED_TO, connectedTo, listType });
}

export function setFilterStaff(staff, listType) {
  return dispatch => dispatch({ type: C.SET_FILTER_STAFF, staff, listType });
}

export function setFilterJobRole(jobRole, listType) {
  return (dispatch) => {
    dispatch({ type: C.SET_FILTER_JOBROLE, jobRole, listType });
  };
}

export function setFilterTaskPriority(priority, listType) {
  return (dispatch) => {
    dispatch({ type: C.SET_FILTER_TASK_PRIORITY, priority, listType });
  };
}

export function setFilterCompany(company, listType) {
  return dispatch => dispatch({ type: C.SET_FILTER_COMPANY, company, listType });
}

export function resetFilter(listType) {
  return (dispatch) => {
    dispatch({ type: C.RESET_FILTER, listType });
  };
}

export function closeError() {
  return (dispatch) => {
    dispatch({ type: C.CLOSE_ERROR });
  };
}

export function closeNotice() {
  return (dispatch) => {
    dispatch({ type: C.CLOSE_NOTICE });
  };
}

export function addRequiredField(field) {
  return (dispatch) => {
    dispatch({ type: C.ADD_REQUIRED_FIELD, requiredField: field });
  };
}

export function removeRequiredField(field) {
  return (dispatch) => {
    dispatch({ type: C.REMOVE_REQUIRED_FIELD, requiredField: field });
  };
}

export function setFilterTitle(title, listType) {
  return (dispatch) => {
    dispatch({ type: C.SET_FILTER_TITLE, title, listType });
  };
}

export function setPrintMode(printMode) {
  return (dispatch) => {
    dispatch({ type: C.SET_PRINT_MODE, printMode });
  };
}

export function setPrintBlocks(printBlocks) {
  return (dispatch) => {
    dispatch({ type: C.SET_PRINT_BLOCKS, printBlocks });
  };
}

export function setPrintTitle(printTitle) {
  return (dispatch) => {
    dispatch({ type: C.SET_PRINT_TITLE, printTitle });
  };
}

export function closeDeleteNotice() {
  return (dispatch) => {
    dispatch({ type: C.CLOSE_DELETE_NOTICE });
  };
}

export function openDeleteNotice(cb) {
  return (dispatch) => {
    dispatch({ type: C.OPEN_DELETE_NOTICE, cb });
  };
}

export function fetchMenuItems() {
  return (dispatch, getState) => {
    dispatch({ type: C.FETCH_MENU_ITEMS_START });
    const ids = getIds(getState);
    requestManager
      .getMenuItems(ids.projectId).then((data) => {
        const nd = normalize(data.data, [menuEntity]);
        const reduxEntities = flattenMenuItems(nd);
        dispatch({
          type: C.FETCH_MENU_ITEMS_SUCCESS,
          ...reduxEntities,
        });
      })
      .catch(error => dispatch({ type: C.FETCH_MENU_ITEMS_FAIL, error }));
  };
}

export function setMenuItemsOpen(menuItem, toggle) {
  return (dispatch) => {
    dispatch({ type: C.SET_MENU_ITEMS, item: menuItem, toggle });
  };
}

export function clearMenu() {
  return (dispatch) => {
    dispatch({ type: C.CLEAR_MENU });
  };
}

export function copyTextToClipboard(text) {
  return (dispatch) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    const success = document.execCommand('copy');
    dispatch({ type: success ? C.COPY_TEXT_SUCCESS : C.COPY_TEXT_FAIL, message: success ? 'Successfully copied text' : null, error: success ? null : Error('Failed to copy text') });
    document.body.removeChild(textArea);
  };
}

export function addGroupByItem(item, type) {
  return (dispatch) => {
    dispatch({ type: C.ADD_GROUP_BY_ITEM, item, listType: type });
  };
}

export function removeGroupByItem(item, type) {
  return (dispatch) => {
    dispatch({ type: C.REMOVE_GROUP_BY_ITEM, item, listType: type });
  };
}

export function updateGroupByArray(array, type) {
  return (dispatch) => {
    dispatch({ type: C.UPDATE_GROUP_BY, array, listType: type });
  };
}

export function updateGroupByItem(item, type) {
  return (dispatch) => {
    dispatch({ type: C.UPDATE_GROUP_BY_ITEM, item, listType: type });
  };
}

export function setDefaultGroupBy(list, type) {
  return (dispatch) => {
    dispatch({ type: C.SET_DEFAULT_GROUP_BY, list, listType: type });
  };
}

export function setFilterSelectOption(option, optionType, listType) {
  return (dispatch) => {
    dispatch({ type: C.SET_FILTER_SELECT_OPTION, option, optionType, listType });
  };
}

export function fetchVersion() {
  return (dispatch, getState) => {
    dispatch({ type: C.FETCH_VERSION_START });
    requestManager
      .getVersion().then((data) => {
        // Check if client version is out of date. If it is, force reload.
        const currentVersion = getState().environmentState.version;
        dispatch({ type: C.FETCH_VERSION_SUCCESS, version: data.data });
        if (currentVersion.length && currentVersion !== data.data) {
          dispatch({ type: PURGE, result: () => null });
          window.location.reload();
        }
      })
      .catch(error => dispatch({ type: C.FETCH_VERSION_FAIL, error }));
  };
}

export function fetchCurrencies() {
  return (dispatch) => {
    dispatch({ type: E.FETCH_CURRENCIES_START });
    requestManager
      .getCurrencies().then((data) => {
        const nd = normalize(data.data, [currency]);
        const reduxEntities = flattenCurrencies(nd);
        dispatch({
          type: E.FETCH_CURRENCIES_SUCCESS,
          ...reduxEntities,
        });
      })
      .catch(error => dispatch({ type: E.FETCH_CURRENCIES_FAIL, error }));
  };
}

export function setMenuItemClicked(itemId) {
  return (dispatch) => {
    requestManager
      .setMenuItemClicked(itemId)
      .then(() => dispatch({ type: C.MENU_CLICK_REGISTERED_SUCCESS }))
      .catch(error => dispatch({ type: C.MENU_CLICK_REGISTERED_FAIL, error }));
  };
}

async function processFiles(files) { return Promise.all(files.map(async file => processFile(file))); }

export function sendBulkEmail(emailData) {
  const dispatchFunction = (dispatch, adaptedEmailData) => {
    dispatch({ type: C.SEND_BULK_EMAIL_START, adaptedEmailData });
    requestManager
      .sendBulkEmail(adaptedEmailData).then(() => {
        dispatch({
          type: C.SEND_BULK_EMAIL_SUCCESS,
        });
      })
      .catch(error => dispatch({ type: C.SEND_BULK_EMAIL_FAIL, error }));
  };

  return async (dispatch) => {
    const adaptedEmailData = emailData;
    if(emailData.attachments && emailData.attachments.length) {
      processFiles(adaptedEmailData.attachments).then((files) => {
        adaptedEmailData.attachments = files;
        dispatchFunction(dispatch, adaptedEmailData);
      });
    } else {
      dispatchFunction(dispatch, adaptedEmailData);
    }
  };
}

export function resetBulkEmailStatus() {
  return(dispatch) => {
    dispatch({ type: C.RESET_BULK_EMAIL_STATUS });
  };
}

export function setFilterConnectedObject(connectedObject, listType) {
  return dispatch => dispatch({ type: C.SET_FILTER_CONNECTED_OBJECT, connectedObject, listType });
}

export function openVideoPlayer(videoData) {
  return dispatch => dispatch({ type: C.OPEN_VIDEO_PLAYER, ...videoData });
}

export function closeVideoPlayer() {
  return dispatch => dispatch({ type: C.CLOSE_VIDEO_PLAYER });
}
