import C from '../constants/constants';

export const initialState = {
  query: '',
  objectResults: {},
  searchPending: false,
};

export default function searchReducer(state = initialState, action) {
  switch(action.type) {
    case C.SEARCH_GLOBAL_START:
      return {
        ...state, searchPending: true, query: action.query,
      };
    case C.SEARCH_GLOBAL_SUCCESS:
      return {
        ...state, searchPending: false, objectResults: state.query && state.query.length ? action.objectResults : {},
      };
    case C.SEARCH_GLOBAL_FAIL:
      return {
        ...state, searchPending: false, error: action.error,
      };
    case C.CLEAR_SEARCH:
      return {
        ...state, searchPending: false, objectResults: {}, query: '',
      };
    case '@@router/LOCATION_CHANGE':
      return {
        ...state, objectResults: {}, query: '',
      };
    default:
      return state;
  }
}
