// @flow
import { normalize } from 'normalizr';
import { PURGE } from 'redux-persist';
import C from '../constants/constants';
import requestManager from '../../libs/requestManager';
import { updateOrCreateBlock } from './objectsActions';
import { getIds } from './actionUtils';
import { fetchProjectSettings } from './environmentActions';
import { project } from '../entities/headers';
import { uuid } from '../../libs/utils';
import objectTypeEntity from '../entities/ObjectType';
import objectDataEntity from '../entities/ObjectData';
import type { TokenUser, Id, Prepared, ObjectData } from '../entities/FlowTypes';
import { IdMapValues } from '../entities/FlowTypes';

const flattenObjects = normalizedObject => ({
  objectTypes: normalizedObject.entities.object_type,
  objectDatas: normalizedObject.entities.object_data,
  blockDatas: normalizedObject.entities.block_data,
  blockTypes: normalizedObject.entities.block_type,
  fieldDatas: normalizedObject.entities.field_data,
  fieldTypes: normalizedObject.entities.field_type,
  people: normalizedObject.entities.person,
  projects: normalizedObject.entities.project,
  selectOptions: normalizedObject.entities.select_option,
  statuses: normalizedObject.entities.status,
  tags: normalizedObject.entities.tag,
  tagGroups: normalizedObject.entities.tag_group,
  files: normalizedObject.entities.file,
});

function afterAuthenticationSuccess(dispatch: any, tokenUser: TokenUser) {
  const { entities } = normalize(tokenUser.project, project);

  dispatch({
    type: C.FETCH_ACCOUNT_SUCCESS,
    account: {
      id: null,
      client_last: tokenUser.client_id,
      username: '',
      has_accepted_terms: true,
    },
  });

  dispatch({
    type: C.FETCH_MY_USER_SUCCESS,
    user: {
      client: tokenUser.client_id,
      last_project: tokenUser.project.id,
      permissions: tokenUser.permissions,
    },
  });

  dispatch({
    type: C.FETCH_PROJECTS_SUCCESS,
    projects: entities.project,
    languages: entities.language,
  });

  dispatch({
    type: C.FETCH_PROJECT_SETTINGS_SUCCESS,
    project: entities.project,
  });

  dispatch({ type: C.LOGIN_SUCCESS });
}

export function setExternallyAccessed(value: boolean) {
  return (dispatch: any) => {
    dispatch({ type: C.SET_EXTERNALLY_ACCESSED, value });
  };
}

export function getObjectType() {
  return (dispatch: any) => {
    dispatch({ type: C.GET_TOKEN_USER_OBJECT_TYPE_START });
    dispatch({ type: C.SHOW_LOADER });
    requestManager
      .getTokenUserObjectType().then((data) => {
        const nd = normalize(data.data, objectTypeEntity);
        const reduxEntities = flattenObjects(nd);
        dispatch({
          type: C.GET_TOKEN_USER_OBJECT_TYPE_SUCCESS,
          ...reduxEntities,
        });
        dispatch({ type: C.HIDE_LOADER });
      })
      .catch(error => dispatch({ type: C.GET_TOKEN_USER_OBJECT_TYPE_FAIL, error }));
  };
}

function getSubObjectData(parent: number) {
  return (dispatch: any, getState: any) => {
    const ids = getIds(getState);
    dispatch({ type: C.GET_TOKEN_USER_OBJECT_DATA_START });
    requestManager.getTokenUserListObjects(ids.clientId, ids.projectId, parent, -1, 100).then((data) => {
      const nd = normalize(data.data, [objectDataEntity]);
      const reduxEntities = flattenObjects(nd);
      dispatch({ type: C.GET_TOKEN_USER_OBJECT_DATA_SUCCESS, ...reduxEntities, object_data_id: data.data && data.data.id });
    });
  };
}

export function getObjectData() {
  return (dispatch: any) => {
    dispatch({ type: C.GET_TOKEN_USER_OBJECT_DATA_START });
    requestManager
      .getTokenUserObjectData().then((data) => {
        const nd = normalize(data.data, objectDataEntity);
        const reduxEntities = flattenObjects(nd);
        const objectId = data.data && data.data.id;
        dispatch({ type: C.GET_TOKEN_USER_OBJECT_DATA_SUCCESS, ...reduxEntities, objectId });
        if (objectId) dispatch(getSubObjectData(objectId));
      })
      .catch(error => dispatch({ type: C.GET_TOKEN_USER_OBJECT_DATA_FAIL, error }));
  };
}

export function getTokenUser(token: string, resolve: ?(TokenUser => void) = null, reject: ?(string => void) = null) {
  localStorage.setItem('X-JETTY-API-TOKEN', token);
  localStorage.removeItem('access_token');
  return (dispatch: any, getState: any) => {
    dispatch({ type: C.SHOW_LOADER });
    dispatch({ type: C.VALIDATE_TOKEN_USER_START });
    const { isExternallyAccessed } = getState().tokenUserState;
    if (!resolve && isExternallyAccessed) dispatch({ type: C.LOGOUT });
    requestManager
      .getTokenUser().then((data) => {
        const tokenUser = data.data;
        dispatch({ type: C.VALIDATE_TOKEN_USER_SUCCESS, tokenUser });
        afterAuthenticationSuccess(dispatch, tokenUser);
        dispatch(getObjectType());
        dispatch({ type: C.HIDE_LOADER });

        if (resolve) resolve(tokenUser);

        if (tokenUser.object_data && !isExternallyAccessed) {
          dispatch(fetchProjectSettings()); // Enabled this to keep behavior from before
        }
        // dispatch(fetchProject());
      })
      .catch((error) => {
        dispatch({ type: C.HIDE_LOADER });
        dispatch({ type: C.VALIDATE_TOKEN_USER_FAIL, error });
        if (reject) reject(error);
      });
  };
}

export function createTokenUser(typeId: number, resolve: ?(TokenUser => void) = null, reject: ?(string => void) = null) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: C.SHOW_LOADER });
    dispatch({ type: C.CREATE_TOKEN_USER_CHILD_START });
    const { preparedObjects } = getState().objectsState;
    const preparedObject = IdMapValues(preparedObjects).find(o => o.object_type === typeId);
    if (preparedObject) delete preparedObject.object_type;
    if (preparedObject && preparedObject.id && typeof preparedObject.id === 'string') delete preparedObject.id;
    if (preparedObject && preparedObject.primary_field && preparedObject.primary_field.id && typeof preparedObject.primary_field.id === 'string') delete preparedObject.primary_field.id;
    requestManager
      .postTokenApiObject(null, null, typeId, [], preparedObject).then((data) => {
        // update the current token user with api token and all that comes with that.
        const apiKey = data.data.token_users && data.data.token_users[0].api_key;
        const promise = new Promise((res, rej) => { dispatch(getTokenUser(apiKey, res, rej)); });
        promise.then((tokenUser: TokenUser) => {
          dispatch({ type: C.CREATE_TOKEN_USER_CHILD_SUCCESS, tokenUser });
          dispatch({ type: C.HIDE_LOADER });
          if (resolve) resolve(tokenUser);
        })
          .catch((error) => {
            if (reject) reject(error);
            dispatch({ type: C.HIDE_LOADER });
            dispatch({ type: C.CREATE_TOKEN_USER_CHILD_FAIL, error });
          });
      })
      .catch((error) => {
        if (reject) reject(error);
        dispatch({ type: C.HIDE_LOADER });
        dispatch({ type: C.CREATE_TOKEN_USER_CHILD_FAIL, error });
      });
  };
}

function submitObjectInternal(senderName: string, senderEmail: string, mailTos: string, objectId: ?number = null, isFormsSubmit: boolean = false) {
  return (dispatch: any) => {
    dispatch({ type: C.SHOW_LOADER });
    const updateBlocks = new Promise((resolve, reject) => {
      if (objectId) dispatch(updateOrCreateBlock(objectId, true, false, resolve, reject));
      else resolve();
    });
    updateBlocks.then(() => {
      dispatch({ type: C.SUBMIT_TOKEN_USER_OBJECT_DATA_START });
      requestManager
        .submitTokenUserObjectData(senderName, senderEmail, mailTos, objectId != null, isFormsSubmit).then(() => {
          dispatch({ type: C.SUBMIT_TOKEN_USER_OBJECT_DATA_SUCCESS, objectId });
          dispatch({ type: C.HIDE_LOADER });
        })
        .catch((error) => {
          dispatch({ type: C.HIDE_LOADER });
          dispatch({ type: C.SUBMIT_TOKEN_USER_OBJECT_DATA_FAIL, error });
        });
    })
      .catch((error) => {
        dispatch({ type: C.HIDE_LOADER });
        dispatch({ type: C.SUBMIT_TOKEN_USER_OBJECT_DATA_FAIL, error });
      });
  };
}

export function submitObject(senderName: string, senderEmail: string, mailTos: string, parentUser: ?TokenUser = null, isFormsSubmit: boolean = true) {
  return (dispatch: any) => {
    if (parentUser && parentUser.object_type_id) {
      const createTokenuser = new Promise((resolve, reject) => {
        if (parentUser && parentUser.object_type_id) dispatch(createTokenUser(parentUser.object_type_id, resolve, reject));
      });
      createTokenuser.then((user: TokenUser) => {
        const objectId = user.object_data && user.object_data.id;
        dispatch(submitObjectInternal(senderName, senderEmail, mailTos, objectId, isFormsSubmit));
      })
        .catch(error => dispatch({ type: C.SUBMIT_TOKEN_USER_OBJECT_DATA_FAIL, error }));
    } else {
      dispatch(submitObjectInternal(senderName, senderEmail, mailTos));
    }
  };
}

export function prepareObjectData(objectData: Prepared<ObjectData>) {
  const object = { ...objectData, id: uuid() };
  return (dispatch: any) => {
    dispatch({ type: C.PREPARE_TOKEN_USER_OBJECT, object });
  };
}

export function toggleTokenUserActive(tokenUser: TokenUser) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: C.TOGGLE_TOKEN_USER_ACTIVE_START });
    const ids = getIds(getState);
    requestManager
      .putTokenUser(ids.clientId, ids.projectId, { id: tokenUser.id, is_active: !tokenUser.is_active }).then((data) => {
        dispatch({ type: C.TOGGLE_TOKEN_USER_ACTIVE_SUCCESS, objectData: { id: data.data.object_data.id, token_users: [data.data] } });
      })
      .catch(error => dispatch({ type: C.TOGGLE_TOKEN_USER_ACTIVE_FAIL, error }));
  };
}

export function fetchSchedulesOnObjectData(objectDataId: Id) {
  return (dispatch: any, getState: any) => {
    const ids = getIds(getState);
    dispatch({ type: C.FETCH_SCHEDULES_ON_OBJECT_DATA_START });
    requestManager
      .getSchedulesOnObjectData(ids.clientId, ids.projectId, objectDataId).then((data) => {
        dispatch({
          type: C.FETCH_SCHEDULES_ON_OBJECT_DATA_SUCCESS,
          calendarSchedules: data.data,
        });
      })
      .catch(error => dispatch({ type: C.FETCH_SCHEDULES_ON_OBJECT_DATA_FAIL, error }));
  };
}

export function purgeLocalState() {
  return (dispatch: any) => {
    dispatch({ type: PURGE, result: () => null });
  };
}
