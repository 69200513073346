export const peopleImportHeaders = [
  { text: 'name', id: 0 },
  { text: 'email', id: 1 },
  { text: 'phone', id: 2 },
  { text: 'title', id: 3 },
  { text: 'company', id: 4 },
  { text: 'organization number', id: 5 },
  { text: 'street name', id: 6 },
  { text: 'city', id: 7 },
  { text: 'zip code', id: 8 },
  { text: 'country', id: 9 },
  { text: 'state', id: 10 },
  { text: 'tags', id: 11 },
  { text: 'birthdate', id: 12 },
  { text: 'notes', id: 13 },
];

export const companiesImportHeaders = [
  { text: 'name', id: 0 },
  { text: 'email', id: 1 },
  { text: 'phone', id: 2 },
  { text: 'tags', id: 3 },
  { text: 'notes', id: 4 },
  { text: 'organization number', id: 5 },
  { text: 'street name', id: 6 },
  { text: 'city', id: 7 },
  { text: 'zip code', id: 8 },
  { text: 'country', id: 9 },
  { text: 'state', id: 10 },
  { text: 'type', id: 11 },
  { text: 'industry', id: 12 },
  { text: 'website', id: 13 },
];
