import C from '../constants/constants';
import $ from '../../libs/reduxOperations';


export const initialState = {
  companies: {},
  companyPending: false,
  isCreating: false,
  isEditingCompany: false,
  preparedCompany: {},
  companiesAutocompleteResults: [],
  organizerCompaniesAutocompleteResults: [],
  engagerCompaniesAutocompleteResults: [],
  selectedCompanies: [],
  preparedAddresses: null,
  preparedPeople: null,
  preparedContactLogs: null,
  isEditingAddresses: false,
  isEditingPeople: false,
  isEditingContactLogs: false,
  companyToBeDeleted: null,
  companyContacts: null,
  importFieldsCompanies: null,
  importCompaniesData: null,
};

export default function companiesReducer(state = initialState, action) {
  switch (action.type) {
    case C.FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: $.addOrUpdateObjectWithObjects(state.companies, action.companies),
        companyPending: false,
      };

    case C.FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        companies: $.addOrUpdateObjectWithObjects(state.companies, action.companies),
        companyPending: false,
      };

    case C.CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        companies: $.addOrUpdateObjectWithObjects(state.companies, action.companies),
        isCreating: false,
        isEditingCompany: false,
        companyPending: false,
        preparedCompany: {},
        preparedAddresses: null,
      };

    case C.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        companies: $.addOrUpdateObjectWithObjects(state.companies, action.companies),
        companyPending: false,
        preparedCompany: {},
        isEditingCompany: false,
        isCreating: false,
      };


    case C.UPDATE_COMPANY_ADDRESSES_SUCCESS:
      return {
        ...state,
        companies: $.addOrUpdateObjectWithObjects(state.companies, action.companies),
        companyPending: false,
        preparedAddresses: null,
        isEditingAddresses: false,
      };

    case C.UPDATE_COMPANY_PEOPLE_SUCCESS:
      return {
        ...state,
        companies: $.addOrUpdateObjectWithObjects(state.companies, action.companies),
        companyPending: false,
        preparedPeople: null,
        isEditingPeople: false,
      };

    case C.UPDATE_COMPANY_CONTACT_LOGS_SUCCESS:
      return {
        ...state,
        companies: $.addOrUpdateObjectWithObjects(state.companies, action.companies),
        companyPending: false,
        preparedContactLogs: null,
        isEditingContactLogs: false,
      };

    case C.UPDATE_COMPANY_COMPANY_CONTACT_LOGS_SUCCESS:
      return {
        ...state,
        companies: $.addOrUpdateObjectWithObjects(state.companies, action.companies),
        companyPending: false,
        preparedContactLogs: null,
        isEditingContactLogs: false,
      };

    case C.DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        companyPending: false,
        companies: $.removeItemWithIdFromList(state.companies, action.objectId),
      };

    case C.FETCH_COMPANIES_START:
      return {
        ...state,
        companyPending: true,
        selectedCompanies: [],
      };
    case C.FETCH_COMPANY_START:
    case C.CREATE_COMPANY_START:
    case C.UPDATE_COMPANY_START:
    case C.DELETE_COMPANY_START:
    case C.UPDATE_COMPANY_ADDRESSES_START:
    case C.UPDATE_COMPANY_PEOPLE_START:
    case C.UPDATE_COMPANY_CONTACT_LOGS_START:
      return {
        ...state,
        companyPending: true,
      };

    case C.FETCH_COMPANIES_FAIL:
    case C.FETCH_COMPANY_FAIL:
    case C.CREATE_COMPANY_FAIL:
    case C.UPDATE_COMPANY_ADDRESSES_FAIL:
    case C.DELETE_COMPANY_PEOPLE_FAIL:
    case C.DELETE_COMPANY_CONTACT_LOGS_FAIL:
      return {
        ...state,
        companyPending: false,
        error: action.error,
      };
    case C.BEGIN_VIEWING_COMPANY:
      return {
        ...state,
        isViewingCompany: true,
        isCreating: false,
        preparedCompany: action.company,
      };
    case C.ABORT_VIEWING_COMPANY:
      return {
        ...state,
        isViewingCompany: false,
        isCreating: false,
        preparedCompany: {},
      };
    case C.BEGIN_EDIT_COMPANY:
      return {
        ...state,
        isCreating: action.isCreating,
        isEditingCompany: true,
        preparedCompany: action.company,
      };

    case C.ABORT_EDIT_COMPANY:
      return {
        ...state,
        isCreating: false,
        isEditingCompany: false,
        preparedCompany: {},
      };

    case C.PREPARE_COMPANY:
      return {
        ...state,
        preparedCompany: action.company,
      };

    case C.BEGIN_EDIT_COMPANY_ADDRESSES:
      return {
        ...state,
        isEditingAddresses: true,
        preparedAddresses: action.addresses,
      };

    case C.ABORT_EDIT_COMPANY_ADDRESSES:
      return {
        ...state,
        isEditingAddresses: false,
        preparedAddresses: null,
      };

    case C.PREPARE_COMPANY_ADDRESSES:
      return {
        ...state,
        preparedAddresses: action.addresses,
      };

    case C.BEGIN_EDIT_COMPANY_PEOPLE:
      return {
        ...state,
        isEditingPeople: true,
        preparedPeople: action.people,
      };

    case C.ABORT_EDIT_COMPANY_PEOPLE:
      return {
        ...state,
        isEditingPeople: false,
        preparedPeople: null,
      };

    case C.PREPARE_COMPANY_PEOPLE:
      return {
        ...state,
        preparedPeople: action.people,
      };

    case C.BEGIN_EDIT_COMPANY_CONTACT_LOGS:
      return {
        ...state,
        isEditingContactLogs: true,
        preparedContactLogs: action.contactLogs,
      };

    case C.ABORT_EDIT_COMPANY_CONTACT_LOGS:
      return {
        ...state,
        isEditingContactLogs: false,
        preparedContactLogs: null,
      };

    case C.PREPARE_COMPANY_CONTACT_LOGS:
      return {
        ...state,
        preparedContactLogs: action.contactLogs,
      };

    case C.FETCH_COMPANY_CONTACTS_SUCCESS:
      return {
        ...state,
        companyToBeDeleted: action.company,
        companyContacts: action.contacts,
      };

    case C.ABORT_DELETE_COMPANY:
      return {
        ...state,
        companyToBeDeleted: null,
        companyContacts: [],
      };

    case C.DELETE_COMPANY_AND_CONNECTIONS_START:
      return {
        ...state,
        companyPending: true,
      };

    case C.DELETE_COMPANY_AND_CONNECTIONS_SUCCESS:
      return {
        ...state,
        companyToBeDeleted: null,
        companyContacts: [],
        companyPending: false,
        companies: $.removeFromObjectById(state.companies, action.company),
      };

    case C.DELETE_COMPANY_AND_CONNECTIONS_FAIL:
      return {
        ...state,
        companyPending: false,
      };


    case C.AUTOCOMPLETE_COMPANIES_START:
      return { ...state, companyPending: true, request: C.AUTOCOMPLETE_COMPANIES };
    case C.AUTOCOMPLETE_COMPANIES_FAIL:
      return {
        ...state, companyPending: false, request: C.AUTOCOMPLETE_COMPANIES, error: action.error,
      };
    case C.AUTOCOMPLETE_COMPANIES_SUCCESS:
      return {
        ...state, companyPending: false, request: C.AUTOCOMPLETE_COMPANIES, companiesAutocompleteResults: action.results,
      };

    case C.AUTOCOMPLETE_ORGANIZER_COMPANIES_SUCCESS:
      return {
        ...state, organizerCompaniesAutocompleteResults: action.results,
      };

    case C.AUTOCOMPLETE_ENGAGER_COMPANIES_SUCCESS:
      return {
        ...state, engagerCompaniesAutocompleteResults: action.results,
      };

    case C.IMPORT_COMPANIES_FIELDS_CREATED:
    case C.PREPARE_IMPORT_FIELDS_COMPANIES:
      return {
        ...state,
        importFieldsCompanies: action.importFields,
      };

    case C.PREPARE_COMPANIES_IMPORT_DATA:
      return {
        ...state,
        importCompaniesData: action.importCompaniesData,
      };

    case C.IMPORT_EXCEL_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: $.addOrUpdateObjectWithObjects(state.companies, action.companies),
        pending: false,
        importFieldsCompanies: null,
        importCompaniesData: null,
      };

    default:
      return state;
  }
}
