// @flow
import type { Id, IdMap, BadgeState, BadgeFramework, BadgeFrameworkSection, BadgeType, Badge } from 'App/entities/FlowTypes';
import C from '../constants/constants';
import $ from '../../libs/reduxOperations';

export const initialState: BadgeState = {
  badgeFrameworks: {},
  badgeFrameworkSections: {},
  badgeTypes: {},
  isCreatingBadgeFramework: false,
  isUpdatingBadgeFramework: false,
  fetchBadgeFrameworksPending: false,
  isCreatingBadge: false,
  isUpdatingBadge: false,
  preparedBadge: {},
  badges: {},
};

type Action = {
  type: string,
  badgeFrameworkId?: Id,
  badgeFramework?: ?BadgeFramework,
  badgeFrameworks?: ?IdMap<BadgeFramework>,
  badgeTypes?: ?IdMap<BadgeType>,
  badgeFrameworkSections?: ?IdMap<BadgeFrameworkSection>,
  isCreatingBadgeFramework?: boolean,
  isUpdatingBadgeFramework?: boolean,
  fetchBadgeFrameworksPending?: boolean,
  badges?: ?IdMap<Badge>,
  badge?: Badge,
};

export default function reducer(state: BadgeState = initialState, action: Action): BadgeState {
  switch (action.type) {
    case C.FETCH_BADGE_FRAMEWORKS_START:
    case C.FETCH_BADGE_FRAMEWORK_START:
      return {
        ...state,
        fetchBadgeFrameworksPending: true,
      };

    case C.FETCH_BADGE_FRAMEWORKS_SUCCESS:
      return {
        ...state,
        badgeFrameworks: action.badgeFrameworks || {},
        badgeFrameworkSections: action.badgeFrameworkSections || {},
        fetchBadgeFrameworksPending: false,
      };
    case C.FETCH_BADGE_FRAMEWORK_SUCCESS:
      return {
        ...state,
        badgeFrameworks: $.addOrReplaceObjectWithObjects(state.badgeFrameworks, action.badgeFrameworks) || {},
        badgeFrameworkSections: $.addOrReplaceObjectWithObjects(state.badgeFrameworkSections, action.badgeFrameworkSections) || {},
        fetchBadgeFrameworksPending: false,
      };

    case C.FETCH_BADGE_FRAMEWORKS_FAIL:
    case C.FETCH_BADGE_FRAMEWORK_FAIL:
      return {
        ...state,
        fetchBadgeFrameworksPending: false,
      };

    case C.PREPARE_BADGE_FRAMEWORK:
      return {
        ...state,
        preparedBadgeFramework: action.badgeFramework,
      };

    case C.BEGIN_CREATE_BADGE_FRAMEWORK:
      return {
        ...state,
        isCreatingBadgeFramework: true,
      };

    case C.ABORT_CREATE_BADGE_FRAMEWORK:
      return {
        ...state,
        isCreatingBadgeFramework: false,
        preparedBadgeFramework: {},
      };

    case C.CREATE_BADGE_FRAMEWORK_START:
      return {
        ...state,
        isUpdatingBadgeFramework: true,
      };

    case C.CREATE_BADGE_FRAMEWORK_SUCCESS:
      return {
        ...state,
        isCreatingBadgeFramework: false,
        isUpdatingBadgeFramework: false,
        preparedBadgeFramework: {},
        badgeFrameworks: $.addOrUpdateObjectWithObjects(state.badgeFrameworks || {}, action.badgeFrameworks || {}),
        badgeFrameworkSections: $.addOrUpdateObjectWithObjects(state.badgeFrameworkSections || {}, action.badgeFrameworkSections || {}),
      };

    case C.CREATE_BADGE_FRAMEWORK_FAIL:
      return {
        ...state,
        isCreatingBadgeFramework: false,
        isUpdatingBadgeFramework: false,
        preparedBadgeFramework: {},
      };

    case C.DELETE_BADGE_FRAMEWORK_START:
      return {
        ...state,
      };

    case C.DELETE_BADGE_FRAMEWORK_SUCCESS:
      return {
        ...state,
        badgeFrameworks: $.removeObjectFromObjectById(state.badgeFrameworks, action.badgeFrameworkId),
      };

    case C.DELETE_BADGE_FRAMEWORK_FAIL:
      return {
        ...state,
      };

    case C.UPDATE_BADGE_FRAMEWORK_START:
      return {
        ...state,
        isUpdatingBadgeFramework: true,
      };
    case C.UPDATE_BADGE_FRAMEWORK_SUCCESS:
      return {
        ...state,
        isUpdatingBadgeFramework: false,
        preparedBadgeFramework: {},
        badgeFrameworks: $.addOrReplaceObjectWithObjects(state.badgeFrameworks, action.badgeFrameworks) || {},
        badgeFrameworkSections: $.addOrReplaceObjectWithObjects(state.badgeFrameworkSections, action.badgeFrameworkSections) || {},
      };
    case C.UPDATE_BADGE_FRAMEWORK_FAIL:
      return {
        ...state,
        isUpdatingBadgeFramework: false,
      };

    case C.FETCH_OBJECT_TYPES_SUCCESS:
    case C.UPDATE_OBJECT_TYPE_SUCCESS:
      return {
        ...state,
        badgeTypes: $.addOrReplaceObjectWithObjects(state.badgeTypes, action.badgeTypes),
        badgeFrameworks: $.addOrReplaceObjectWithObjects(state.badgeFrameworks, action.badgeFrameworks) || {},
        badgeFrameworkSections: $.addOrReplaceObjectWithObjects(state.badgeFrameworkSections, action.badgeFrameworkSections) || {},
      };

    case C.PREPARE_BADGE:
      return {
        ...state,
        preparedBadge: action.badge,
      };

    case C.BEGIN_CREATE_BADGE:
      return {
        ...state,
        isCreatingBadge: true,
      };

    case C.ABORT_CREATE_BADGE:
      return {
        ...state,
        isCreatingBadge: false,
        preparedBadge: {},
      };

    case C.CREATE_BADGE_START:
      return {
        ...state,
        isUpdatingBadge: true,
      };

    case C.CREATE_BADGE_SUCCESS:
      return {
        ...state,
        isCreatingBadge: false,
        isUpdatingBadge: false,
        preparedBadge: {},
        badges: $.addOrUpdateObjectWithObjects(state.badges || {}, action.badges || {}),
      };

    case C.CREATE_BADGE_FAIL:
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        isCreatingBadge: false,
        isUpdatingBadge: false,
        preparedBadge: {},
      };

    default:
      return state;
  }
}
