import moment from 'moment-timezone';

// @flow

type Day = {
  date?: moment.Moment,
};

type Week = Array<Day>;

export const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const weekdays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
export const hours = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '00'];
export const amPmHours = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
export const am = 'am';
export const pm = 'pm';

export const hoursWithOffset = (offset: number): Array<string> =>
  hours.slice(offset).concat(hours.slice(0, offset));

export const offsetDate = (date: moment.Moment, offset: number = 0): moment.Moment => {
  const newDate = moment.unix(date.unix());
  return newDate.subtract(offset, 'hours');
};

export const getLengthOfMonth = (date: momnet.Moment): number =>
  date.daysInMonth();

export const getLastDayOfMonth = (date: moment.Moment): moment.Moment =>
  date.endOf('months');

export const getFirstDayOfMonth = (date: moment.Moment): moment.Moment =>
  date.startOf('months');

export const getFirstDayOfNextMonth = (date: moment.Moment): moment.Moment =>
  moment(date).add(1, 'months').startOf('months');

export const getFirstDayOfPreviousMonth = (date: moment.Moment): moment.Moment =>
  moment(date).subtract(1, 'months').startOf('months');

export const getFirstDayOfYear = (date: moment.Moment): moment.Moment =>
  date.startOf('year');

export const getPaddedDaysOfMonth = (date: moment.Moment): Array<Day> => {
  const weekday = getFirstDayOfMonth(date).day();
  const lastWeekday = getLastDayOfMonth(date).day() || 7;
  const days = [];
  const length = getLengthOfMonth(date);

  if(weekday === 0) {
    for (let i = 0; i < 6; i += 1) {
      days.push({});
    }
  } else {
    for (let i = 0; i < weekday - 1; i += 1) {
      days.push({});
    }
  }

  for (let i = 0; i < length; i += 1) {
    days.push({ date: moment({ year: date.year(), month: date.month(), day: i + 1 }) });
  }

  for (let i = 0; i < 7 - lastWeekday; i += 1) {
    days.push({});
  }
  return days;
};

export const getWeeksOfMonth = (date: moment.Moment): Array<Week> => {
  const newDate = moment(date);
  const weeks = [];
  const days = getPaddedDaysOfMonth(newDate);
  for(let i = 0; i < days.length; i += 7) {
    weeks.push(days.slice(i, i + 7));
  }
  return weeks;
};

export const isSameDay = (first: ?moment.Moment, last: ?moment.Moment): boolean =>
  !!first &&
  !!last &&
  first.isSame(last, 'date');

export const isSameDateTime = (first: ?moment.Moment, last: ?moment.Moment): boolean =>
  !!first &&
  !!last &&
  first.isSame(last, 'second');

export const isSameTime = (first: ?moment.Moment, last: ?moment.Moment): boolean =>
  !!first &&
  !!last &&
  first.isSame(last, 'minute');

export const dayInMonth = (date1: moment.Moment, date2: moment.Moment): boolean =>
  !!date1 &&
  !!date2 &&
  date1.isSame(date2, 'month');

export const isOverlapping = (start1: moment.Moment, end1: moment.Moment, start2: moment.Moment, end2: moment.Moment): boolean => (
  (start1 < start2 && start2 < end1) ||
  (start2 < start1 && start1 < end2) ||
  (start1 === start2));

export const isTimeInInterval = (start: time, end: time, intervalStart: time, intervalEnd: time): boolean => {
  if(!intervalStart && !intervalEnd) return true;
  if(!intervalEnd) return (start > intervalStart);
  if(!intervalStart) return (end < intervalEnd);
  return (
    start > intervalStart && start < intervalEnd && end > intervalStart && end < intervalEnd
  );
};

export const isDateTimeInInterval = (start: moment.Moment, end: moment.Moment, intervalStart: moment.Moment, intervalEnd: moment.Moment): boolean => {
  if(!intervalStart && !intervalEnd) return true;
  if(!intervalEnd) return (start > intervalStart);
  if(!intervalStart) return (end < intervalEnd);
  return (
    start > intervalStart && start < intervalEnd && end > intervalStart && end < intervalEnd
  );
};

export const dayInInterval = (day: moment.Moment, start: moment.Moment, stop: moment.Moment): boolean =>
  isSameDay(day, start) || isSameDay(day, stop) || (day > start && day < stop);

export const getSecondsElapsedOfDay = (date: moment.Moment, offset: ?number = null): number => {
  const paddedDate = offset ? offsetDate(date, offset) : date;
  return (paddedDate.hour() * 60 * 60) + (paddedDate.minute() * 60) + paddedDate.second();
};

export const getIntervalLength = (start: Date, end: Date) =>
  Math.abs(end.getTime() - start.getTime());

/**
 * Return the time as a percentage of a day
 */
export const getPercentageOfDay = (date: moment.Moment, offset: ?number = null) =>
  (getSecondsElapsedOfDay(date, offset) / 86400) * 100;

export const pad = (number: number): string =>
  (number < 10 ? `0${number}` : `${number}`);

export const enumerateDaysBetweenDates = (startDate: moment.Moment, endDate: moment.Moment) => {
  const days = [];
  if(isSameDay(startDate, endDate)) {
    days.push(startDate.format('YYYY-MM-DDTHH:mm:ss.SSSSSZ'));
  }
  const startHours = startDate.get('hour');
  const startMinutes = startDate.get('minute');
  const currDate = startDate.clone().startOf('day');
  const lastDate = endDate.clone().startOf('day');
  days.push(startDate.format('YYYY-MM-DDTHH:mm:ss.SSSSSZ'));
  if(startDate.diff(endDate, 'days') > 1) {
    while(currDate.subtract(1, 'days').diff(lastDate) > 0) {
      const intermediateDate = currDate.clone();
      intermediateDate.set({ hour: startHours, minute: startMinutes });
      days.push(intermediateDate.format('YYYY-MM-DDTHH:mm:ss.SSSSSZ'));
    }
  } else if(endDate.diff(startDate, 'days') > 1) {
    while(currDate.add(1, 'days').diff(lastDate) < 0) {
      const intermediateDate = currDate.clone();
      intermediateDate.set({ hour: startHours, minute: startMinutes });
      days.push(intermediateDate.format('YYYY-MM-DDTHH:mm:ss.SSSSSZ'));
    }
  }
  days.push(endDate.format('YYYY-MM-DDTHH:mm:ss.SSSSSZ'));
  return days;
};

export const enumerateMinutesBetweenDates = (startDate: moment.Moment, endDate: moment.Moment) => {
  const minutes = [];
  const currDate = startDate.startOf('minute');
  const lastDate = endDate.startOf('minute');
  minutes.push(startDate);
  while(currDate.add(1, 'minutes').diff(lastDate) < 0) {
    const intermediateDate = currDate.clone();
    minutes.push(intermediateDate);
  }
  minutes.push(endDate);
  return minutes;
};

/**
 * '12:00'
 */
// export const prettyTime = (date: Date): string =>
//   `${pad(date.getHours())}:${pad(date.getMinutes())}`;
export const prettyTime = (date: moment.Moment): string =>
  moment(date).format('HH:mm');

/**
 * '12:00 am'
 */
export const prettyAmPmTime = (date: moment.Moment): string =>
  moment(date).format('h:mm a');

export const getWeekNumber = (date: moment.Moment): number =>
  date.isoWeek();

export const getWeek = (date: moment.Moment): Week => {
  let firstDateOfWeek = moment({ year: date.year(), month: date.month(), day: date.date() });
  firstDateOfWeek = firstDateOfWeek.subtract(date.day() - 1, 'days');
  const week = [];
  for (let i = 0; i < 7; i += 1) {
    let newDate = moment({ year: firstDateOfWeek.year(), month: firstDateOfWeek.month(), day: firstDateOfWeek.date() });
    newDate = newDate.add(i, 'days');
    week.push({ date: newDate });
  }
  return week;
};

export const getWeekday = (date: moment.Moment): string =>
  ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'][date.day()];

export const getFullWeekday = (date: Date): string =>
  ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][date.getDay()];

export const getFullWeekdayByIndex = (index: number): string =>
  ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'][index];

/**
 * 'January'
 */
export const prettyMonth = (date: moment.Moment): string =>
  moment(date).format('MMMM');

/**
 * 'Sunday January 01'
 */
export const prettyDate = (date: moment.Moment): String =>
  moment(date).format('dddd MMMM D');

/**
 * 'January 1'
 */
export const prettyMonthAndDay = (date: moment.Moment): String =>
  moment(date).format('MMM D');

export const momentPrettyDate = (date: any, timezone: string): string => {
  const d = moment(date).tz(timezone);
  return `${d.format('dddd MMMM DD')}`;
};

// export const YMD = (date: Date): string =>
//   (date ? `${date.toISOString().substring(0, 10)}` : '');


export const YMD = (date: moment.Moment): string =>
  (date ? `${moment(date).format().substring(0, 10)}` : '');

/**
 * '2018/01/01'
 */
export const yyyymmdd = (date: moment.Moment): string =>
  moment(date).format('YYYY/MM/DD');

/**
 * 'Sun 2018/01/01'
 */
export const yyyymmddWithWeekday = (date: Date): string =>
  moment(date).format('dddd YYYY/MM/DD');

/**
 * 'Sun 2018/01/01'
 */
export const yyyymm = (date: moment.Moment): string =>
  moment(date).format('YYYY-MM');

// export const renderPrettyTime = (time: Date, amPmFormat?: boolean) => {
//   if(amPmFormat) return prettyAmPmTime(time);
//   return prettyTime(time);
// };

export const renderPrettyTime = (time: moment.Moment, amPmFormat?: boolean) => {
  if(amPmFormat) return prettyAmPmTime(time);
  return prettyTime(time);
};

export const renderTimezone = (time: any, amPmFormat?: boolean) => {
  if(amPmFormat) return time.format('h:mm A');
  return time.format('HH:mm');
};

/**
 * '2018/01/01 12:00' // '2018/01/01 12:00 AM
 */
export const yyyymmddhhmm = (date: moment.Moment, amPmFormat?: boolean): string =>
  `${date.format('YYYY/MM/D')} ${renderPrettyTime(date, amPmFormat)}`;

export const applyTimezone = (date: string, timezone: string): any =>
  moment(date).tz(timezone);

const prettyDateWithYear = (date: moment.Moment): string =>
  date.format('LL');

export const prettyDateWithYearMaybe = (date: moment.Moment) => {
  if(moment().year() === date.year()) {
    return prettyDate(date);
  }
  return prettyDateWithYear(date);
};

export const getStartTime = e => (e.schedule && e.schedule.id && e.schedule.start_time) || (e.dateObject && e.dateObject.schedule && e.dateObject.schedule.start_time) || (e.primary_date_fields && e.primary_date_fields.length && e.primary_date_fields[0].schedule && e.primary_date_fields[0].schedule.start_time); // TODO Fix so all dates shows
export const getEndTime = e => (e.schedule && e.schedule.id && e.schedule.end_time) || (e.dateObject && e.dateObject.schedule && e.dateObject.schedule.end_time) || (e.primary_date_fields && e.primary_date_fields.length && e.primary_date_fields[0].schedule && e.primary_date_fields[0].schedule.end_time);


export const isDate = (date: string) => {
  if (date) {
    return moment.isDate(new Date(date));
  }
  return false;
};

export const getClientProjectOffsetHoursForDate = (date: Date): number => {
  const clientTimezone = window.clientTimezone || moment.tz.guess();
  const d = moment(date);
  const projectOffset = d.utcOffset();
  const clientOffset = moment.tz(d.utc(), clientTimezone).utcOffset();
  return (projectOffset - clientOffset) / 60;
};

/*
* The following functions are used in rendering loops since moment can be very slow.
*/

export const addHoursNative = (date: Date) =>
  date.setTime(date.getTime() + (getClientProjectOffsetHoursForDate(date) * 60 * 60 * 1000));

export const isSameDayNative = (first: ?Date, last: ?Date): boolean => {
  addHoursNative(first);
  addHoursNative(last);
  return (
    !!first &&
    !!last &&
    first.getFullYear() === last.getFullYear() &&
    first.getMonth() === last.getMonth() &&
    first.getDate() === last.getDate()
  );
};

export const dayInIntervalNative = (day: Date, start: Date, stop?: Date): boolean => {
  addHoursNative(day);
  addHoursNative(start);
  if(stop) addHoursNative(stop, getClientProjectOffsetHoursForDate(stop));
  return isSameDayNative(day, start) || (stop && isSameDayNative(day, stop)) || (day > start && day < stop);
};

export const isOverlappingNative = (start1: Date, end1: Date, start2: Date, end2: Date): boolean => (
  (start1 < start2 && start2 < end1) ||
  (start2 < start1 && start1 < end2) ||
  isSameDayNative(start1, start2));

export const offsetDateNative = (date: Date, offset: number = 0): Date => {
  addHoursNative(date, getClientProjectOffsetHoursForDate(date) + offset);
  return date;
};
