import C from '../constants/constants';
import $ from '../../libs/reduxOperations';


export const initialState = {
  pendingFiles: [],
  currentPreviewFile: null,
  isUploading: false,
  isUpdating: false,
  selectedFiles: [],
  files: {},
  nextPage: 1,
  currentPage: 1,
  paginationReachedBottom: false,
  currentFileId: null,
  showingImageLibrary: false,
  showingImageCropper: false,
  pageSize: 50,
  didCrop: false,
};

const didPaginationReachedBottom = (paginationReachedBottom, files, reset) => {
  if(reset) return false;
  if(!files.length) return true;
  return paginationReachedBottom;
};

const addOrUpdateByTempId = (files, file) => {
  const index = files.findIndex(f => f.tempId === file.tempId);
  const newFiles = files.slice();
  if(index !== -1) {
    newFiles.splice(index, 1, file);
  } else {
    newFiles.push(file);
  }
  return newFiles;
};

export default function fileReducer(state = initialState, action) {
  switch (action.type) {
    case C.FILE_UPLOAD_PENDING:
      return {
        ...state,
        pendingFiles: addOrUpdateByTempId(state.pendingFiles, action.file),
      };

    case C.FILE_PREVIEW_PENDING:
      return {
        ...state,
        currentPreviewFile: action.file,
      };


    case C.FILE_PREVIEW_CLEAR:
      return {
        ...state,
        currentPreviewFile: null,
      };

    case C.FILE_UPLOAD_CANCELED:
      return {
        ...state,
        pendingFiles: $.removeFromArray(state.pendingFiles, action.file),
        isUploading: false,
        didCrop: false,
      };

    case C.FILE_UPLOAD_START:
      return {
        ...state,
        isUploading: true,
      };

    case C.FILE_UPLOAD_FAIL:
      return {
        ...state,
        isUploading: false,
      };

    case C.FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        pendingFiles: $.removeFromArrayByKey(state.pendingFiles, { ...action.file, tempId: action.tempId }, 'tempId'),
        files: $.addOrUpdateObjectWithObjects(state.files, action.files),
        isUploading: false,
      };

    case C.FILE_UPDATE_START:
      return {
        ...state,
        isUpdating: true,
        pending: true,
      };

    case C.FILE_UPDATE_FAIL:
      return {
        ...state,
        isUpdating: false,
        pending: false,
      };

    case C.FILE_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        pendingFiles: action.crop ? [action.file] : $.removeFromArrayByKey(state.pendingFiles, action.file, 'id'),
        files: $.addOrUpdateObjectWithObjects(state.files, action.files),
        didCrop: action.crop,
        pending: action.crop,
      };

    case C.FILE_UPDATE_META_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        pendingFiles: action.crop ? [action.file] : $.removeFromArrayByKey(state.pendingFiles, action.file, 'id'),
        files: $.addOrUpdateObjectWithObjects(state.files, action.files),
        didCrop: action.crop,
        pending: action.crop,
      };

    case C.FETCH_FILE_START:
    case C.FETCH_FILES_START:
    case C.DELETE_FILE_START:
      return {
        ...state,
        pending: true,
      };

    case C.FETCH_POSTS_SUCCESS:
    case C.FETCH_POST_SUCCESS:
      return {
        ...state,
        files: { ...state.files, ...action.files },
      };

    case C.FETCH_FILES_SUCCESS:
      return {
        ...state,
        pending: false,
        files: action.reset ? (action.files && Object.values(action.files)) : $.addOrUpdateObjectWithObjects(state.files, action.files), // TODO is this array or object!?
        nextPage: state.nextPage + 1,
        paginationReachedBottom: !action.files || !Object.values(action.files).length,
      };

    case C.FETCH_FILE_SUCCESS:
      return {
        ...state,
        pending: false,
        files: action.reset ? (action.files && Object.values(action.files)) : $.addOrUpdateObjectWithObjects(state.files, action.files),
        pendingFiles: action.files && Object.values(action.files).length ? addOrUpdateByTempId(state.pendingFiles, Object.values(action.files)[0]) : [],
      };

    case C.FETCH_FILE_FAIL:
    case C.FETCH_FILES_FAIL:
    case C.DELETE_FILE_FAIL:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    case C.TOGGLE_SELECT_FILE:
      return {
        ...state,
        selectedFiles: $.addOrRemoveFromArrayById(state.selectedFiles, action.file),
      };

    case C.SELECT_FILE:
      return {
        ...state,
        selectedFiles: $.addToArrayById(state.selectedFiles, action.file),
      };

    case C.DESELECT_FILE:
      return {
        ...state,
        selectedFiles: $.removeFromArrayById(state.selectedFiles, action.file),
      };

    case C.DELETE_FILE_SUCCESS:
      return {
        ...state,
        selectedFiles: $.removeFromArrayById(state.selectedFiles, action.file),
        files: $.removeObjectFromObjectById(state.files, (action.file && action.file.id)),
      };

    case C.FETCH_OBJECT_SUCCESS:
    case C.FETCH_OBJECTS_SUCCESS:
      return {
        ...state,
        files: { ...state.files, ...action.files },
      };

    case C.EDIT_FILE:
      return {
        ...state,
        currentFileId: action.currentFileId,
      };

    case C.SHOW_IMAGE_LIBRARY:
      return {
        ...state,
        showingImageLibrary: true,
      };

    case C.HIDE_IMAGE_LIBRARY:
      return {
        ...state,
        showingImageLibrary: false,
      };

    case C.SHOW_IMAGE_CROPPER:
      return {
        ...state,
        showingImageCropper: true,
        didCrop: false,
      };

    case C.HIDE_IMAGE_CROPPER:
      return {
        ...state,
        showingImageCropper: false,
      };

    case C.FETCH_FILES_LOCAL:
      return {
        ...state,
        nextPage: action.reset ? 1 : state.nextPage + 1,
        currentPage: state.nextPage,
        paginationReachedBottom: didPaginationReachedBottom(
          state.paginationReachedBottom,
          Object.values(state.files) ? Object.values(state.files).slice(state.nextPage * state.pageSize, (state.nextPage + 1) * state.pageSize) : [],
          action.reset,
        ),
      };

    case C.FETCH_FILES_INDEX_SUCCESS:
      return {
        ...state,
        files: $.addOrUpdateObjectWithObjects(state.files, action.files),
      };

    case C.UPDATE_OBJECT_DUPLICATE_SUCCESS:
      return {
        ...state,
        files: $.addOrUpdateObjectWithObjects(state.files, action.files),
      };

    default:
      return state;
  }
}
