// @flow
const C = {
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  FILE_UPLOAD_PENDING: 'FILE_UPLOAD_PENDING',
  FILE_PREVIEW_PENDING: 'FILE_PREVIEW_PENDING',
  FILE_PREVIEW_CLEAR: 'FILE_PREVIEW_CLEAR',
  FILE_UPLOAD_START: 'FILE_UPLOAD_START',
  FILE_UPLOAD_SUCCESS: 'FILE_UPLOAD_SUCCESS',
  FILE_UPLOAD_FAIL: 'FILE_UPLOAD_FAIL',
  FILE_UPLOAD_CANCELED: 'FILE_UPLOAD_CANCELED',
  FETCH_USER_CLIENT_START: 'FETCH_USER_CLIENT_START',
  FETCH_USER_CLIENT_SUCCESS: 'FETCH_USER_CLIENT_SUCCESS',
  FETCH_USER_CLIENT_FAIL: 'FETCH_USER_CLIENT_FAIL',
  SET_EMAIL: 'SET_EMAIL',
  SELECT_OBJECT: 'SELECT_OBJECT',
  DESELECT_OBJECT: 'DESELECT_OBJECT',
  FETCH_OBJECTS: 'FETCH_OBJECTS',
  CREATE_OBJECT: 'CREATE_OBJECT',
  FETCH_OBJECTS_START: 'FETCH_OBJECTS_START',
  FETCH_OBJECTS_SUCCESS: 'FETCH_OBJECTS_SUCCESS',
  FETCH_OBJECTS_FAIL: 'FETCH_OBJECTS_FAIL',
  FETCH_OBJECT_COUNTS_START: 'FETCH_OBJECT_COUNTS_START',
  FETCH_OBJECT_COUNTS_SUCCESS: 'FETCH_OBJECT_COUNTS_SUCCESS',
  TOGGLE_SELECT_OBJECT: 'TOGGLE_SELECT_OBJECT',
  FETCH_OBJECT_TYPES_START: 'FETCH_OBJECT_TYPES_START',
  FETCH_OBJECT_TYPES_SUCCESS: 'FETCH_OBJECT_TYPES_SUCCESS',
  FETCH_OBJECT_TYPES_FAIL: 'FETCH_OBJECT_TYPES_FAIL',
  FETCH_OBJECT_START: 'FETCH_OBJECT_START',
  FETCH_OBJECT_SUCCESS: 'FETCH_OBJECT_SUCCESS',
  FETCH_OBJECT_FAIL: 'FETCH_OBJECT_FAIL',
  CREATE_OBJECT_START: 'CREATE_OBJECT_START',
  CREATE_OBJECT_SUCCESS: 'CREATE_OBJECT_SUCCESS',
  CREATE_OBJECT_FAIL: 'CREATE_OBJECT_FAIL',
  CREATE_OBJECT_DIRECTLY_START: 'CREATE_OBJECT_DIRECTLY_START',
  CREATE_OBJECT_DIRECTLY_SUCCESS: 'CREATE_OBJECT_DIRECTLY_SUCCESS',
  CREATE_OBJECT_DIRECTLY_FAIL: 'CREATE_OBJECT_DIRECTLY_FAIL',
  UPDATE_OBJECT_START: 'UPDATE_OBJECT_START',
  UPDATE_OBJECT_SUCCESS: 'UPDATE_OBJECT_SUCCESS',
  UPDATE_OBJECT_FAIL: 'UPDATE_OBJECT_FAIL',
  UPDATE_OBJECT: 'UPDATE_OBJECT',
  UPDATE_OR_CREATE_BLOCK_START: 'UPDATE_OR_CREATE_BLOCK_START',
  UPDATE_OR_CREATE_BLOCK: 'UPDATE_OR_CREATE_BLOCK',
  UPDATE_BLOCK_START: 'UPDATE_BLOCK_START',
  UPDATE_BLOCK_SUCCESS: 'UPDATE_BLOCK_SUCCESS',
  UPDATE_BLOCK_FAIL: 'UPDATE_BLOCK_FAIL',
  CREATE_BLOCK_START: 'CREATE_BLOCK_START',
  CREATE_BLOCK_SUCCESS: 'CREATE_BLOCK_SUCCESS',
  CREATE_BLOCK_FAIL: 'CREATE_BLOCK_FAIL',
  BASE_OBJECT_DID_UPDATE: 'BASE_OBJECT_DID_UPDATE',
  CREATE_SUB_OBJECT_START: 'CREATE_SUB_OBJECT_START',
  CREATE_SUB_OBJECT_SUCCESS: 'CREATE_SUB_OBJECT_SUCCESS',
  CREATE_SUB_OBJECT_FAIL: 'CREATE_SUB_OBJECT_FAIL',
  CREATE_AND_INSERT_SUB_OBJECT_START: 'CREATE_AND_INSERT_SUB_OBJECT_START',
  CREATE_AND_INSERT_SUB_OBJECT_SUCCESS: 'CREATE_AND_INSERT_SUB_OBJECT_SUCCESS',
  CREATE_AND_INSERT_SUB_OBJECT_FAIL: 'CREATE_AND_INSERT_SUB_OBJECT_FAIL',
  INSERT_SUB_OBJECT_START: 'INSERT_SUB_OBJECT_START',
  INSERT_SUB_OBJECT_SUCCESS: 'INSERT_SUB_OBJECT_SUCCESS',
  PREPARE_FIELD: 'PREPARE_FIELD',
  ADD_SUB_OBJECT: 'ADD_SUB_OBJECT',
  CREATE_SUB_OBJECT: 'CREATE_SUB_OBJECT',
  CLOSE_CREATE_FORM: 'CLOSE_CREATE_FORM',
  FIELD_ADD_OR_REMOVE_OBJECT: 'FIELD_ADD_OR_REMOVE_OBJECT',
  BEGIN_CREATE_OBJECT: 'BEGIN_CREATE_OBJECT',
  ABORT_CREATE_OBJECT: 'ABORT_CREATE_OBJECT',
  ACTIVATE_FIELDS_EDIT_MODE: 'ACTIVATE_FIELDS_EDIT_MODE',
  DEACTIVATE_FIELDS_EDIT_MODE: 'DEACTIVATE_FIELDS_EDIT_MODE',
  LOGOUT: 'LOGOUT',
  SET_THEME: 'SET_THEME',
  DELETE_OBJECT_START: 'DELETE_OBJECT_START',
  DELETE_OBJECT_SUCCESS: 'DELETE_OBJECT_SUCCESS',
  DELETE_OBJECT_FAIL: 'DELETE_OBJECT_FAIL',
  FETCH_PEOPLE_START: 'FETCH_PEOPLE_START',
  FETCH_PERSON_START: 'FETCH_PERSON_START',
  CREATE_PERSON_START: 'CREATE_PERSON_START',
  UPDATE_PERSON_START: 'UPDATE_PERSON_START',
  DELETE_PERSON_START: 'DELETE_PERSON_START',
  NOTIFY_PEOPLE_START: 'NOTIFY_PEOPLE_START',
  FETCH_PEOPLE_FAIL: 'FETCH_PEOPLE_FAIL',
  FETCH_PERSON_FAIL: 'FETCH_PERSON_FAIL',
  CREATE_PERSON_FAIL: 'CREATE_PERSON_FAIL',
  UPDATE_PERSON_FAIL: 'UPDATE_PERSON_FAIL',
  DELETE_PERSON_FAIL: 'DELETE_PERSON_FAIL',
  NOTIFY_PEOPLE_FAIL: 'NOTIFY_PEOPLE_FAIL',
  FETCH_PEOPLE_SUCCESS: 'FETCH_PEOPLE_SUCCESS',
  FETCH_PERSON_SUCCESS: 'FETCH_PERSON_SUCCESS',
  CREATE_PEOPLE_SUCCESS: 'CREATE_PEOPLE_SUCCESS',
  UPDATE_PEOPLE_SUCCESS: 'UPDATE_PEOPLE_SUCCESS',
  DELETE_PEOPLE_SUCCESS: 'DELETE_PEOPLE_SUCCESS',
  NOTIFY_PEOPLE_SUCCESS: 'NOTIFY_PEOPLE_SUCCESS',
  CREATE_TAG_START: 'CREATE_TAG_START',
  CREATE_TAG_SUCCESS: 'CREATE_TAG_SUCCESS',
  CREATE_TAG_FAIL: 'CREATE_TAG_FAIL',
  AUTOCOMPLETE_TAGS_START: 'AUTOCOMPLETE_TAGS_START',
  AUTOCOMPLETE_TAGS_FAIL: 'AUTOCOMPLETE_TAGS_FAIL',
  AUTOCOMPLETE_TAGS_SUCCESS: 'AUTOCOMPLETE_TAGS_SUCCESS',
  AUTOCOMPLETE_TAGS: 'AUTOCOMPLETE_TAGS',
  CREATE_PERSON_SUCCESS: 'CREATE_PERSON_SUCCESS',
  UPDATE_PERSON_SUCCESS: 'UPDATE_PERSON_SUCCESS',
  DELETE_PERSON_SUCCESS: 'DELETE_PERSON_SUCCESS',
  BEGIN_EDIT_PERSON: 'BEGIN_EDIT_PERSON',
  ABORT_EDIT_PERSON: 'ABORT_EDIT_PERSON',
  FETCH_TASKS_START: 'FETCH_TASKS_START',
  FETCH_TASK_START: 'FETCH_TASK_START',
  CREATE_TASK_START: 'CREATE_TASK_START',
  UPDATE_TASK_START: 'UPDATE_TASK_START',
  DELETE_TASK_START: 'DELETE_TASK_START',
  FETCH_TASKS_FAIL: 'FETCH_TASKS_FAIL',
  FETCH_TASK_FAIL: 'FETCH_TASK_FAIL',
  CREATE_TASK_FAIL: 'CREATE_TASK_FAIL',
  UPDATE_TASK_FAIL: 'UPDATE_TASK_FAIL',
  DELETE_TASK_FAIL: 'DELETE_TASK_FAIL',
  FETCH_TASKS_SUCCESS: 'FETCH_TASKS_SUCCESS',
  FETCH_TASK_SUCCESS: 'FETCH_TASK_SUCCESS',
  CREATE_TASK_SUCCESS: 'CREATE_TASK_SUCCESS',
  UPDATE_TASK_SUCCESS: 'UPDATE_TASK_SUCCESS',
  DELETE_TASK_SUCCESS: 'DELETE_TASK_SUCCESS',
  ASSIGN_TASKS_START: 'ASSIGN_TASKS_START',
  ASSIGN_TASKS_SUCCESS: 'ASSIGN_TASKS_SUCCESS',
  ASSIGN_TASKS_FAIL: 'ASSIGN_TASKS_FAIL',
  FETCH_STAFF_START: 'FETCH_STAFF_START',
  FETCH_STAFF_SUCCESS: 'FETCH_STAFF_SUCCESS',
  FETCH_STAFF_FAIL: 'FETCH_STAFF_FAIL',
  CLEAR_SELECT_TASK: 'CLEAR_SELECT_TASK',
  SELECT_TASK: 'SELECT_TASK',
  DESELECT_TASK: 'DESELECT_TASK',
  TOGGLE_SELECT_TASK: 'TOGGLE_SELECT_TASK',
  BEGIN_EDIT_TASK: 'BEGIN_EDIT_TASK',
  ABORT_EDIT_TASK: 'ABORT_EDIT_TASK',
  BEGIN_VIEWING_TASK: 'BEGIN_VIEWING_TASK',
  ABORT_VIEWING_TASK: 'ABORT_VIEWING_TASK',
  BEGIN_COMPLETING_TASK: 'BEGIN_COMPLETING_TASK',
  ABORT_COMPLETING_TASK: 'ABORT_COMPLETING_TASK',
  TOGGLE_ASSIGN_MODE: 'TOGGLE_ASSIGN_MODE',
  BASE_FIELD: 'BASE_FIELD',
  PEOPLE: 'PEOPLE',
  TASKS: 'TASKS',
  STAFF: 'STAFF',
  SCHEDULES: 'SCHEDULES',
  PREPARE_PERSON: 'PREPARE_PERSON',
  PREPARE_TASK: 'PREPARE_TASK',
  PREPARE_SUBTASK: 'PREPARE_SUBTASK',
  BEGIN_EDIT_SUBTASK: 'BEGIN_EDIT_SUBTASK',
  ABORT_EDIT_SUBTASK: 'ABORT_EDIT_SUBTASK',
  CONFIRM_EDIT_SUBTASK: 'CONFIRM_EDIT_SUBTASK',
  CLEAR_SELECT_PEOPLE: 'CLEAR_SELECT_PEOPLE',
  SELECT_PERSON: 'SELECT_PERSON',
  DESELECT_PERSON: 'DESELECT_PERSON',
  TOGGLE_SELECT_PERSON: 'TOGGLE_SELECT_PERSON',
  BEGIN_VIEWING_PERSON: 'BEGIN_VIEWING_PERSON',
  ABORT_VIEWING_PERSON: 'ABORT_VIEWING_PERSON',
  INCREASE_CURRENT_OBJECT_PAGE: 'INCREASE_CURRENT_OBJECT_PAGE',
  AUTOCOMPLETE_PEOPLE_START: 'AUTOCOMPLETE_PEOPLE_START',
  AUTOCOMPLETE_PEOPLE_FAIL: 'AUTOCOMPLETE_PEOPLE_FAIL',
  AUTOCOMPLETE_PEOPLE_SUCCESS: 'AUTOCOMPLETE_PEOPLE_SUCCESS',
  AUTOCOMPLETE_ORGANIZER_PEOPLE_SUCCESS:
    'AUTOCOMPLETE_ORGANIZER_PEOPLE_SUCCESS',
  AUTOCOMPLETE_ENGAGER_PEOPLE_SUCCESS: 'AUTOCOMPLETE_ENGAGER_PEOPLE_SUCCESS',
  AUTOCOMPLETE_STAFF_START: 'AUTOCOMPLETE_STAFF_START',
  AUTOCOMPLETE_STAFF_FAIL: 'AUTOCOMPLETE_STAFF_FAIL',
  AUTOCOMPLETE_STAFF_SUCCESS: 'AUTOCOMPLETE_STAFF_SUCCESS',
  AUTOCOMPLETE_OBJECTS_START: 'AUTOCOMPLETE_OBJECTS_START',
  AUTOCOMPLETE_OBJECTS_FAIL: 'AUTOCOMPLETE_OBJECTS_FAIL',
  AUTOCOMPLETE_OBJECTS_SUCCESS: 'AUTOCOMPLETE_OBJECTS_SUCCESS',
  ACTIVATE_LIST_MODE: 'ACTIVATE_LIST_MODE',
  ACTIVATE_CALENDAR_MODE: 'ACTIVATE_CALENDAR_MODE',
  LIST: 'LIST',
  CALENDAR: 'CALENDAR',
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  CATEGORY: 'CATEGORY',
  SET_CALENDAR_VIEW: 'SET_CALENDAR_VIEW',
  FETCH_CALENDAR_SCHEDULE_START: 'FETCH_CALENDAR_SCHEDULE_START',
  FETCH_CALENDAR_SCHEDULE_SUCCESS: 'FETCH_CALENDAR_SCHEDULE_SUCCESS',
  FETCH_CALENDAR_SCHEDULE_FAIL: 'FETCH_CALENDAR_SCHEDULE_FAIL',
  FETCH_GLOBAL_SCHEDULE_START: 'FETCH_GLOBAL_SCHEDULE_START',
  FETCH_GLOBAL_SCHEDULE_SUCCESS: 'FETCH_GLOBAL_SCHEDULE_SUCCESS',
  FETCH_GLOBAL_SCHEDULE_FAIL: 'FETCH_GLOBAL_SCHEDULE_FAIL',
  CREATE_SCHEDULE_START: 'CREATE_SCHEDULE_START',
  CREATE_SCHEDULE_SUCCESS: 'CREATE_SCHEDULE_SUCCESS',
  CREATE_SCHEDULE_FAIL: 'CREATE_SCHEDULE_FAIL',
  UPDATE_SCHEDULE_START: 'UPDATE_SCHEDULE_START',
  UPDATE_SCHEDULE_SUCCESS: 'UPDATE_SCHEDULE_SUCCESS',
  UPDATE_SCHEDULE_FAIL: 'UPDATE_SCHEDULE_FAIL',
  DELETE_SCHEDULE_START: 'DELETE_SCHEDULE_START',
  DELETE_SCHEDULE_SUCCESS: 'DELETE_SCHEDULE_SUCCESS',
  DELETE_SCHEDULE_FAIL: 'DELETE_SCHEDULE_FAIL',
  BEGIN_EDIT_SCHEDULE: 'BEGIN_EDIT_SCHEDULE',
  ABORT_EDIT_SCHEDULE: 'ABORT_EDIT_SCHEDULE',
  BEGIN_VIEWING_SCHEDULE: 'BEGIN_VIEWING_SCHEDULE',
  ABORT_VIEWING_SCHEDULE: 'ABORT_VIEWING_SCHEDULE',
  PREPARE_SCHEDULE: 'PREPARE_SCHEDULE',
  FETCH_SCHEDULE_SUCCESS: 'FETCH_SCHEDULE_SUCCESS',
  FETCH_USER_START: 'FETCH_USER_START',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_FAIL: 'FETCH_USER_FAIL',
  FETCH_ACCOUNT_START: 'FETCH_ACCOUNT_START',
  FETCH_ACCOUNT_SUCCESS: 'FETCH_ACCOUNT_SUCCESS',
  FETCH_ACCOUNT_FAIL: 'FETCH_ACCOUNT_FAIL',
  FETCH_PROJECT_SETTINGS_START: 'FETCH_PROJECT_SETTINGS_START',
  FETCH_PROJECT_SETTINGS_SUCCESS: 'FETCH_PROJECT_SETTINGS_SUCCESS',
  FETCH_PROJECT_SETTINGS_FAIL: 'FETCH_PROJECT_SETTINGS_FAIL',
  SET_OBJECT_STATUS_START: 'SET_OBJECT_STATUS_START',
  SET_OBJECT_STATUS_SUCCESS: 'SET_OBJECT_STATUS_SUCCESS',
  SET_OBJECT_STATUS_FAIL: 'SET_OBJECT_STATUS_FAIL',
  SET_FILTER_DEPARTMENTS: 'SET_FILTER_DEPARTMENTS',
  SET_FILTER_STATUS: 'SET_FILTER_STATUS',
  TOGGLE_FILTER_PUBLISHED: 'TOGGLE_FILTER_PUBLISHED',
  SET_FILTER_AVAILABILITY: 'SET_FILTER_AVAILABILITY',
  SET_FILTER_DATES: 'SET_FILTER_DATES',
  SET_FILTER_TAG: 'SET_FILTER_TAG',
  SET_FILTER_ROLES: 'SET_FILTER_ROLES',
  SET_FILTER_CATEGORY: 'SET_FILTER_CATEGORY',
  SET_FILTER_TASKS: 'SET_FILTER_TASKS',
  SET_FILTER_EXACT: 'SET_FILTER_EXACT',
  SET_FILTER_COMPANY: 'SET_FILTER_COMPANY',
  SET_FILTER_TIME: 'SET_FILTER_TIME',
  RESET_FILTER: 'RESET_FILTER',
  TOGGLE_SHOW_LOCATION: 'TOGGLE_SHOW_LOCATION',
  TOGGLE_SHOW_STATUS: 'TOGGLE_SHOW_STATUS',
  SET_PROJECT_START: 'SET_PROJECT_START',
  SET_PROJECT_SUCCESS: 'SET_PROJECT_SUCCESS',
  SET_PROJECT_FAIL: 'SET_PROJECT_FAIL',
  SET_CLIENT_START: 'SET_CLIENT_START',
  SET_CLIENT_SUCCESS: 'SET_CLIENT_SUCCESS',
  SET_CLIENT_FAIL: 'SET_CLIENT_FAIL',
  FILES: 'FILES',
  FETCH_FILES_START: 'FETCH_FILES_START',
  FETCH_FILES_SUCCESS: 'FETCH_FILES_SUCCESS',
  FETCH_FILES_FAIL: 'FETCH_FILES_FAIL',
  FETCH_FILE_START: 'FETCH_FILE_START',
  FETCH_FILE_SUCCESS: 'FETCH_FILE_SUCCESS',
  FETCH_FILE_FAIL: 'FETCH_FILE_FAIL',
  TOGGLE_SELECT_FILE: 'TOGGLE_SELECT_FILE',
  DELETE_FILE_START: 'DELETE_FILE_START',
  DELETE_FILE_SUCCESS: 'DELETE_FILE_SUCCESS',
  DELETE_FILE_FAIL: 'DELETE_FILE_FAIL',
  SELECT_FILE: 'SELECT_FILE',
  DESELECT_FILE: 'DESELECT_FILE',
  SET_CURRENT_OBJECT: 'SET_CURRENT_OBJECT',
  UNSET_CURRENT_OBJECT: 'UNSET_CURRENT_OBJECT',
  FETCH_TAGS_START: 'FETCH_TAGS_START',
  FETCH_TAGS_SUCCESS: 'FETCH_TAGS_SUCCESS',
  FETCH_TAGS_FAIL: 'FETCH_TAGS_FAIL',
  CLOSE_ERROR: 'CLOSE_ERROR',
  VALIDATE_TOKEN_SUCCESS: 'VALIDATE_TOKEN_SUCCESS',
  VALIDATE_TOKEN_START: 'VALIDATE_TOKEN_START',
  VALIDATE_TOKEN_FAIL: 'VALIDATE_TOKEN_FAIL',
  POSTS: 'POSTS',
  FETCH_POSTS_START: 'FETCH_POSTS_START',
  FETCH_POSTS_SUCCESS: 'FETCH_POSTS_SUCCESS',
  FETCH_POSTS_FAIL: 'FETCH_POSTS_FAIL',
  FETCH_POST_START: 'FETCH_POST_START',
  FETCH_POST_SUCCESS: 'FETCH_POST_SUCCESS',
  FETCH_POST_FAIL: 'FETCH_POST_FAIL',
  FETCH_POST_TYPES_START: 'FETCH_POST_TYPES_START',
  FETCH_POST_TYPES_SUCCESS: 'FETCH_POST_TYPES_SUCCESS',
  FETCH_POST_TYPES_FAIL: 'FETCH_POST_TYPES_FAIL',
  PREPARE_POST: 'PREPARE_POST',
  CREATE_POST_START: 'CREATE_POST_START',
  CREATE_POST_SUCCESS: 'CREATE_POST_SUCCESS',
  CREATE_POST_FAIL: 'CREATE_POST_FAIL',
  UPDATE_POST_START: 'UPDATE_POST_START',
  UPDATE_POST_SUCCESS: 'UPDATE_POST_SUCCESS',
  UPDATE_POST_FAIL: 'UPDATE_POST_FAIL',
  VALIDATE_TOKEN_USER_START: 'VALIDATE_TOKEN_USER_START',
  VALIDATE_TOKEN_USER_FAIL: 'VALIDATE_TOKEN_USER_FAIL',
  VALIDATE_TOKEN_USER_SUCCESS: 'VALIDATE_TOKEN_USER_SUCCESS',
  GET_TOKEN_USER_OBJECT_TYPE_START: 'GET_TOKEN_USER_OBJECT_TYPE_START',
  GET_TOKEN_USER_OBJECT_TYPE_FAIL: 'GET_TOKEN_USER_OBJECT_TYPE_FAIL',
  GET_TOKEN_USER_OBJECT_TYPE_SUCCESS: 'GET_TOKEN_USER_OBJECT_TYPE_SUCCESS',
  GET_TOKEN_USER_OBJECT_DATA_START: 'GET_TOKEN_USER_OBJECT_DATA_START',
  GET_TOKEN_USER_OBJECT_DATA_FAIL: 'GET_TOKEN_USER_OBJECT_DATA_FAIL',
  GET_TOKEN_USER_OBJECT_DATA_SUCCESS: 'GET_TOKEN_USER_OBJECT_DATA_SUCCESS',
  SUBMIT_TOKEN_USER_OBJECT_DATA_START: 'SUBMIT_TOKEN_USER_OBJECT_DATA_START',
  SUBMIT_TOKEN_USER_OBJECT_DATA_FAIL: 'SUBMIT_TOKEN_USER_OBJECT_DATA_FAIL',
  SUBMIT_TOKEN_USER_OBJECT_DATA_SUCCESS:
    'SUBMIT_TOKEN_USER_OBJECT_DATA_SUCCESS',
  TOGGLE_TOKEN_USER_ACTIVE_START: 'TOGGLE_TOKEN_USER_ACTIVE_START',
  TOGGLE_TOKEN_USER_ACTIVE_SUCCESS: 'TOGGLE_TOKEN_USER_ACTIVE_SUCCESS',
  TOGGLE_TOKEN_USER_ACTIVE_FAIL: 'TOGGLE_TOKEN_USER_ACTIVE_FAIL',
  FETCH_ROLES_START: 'FETCH_ROLES_START',
  FETCH_ROLES_SUCCESS: 'FETCH_ROLES_SUCCESS',
  FETCH_ROLES_FAIL: 'FETCH_ROLES_FAIL',
  FETCH_DEPARTMENTS_START: 'FETCH_DEPARTMENTS_START',
  FETCH_DEPARTMENTS_SUCCESS: 'FETCH_DEPARTMENTS_SUCCESS',
  FETCH_DEPARTMENTS_FAIL: 'FETCH_DEPARTMENTS_FAIL',
  FETCH_USER_DEPARTMENTS_START: 'FETCH_USER_DEPARTMENTS_START',
  FETCH_USER_DEPARTMENTS_SUCCESS: 'FETCH_USER_DEPARTMENTS_SUCCESS',
  FETCH_USER_DEPARTMENTS_FAIL: 'FETCH_USER_DEPARTMENTS_FAIL',
  FETCH_PERMISSIONS_START: 'FETCH_PERMISSIONS_START',
  FETCH_PERMISSIONS_SUCCESS: 'FETCH_PERMISSIONS_SUCCESS',
  FETCH_PERMISSIONS_FAIL: 'FETCH_PERMISSIONS_FAIL',
  ROLES: 'ROLES',
  DEPARTMENTS: 'DEPARTMENTS',
  PERMISSIONS: 'PERMISSIONS',
  CLOSE_NOTICE: 'CLOSE_NOTICE',
  CREATE: 'CREATE',
  READ: 'READ',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  CHILDREN_CREATE: 'CHILDREN_CREATE',
  CHILDREN_UPDATE: 'CHILDREN_UPDATE',
  CHILDREN_READ: 'CHILDREN_READ',
  CHILDREN_DELETE: 'CHILDREN_DELETE',
  ADD_REQUIRED_FIELD: 'ADD_REQUIRED_FIELD',
  REMOVE_REQUIRED_FIELD: 'REMOVE_REQUIRED_FIELD',
  FIELDS_REQUIRED: 'FIELDS_REQUIRED',
  UNPREPARE_POST: 'UNPREPARE_POST',
  DELETE_POST_START: 'DELETE_POST_START',
  DELETE_POST_SUCCESS: 'DELETE_POST_SUCCESS',
  DELETE_POST_FAIL: 'DELETE_POST_FAIL',
  FETCH_SITE_START: 'FETCH_SITE_START',
  FETCH_SITE_SUCCESS: 'FETCH_SITE_SUCCESS',
  FETCH_SITE_FAIL: 'FETCH_SITE_FAIL',
  UPDATE_SITE_START: 'UPDATE_SITE_START',
  UPDATE_SITE_SUCCESS: 'UPDATE_SITE_SUCCESS',
  UPDATE_SITE_FAIL: 'UPDATE_SITE_FAIL',
  TOGGLE_USER_PERMISSION_START: 'TOGGLE_USER_PERMISSION_START',
  TOGGLE_USER_PERMISSION_SUCCESS: 'TOGGLE_USER_PERMISSION_SUCCESS',
  TOGGLE_USER_PERMISSION_FAIL: 'TOGGLE_USER_PERMISSION_FAIL',
  UPDATE_MENU_START: 'UPDATE_MENU_START',
  UPDATE_MENU_SUCCESS: 'UPDATE_MENU_SUCCESS',
  UPDATE_MENU_FAIL: 'UPDATE_MENU_FAIL',
  CREATE_MENU_START: 'CREATE_MENU_START',
  CREATE_MENU_SUCCESS: 'CREATE_MENU_SUCCESS',
  CREATE_MENU_FAIL: 'CREATE_MENU_FAIL',
  PREPARE_MENU: 'PREPARE_MENU',
  UNPREPARE_MENUS: 'UNPREPARE_MENUS',
  TOKEN_USER_SUBMIT_OBJECT: 'TOKEN_USER_SUBMIT_OBJECT',
  FETCH_PROJECT_START: 'FETCH_PROJECT_START',
  FETCH_PROJECT_SUCCESS: 'FETCH_PROJECT_SUCCESS',
  FETCH_PROJECT_FAIL: 'FETCH_PROJECT_FAIL',
  SET_FILTER_USERS: 'SET_FILTER_USERS',
  SEARCH_GLOBAL_START: 'SEARCH_GLOBAL_START',
  SEARCH_GLOBAL_SUCCESS: 'SEARCH_GLOBAL_SUCCESS',
  SEARCH_GLOBAL_FAIL: 'SEARCH_GLOBAL_FAIL',
  CLEAR_SEARCH: 'CLEAR_SEARCH',
  PREPARE_BLOCK: 'PREPARE_BLOCK',
  ORDER_BY_PRIMARY_FIELD: 'ORDER_BY_PRIMARY_FIELD',
  ORDER_BY_PRIMARY_TAGS: 'ORDER_BY_PRIMARY_TAGS',
  ORDER_BY_CATEGORY: 'ORDER_BY_CATEGORY',
  ORDER_BY_STATUS: 'ORDER_BY_STATUS',
  ORDER_BY_TIME: 'ORDER_BY_TIME',
  ORDER_BY_DATE: 'ORDER_BY_DATE',
  ORDER_BY_ADDED_BY: 'ORDER_BY_ADDED_BY',
  SET_FILTER_ORDER_BY: 'SET_FILTER_ORDER_BY',
  SET_FILTER_PRIMARY_CATEGORY: 'SET_FILTER_PRIMARY_CATEGORY',
  SET_FILTER_SECONDARY_CATEGORY: 'SET_FILTER_SECONDARY_CATEGORY',
  SET_FILTER_TERTIARY_CATEGORY: 'SET_FILTER_TERTIARY_CATEGORY',
  SET_FILTER_OBJECT: 'SET_FILTER_OBJECT',
  SET_FILTER_CONNECTED_TO: 'SET_FILTER_CONNECTED_TO',
  SET_FILTER_STAFF: 'SET_FILTER_STAFF',
  CUSTOM_LIST: 'CUSTOM_LIST',
  AUTOCOMPLETE_TAGS_CLEAR: 'AUTOCOMPLETE_TAGS_CLEAR',
  FETCH_USERS_START: 'FETCH_USERS_START',
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
  FETCH_USERS_FAIL: 'FETCH_USERS_FAIL',
  FETCH_MY_USER_START: 'FETCH_MY_USER_START',
  FETCH_MY_USER_SUCCESS: 'FETCH_MY_USER_SUCCESS',
  FETCH_MY_USER_FAIL: 'FETCH_MY_USER_FAIL',
  UPDATE_USER_START: 'UPDATE_USER_START',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAIL: 'UPDATE_USER_FAIL',
  UPDATE_SETUP_PERSON_START: 'UPDATE_SETUP_PERSON_START',
  UPDATE_SETUP_PERSON_SUCCESS: 'UPDATE_SETUP_PERSON_SUCCESS',
  UPDATE_SETUP_PERSON_FAIL: 'UPDATE_SETUP_PERSON_FAIL',
  CREATE_SETUP_PERSON_START: 'CREATE_SETUP_PERSON_START',
  CREATE_SETUP_PERSON_SUCCESS: 'CREATE_SETUP_PERSON_SUCCESS',
  CREATE_SETUP_PERSON_FAIL: 'CREATE_SETUP_PERSON_FAIL',
  UPDATE_ACCOUNT_START: 'UPDATE_ACCOUNT_START',
  UPDATE_ACCOUNT_SUCCESS: 'UPDATE_ACCOUNT_SUCCESS',
  UPDATE_ACCOUNT_FAIL: 'UPDATE_ACCOUNT_FAIL',
  PREPARE_ACCOUNT: 'PREPARE_ACCOUNT',
  PREPARE_USER: 'PREPARE_USER',
  UNPREPARE_USER: 'UNPREPARE_USER',
  PREPARE_SETUP_PERSON: 'PREPARE_SETUP_PERSON',
  PREPARE_ROLE: 'PREPARE_ROLE',
  GENERATE_NEW_PASSWORD_START: 'GENERATE_NEW_PASSWORD_START',
  GENERATE_NEW_PASSWORD_SUCCESS: 'GENERATE_NEW_PASSWORD_SUCCESS',
  GENERATE_NEW_PASSWORD_FAIL: 'GENERATE_NEW_PASSWORD_FAIL',
  CREATE_USER_START: 'CREATE_USER_START',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAIL: 'CREATE_USER_FAIL',
  SHOW_CREATE_USER_MODAL: 'SHOW_CREATE_USER_MODAL',
  HIDE_CREATE_USER_MODAL: 'HIDE_CREATE_USER_MODAL',
  UPDATE_ROLE_START: 'UPDATE_ROLE_START',
  UPDATE_ROLE_SUCCESS: 'UPDATE_ROLE_SUCCESS',
  UPDATE_ROLE_FAIL: 'UPDATE_ROLE_FAIL',
  CREATE_ROLE_START: 'CREATE_ROLE_START',
  CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
  CREATE_ROLE_FAIL: 'CREATE_ROLE_FAIL',
  PREPARE_SETTING: 'PREPARE_SETTING',
  SHOW_CREATE_SETTINGS_MODAL: 'SHOW_CREATE_SETTINGS_MODAL',
  HIDE_CREATE_SETTINGS_MODAL: 'HIDE_CREATE_SETTINGS_MODAL',
  ROLE: 'ROLE',
  SET_FILTER_TITLE: 'SET_FILTER_TITLE',
  ORDER_BY_SECONDARY_FIELD: 'ORDER_BY_SECONDARY_FIELD',
  ORDER_BY_SECONDARY_CATEGORY: 'ORDER_BY_SECONDARY_CATEGORY',
  ORDER_BY_TERTIARY_CATEGORY: 'ORDER_BY_TERTIARY_CATEGORY',
  FETCH_GLOBAL_SCHEDULE_LOCAL: 'FETCH_GLOBAL_SCHEDULE_LOCAL',
  SET_PRIMARY_FILE_START: 'SET_PRIMARY_FILE_START',
  SET_PRIMARY_FILE_SUCCESS: 'SET_PRIMARY_FILE_SUCCESS',
  SET_PRIMARY_FILE_FAIL: 'SET_PRIMARY_FILE_FAIL',
  FETCH_SCHEDULES_ON_OBJECT_DATA_START: 'FETCH_SCHEDULES_ON_OBJECT_DATA_START',
  FETCH_SCHEDULES_ON_OBJECT_DATA_SUCCESS:
    'FETCH_SCHEDULES_ON_OBJECT_DATA_SUCCESS',
  FETCH_SCHEDULES_ON_OBJECT_DATA_FAIL: 'FETCH_SCHEDULES_ON_OBJECT_DATA_FAIL',
  FETCH_POST_LINKS_START: 'FETCH_POST_LINKS_START',
  FETCH_POST_LINKS_SUCCESS: 'FETCH_POST_LINKS_SUCCESS',
  FETCH_POST_LINKS_FAIL: 'FETCH_POST_LINKS_FAIL',
  SET_PRINT_MODE: 'SET_PRINT_MODE',
  SET_PRINT_BLOCKS: 'SET_PRINT_BLOCKS',
  FETCH_OBJECTS_LOCAL: 'FETCH_OBJECTS_LOCAL',
  PREPARE_OBJECT: 'PREPARE_OBJECT',
  ADD_SUB_OBJECT_PRIMARY: 'ADD_SUB_OBJECT_PRIMARY',
  SET_PRINT_TITLE: 'SET_PRINT_TITLE',
  CLOSE_DELETE_NOTICE: 'CLOSE_DELETE_NOTICE',
  OPEN_DELETE_NOTICE: 'OPEN_DELETE_NOTICE',
  EDIT_FILE: 'EDIT_FILE',
  FILE_UPDATE_START: 'FILE_UPDATE_START',
  FILE_UPDATE_SUCCESS: 'FILE_UPDATE_SUCCESS',
  FILE_UPDATE_FAIL: 'FILE_UPDATE_FAIL',
  FILE_UPDATE_META_SUCCESS: 'FILE_UPDATE_META_SUCCESS',
  FILE_UPDATE_META_FAIL: 'FILE_UPDATE_META_FAIL',
  TOGGLE_EXTERNAL_START: 'TOGGLE_EXTERNAL_START',
  TOGGLE_EXTERNAL_SUCCESS: 'TOGGLE_EXTERNAL_SUCCESS',
  TOGGLE_EXTERNAL_FAIL: 'TOGGLE_EXTERNAL_FAIL',
  ACCEPT_TERMS_START: 'ACCEPT_TERMS_START',
  ACCEPT_TERMS_SUCCESS: 'ACCEPT_TERMS_SUCCESS',
  ACCEPT_TERMS_FAIL: 'ACCEPT_TERMS_FAIL',
  ACCEPT_TERMS: 'ACCEPT_TERMS',
  TOGGLE_TERMS_ACCEPT: 'TOGGLE_TERMS_ACCEPT',
  TERMS_TEXT:
    'In order for your organization to use Jetty, with you as a user, some of your personal information must be stored. This includes name, email address and phone number, but could also, should your organization so chose include additional information like physical address, special requirements on food, clothing size, and information on your skills and experiences. If this additional information is needed, you will be asked to provide it your self. \n \nThis data is gathered so that your organization can contact you, or to plan catering, need for protective clothes, or other things that is necessary for you to cooperate with your organization. Note that your contact information might be given to third parties who would need these in order to perform their duties in their collaboration with your organization. \n \nFrom time to time, Jetty will send out emails displaying new functionality in the system, describing how-tos, or other content for training and support reasons. Jetty will NOT send any emails with marketing purposes. \n \nPlease note that Jetty is a cloud based service, and that the data stored in Jetty could be moved between countries and continents, for example due to load balancing, or for backup reasons. \n \nYour consent to storing this information can be withdrawn at any time. This can be done by sending an email to withdraw@jetty.se withdrawing your consent will result in your personal information immediately being erased from Jetty, and your user account deleted. \n \nIf you consent to having your personal information stored, please accept terms below.',
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  SET_OBJECT_PUBLISHED_START: 'SET_OBJECT_PUBLISHED_START',
  SET_OBJECT_PUBLISHED_SUCCESS: 'SET_OBJECT_PUBLISHED_SUCCESS',
  SET_OBJECT_PUBLISHED_FAIL: 'SET_OBJECT_PUBLISHED_FAIL',
  SHOW_IMAGE_LIBRARY: 'SHOW_IMAGE_LIBRARY',
  HIDE_IMAGE_LIBRARY: 'HIDE_IMAGE_LIBRARY',
  SHOW_IMAGE_CROPPER: 'SHOW_IMAGE_CROPPER',
  HIDE_IMAGE_CROPPER: 'HIDE_IMAGE_CROPPER',
  FETCH_CLIENTS_START: 'FETCH_CLIENTS_START',
  FETCH_CLIENTS_FAIL: 'FETCH_CLIENTS_FAIL',
  FETCH_CLIENTS_SUCCESS: 'FETCH_CLIENTS_SUCCESS',
  FETCH_PROJECTS_START: 'FETCH_PROJECTS_START',
  FETCH_PROJECTS_FAIL: 'FETCH_PROJECTS_FAIL',
  FETCH_PROJECTS_SUCCESS: 'FETCH_PROJECTS_SUCCESS',
  FETCH_NOTIFICATIONS_START: 'FETCH_NOTIFICATIONS_START',
  FETCH_NOTIFICATIONS_FAIL: 'FETCH_NOTIFICATIONS_FAIL',
  FETCH_NOTIFICATIONS_SUCCESS: 'FETCH_NOTIFICATIONS_SUCCESS',
  FETCH_NOTIFICATIONS_COUNT_START: 'FETCH_NOTIFICATIONS_COUNT_START',
  FETCH_NOTIFICATIONS_COUNT_FAIL: 'FETCH_NOTIFICATIONS_COUNT_FAIL',
  FETCH_NOTIFICATIONS_COUNT_SUCCESS: 'FETCH_NOTIFICATIONS_COUNT_SUCCESS',
  UPDATE_NOTIFICATION_START: 'UPDATE_NOTIFICATION_START',
  UPDATE_NOTIFICATION_FAIL: 'UPDATE_NOTIFICATION_FAIL',
  UPDATE_NOTIFICATION_SUCCESS: 'UPDATE_NOTIFICATION_SUCCESS',
  CLEAR_NOTIFICATIONS_START: 'CLEAR_NOTIFICATIONS_START',
  CLEAR_NOTIFICATIONS_FAIL: 'CLEAR_NOTIFICATIONS_FAIL',
  CLEAR_NOTIFICATIONS_SUCCESS: 'CLEAR_NOTIFICATIONS_SUCCESS',
  FETCH_REPORT_TYPES_SUCCESS: 'FETCH_REPORT_TYPES_SUCCESS',
  FETCH_REPORT_FAIL: 'FETCH_REPORT_FAIL',
  SELECT_REPORT_TYPE: 'SELECT_REPORT_TYPE',
  FETCH_REPORT_START: 'FETCH_REPORT_START',
  FETCH_REPORT_SUCCESS: 'FETCH_REPORT_SUCCESS',
  SET_REPORT_SORTING_COLUMN: 'SET_REPORT_SORTING_COLUMN',
  UPDATE_REPORT_FILTER: 'UPDATE_REPORT_FILTER',
  SET_HIDDEN_EMPTY_ROWS: 'SET_HIDDEN_EMPTY_ROWS',
  UPDATE_USER: 'UPDATE_USER',
  CREATE_USER: 'CREATE_USER',
  UPDATE_ROLE: 'UPDATE_ROLE',
  CREATE_ROLE: 'CREATE_ROLE',
  UPDATE_ACCOUNT: 'UPDATE_ACCOUNT',
  FETCH_FILES_LOCAL: 'FETCH_FILES_LOCAL',
  ORDER_BY_NAME: 'ORDER_BY_NAME',
  ORDER_BY_ROLE: 'ORDER_BY_ROLE',
  ORDER_BY_CATEGORY_OBJECT: 'ORDER_BY_CATEGORY_OBJECT',
  ORDER_BY_TITLE: 'ORDER_BY_TITLE',
  ORDER_BY_ASSIGNED: 'ORDER_BY_ASSIGNED',
  ORDER_BY_INVITED: 'ORDER_BY_INVITED',
  ORDER_BY_PRIORITY: 'ORDER_BY_PRIORITY',
  ORDER_BY_UPLOADED_TO: 'ORDER_BY_UPLOADED_TO',
  ORDER_BY_UPLOADED: 'ORDER_BY_UPLOADED',
  ORDER_BY_FINANCIAL: 'ORDER_BY_FINANCIAL',
  ORDER_BY_EXTERNAL: 'ORDER_BY_EXTERNAL',
  ORDER_BY_TAGS: 'ORDER_BY_TAGS',
  ORDER_BY_AUTHOR: 'ORDER_BY_AUTHOR',
  SET_FILTER_JOBROLE: 'SET_FILTER_JOBROLE',
  CREATE_COMMENT_START: 'CREATE_COMMENT_START',
  CREATE_COMMENT_SUCCESS: 'CREATE_COMMENT_SUCCESS',
  CREATE_COMMENT_FAIL: 'CREATE_COMMENT_FAIL',
  UPDATE_COMMENT_START: 'UPDATE_COMMENT_START',
  UPDATE_COMMENT_SUCCESS: 'UPDATE_COMMENT_SUCCESS',
  UPDATE_COMMENT_FAIL: 'UPDATE_COMMENT_FAIL',
  DELETE_COMMENT_START: 'DELETE_COMMENT_START',
  DELETE_COMMENT_SUCCESS: 'DELETE_COMMENT_SUCCESS',
  DELETE_COMMENT_FAIL: 'DELETE_COMMENT_FAIL',
  PREPARE_COMMENT: 'PREPARE_COMMENT',
  SET_FILTER_TASK_PRIORITY: 'SET_FILTER_TASK_PRIORITY',
  TASK_PRIORITIES: {
    NONE: { name: 'None', color: '#9f9f9f' },
    LOW: { name: 'Low', color: '#2D8EFF' },
    MEDIUM: { name: 'Medium', color: '#FFEE58' },
    HIGH: { name: 'High', color: '#F44336' },
  },
  PRIMARY_CATEGORY_CONFLICT_FOUND: 'PRIMARY_CATEGORY_CONFLICT_FOUND',
  RESET_PRIMARY_CATEGORY_CONFLICTS: 'RESET_PRIMARY_CATEGORY_CONFLICTS',
  PRIMARY_CATEGORY_CONFLICT_PENDING: 'PRIMARY_CATEGORY_CONFLICT_PENDING',
  FETCH_TAG_GROUPS_START: 'FETCH_TAG_GROUPS_START',
  FETCH_TAG_GROUPS_SUCCESS: 'FETCH_TAG_GROUPS_SUCCESS',
  FETCH_TAG_GROUPS_FAIL: 'FETCH_TAG_GROUPS_FAIL',
  RENAME_TAG: 'RENAME_TAG',
  DISABLE_TAG: 'DISABLE_TAG',
  CREATE_MERGE_TAG: 'CREATE_MERGE_TAG',
  UNPREPARE_TAGS: 'UNPREPARE_TAGS',
  PREPARE_PROJECT: 'PREPARE_PROJECT',
  UPDATE_PROJECT_START: 'UPDATE_PROJECT_START',
  UPDATE_PROJECT_SUCCESS: 'UPDATE_PROJECT_SUCCESS',
  UPDATE_PROJECT_FAIL: 'UPDATE_PROJECT_FAIL',
  PREPARE_JOB_ROLE: 'PREPARE_JOB_ROLE',
  UNPREPARE_JOB_ROLES: 'UNPREPARE_JOB_ROLES',
  UPDATE_JOB_ROLES_SUCCESS: 'UPDATE_JOB_ROLES_SUCCESS',
  UPDATE_JOB_ROLES_FAIL: 'UPDATE_JOB_ROLES_FAIL',
  PREPARE_DEPARTMENT: 'PREPARE_DEPARTMENT',
  UNPREPARE_DEPARTMENTS: 'UNPREPARE_DEPARTMENTS',
  UPDATE_TAGS_SUCCESS: 'UPDATE_TAGS_SUCCESS',
  UPDATE_TAGS_FAIL: 'UPDATE_TAGS_FAIL',
  CREATE_TAGS_SUCCESS: 'CREATE_TAGS_SUCCESS',
  CREATE_TAGS_FAIL: 'CREATE_TAGS_FAIL',
  UPDATE_DEPARTMENTS_SUCCESS: 'UPDATE_DEPARTMENTS_SUCCESS',
  UPDATE_DEPARTMENTS_FAIL: 'UPDATE_DEPARTMENTS_FAIL',
  SET_KEY_VALUE: 'SET_KEY_VALUE',
  PREPARE_BLOCK_TYPE: 'PREPARE_BLOCK_TYPE',
  UNPREPARE_BLOCK_TYPE: 'UNPREPARE_BLOCK_TYPE',
  UPDATE_OBJECT_TYPE_SUCCESS: 'UPDATE_OBJECT_TYPE_SUCCESS',
  UPDATE_OBJECT_TYPE_FAIL: 'UPDATE_OBJECT_TYPE_FAIL',
  PREPARE_FIELD_TYPE: 'PREPARE_FIELD_TYPE',
  SET_EDIT_EXTERNAL: 'SET_EDIT_EXTERNAL',
  FETCH_FILES_INDEX_START: 'FETCH_FILES_INDEX_START',
  FETCH_FILES_INDEX_SUCCESS: 'FETCH_FILES_INDEX_SUCCESS',
  FETCH_FILES_INDEX_FAIL: 'FETCH_FILES_INDEX_FAIL',
  PREPARE_OBJECT_TYPE: 'PREPARE_OBJECT_TYPE',
  UNPREPARE_OBJECT_TYPE: 'UNPREPARE_OBJECT_TYPE',
  UNPREPARE_FIELD_TYPE: 'UNPREPARE_FIELD_TYPE',
  CLEAR_ALL_FILTERS: 'CLEAR_ALL_FILTERS',
  COMPANIES: 'COMPANIES',
  FETCH_COMPANIES_START: 'FETCH_COMPANIES_START',
  FETCH_COMPANY_START: 'FETCH_COMPANY_START',
  CREATE_COMPANY_START: 'CREATE_COMPANY_START',
  UPDATE_COMPANY_START: 'UPDATE_COMPANY_START',
  DELETE_COMPANY_START: 'DELETE_COMPANY_START',
  FETCH_COMPANIES_FAIL: 'FETCH_COMPANIES_FAIL',
  FETCH_COMPANY_FAIL: 'FETCH_COMPANY_FAIL',
  CREATE_COMPANY_FAIL: 'CREATE_COMPANY_FAIL',
  UPDATE_COMPANY_FAIL: 'UPDATE_COMPANY_FAIL',
  DELETE_COMPANY_FAIL: 'DELETE_COMPANY_FAIL',
  FETCH_COMPANIES_SUCCESS: 'FETCH_COMPANIES_SUCCESS',
  FETCH_COMPANY_SUCCESS: 'FETCH_COMPANY_SUCCESS',
  PREPARE_COMPANY: 'PREPARE_COMPANY',
  CREATE_COMPANY_SUCCESS: 'CREATE_COMPANY_SUCCESS',
  UPDATE_COMPANY_SUCCESS: 'UPDATE_COMPANY_SUCCESS',
  DELETE_COMPANY_SUCCESS: 'DELETE_COMPANY_SUCCESS',
  BEGIN_EDIT_COMPANY: 'BEGIN_EDIT_COMPANY',
  ABORT_EDIT_COMPANY: 'ABORT_EDIT_COMPANY',
  BEGIN_VIEWING_COMPANY: 'BEGIN_VIEWING_COMPANY',
  ABORT_VIEWING_COMPANY: 'ABORT_VIEWING_COMPANY',
  BEGIN_EDIT_COMPANY_ADDRESSES: 'BEGIN_EDIT_COMPANY_ADDRESSES',
  ABORT_EDIT_COMPANY_ADDRESSES: 'ABORT_EDIT_COMPANY_ADDRESSES',
  BEGIN_EDIT_COMPANY_PEOPLE: 'BEGIN_EDIT_COMPANY_PEOPLE',
  ABORT_EDIT_COMPANY_PEOPLE: 'ABORT_EDIT_COMPANY_PEOPLE',
  PREPARE_COMPANY_ADDRESSES: 'PREPARE_COMPANY_ADDRESSES',
  PREPARE_COMPANY_PEOPLE: 'PREPARE_COMPANY_PEOPLE',
  UPDATE_COMPANY_ADDRESSES_FAIL: 'UPDATE_COMPANY_ADDRESSES_FAIL',
  UPDATE_COMPANY_ADDRESSES_START: 'UPDATE_COMPANY_ADDRESSES_START',
  UPDATE_COMPANY_ADDRESSES_SUCCESS: 'UPDATE_COMPANY_ADDRESSES_SUCCESS',
  UPDATE_COMPANY_PEOPLE_FAIL: 'UPDATE_COMPANY_PEOPLE_FAIL',
  UPDATE_COMPANY_PEOPLE_START: 'UPDATE_COMPANY_PEOPLE_START',
  UPDATE_COMPANY_PEOPLE_SUCCESS: 'UPDATE_COMPANY_PEOPLE_SUCCESS',
  PREPARE_COMPANY_CONTACT_LOGS: 'PREPARE_COMPANY_CONTACT_LOGS',
  UPDATE_COMPANY_CONTACT_LOGS_FAIL: 'UPDATE_COMPANY_CONTACT_LOGS_FAIL',
  UPDATE_COMPANY_CONTACT_LOGS_START: 'UPDATE_COMPANY_CONTACT_LOGS_START',
  UPDATE_COMPANY_CONTACT_LOGS_SUCCESS: 'UPDATE_COMPANY_CONTACT_LOGS_SUCCESS',
  BEGIN_EDIT_COMPANY_CONTACT_LOGS: 'BEGIN_EDIT_COMPANY_CONTACT_LOGS',
  ABORT_EDIT_COMPANY_CONTACT_LOGS: 'ABORT_EDIT_COMPANY_CONTACT_LOGS',
  CREATE_OBJECT_TYPE_START: 'CREATE_OBJECT_TYPE_START',
  CREATE_OBJECT_TYPE_SUCCESS: 'CREATE_OBJECT_TYPE_SUCCESS',
  CREATE_OBJECT_TYPE_FAIL: 'CREATE_OBJECT_TYPE_FAIL',
  UPDATE_OBJECT_TYPE_START: 'UPDATE_OBJECT_TYPE_START',
  DELETE_OBJECT_TYPE_START: 'DELETE_OBJECT_TYPE_START',
  DELETE_OBJECT_TYPE_SUCCESS: 'DELETE_OBJECT_TYPE_SUCCESS',
  DELETE_OBJECT_TYPE_FAIL: 'DELETE_OBJECT_TYPE_FAIL',
  ROUTED_TO_OBJECT_TYPE: 'ROUTED_TO_OBJECT_TYPE',
  AUTOCOMPLETE_COMPANIES_START: 'AUTOCOMPLETE_COMPANIES_START',
  AUTOCOMPLETE_COMPANIES_FAIL: 'AUTOCOMPLETE_COMPANIES_FAIL',
  AUTOCOMPLETE_COMPANIES_SUCCESS: 'AUTOCOMPLETE_COMPANIES_SUCCESS',
  AUTOCOMPLETE_ORGANIZER_COMPANIES_SUCCESS:
    'AUTOCOMPLETE_ORGANIZER_COMPANIES_SUCCESS',
  AUTOCOMPLETE_ENGAGER_COMPANIES_SUCCESS:
    'AUTOCOMPLETE_ENGAGER_COMPANIES_SUCCESS',
  ORDER_BY_INDUSTRY: 'ORDER_BY_INDUSTRY',
  ORDER_BY_TYPE: 'ORDER_BY_TYPE',
  ORDER_BY_EMAIL: 'ORDER_BY_EMAIL',
  ORDER_BY_PHONE: 'ORDER_BY_PHONE',
  SET_MENU_ITEMS: 'SET_MENU_ITEMS',
  FETCH_MENU_ITEMS_START: 'FETCH_MENU_ITEMS_START',
  FETCH_MENU_ITEMS_SUCCESS: 'FETCH_MENU_ITEMS_SUCCESS',
  FETCH_MENU_ITEMS_FAIL: 'FETCH_MENU_ITEMS_FAIL',
  DELETE_BLOCK_TYPE_START: 'DELETE_BLOCK_TYPE_START',
  DELETE_BLOCK_TYPE_SUCCESS: 'DELETE_BLOCK_TYPE_SUCCESS',
  DELETE_BLOCK_TYPE_FAIL: 'DELETE_BLOCK_TYPE_FAIL',
  BLOCK_DATA: 'BLOCK_DATA',
  TASK: 'TASK',
  CREATE_TOKEN_USER_CHILD_START: 'CREATE_TOKEN_USER_CHILD_START',
  CREATE_TOKEN_USER_CHILD_FAIL: 'CREATE_TOKEN_USER_CHILD_FAIL',
  CREATE_TOKEN_USER_CHILD_SUCCESS: 'CREATE_TOKEN_USER_CHILD_SUCCESS',
  PREPARE_TOKEN_USER_OBJECT: 'PREPARE_TOKEN_USER_OBJECT',
  CREATE_INNER_OBJECT_DATA_SUCCESS: 'CREATE_INNER_OBJECT_DATA_SUCCESS',
  VALIDATE_DATA_FAIL: 'VALIDATE_DATA_FAIL',
  COPY_TEXT_SUCCESS: 'COPY_TEXT_SUCCESS',
  COPY_TEXT_FAIL: 'COPY_TEXT_FAIL',
  OBJECT_FILES: 'OBJECT_FILES',
  ADD_GROUP_BY_ITEM: 'ADD_GROUP_BY',
  ADD_GROUP_BY_FAIL: 'ADD_GROUP_BY_FAIL',
  ADD_GROUP_BY_SUCCESS: 'ADD_GROUP_BY_SUCCESS',
  UPDATE_GROUP_BY: 'UPDATE_GROUP_BY',
  UPDATE_GROUP_BY_ITEM: 'UPDATE_GROUP_BY_ITEM',
  UPDATE_GROUP_BY_FAIL: 'UPDATE_GROUP_BY_FAIL',
  UPDATE_GROUP_BY_SUCCESS: 'UPDATE_GROUP_BY_SUCCESS',
  REMOVE_GROUP_BY_ITEM: 'REMOVE_GROUP_BY',
  INTERNAL_LIST: 'INTERNAL_LIST',
  INTERNAL_CONTACTS: 'INTERNAL_CONTACTS',
  INTERNAL_CONTACTS_PRINT: 'INTERNAL_CONTACTS_PRINT',
  SET_FILTER_SELECT_OPTION: 'SET_FILTER_SELECT_OPTION',
  OBJECT_DATA_LIST: 'OBJECT_DATA_LIST',
  PREPARE_PERMISSION: 'PREPARE_PERMISSION',
  UNPREPARE_PERMISSION: 'UNPREPARE_PERMISSION',
  UPDATE_USER_PERMISSIONS_START: 'UPDATE_USER_PERMISSIONS_START',
  UPDATE_USER_PERMISSIONS_SUCCESS: 'UPDATE_USER_PERMISSIONS_SUCCESS',
  UPDATE_USER_PERMISSIONS_FAIL: 'UPDATE_USER_PERMISSIONS_FAIL',
  ACTIVATE_OVERALL_RIGHTS_EDIT_MODE: 'ACTIVATE_OVERALL_RIGHTS_EDIT_MODE',
  DEACTIVATE_OVERALL_RIGHTS_EDIT_MODE: 'DEACTIVATE_OVERALL_RIGHTS_EDIT_MODE',
  ACTIVATE_BLOCK_RIGHTS_EDIT_MODE: 'ACTIVATE_BLOCK_RIGHTS_EDIT_MODE',
  DEACTIVATE_BLOCK_RIGHTS_EDIT_MODE: 'DEACTIVATE_BLOCK_RIGHTS_EDIT_MODE',
  ACTIVATE_OBJECT_RIGHTS_EDIT_MODE: 'ACTIVATE_OBJECT_RIGHTS_EDIT_MODE',
  DEACTIVATE_OBJECT_RIGHTS_EDIT_MODE: 'DEACTIVATE_OBJECT_RIGHTS_EDIT_MODE',
  FETCH_CONTRACT_TEMPLATES_START: 'FETCH_CONTRACT_TEMPLATES_START',
  FETCH_CONTRACT_TEMPLATES_SUCCESS: 'FETCH_CONTRACT_TEMPLATES_SUCCESS',
  FETCH_CONTRACT_TEMPLATES_FAIL: 'FETCH_CONTRACT_TEMPLATES_FAIL',
  PREPARE_CONTRACT_TEMPLATE: 'PREPARE_CONTRACT_TEMPLATE',
  CREATE_CONTRACT_TEMPLATE_START: 'CREATE_CONTRACT_TEMPLATE_START',
  CREATE_CONTRACT_TEMPLATE_SUCCESS: 'CREATE_CONTRACT_TEMPLATE_SUCCESS',
  CREATE_CONTRACT_TEMPLATE_FAIL: 'CREATE_CONTRACT_TEMPLATE_FAIL',
  BEGIN_EDIT_CONTRACT_TEMPLATE: 'BEGIN_EDIT_CONTRACT_TEMPLATE',
  ABORT_EDIT_CONTRACT_TEMPLATE: 'ABORT_EDIT_CONTRACT_TEMPLATE',
  UPDATE_CONTRACT_TEMPLATE_START: 'UPDATE_CONTRACT_TEMPLATE_START',
  UPDATE_CONTRACT_TEMPLATE_SUCCESS: 'UPDATE_CONTRACT_TEMPLATE_SUCCESS',
  UPDATE_CONTRACT_TEMPLATE_FAIL: 'UPDATE_CONTRACT_TEMPLATE_FAIL',
  SET_CONTRACT_TEMPLATE_BLOCKS: 'SET_CONTRACT_TEMPLATE_BLOCKS',
  GENERATE_CONTRACT_PDF_START: 'GENERATE_CONTRACT_PDF_START',
  GENERATE_CONTRACT_PDF_SUCCESS: 'GENERATE_CONTRACT_PDF_SUCCESS',
  GENERATE_CONTRACT_PDF_FAIL: 'GENERATE_CONTRACT_PDF_FAIL',
  PREPARE_CONTRACT_PDF: 'PREPARE_CONTRACT_PDF',
  START_CONTRACT_DOCUMENT_SIGNING_START:
    'START_CONTRACT_DOCUMENT_SIGNING_START',
  START_CONTRACT_DOCUMENT_SIGNING_SUCCESS:
    'START_CONTRACT_DOCUMENT_SIGNING_SUCCESS',
  START_CONTRACT_DOCUMENT_SIGNING_FAIL: 'START_CONTRACT_DOCUMENT_SIGNING_FAIL',
  FETCH_CONTRACTS_START: 'FETCH_CONTRACTS_START',
  FETCH_CONTRACTS_SUCCESS: 'FETCH_CONTRACTS_SUCCESS',
  FETCH_CONTRACTS_FAIL: 'FETCH_CONTRACTS_FAIL',
  CONTRACTS: 'CONTRACTS',
  OPEN_CONTRACT_PDF_UPLOAD: 'OPEN_CONTRACT_PDF_UPLOAD',
  CLOSE_CONTRACT_PDF_UPLOAD: 'CLOSE_CONTRACT_PDF_UPLOAD',
  PREPARE_CONTRACT: 'PREPARE_CONTRACT',
  UPDATE_CONTRACT_START: 'UPDATE_CONTRACT_START',
  UPDATE_CONTRACT_SUCCESS: 'UPDATE_CONTRACT_SUCCESS',
  UPDATE_CONTRACT_FAIL: 'UPDATE_CONTRACT_FAIL',
  PREPARE_STATUS: 'PREPARE_STATUS',
  UNPREPARE_STATUS: 'UNPREPARE_STATUS',
  ORDER_BY_ORGANIZER: 'ORDER_BY_ORGANIZER',
  ORDER_BY_ENGAGER: 'ORDER_BY_ENGAGER',
  ORDER_BY_FILES: 'ORDER_BY_FILES',
  ORDER_BY_CONTRACT_TITLE: 'ORDER_BY_CONTRACT_TITLE',
  GROUP_BY_DEFAULT: {
    TASKS: {
      TIME: {
        id: 1,
        text: 'Date',
        sortingKey: 'ORDER_BY_TIME',
        width: 2,
        orderIndex: 0,
        order: 'ASC',
      },
    },
    MANAGE_TASKS: {
      TIME: {
        id: 1,
        text: 'Date',
        sortingKey: 'ORDER_BY_TIME',
        width: 2,
        orderIndex: 0,
        order: 'ASC',
      },
    },
    SCHEDULES: {
      TIME: {
        id: 0,
        text: 'Time',
        sortingKey: 'ORDER_BY_DATE',
        width: 3,
        orderIndex: 0,
        order: 'ASC',
      },
      CATEGORY: {
        id: 2,
        sortingKey: 'ORDER_BY_CATEGORY',
        width: 3,
        orderIndex: 1,
        order: 'ASC',
      },
    },
  },
  ORDER_BY_DEFAULT: {
    SCHEDULES: { sortingKey: 'ORDER_BY_TIME', order: 'ASC' },
  },
  SET_DEFAULT_GROUP_BY: 'SET_DEFAULT_GROUP_BY',
  INTERNAL_SCHEDULING_LIST: 'INTERNAL_SCHEDULING_LIST',
  REPORT_LIST: 'REPORT_LIST',
  FETCH_VERSION_START: 'FETCH_VERSION_START',
  FETCH_VERSION_SUCCESS: 'FETCH_VERSION_SUCCESS',
  FETCH_VERSION_FAIL: 'FETCH_VERSION_FAIL',
  SET_EXTERNALLY_ACCESSED: 'SET_EXTERNALLY_ACCESSED',
  USERS_CREATE: 'USERS_CREATE',
  USERS_UPDATE: 'USERS_UPDATE',
  CLEAR_MENU: 'CLEAR_MENU',
  UPDATE_ROLE_PERMISSIONS_START: 'UPDATE_ROLE_PERMISSIONS_START',
  UPDATE_ROLE_PERMISSIONS_SUCCESS: 'UPDATE_ROLE_PERMISSIONS_SUCCESS',
  UPDATE_ROLE_PERMISSIONS_FAIL: 'UPDATE_ROLE_PERMISSIONS_FAIL',
  COUNTRY: 'COUNTRY',
  PARENT_FIELD: 'PARENT_FIELD',
  ORDER_BY_CHILD_NAME: 'ORDER_BY_CHILD_NAME',
  INTERNAL_COMPANIES: 'INTERNAL_COMPANIES',
  INTERNAL_ADDRESSES: 'INTERNAL_ADDRESSES',
  INTERNAL_PEOPLE: 'INTERNAL_PEOPLE',
  INTERNAL_CONTACT_LOG: 'INTERNAL_CONTACT_LOG',
  USER: 'USER',
  TEMPLATES: 'TEMPLATES',
  TAG_UNIQUE_FAIL: 'TAG_UNIQUE_FAIL',
  TOGGLE_POST_ACTIVE_START: 'TOGGLE_POST_ACTIVE_START',
  TOGGLE_POST_ACTIVE_SUCCESS: 'TOGGLE_POST_ACTIVE_SUCCESS',
  TOGGLE_POST_ACTIVE_FAIL: 'TOGGLE_POST_ACTIVE_FAIL',
  DELETE_PROJECTINFO_START: 'DELETE_PROJECTINFO_START',
  DELETE_PROJECTINFO_SUCCESS: 'DELETE_PROJECTINFO_SUCCESS',
  DELETE_PROJECTINFO_FAIL: 'DELETE_PROJECTINFO_FAIL',
  CLEAR_REPORT_FILTER: 'CLEAR_REPORT_FILTER',
  FETCH_SUB_REPORT_START: 'FETCH_SUB_REPORT_START',
  FETCH_SUB_REPORT_SUCCESS: 'FETCH_SUB_REPORT_SUCCESS',
  FETCH_SUB_REPORT_FAIL: 'FETCH_SUB_REPORT_FAIL',
  EXPORT_FORMS_START: 'EXPORT_FORMS_START',
  EXPORT_FORMS_SUCCESS: 'EXPORT_FORMS_SUCCESS',
  EXPORT_FORMS_FAIL: 'EXPORT_FORMS_FAIL',
  FETCH_TASK_REPORTS_START: 'FETCH_TASK_REPORTS_START',
  FETCH_TASK_REPORTS_SUCCESS: 'FETCH_TASK_REPORTS_SUCCESS',
  FETCH_TASK_REPORTS_FAIL: 'FETCH_TASK_REPORTS_FAIL',
  DELETE_TASK_REPORT_START: 'DELETE_TASK_REPORT_START',
  DELETE_TASK_REPORT_SUCCESS: 'DELETE_TASK_REPORT_SUCCESS',
  DELETE_TASK_REPORT_FAIL: 'DELETE_TASK_REPORT_FAIL',
  UPDATE_OR_CREATE_TASK_REPORTS_SUCCESS:
    'UPDATE_OR_CREATE_TASK_REPORTS_SUCCESS',
  UPDATE_OR_CREATE_TASK_REPORTS_START: 'UPDATE_OR_CREATE_TASK_REPORTS_START',
  UPDATE_OR_CREATE_TASK_REPORTS_FAIL: 'UPDATE_OR_CREATE_TASK_REPORTS_FAIL',
  PREPARE_TASK_REPORT: 'PREPARE_TASK_REPORT',
  UNPREPARE_TASK_REPORT: 'UNPREPARE_TASK_REPORT',
  BEGIN_EDIT_TASK_REPORTS: 'BEGIN_EDIT_TASK_REPORTS',
  ABORT_EDIT_TASK_REPORTS: 'ABORT_EDIT_TASK_REPORTS',
  UPDATE_TASK_REPORT_SUCCESS: 'UPDATE_TASK_REPORT_SUCCESS',
  CREATE_TASK_REPORT_SUCCESS: 'CREATE_TASK_REPORT_SUCCESS',
  TASK_REPORTS: 'TASK_REPORTS',
  MANAGE_TASKS: 'MANAGE_TASKS',
  PREPARE_SITE: 'PREPARE_SITE',
  UNPREPARE_SITE: 'UNPREPARE_SITE',
  CLEAR_CACHE_START: 'CLEAR_CACHE_START',
  CLEAR_CACHE_SUCCESS: 'CLEAR_CACHE_SUCCESS',
  CLEAR_CACHE_FAIL: 'CLEAR_CACHE_FAIL',
  LOG_SEARCH_SUCCESS: 'LOG_SEARCH_SUCCESS',
  LOG_SEARCH_FAIL: 'LOG_SEARCH_FAIL',
  SEARCH_RESULT_USED: 'SEARCH_RESULT_USED',
  SEARCH_RESULT_UNUSED: 'SEARCH_RESULT_UNUSED',
  RESET_PASSWORD_LINK_SENT: 'RESET_PASSWORD_LINK_SENT',
  RESET_PASSWORD_LINK_FAIL: 'RESET_PASSWORD_LINK_FAIL',
  FETCH_USER_FROM_RESET_TOKEN_SUCCESS: 'FETCH_USER_FROM_RESET_TOKEN_SUCCESS',
  FETCH_USER_FROM_RESET_TOKEN_FAIL: 'FETCH_USER_FROM_RESET_TOKEN_FAIL',
  RESET_PASSWORD_START: 'RESET_PASSWORD_START',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAIL: 'RESET_PASSWORD_FAIL',
  FETCH_PERSON_CONTACTS_SUCCESS: 'FETCH_PERSON_CONTACTS_SUCCESS',
  FETCH_PERSON_CONTACTS_FAIL: 'FETCH_PERSON_CONTACTS_FAIL',
  FETCH_COMPANY_CONTACTS_SUCCESS: 'FETCH_COMPANY_CONTACTS_SUCCESS',
  FETCH_COMPANY_CONTACTS_FAIL: 'FETCH_COMPANY_CONTACTS_FAIL',
  ABORT_DELETE_PERSON: 'ABORT_DELETE_PERSON',
  ABORT_DELETE_COMPANY: 'ABORT_DELETE_COMPANY',
  DELETE_PERSON_AND_CONNECTIONS_START: 'DELETE_PERSON_AND_CONNECTIONS_START',
  DELETE_PERSON_AND_CONNECTIONS_SUCCESS:
    'DELETE_PERSON_AND_CONNECTIONS_SUCCESS',
  DELETE_PERSON_AND_CONNECTIONS_FAIL: 'DELETE_PERSON_AND_CONNECTIONS_FAIL',
  DELETE_COMPANY_AND_CONNECTIONS_START: 'DELETE_COMPANY_AND_CONNECTIONS_START',
  DELETE_COMPANY_AND_CONNECTIONS_SUCCESS:
    'DELETE_COMPANY_AND_CONNECTIONS_SUCCESS',
  DELETE_COMPANY_AND_CONNECTIONS_FAIL: 'DELETE_COMPANY_AND_CONNECTIONS_FAIL',
  SET_FILTER_TITLE_TAG: 'SET_FILTER_TITLE_TAG',
  SET_FILTER_TITLES_EXACT: 'SET_FILTER_TITLES_EXACT',
  ORDER_BY_SITE: 'ORDER_BY_SITE',
  ORDER_BY_URL: 'ORDER_BY_URL',
  ORDER_BY_MENU: 'ORDER_BY_MENU',
  ORDER_BY_IMAGE: 'ORDER_BY_IMAGE',
  ORDER_BY_BLOCK_COUNT: 'ORDER_BY_BLOCK_COUNT',
  ORDER_BY_SOCIAL_IMAGE: 'ORDER_BY_SOCIAL_IMAGE',
  POSTS_ALT: 'POSTS_ALT',
  FETCH_OBJECT_DUPLICATION_START: 'FETCH_OBJECT_DUPLICATION_START',
  FETCH_OBJECT_DUPLICATION_SUCCESS: 'FETCH_OBJECT_DUPLICATION_SUCCESS',
  FETCH_OBJECT_DUPLICATION_FAIL: 'FETCH_OBJECT_DUPLICATION_FAIL',
  DUPLICATE_OBJECT_START: 'DUPLICATE_OBJECT_START',
  DUPLICATE_OBJECT_SUCCESS: 'DUPLICATE_OBJECT_SUCCESS',
  DUPLICATE_OBJECT_FAIL: 'DUPLICATE_OBJECT_FAIL',
  DUPLICATE_OBJECT_READY: 'DUPLICATE_OBJECT_READY',
  UPDATE_DUPLICATION_OBJECT: 'UPDATE_DUPLICATION_OBJECT',
  UPDATE_OBJECT_DUPLICATE_SUCCESS: 'UPDATE_OBJECT_DUPLICATE_SUCCESS',
  PREPARE_OBJECT_DUPLICATE: 'PREPARE_OBJECT_DUPLICATE',
  SHOW_DUPLICATE_TIME_NOTICE: 'SHOW_DUPLICATE_TIME_NOTICE',
  FETCH_TASK_START_DUPLICATION: 'FETCH_TASK_START_DUPLICATION',
  FETCH_TASK_SUCCESS_DUPLICATION: 'FETCH_TASK_SUCCESS_DUPLICATION',
  FETCH_TASK_FAIL_DUPLICATION: 'FETCH_TASK_FAIL_DUPLICATION',
  BEGIN_DUPLICATE_TASK: 'BEGIN_DUPLICATE_TASK',
  UPDATE_OBJECT_DUPLICATE_START: 'UPDATE_OBJECT_DUPLICATE_START',
  UPDATE_OBJECT_DUPLICATE: 'UPDATE_OBJECT_DUPLICATE',
  STRIPE_CHARGE_SUCCESS: 'STRIPE_CHARGE_SUCCESS',
  STRIPE_CHARGE_FAIL: 'STRIPE_CHARGE_FAIL',
  GET_PAYMENTINTENT_SUCCESS: 'GET_PAYMENTINTENT_SUCCESS',
  GET_PAYMENTINTENT_FAIL: 'GET_PAYMENTINTENT_FAIL',
  UPDATE_PAYMENTINTENT_SUCCESS: 'UPDATE_PAYMENTINTENT_SUCCESS',
  UPDATE_PAYMENTINTENT_FAIL: 'UPDATE_PAYMENTINTENT_FAIL',
  PREPARE_BILLING_INFORMATION: 'PREPARE_BILLING_INFORMATION',
  FETCH_STRIPE_PRODUCTS_START: 'FETCH_STRIPE_PRODUCTS_START',
  FETCH_STRIPE_PRODUCTS_SUCCESS: 'FETCH_STRIPE_PRODUCTS_SUCCESS',
  FETCH_STRIPE_PRODUCTS_FAIL: 'FETCH_STRIPE_PRODUCTS_FAIL',
  SET_STRIPE_PRODUCT: 'SET_STRIPE_PRODUCT',
  SET_STRIPE_BILLING_INFORMATION: 'SET_STRIPE_BILLING_INFORMATION',
  FETCH_SUBSCRIPTION_PERIODS_START: 'FETCH_SUBSCRIPTION_PERIODS_START',
  FETCH_SUBSCRIPTION_PERIODS_SUCCESS: 'FETCH_SUBSCRIPTION_PERIODS_SUCCESS',
  FETCH_SUBSCRIPTION_PERIODS_FAIL: 'FETCH_SUBSCRIPTION_PERIODS_FAIL',
  FETCH_SUBSCRIPTIONS_START: 'FETCH_SUBSCRIPTIONS_START',
  FETCH_SUBSCRIPTIONS_SUCCESS: 'FETCH_SUBSCRIPTIONS_SUCCESS',
  FETCH_SUBSCRIPTIONS_FAIL: 'FETCH_SUBSCRIPTIONS_FAIL',
  CREATE_SUBSCRIPTION_START: 'CREATE_SUBSCRIPTION_START',
  CREATE_SUBSCRIPTION_SUCCESS: 'CREATE_SUBSCRIPTION_SUCCESS',
  CREATE_SUBSCRIPTION_FAIL: 'CREATE_SUBSCRIPTION_FAIL',
  UPDATE_SUBSCRIPTION_START: 'UPDATE_SUBSCRIPTION_START',
  UPDATE_SUBSCRIPTION_SUCCESS: 'UPDATE_SUBSCRIPTION_SUCCESS',
  UPDATE_SUBSCRIPTION_FAIL: 'UPDATE_SUBSCRIPTION_FAIL',
  UNSUBSCRIBE_START: 'UNSUBSCRIBE_START',
  UNSUBSCRIBE_FAIL: 'UNSUBSCRIBE_FAIL',
  UNSUBSCRIBE_SUCCESS: 'UNSUBSCRIBE_SUCCESS',
  CREATE_SUBSCRIPTION_PLAN_START: 'CREATE_SUBSCRIPTION_PLAN_START',
  CREATE_SUBSCRIPTION_PLAN_SUCCESS: 'CREATE_SUBSCRIPTION_PLAN_SUCCESS',
  CREATE_SUBSCRIPTION_PLAN_FAIL: 'CREATE_SUBSCRIPTION_PLAN_FAIL',
  UPDATE_SUBSCRIPTION_PLAN_START: 'UPDATE_SUBSCRIPTION_PLAN_START',
  UPDATE_SUBSCRIPTION_PLAN_SUCCESS: 'UPDATE_SUBSCRIPTION_PLAN_SUCCESS',
  UPDATE_SUBSCRIPTION_PLAN_FAIL: 'UPDATE_SUBSCRIPTION_PLAN_FAIL',
  FETCH_BILLING_INFO_START: 'FETCH_BILLING_INFO_START',
  FETCH_BILLING_INFO_SUCCESS: 'FETCH_BILLING_INFO_SUCCESS',
  FETCH_BILLING_INFO_FAIL: 'FETCH_BILLING_INFO_FAIL',
  CREATE_BILLING_INFO_START: 'CREATE_BILLING_INFO_START',
  CREATE_BILLING_INFO_SUCCESS: 'CREATE_BILLING_INFO_SUCCESS',
  CREATE_BILLING_INFO_FAIL: 'CREATE_BILLING_INFO_FAIL',
  UPDATE_BILLING_INFO_START: 'UPDATE_BILLING_INFO_START',
  UPDATE_BILLING_INFO_SUCCESS: 'UPDATE_BILLING_INFO_SUCCESS',
  UPDATE_BILLING_INFO_FAIL: 'UPDATE_BILLING_INFO_FAIL',
  CREATE_STRIPE_CUSTOMER_START: 'CREATE_STRIPE_CUSTOMER_START',
  CREATE_STRIPE_CUSTOMER_SUCCESS: 'CREATE_STRIPE_CUSTOMER_SUCCESS',
  CREATE_STRIPE_CUSTOMER_FAIL: 'CREATE_STRIPE_CUSTOMER_FAIL',
  PREPARE_STRIPE_PLAN: 'PREPARE_STRIPE_PLAN',
  PREPARE_STRIPE: 'PREPARE_STRIPE',
  FETCH_INVOICES_SUCCESS: 'FETCH_INVOICES_SUCCESS',
  FETCH_INVOICES_FAIL: 'FETCH_INVOICES_FAIL',
  INVOICES: 'INVOICES',
  STRIPE_PRODUCTS: 'STRIPE_PRODUCTS',
  SET_STRIPE_PLAN_FAIL: 'SET_STRIPE_PLAN_FAIL',
  EDIT_STRIPE_PROUDCT: 'EDIT_STRIPE_PROUDCT',
  EDIT_STRIPE_BILLING: 'EDIT_STRIPE_BILLING',
  MENU_CLICK_REGISTERED_SUCCESS: 'MENU_CLICK_REGISTERED_SUCCESS',
  MENU_CLICK_REGISTERED_FAIL: 'MENU_CLICK_REGISTERED_FAIL',
  FIRST: 'FIRST',
  MIDDLE: 'MIDDLE',
  LAST: 'LAST',
  SECOND: 'SECOND',
  IMPORT_EXCEL_START: 'IMPORT_EXCEL_START',
  IMPORT_FIELDS_CREATED: 'IMPORT_FIELDS_CREATED',
  PREPARE_IMPORT_FIELDS: 'PREPARE_IMPORT_FIELDS',
  PREPARE_IMPORT_EXCEL_DATA: 'PREPARE_IMPORT_EXCEL_DATA',
  IMPORT_EXCEL_DATA_START: 'IMPORT_EXCEL_DATA_START',
  IMPORT_EXCEL_DATA_SUCCESS: 'IMPORT_EXCEL_DATA_SUCCESS',
  IMPORT_EXCEL_DATA_FAIL: 'IMPORT_EXCEL_DATA_FAIL',
  IMPORT_EXCEL_FINISHED: 'IMPORT_EXCEL_FINISHED',
  CREATE_IMPORT_DATA_SUCCESS: 'CREATE_IMPORT_DATA_SUCCESS',
  CREATE_IMPORT_DATA_FAIL: 'CREATE_IMPORT_DATA_FAIL',
  FETCH_IMPORT_STATE: 'FETCH_IMPORT_STATE',
  IMPORT_PEOPLE_START: 'IMPORT_PEOPLE_START',
  IMPORT_PEOPLE_FIELDS_CREATED: 'IMPORT_PEOPLE_FIELDS_CREATED',
  PREPARE_PEOPLE_IMPORT_DATA: 'PREPARE_PEOPLE_IMPORT_DATA',
  PREPARE_IMPORT_FIELDS_PEOPLE: 'PREPARE_IMPORT_FIELDS_PEOPLE',
  IMPORT_EXCEL_PEOPLE_START: 'IMPORT_EXCEL_PEOPLE_START',
  IMPORT_EXCEL_PEOPLE_SUCCESS: 'IMPORT_EXCEL_PEOPLE_SUCCESS',
  IMPORT_EXCEL_PEOPLE_FAIL: 'IMPORT_EXCEL_PEOPLE_FAIL',
  IMPORT_COMPANIES_START: 'IMPORT_COMPANIES_START',
  IMPORT_COMPANIES_FIELDS_CREATED: 'IMPORT_COMPANIES_FIELDS_CREATED',
  PREPARE_COMPANIES_IMPORT_DATA: 'PREPARE_COMPANIES_IMPORT_DATA',
  PREPARE_IMPORT_FIELDS_COMPANIES: 'PREPARE_IMPORT_FIELDS_COMPANIES',
  IMPORT_EXCEL_COMPANIES_START: 'IMPORT_EXCEL_COMPANIES_START',
  IMPORT_EXCEL_COMPANIES_SUCCESS: 'IMPORT_EXCEL_COMPANIES_SUCCESS',
  IMPORT_EXCEL_COMPANIES_FAIL: 'IMPORT_EXCEL_COMPANIES_FAIL',
  IMPORT_EXCEL_BLOCK_START: 'IMPORT_EXCEL_BLOCK_START',
  PREPARE_IMPORT_EXCEL_BLOCK_DATA: 'PREPARE_IMPORT_EXCEL_BLOCK_DATA',
  IMPORT_FIELDS_BLOCK_CREATED: 'IMPORT_FIELDS_BLOCK_CREATED',
  PREPARE_IMPORT_BLOCK_FIELDS: 'PREPARE_IMPORT_BLOCK_FIELDS',
  IMPORT_EXCEL_BLOCK_SUCCESS: 'IMPORT_EXCEL_BLOCK_SUCCESS',
  IMPORT_EXCEL_BLOCK_FAIL: 'IMPORT_EXCEL_BLOCK_FAIL',
  IMPORT_EXCEL_BLOCK_FINISHED: 'IMPORT_EXCEL_BLOCK_FINISHED',
  IMPORT_FIELDS_BLOCK_FAIL: 'IMPORT_FIELDS_BLOCK_FAIL',
  IMPORT_FIELDS_FAIL: 'IMPORT_FIELDS_FAIL',
  IMPORT_PEOPLE_FIELDS_FAIL: 'IMPORT_PEOPLE_FIELDS_FAIL',
  IMPORT_COMPANIES_FIELDS_FAIL: 'IMPORT_COMPANIES_FIELDS_FAIL',
  PREPARE_REOCCURING_OBJECT: 'PREPARE_REOCCURING_OBJECT',
  CREATE_REOCCURING_STARTED: 'CREATE_REOCCURING_STARTED',
  CREATE_REOCCURING_SUCCESS: 'CREATE_REOCCURING_SUCCESS',
  CREATE_REOCCURING_FAILED: 'CREATE_REOCCURING_FAILED',
  FETCH_REOCCURING_STATE: 'FETCH_REOCCURING_STATE',
  OPEN_REOCCURING_MODAL: 'OPEN_REOCCURING_MODAL',
  CLOSE_REOCCURING_MODAL: 'CLOSE_REOCCURING_MODAL',
  FIND_PERSON_BY_EMAIL_SUCCESS: 'FIND_PERSON_BY_EMAIL_SUCCESS',
  FIND_PERSON_BY_EMAIL_FAIL: 'FIND_PERSON_BY_EMAIL_FAIL',
  UNSET_OBJECT_PERSON: 'UNSET_OBJECT_PERSON',
  CLEAR_OBJECT_SELETION: 'CLEAR_OBJECT_SELETION',
  SEND_BULK_EMAIL_START: 'SEND_BULK_EMAIL_START',
  SEND_BULK_EMAIL_SUCCESS: 'SEND_BULK_EMAIL_SUCCESS',
  SEND_BULK_EMAIL_FAIL: 'SEND_BULK_EMAIL_FAIL',
  RESET_BULK_EMAIL_STATUS: 'RESET_BULK_EMAIL_STATUS',
  SET_FILTER_CONNECTED_OBJECT: 'SET_FILTER_CONNECTED_OBJECT',
  CONNECT_PERSON_OBJECT_SUCCESS: 'CONNECT_PERSON_OBJECT_SUCCESS',
  CONNECT_PERSON_OBJECT_FAIL: 'CONNECT_PERSON_OBJECT_FAIL',
  FETCH_BADGE_FRAMEWORKS_START: 'FETCH_BADGE_FRAMEWORKS_START',
  FETCH_BADGE_FRAMEWORKS_SUCCESS: 'FETCH_BADGE_FRAMEWORKS_SUCCESS',
  FETCH_BADGE_FRAMEWORKS_FAIL: 'FETCH_BADGE_FRAMEWORKS_FAIL',
  FETCH_BADGE_FRAMEWORK_START: 'FETCH_BADGE_FRAMEWORK_START',
  FETCH_BADGE_FRAMEWORK_SUCCESS: 'FETCH_BADGE_FRAMEWORK_SUCCESS',
  FETCH_BADGE_FRAMEWORK_FAIL: 'FETCH_BADGE_FRAMEWORK_FAIL',
  BADGE_FRAMEWORK: 'BADGE_FRAMEWORK',
  CREATE_BADGE_FRAMEWORK_START: 'CREATE_BADGE_FRAMEWORK_START',
  CREATE_BADGE_FRAMEWORK_SUCCESS: 'CREATE_BADGE_FRAMEWORK_SUCCESS',
  CREATE_BADGE_FRAMEWORK_FAIL: 'CREATE_BADGE_FRAMEWORK_FAIL',
  BEGIN_CREATE_BADGE_FRAMEWORK: 'BEGIN_CREATE_BADGE_FRAMEWORK',
  ABORT_CREATE_BADGE_FRAMEWORK: 'ABORT_CREATE_BADGE_FRAMEWORK',
  PREPARE_BADGE_FRAMEWORK: 'PREPARE_BADGE_FRAMEWORK',
  UPDATE_BADGE_FRAMEWORK_START: 'UPDATE_BADGE_FRAMEWORK_START',
  UPDATE_BADGE_FRAMEWORK_SUCCESS: 'UPDATE_BADGE_FRAMEWORK_SUCCESS',
  UPDATE_BADGE_FRAMEWORK_FAIL: 'UPDATE_BADGE_FRAMEWORK_FAIL',
  DELETE_BADGE_FRAMEWORK_START: 'DELETE_BADGE_FRAMEWORK_START',
  DELETE_BADGE_FRAMEWORK_SUCCESS: 'DELETE_BADGE_FRAMEWORK_SUCCESS',
  DELETE_BADGE_FRAMEWORK_FAIL: 'DELETE_BADGE_FRAMEWORK_FAIL',
  CREATE_BADGE_START: 'CREATE_BADGE_START',
  CREATE_BADGE_SUCCESS: 'CREATE_BADGE_SUCCESS',
  CREATE_BADGE_FAIL: 'CREATE_BADGE_FAIL',
  BEGIN_CREATE_BADGE: 'BEGIN_CREATE_BADGE',
  ABORT_CREATE_BADGE: 'ABORT_CREATE_BADGE',
  PREPARE_BADGE: 'PREPARE_BADGE',
  CREATE_ACCOUNT_START: 'CREATE_ACCOUNT_START',
  CREATE_ACCOUNT_SUCCESS: 'CREATE_ACCOUNT_SUCCESS',
  CREATE_ACCOUNT_FAIL: 'CREATE_ACCOUNT_FAIL',
  CREATE_CLIENT_START: 'CREATE_CLIENT_START',
  CREATE_CLIENT_SUCCESS: 'CREATE_CLIENT_SUCCESS',
  CREATE_CLIENT_FAIL: 'CREATE_CLIENT_FAIL',
  CREATE_PROJECT_START: 'CREATE_PROJECT_START',
  CREATE_PROJECT_SUCCESS: 'CREATE_PROJECT_SUCCESS',
  CREATE_PROJECT_FAIL: 'CREATE_PROJECT_FAIL',
  OPEN_VIDEO_PLAYER: 'OPEN_VIDEO_PLAYER',
  CLOSE_VIDEO_PLAYER: 'CLOSE_VIDEO_PLAYER',
};

export default C;
