import C from '../constants/constants';
import $ from '../../libs/reduxOperations';

export const initialState = {
  globalSchedules: {},
  calendarSchedules: [],
  calendarView: C.MONTH,
  showLocation: true,
  showStatus: true,
  schedulePending: false,
  globalPending: false,
  isCreating: false,
  isEditingSchedule: false,
  nextPage: 1,
  currentPage: 1,
  paginationReachedBottom: false,
  preparedSchedule: {},
  pageSize: 50,
  calendarSuccessHash: ""
};

const didPaginationReachedBottom = (paginationReachedBottom, schedules, reset) => {
  if(reset) return false;
  if(!schedules.length) return true;
  return paginationReachedBottom;
};

export default function scheduleReducer(state = initialState, action) {
  switch(action.type) {
    case C.FETCH_CALENDAR_SCHEDULE_SUCCESS:
      return { ...state, globalPending: false, calendarSchedules: action.calendarSchedules, calendarSuccessHash: action.requestHash };
    case C.DELETE_OBJECT_SUCCESS:
      return { ...state, calendarSchedules: $.removeItemWithIdFromList(state.calendarSchedules, action.objectId) };
    case C.FETCH_GLOBAL_SCHEDULE_SUCCESS:
      return {
        ...state,
        globalPending: false,
        nextPage: action.reset ? 1 : state.nextPage + 1,
        globalSchedules: action.globalSchedules || state.globalSchedules, // $.addOrUpdateObjectWithObjects(state.globalSchedules, action.globalSchedules),
        paginationReachedBottom: didPaginationReachedBottom(state.paginationReachedBottom, action.globalSchedules ? Object.values(action.globalSchedules) : [], action.reset),
      };
    case C.FETCH_CALENDAR_SCHEDULE_START:
    case C.FETCH_GLOBAL_SCHEDULE_START:
      return {
        ...state,
        globalPending: true,
      };

    case C.FETCH_CALENDAR_SCHEDULE_FAIL:
    case C.FETCH_GLOBAL_SCHEDULE_FAIL:
      return {
        ...state,
        globalPending: false,
        error: action.error,
      };

    case C.CREATE_SCHEDULE_SUCCESS:
    case C.FETCH_SCHEDULE_SUCCESS:
      return {
        ...state,
        globalSchedules: $.addOrUpdateObjectWithObjects(state.globalSchedules, action.globalSchedules),
        isEditingSchedule: false,
        schedulePending: false,
        isCreating: false,
      };

    case C.UPDATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        globalSchedules: $.addOrUpdateObjectWithObjects(state.globalSchedules, action.globalSchedules),
        schedulePending: false,
        isEditingSchedule: false,
        selectedTasks: [],
      };

    case C.DELETE_SCHEDULE_SUCCESS:
      return {
        ...state,
        schedulePending: false,
        globalSchedules: $.removeObjectFromObjectById(state.globalSchedules, action.scheduleId),
      };

    case C.BEGIN_EDIT_SCHEDULE:
      return {
        ...state,
        isEditingSchedule: true,
        isCreating: action.isCreating,
        preparedSchedule: action.schedule,
      };
    case C.ABORT_EDIT_SCHEDULE:
    case C.CREATE_BLOCK_SUCCESS:
    case C.UPDATE_BLOCK_SUCCESS:
      document.body.classList.remove('locked');
      return {
        ...state,
        isEditingSchedule: false,
        isCreating: false,
        preparedSchedule: {},
        globalSchedules: $.addOrUpdateObjectWithObjects(state.globalSchedules, action.globalSchedules),
        schedulePending: false,
      };

    case C.PREPARE_SCHEDULE:
      return {
        ...state,
        preparedSchedule: action.schedule,
      };

    case C.SET_CALENDAR_VIEW:
      return { ...state, calendarView: action.view };

    case C.TOGGLE_SHOW_STATUS:
      return { ...state, showStatus: !state.showStatus };

    case C.TOGGLE_SHOW_LOCATION:
      return { ...state, showLocation: !state.showLocation };

    case C.FETCH_PROJECT_SETTINGS_SUCCESS:
    case C.FETCH_TASKS_SUCCESS:
    case C.FETCH_TASK_SUCCESS:
    case C.CREATE_TASK_SUCCESS:
    case C.UPDATE_TASK_SUCCESS:
    case C.FETCH_OBJECTS_SUCCESS:
    case C.FETCH_OBJECT_TYPES_SUCCESS:
    case C.FETCH_OBJECT_SUCCESS:
      return {
        ...state,
        globalSchedules: $.addOrUpdateObjectWithObjects(state.globalSchedules, action.globalSchedules),
      };

    case C.FETCH_GLOBAL_SCHEDULE_LOCAL:
      return {
        ...state,
        nextPage: action.reset ? 1 : state.nextPage + 1,
        currentPage: state.nextPage,
        paginationReachedBottom: didPaginationReachedBottom(
          state.paginationReachedBottom,
          Object.values(state.globalSchedules) ? Object.values(state.globalSchedules).slice(state.nextPage * state.pageSize, (state.nextPage + 1) * state.pageSize) : [],
          action.reset,
        ),
      };

    case C.CREATE_SCHEDULE_START:
      return {
        ...state,
        schedulePending: true,
      };

    default:
      return state;
  }
}
