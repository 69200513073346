// @flow
import type { IdMap, Id } from '../App/entities/FlowTypes';
import { IdMapEntries } from '../App/entities/FlowTypes';

export default {
  addOrUpdateListWithList(oldList: any, data: any) {
    const list = oldList.slice();

    data.forEach((d) => {
      const index = list.findIndex(l => l.id === d.id);
      if(index > -1) {
        list.splice(index, 1, d);
      } else {
        list.push(d);
      }
    });

    return list;
  },

  addOrUpdateListWithListOfIds(oldList: any, data: any) {
    const list = oldList.slice();

    data.forEach((d) => {
      const index = list.findIndex(l => l === d);
      if(index > -1) {
        list.splice(index, 1, d);
      } else {
        list.push(d);
      }
    });

    return list;
  },

  addOrUpdateObject(oldObject: any, newObject: any, reset: boolean = false) {
    if(reset) return newObject || {};
    return { ...oldObject, ...newObject };
  },

  addOrUpdateObjectWithObjects<T>(oldObjects: IdMap<T>, newObjects: IdMap<T>, reset: boolean = false): IdMap<T> {
    if(reset) return newObjects || {};
    const mergedObjects = {};
    IdMapEntries(newObjects).forEach(([id, obj]) => {
      mergedObjects[id] = { ...oldObjects[id], ...obj };
    });
    return { ...oldObjects, ...mergedObjects };
  },

  removeItemWithIdFromList(oldList: any, id: any) {
    const list = oldList.slice();

    const index = list.findIndex(l => l.id === id);
    if(index > -1) list.splice(index, 1);

    return list;
  },

  removeFromArray<T>(arr: T[], item: T): T[] {
    const index = arr.findIndex(elem => item === elem);
    if(index === -1) return arr;

    const arrCopy = [].concat(arr);
    arrCopy.splice(index, 1);
    return arrCopy;
  },

  addObjectToObject<T: { id: Id }>(objects: IdMap<T>, item: T, reset: boolean = false): IdMap<T> {
    const newListObject = { ...objects };
    newListObject[item.id] = reset ? { ...item } : { ...newListObject[item.id], ...item };
    return newListObject;
  },

  addOrRemoveObjectFromObject<T: { id: Id }>(objects: IdMap<T>, item: T): IdMap<T> {
    const newListObject = { ...objects };
    const removed = this.removeObjectFromObject(newListObject, item);
    const added = this.addObjectToObject(newListObject, item);
    if(Object.values(removed).length !== Object.values(newListObject).length) return removed;
    return added;
  },

  addArrayOfObjectsToObject(listObject: any, itemArray: any) {
    const newListObject = { ...listObject };
    itemArray.forEach((item) => {
      newListObject[item.id] = { ...newListObject[item.id], ...item };
    });
    return newListObject;
  },

  addOrUpdateObjectInArrayById<T: { id: Id }>(arr: T[], item: T): T[] {
    const newArr = [].concat(arr);
    const index = newArr.findIndex(e => e.id === item.id);
    if(index === -1) return newArr.concat(item);
    newArr[index] = item;
    return newArr;
  },

  addOrUpdateAttributesInObjectInArrayById(arr: any, item: any) {
    const newArr: any = [].concat(arr);
    const index = newArr.findIndex(e => e.id === item.id);
    if(index === -1) return newArr.concat(item);
    newArr[index] = { ...newArr[index], ...item };
    return newArr;
  },

  removeObjectFromObject(listObject: any, object: any) {
    const newListObject = { ...listObject };
    delete newListObject[object.id];
    return newListObject;
  },

  removeObjectFromObjectById(listObject: any, id: any) {
    const newListObject = { ...listObject };
    delete newListObject[id];
    return newListObject;
  },

  addToArray<T>(arr: T[], item: T): T[] {
    const index = arr.findIndex(e => e === item);
    if(index === -1) return arr.concat(item);
    return arr;
  },

  addOrRemoveFromArray<T>(arr: T[], item: T): T[] {
    const removed = this.removeFromArray(arr, item);
    const added = this.addToArray(arr, item);
    if(removed.length !== arr.length) return removed;
    return added;
  },

  removeFromArrayById<T: { id?: Id }>(arr: T[], item: T): T[] {
    const index = arr.findIndex(elem => item.id === elem.id);
    if(index === -1) return arr;

    const arrCopy: any = [].concat(arr);
    arrCopy.splice(index, 1);
    return arrCopy;
  },

  addToArrayById(arr: any, item: any) {
    const index = arr.findIndex(e => e.id === item.id);
    if(index === -1) return arr.concat(item);
    return arr;
  },

  addOrRemoveFromArrayById(arr: any, item: any) {
    const removed = this.removeFromArrayById(arr, item);
    const added = this.addToArray(arr, item);
    if(removed.length !== arr.length) return removed;
    return added;
  },

  addOrRemoveFromArrayByKey(arr: any, item: any, key: any) {
    const removed = this.removeFromArrayByKey(arr, item, key);
    const added = this.addToArray(arr, item);
    if(removed.length !== arr.length) return removed;
    return added;
  },

  updateObjectById<T>(objects: IdMap<T>, id: Id, newObject: T): IdMap<T> {
    const oldObject = objects[id];
    if (!oldObject) return objects;
    return {
      ...objects,
      [id]: {
        ...oldObject, ...newObject,
      },
    };
  },

  updateObjectWithMethodById<T>(objects: IdMap<T>, id: Id, update: T => T): IdMap<T> {
    const oldObject = objects[id];
    if (!oldObject) return objects;
    return {
      ...objects,
      [id]: {
        ...update(oldObject),
      },
    };
  },

  removeFromArrayByKey(arr: any, item: any, key: any) {
    const index = arr.findIndex(elem => item[key] === elem[key]);
    if(index === -1) return arr;

    const arrCopy: any = [].concat(arr);
    arrCopy.splice(index, 1);
    return arrCopy;
  },

  removeFromObjectById<U: { id?: number }, T: { [number]: U | void }>(object: T, item: U): T {
    const { ...clone } = object;
    if (item.id) delete clone[item.id];
    return clone;
  },

  intersect(a: any, b: any) {
    const bset = new Set(b);
    if(!a) return false;
    if(!b) return true;
    const intersection = new Set(a.filter(x => bset.has(x)));
    return (intersection && intersection.size);
  },

  intersectAll(a: any, b: any) {
    const bset = new Set(b);
    if(!a) return false;
    if(!b) return true;
    const intersection = new Set(a.filter(x => bset.has(x)));
    return (intersection && intersection.size && intersection.size === a.length);
  },

  addOrUpdateByTempId(arr: any[], elem: any) {
    const index = arr.findIndex(a => a.tempId === elem.tempId);
    const newArr: any[] = arr.slice();
    if(index !== -1) {
      newArr.splice(index, 1, elem);
    } else {
      newArr.push(elem);
    }
    return newArr;
  },

  addOrUpdateListOfObjectsWithObjectById(oldList: any, object: any) {
    const newObject = { ...oldList.find(o => o.id === object.id), ...object };
    const index = oldList.findIndex(a => a.id === object.id);
    const newArr: any[] = oldList.slice();
    if(index !== -1) {
      newArr.splice(index, 1, newObject);
    } else {
      newArr.push(newObject);
    }
    return newArr;
  },

  addOrReplaceObjectWithObjects<T>(oldObjects?: ?IdMap<T>, newObjects?: ?IdMap<T>, reset?: boolean = false): ?IdMap<T> {
    if(reset) return newObjects || {};
    if(!newObjects) return oldObjects;
    const mergedObjects = {};
    IdMapEntries(newObjects).forEach(([id, obj]) => {
      mergedObjects[id] = { ...obj };
    });
    return { ...oldObjects, ...mergedObjects };
  },

};
