// @flow
import type { SetupState } from 'App/entities/FlowTypes';
import C from '../constants/constants';
import $ from '../../libs/reduxOperations';

export const initialState: SetupState = {
  blockEditor: {
    isEditingExternal: false,
    preparedObjectTypes: {},
    preparedBlockTypes: {},
    preparedFieldTypes: {},
    preparedStatuses: {},
    selectedObjectTypes: {},
  },
};

export default function reducer(state: SetupState = initialState, action: any) {
  switch (action.type) {
    case C.PREPARE_BLOCK_TYPE:
      return {
        ...state,
        blockEditor: {
          ...state.blockEditor,
          preparedBlockTypes: $.addObjectToObject<any>(state.blockEditor.preparedBlockTypes, action.blockType),
        },
      };

    case C.UNPREPARE_BLOCK_TYPE: {
      const preparedBlockTypes = { ...state.blockEditor.preparedBlockTypes };
      delete preparedBlockTypes[action.blockTypeId];
      return {
        ...state,
        blockEditor: {
          ...state.blockEditor,
          preparedBlockTypes,
        },
      };
    }

    case C.PREPARE_FIELD_TYPE:
      return {
        ...state,
        blockEditor: {
          ...state.blockEditor,
          preparedFieldTypes: $.addObjectToObject<any>(state.blockEditor.preparedFieldTypes, action.fieldType, true),
        },
      };

    case C.PREPARE_STATUS:
      return {
        ...state,
        blockEditor: {
          ...state.blockEditor,
          preparedStatuses: $.addObjectToObject<any>(state.blockEditor.preparedStatuses, action.status, true),
        },
      };

    case C.SET_EDIT_EXTERNAL:
      return {
        ...state,
        blockEditor: {
          ...state.blockEditor,
          isEditingExternal: action.value,
        },
      };

    case C.PREPARE_OBJECT_TYPE:
      return {
        ...state,
        blockEditor: {
          ...state.blockEditor,
          preparedObjectTypes: $.addObjectToObject<any>(state.blockEditor.preparedObjectTypes, action.objectType),
        },
      };

    case C.UNPREPARE_OBJECT_TYPE: {
      const preparedObjectTypes = { ...state.blockEditor.preparedObjectTypes };
      delete preparedObjectTypes[action.objectTypeId];
      return {
        ...state,
        blockEditor: {
          ...state.blockEditor,
          preparedObjectTypes,
          preparedStatuses: {},
        },
      };
    }

    case C.UNPREPARE_FIELD_TYPE: {
      const preparedFieldTypes = { ...state.blockEditor.preparedFieldTypes };
      delete preparedFieldTypes[action.fieldTypeId];
      return {
        ...state,
        blockEditor: {
          ...state.blockEditor,
          preparedFieldTypes,
        },
      };
    }

    case C.UNPREPARE_STATUS: {
      const preparedStatuses = { ...state.blockEditor.preparedStatuses };
      delete preparedStatuses[action.fieldTypeId];
      return {
        ...state,
        blockEditor: {
          ...state.blockEditor,
          preparedStatuses,
        },
      };
    }

    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        blockEditor: {
          isEditingExternal: state.blockEditor.isEditingExternal,
          preparedObjectTypes: {},
          preparedBlockTypes: {},
          preparedFieldTypes: {},
        },
      };

    default:
      return state;
  }
}
