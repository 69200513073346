// @flow

import React from 'react';

import s from './Loader.module.scss';

const Loader = (props?: any = { width: 4, size: 42 }) => {
  const diff = props.width && props.size && props.size - props.width;
  return (
    <div className={s.loader} style={{ width: `${props.size}px`, height: `${props.size}px` }}>
      <div style={{ width: `${diff}px`, height: `${diff}px` }} />
      <div style={{ width: `${diff}px`, height: `${diff}px` }} />
      <div style={{ width: `${diff}px`, height: `${diff}px` }} />
      <div style={{ width: `${diff}px`, height: `${diff}px` }} />
    </div>
  );
};

export default Loader;
