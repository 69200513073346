// @flow
import { uuid } from './utils';

/**
 * Creates a file entity (as in doctrine entity)
 * from an uploaded or dropped file
 */
export default async function processFile(file: any) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return new Promise((resolve) => {
    reader.onload = () => {
      resolve({
        tempId: uuid(),
        base64: reader.result,
        name: file.name,
        file_size: file.size,
        financial: false,
        external: true,
        copyright: null,
        restrictions: null,
        alt_text: null,
        type: file.type,
      });
    };
  });
}
