import moment from 'moment-timezone';
import $ from './reduxOperations';
import { YMD, isOverlapping, enumerateMinutesBetweenDates, isDateTimeInInterval } from '../App/components/calendar/DateUtils';
import I from '../App/constants/inputTypes';
import { IdMapValues } from '../App/entities/FlowTypes';

const checkIfValueHasUserInfo = (value) => {
  const user = JSON.parse(value);
  return !!(user.fullName &&
    user.fullName.length &&
    user.email &&
    user.email.length);
};

export const hasValue = field => ( //eslint-disable-line
  !!((field.value !== undefined && field.value !== null && (field.value.length || !Number.isNaN(parseFloat(field.value)) || Object.values(field.value))) ||
      (field.selected_options && field.selected_options.length) ||
      (field.object_datas && field.object_datas.length) ||
      (field.people && field.people.length) ||
      (field.companies && field.companies.length) ||
      (field.tags && field.tags.length) ||
      (field.schedule && Object.keys(field.schedule).length) ||
      (field.contacts && field.contacts.length) ||
      (field.files && field.files.length) ||
      (field.pendingFiles && field.pendingFiles.length) ||
      (field.contracts && field.contracts.length && !field.contracts.find(con =>
        !(con.organizer && con.organizer.company_name && con.engager && con.engager.company_name && con.organizer.company_name.length > 0 && con.engager.company_name.length > 0))) ||
      (field.economies && field.economies.length)) &&
      (field.input_type !== I.CHECKBOX || (field.input_type === I.CHECKBOX && (field.value !== undefined && field.value !== null))) &&
      (field.input_type !== I.USER || (field.input_type === I.USER && (field.value !== undefined && field.value !== null && checkIfValueHasUserInfo(field.value))))
);

// TODO: Add more value types.
export const maybeGetAnyValue = (field) => {
  if(!field) return null;
  if(field.value) return field.value;
  if((field.input_type === I.SELECT || field.input_type === I.SELECTMULTIPLE) && field.selected_options && field.selected_options.length) {
    const returnString = field.selected_options.map(option => option.value).join(' ');
    return returnString;
  }
  if(field.tags && field.tags.length) {
    const returnString = field.tags.map(tag => tag.name).join(' ');
    return returnString;
  }
  return null;
};

export const globalScheduleData = (entry, types = [], objectDatas = {}, blockDatas = {}, fieldDatas = {}, fieldTypes = {}, selectOptions = {}, tasks = {}) => {
  const objData = objectDatas[entry.object_data];
  const parentObjects = Object.values(objectDatas).filter(obj => objData && objData.parent_object_datas && objData.parent_object_datas.includes(obj.id));
  const primaryCategoriesObject = objectDatas[objData && objData.primary_category_objects && objData.primary_category_objects[0]];
  const siblingTypeField = (entry.field_data && fieldDatas[entry.field_data] && fieldDatas[entry.field_data].block_data_id && blockDatas[fieldDatas[entry.field_data].block_data_id] && blockDatas[fieldDatas[entry.field_data].block_data_id].field_datas && Object.values(fieldDatas).find(fieldData => blockDatas[fieldDatas[entry.field_data].block_data_id].field_datas.includes(fieldData.id) && fieldData.field_type && fieldTypes[fieldData.field_type] && fieldTypes[fieldData.field_type].input_type === 'SELECT'));
  const siblingTitleField = (entry.field_data && fieldDatas[entry.field_data] && fieldDatas[entry.field_data].block_data_id && blockDatas[fieldDatas[entry.field_data].block_data_id] && blockDatas[fieldDatas[entry.field_data].block_data_id].field_datas && Object.values(fieldDatas).find(fieldData => blockDatas[fieldDatas[entry.field_data].block_data_id].field_datas.includes(fieldData.id) && fieldData.field_type && fieldTypes[fieldData.field_type] && fieldTypes[fieldData.field_type].input_type === 'TAG' && fieldTypes[fieldData.field_type].is_single_tag));
  return {
    ...entry,
    type: (entry.selected_option && selectOptions[entry.selected_option] && selectOptions[entry.selected_option].value)
      || (entry.field_data && fieldDatas[entry.field_data] && fieldDatas[entry.field_data].select_options && fieldDatas[entry.field_data].select_options[0] && fieldDatas[entry.field_data].select_options[0].value)
      || (entry.field_data && fieldDatas[entry.field_data] && fieldDatas[entry.field_data].field_type && fieldTypes[fieldDatas[entry.field_data].field_type].select_options && fieldTypes[fieldDatas[entry.field_data].field_type].select_options[0] && fieldTypes[fieldDatas[entry.field_data].field_type].select_options[0].value)
      || (entry && entry.primary_field && fieldDatas[entry.primary_field] && fieldDatas[entry.primary_field].selected_options && fieldDatas[entry.primary_field].selected_options[0] && fieldDatas[entry.primary_field].selected_options[0].value)
      || (entry.field_data && fieldDatas[entry.field_data] && fieldDatas[entry.field_data].text)
      || (siblingTypeField && siblingTypeField.selected_options && siblingTypeField.selected_options[0] && siblingTypeField.selected_options[0].value)
      || (entry.field_data && fieldDatas[entry.field_data] && fieldDatas[entry.field_data].field_type && fieldTypes[fieldDatas[entry.field_data].field_type] && fieldTypes[fieldDatas[entry.field_data].field_type].text)
      || (entry.task && tasks[entry.task] && 'Task'),
    title: (siblingTitleField && siblingTitleField.tags && siblingTitleField.tags[0] && siblingTitleField.tags[0].name)
      || (entry.task && tasks[entry.task] && tasks[entry.task].title),
    objects: [
      (parentObjects && parentObjects.find(datas => types[0] && types[0].id === datas.object_type_id) && parentObjects.find(datas => types[0].id === datas.object_type_id).primary_field && fieldDatas[parentObjects.find(datas => types[0].id === datas.object_type_id).primary_field] && fieldDatas[parentObjects.find(datas => types[0].id === datas.object_type_id).primary_field].value)
      || (primaryCategoriesObject && primaryCategoriesObject.primary_field && fieldDatas[primaryCategoriesObject.primary_field] && fieldDatas[primaryCategoriesObject.primary_field].value)
      || (parentObjects && parentObjects[0] && parentObjects[0].primary_category_objects && objectDatas[parentObjects[0].primary_category_objects[0]] && objectDatas[parentObjects[0].primary_category_objects[0]].primary_field && fieldDatas[objectDatas[parentObjects[0].primary_category_objects[0]].primary_field] && fieldDatas[objectDatas[parentObjects[0].primary_category_objects[0]].primary_field].value),
      (parentObjects && parentObjects.find(datas => types[1] && types[1].id === datas.object_type_id) && parentObjects.find(datas => types[1].id === datas.object_type_id).primary_field && fieldDatas[parentObjects.find(datas => types[1].id === datas.object_type_id).primary_field] && fieldDatas[parentObjects.find(datas => types[1].id === datas.object_type_id).primary_field].value),
    ],
  };
};

export const getPrimaryFieldValue = (entry, objectDatas = {}, fieldDatas = {}) => {
  if(entry.object_datas && entry.object_datas.length && objectDatas[entry.object_datas[0]] && objectDatas[entry.object_datas[0]].primary_field) {
    const primaryField = fieldDatas[objectDatas[entry.object_datas[0]].primary_field];
    return primaryField ? primaryField.value : '';
  }
  return '';
};

export const addTypeObjectToData = (dataObject, type) => {
  const newDataObject = { ...dataObject };
  newDataObject[type] = { id: (dataObject[type] && (typeof dataObject[type] === 'string' || typeof dataObject[type] === 'number')) ? dataObject[type] : dataObject[`${type}_id`] };
  return newDataObject;
};

export const filterFields = (fields, isDeleting = false) => {
  const valueFields = fields.filter(field => hasValue(field) || isDeleting);
  return valueFields.length ? valueFields.map((field) => {
    const newField = { ...field };
    if(newField.is_being_created) {
      delete newField.id;
    }
    delete newField.translations;
    delete newField.translates;
    return newField;
  }) : valueFields;
};

const applyFilters = filters => !filters.map(filter => filter()).includes(false);

export const filterObjectDatas = (objects, objectDatas, filter, fieldDatas = null) =>
  objects.filter((objData) => {
    const filters = [];
    filters.push(() => !objData.primary_category_object_has_been_filtered);
    if(filter.statuses && filter.statuses.length) filters.push(() => filter.statuses.includes(objData.status));
    if(filter.published) filters.push(() => objData.is_published);
    if(filter.categories && filter.categories.length) filters.push(() => !!$.intersect(filter.categories, objData.primary_category_objects && objData.primary_category_objects.reduce((curr, acc) => ((objectDatas[curr] && objectDatas[curr].primary_category_objects && objectDatas[curr].primary_category_objects) && acc.concat(objectDatas[curr].primary_category_objects)), [])));
    if(filter.exactMatch) {
      if(filter.tags && filter.tags.length) {
        if (objData.primary_tags && objData.primary_tags.length > 0) filters.push(() => !!$.intersectAll(filter.tags, objData.primary_tags));
      }
    } else if (filter.tags && filter.tags.length) {
      if(objData.primary_tags && objData.primary_tags.length > 0) filters.push(() => !!$.intersect(filter.tags, objData.primary_tags));
      else {
        let selected = false;
        if (objData && objData.list_field_datas && objData.list_field_datas.length) {
          objData.list_field_datas.forEach((fieldDataId) => {
            const fieldTags = [];
            fieldDatas[fieldDataId].tags.forEach((tag, i) => {
              fieldTags[i] = tag.id;
            });
            if($.intersect(filter.tags, fieldTags)) selected = true;
          });
        }
        filters.push(() => selected);
      }
    }
    if(filter.users && filter.users.length) filters.push(() => filter.users.includes(objData.added_by && objData.added_by.id));
    if(filter.selectOptions) {
      const filterSelectOptions = Object.values(filter.selectOptions);
      let selected = false;
      const fSO = filterSelectOptions.filter(options => options.length > 0);
      if(fSO.length > 0) {
        fSO.forEach((selectOptions) => {
          selected = false;
          const tempSelected = selectOptions.map((options) => {
            if(objData.external_field_datas) {
              objData.external_field_datas.forEach((fieldDataId) => {
                if(fieldDatas[fieldDataId] && fieldDatas[fieldDataId].selected_options && fieldDatas[fieldDataId].selected_options.filter(op => op.id === options.id).length > 0) selected = true;
              });
            }
            if(objData.list_field_datas) {
              objData.list_field_datas.forEach((fieldDataId) => {
                if(fieldDatas[fieldDataId] && fieldDatas[fieldDataId].selected_options && fieldDatas[fieldDataId].selected_options.filter(op => op.id === options.id).length > 0) selected = true;
              });
            }
            return selected;
          });
          filters.push(() => !!tempSelected.includes(true));
        });
      }
    }
    if(filters && filters.length) return applyFilters(filters);
    return filter && !!filter.published && !!filter.statuses && !!filter.categories && !!filter.tags;
  });

export const filterStaff = (staff, filter = {}, users, assignments = null, objectDatas = {}, fieldDatas = {}) =>
  staff.filter((staf) => {
    const filters = [];
    const user = users && users.length && users.find(u => u.id === staf.user);
    if(user && !user.active) filters.push(() => false);
    if(filter.jobRoles && filter.jobRoles.length) filters.push(() => !!$.intersect(filter.jobRoles, staf.job_roles));
    if(filter.exactMatch) {
      if(filter.tags && filter.tags.length) filters.push(() => !!$.intersectAll(filter.tags, (staf.tags && staf.tags.map(t => t.id))));
    } else if (filter.tags && filter.tags.length) {
      filters.push(() => !!$.intersect(filter.tags, (staf.tags && staf.tags.map(t => t.id))));
    }
    if(filter.titles && filter.titles.length) filters.push(() => !!(filter.titles.find(title => title.toLowerCase() === (staf.city && staf.city.toLowerCase()))));

    if (filter.available) {
      // The assignments in which this user is currently linked to
      const asses = assignments && assignments.filter(ass => ass.user === staf.user_id && ass.schedule);
      // We want to filter out who is not doing a task at the filtered dates
      if (asses && asses.length && filter.startTime) {
        filters.push(() => !asses.find((ass) => {
          const isAfter = moment(ass.schedule.end_time || ass.schedule.start_time) < moment(filter.startTime);
          const isBefore = moment(ass.schedule.start_time) > filter.endTime && moment(filter.endTime);
          if (isAfter || isBefore) return false;
          return !ass.completed;
        }));
      }
    }
    if(filter.connectedObject) {
      if(staf.connected_objects && staf.connected_objects.length) {
        filters.push(() => !!staf.connected_objects.find(co => objectDatas && objectDatas[co] && objectDatas[co].object_type === filter.connectedObject.id));
        if(filter.connectedObject.items.length) {
          filter.connectedObject.items.forEach((item) => {
            if(item.options && item.options.length) {
              const tempSelected = item.options.map((option) => {
                let selected = false;
                if(staf.connected_objects) {
                  staf.connected_objects.forEach((connectedObjectId) => {
                    const connectedObject = objectDatas[connectedObjectId];
                    if(connectedObject.select_field_datas) {
                      connectedObject.select_field_datas.forEach((fieldDataId) => {
                        if(fieldDatas[fieldDataId] && fieldDatas[fieldDataId].selected_options && fieldDatas[fieldDataId].selected_options.filter(op => op.id === option.id).length > 0) selected = true;
                      });
                    }
                  });
                  return selected;
                }
                return selected;
              });
              filters.push(() => !!tempSelected.includes(true));
            }
          });
        }
      } else {
        filters.push(() => false);
      }
    }
    if(filters && filters.length) return applyFilters(filters);
    return filter && !!filter.jobRoles;
  });

export const filterTasks = (tasks, filter, user = null) =>
  tasks.filter((task) => {
    const filters = [];
    if(filter.myTasks && user) filters.push(() => !!task.assignments.find(ass => ass.user === user.id));
    if(filter.exactMatch) {
      if(filter.tags && filter.tags.length) filters.push(() => !!$.intersectAll(filter.tags, (task.tags && task.tags.map(t => t.id))));
    } else if (filter.tags && filter.tags.length) {
      filters.push(() => !!$.intersect(filter.tags, (task.tags && task.tags.map(t => t.id))));
    }
    if(filter && (filter.startDate || filter.endDate)) filters.push(() => !!(!filter.endDate ? task.sort >= filter.startDate : (task.sort >= filter.startDate && YMD(moment(task.sort)) <= filter.endDate)));
    if(filter && filter.statuses && filter.statuses.length) filters.push(() => !!$.intersect(filter.statuses, [task.status && task.status.id]));
    if(filter && filter.categories && filter.categories.length) filters.push(() => !!$.intersect(filter.categories, task.object_datas));
    if(filter.jobRoles && filter.jobRoles.length) filters.push(() => !!$.intersect(filter.jobRoles, task.required_job_roles && task.required_job_roles.map(rjr => rjr.job_role)));
    if(filter.taskPriorities && filter.taskPriorities.length) filters.push(() => filter.taskPriorities.includes(task.priority));
    if(filter && filter.staff && filter.staff.length) {
      const filterStaffPeople = filter.staff.filter(s => s !== -1);
      if(filterStaffPeople) {
        const hasUnassignedTaskFilter = filter.staff.includes(-1);
        const isTaskAssignmentsEmpty = task.assignments.length === 0;
        const taskAssignmentUserMap = task.assignments.map(ass => ass.user);
        if(filterStaffPeople.length) filters.push(() => (!!$.intersect(filterStaffPeople, taskAssignmentUserMap)) || (hasUnassignedTaskFilter && isTaskAssignmentsEmpty));
        if(filterStaffPeople.length === 0 && hasUnassignedTaskFilter) filters.push(() => isTaskAssignmentsEmpty);
      }
    }
    if(filters && filters.length) return applyFilters(filters);
    return filter && !!filter.tags && !filter.myTasks && !filter.startDate && !filter.endDate && !!filter.jobRoles && !!filter.taskPriorities;
  });

export const filterPeople = (people, filter) =>
  people.filter((person) => {
    const filters = [];
    if(filter.exactMatch) {
      if(filter.tags && filter.tags.length) filters.push(() => !!$.intersectAll(filter.tags, (person.tags && person.tags.map(t => t.id))));
    } else if (filter.tags && filter.tags.length) {
      filters.push(() => !!$.intersect(filter.tags, (person.tags && person.tags.map(t => t.id))));
    }
    if(filter.exactTitlesMatch) {
      if(filter.titleTags && filter.titleTags.length) filters.push(() => !!$.intersectAll(filter.titleTags, (person.all_title_titleTags && person.all_title_tags.map(t => t.id))));
    } else if (filter.titleTags && filter.titleTags.length) {
      filters.push(() => !!$.intersect(filter.titleTags, (person.all_title_tags && person.all_title_tags.map(t => t.id))));
    }
    if(filter.titles && filter.titles.length) filters.push(() => !!(filter.titles.find(title => title.toLowerCase() === (person.city && person.city.toLowerCase()))));

    if(filters && filters.length) return applyFilters(filters);
    return filter && !!filter.tags;
  });

export const filterSchedules = (schedules, tags, departments, filter) =>
  schedules.filter((schedule) => {
    const filters = [];
    if(filter && (filter.startDate || filter.endDate)) filters.push(() => !!(!filter.endDate ? schedule.start_time >= filter.startDate : (schedule.start_time >= filter.startDate && YMD(moment(schedule.start_time)) <= filter.endDate)));
    if(filter.titles && filter.titles.length) filters.push(() => !!(filter.titles.find(title => title.toLowerCase() === (schedule.schedule_title && schedule.schedule_title.toLowerCase()))));
    if(filter.primaryCategories && filter.primaryCategories.length && schedule.object_datas) {
      filters.push(() => !!(
        filter.primaryCategories.find(obj1 => schedule.object_datas && schedule.object_datas.find(obj => obj.primary_field && obj.primary_field.id === obj1)) ||
        schedule.object_datas.find(obj => obj.inner_primary_category_objects && obj.inner_primary_category_objects.find(innerObj => innerObj.primary_field && filter.primaryCategories && filter.primaryCategories.includes(innerObj.primary_field.id)))
      ));
    }
    if(filter.secondaryCategories && filter.secondaryCategories.length) filters.push(() => !!(filter.secondaryCategories.find(bok => schedule.object_datas && schedule.object_datas.find(obj => obj.primary_field && obj.primary_field.id === bok))));
    if(filter.tertiaryCategories && filter.tertiaryCategories.length) filters.push(() => !!(filter.tertiaryCategories.find(bok => schedule.object_datas && schedule.object_datas.find(obj => obj.primary_field && obj.primary_field.id === bok))));
    if(filter.departments && filter.departments.length) filters.push(() => !!(filter.departments.find(dept => departments[dept] && schedule.departments && !!schedule.departments.find(schDept => schDept.id === dept))));
    if(filters && filters.length) return applyFilters(filters);
    return filter && !filter.startDate && !filter.endDate && !!filter.tags && !!filter.primaryCategories && !!filter.secondaryCategories && !!filter.departments;
  });

export const filterFiles = (files, objectDatas, filter) =>
  files.filter((file) => {
    const object = file.object_datas && file.object_datas.length > 0 && (objectDatas[file.object_datas[0]] && objectDatas[file.object_datas[0]]);
    const primaryField = object && object.primary_field;
    const inDate = (filter.startDate || filter.endDate) && !!(!filter.endDate ? file.created_at >= filter.startDate : (file.created_at >= filter.startDate && YMD(moment(file.created_at)) <= filter.endDate));
    let inTags;
    if(filter.exactMatch) {
      if(filter.tags && filter.tags.length) inTags = $.intersectAll(filter.tags, file.tags.map(t => t.id));
    } else if (filter.tags && filter.tags.length) inTags = $.intersect(filter.tags, file.tags.map(t => t.id));
    // const inTags = filter.tags && filter.tags.length && $.intersect(filter.tags, file.tags.map(t => t.id));

    const inprimaryCategory = filter.primaryCategories.includes(primaryField) || (object && object.inner_primary_category_objects && object.inner_primary_category_objects.find(innerObj => objectDatas[innerObj] && objectDatas[innerObj].primary_field && filter.primaryCategories && filter.primaryCategories.includes(objectDatas[innerObj].primary_field)));
    const insecondaryCategory = filter.secondaryCategories.includes(primaryField);

    const noFilter = filter.primaryCategories.length === 0 && filter.secondaryCategories.length === 0 && filter.tags.length === 0 && filter.startDate === null && filter.endDate === null;

    return inTags || inprimaryCategory || insecondaryCategory || inDate || noFilter;
  });

export const filterObjectSchedules = (schedules, filter) =>
  schedules.filter((schedule) => {
    const filters = [];
    if(filter.statuses && filter.statuses.length && schedule.status) filters.push(() => filter.statuses.includes(schedule.status.id));
    if(filter.categories && filter.categories.length && schedule.primary_category_objects.length && schedule.primary_category_objects[0].primary_category_objects) {
      filters.push(() => !!$.intersect(filter.categories, schedule.primary_category_objects[0].primary_category_objects.map(obj => obj.id)));
    }
    if(filter.tags && filter.tags.length && schedule.primary_tags) filters.push(() => !!$.intersect(filter.tags, schedule.primary_tags.map(tag => tag.id)));
    if(filters && filters.length) return applyFilters(filters);
    return filter && !!filter.statuses && !!filter.categories && !!filter.tags;
  });

export const filterEconomies = (economies, filter) =>
  economies.filter((economy) => {
    const filters = [];
    if(filter.objects && filter.objects.length) filters.push(() => !!economy.object_data && filter.objects.includes(economy.object_data.id));
    if(filter.connectedTo && filter.connectedTo.length) filters.push(() => !!economy.connected_to && filter.connectedTo.includes(economy.connected_to.id));
    if(filter && (filter.startDate || filter.endDate)) filters.push(() => !!(economy.date && (!filter.endDate ? economy.date.start_time >= filter.startDate : (economy.date.start_time >= filter.startDate && YMD(moment(economy.date.start_time)) <= filter.endDate))));
    if(filters && filters.length) return applyFilters(filters);
    return filter && !!filter.objects && !!filter.connectedTo;
  });

export const sortedObjectValuesByKeys = object => Object.keys(object).slice().sort((a, b) => a - b).map(k => object[k]);

export const getBlocksInObject = (object, blockTypes, blockDatas, preparedBlocks, filter, showExternal = false, showAll = false) => {
  const filterBlock = block => blockTypes[block.block_type_id] && blockTypes[block.block_type_id].active && (filter.departments && filter.departments.length ? $.intersect(blockTypes[block.block_type_id].departments && blockTypes[block.block_type_id].departments, filter.departments) : true);

  if (!object) return [];
  let parsedBlockTypes = [];
  if(showAll) parsedBlockTypes = Object.values(blockTypes).filter(block => (block.object_type_id === object.object_type && filterBlock(block)));
  else parsedBlockTypes = Object.values(blockTypes).filter(block => (block.object_type_id === object.object_type && (showExternal ? block.is_external : !block.is_external) && filterBlock(block)));
  const parsedBlockDatas = Object.values(blockDatas).filter(block => (block.object_data_id === object.id && filterBlock(block)));
  const blocks = parsedBlockTypes.map((blockType) => {
    const { id, ...filteredBlockType } = blockType;
    return ({ ...filteredBlockType, ...(Object.values(preparedBlocks).find(prepBlock => prepBlock.block_type_id === blockType.id) || (parsedBlockDatas && parsedBlockDatas.find(blockData => blockData.block_type_id === blockType.id))) });
  });
  return blocks;
};

export const findSchedulingBlock = (objectData, blockDatas, subObjectBlockType) => (
  Object.values(blockDatas)
    .find(blockData => (subObjectBlockType && blockData.block_type === subObjectBlockType.id) && objectData.block_datas && objectData.block_datas.find(innerBlockData => innerBlockData === blockData.id))
    || (objectData && objectData.block_datas && blockDatas[objectData.block_datas[0]])
);

export const findSchedulingFields = (objectData, fieldDatas, blockDatas, objectDatas, subObjectBlockType, onlyExternal) => {
  const block = findSchedulingBlock(objectData, blockDatas, subObjectBlockType);
  const fields = Object.values(fieldDatas).filter(fieldData => (fieldData.input_type === 'SCHEDULING' && ((block && block.field_datas && block.field_datas.includes(fieldData.id) && (onlyExternal ? fieldData.schedule && fieldData.schedule.is_external : true)) ||
    (objectData.primary_category_objects && objectData.primary_category_objects.length && objectData.primary_category_objects.find(o => objectDatas[o] && objectDatas[o].primary_category_objects && objectDatas[o].primary_date_fields && objectDatas[o].primary_date_fields.includes(fieldData.id)))
    || (objectData.is_primary_category_for_objects && objectData.is_primary_category_for_objects.length && objectData.is_primary_category_for_objects.find(o => objectDatas[o] && objectDatas[o].primary_date_fields && objectDatas[o].primary_date_fields.includes(fieldData.id))))));
  return fields;
};

export const nullComparator = (a, b) => {
  if ((typeof a !== 'undefined' && typeof b === 'undefined') || (a !== null && b === null)) return -1;
  if ((typeof a === 'undefined' && typeof b !== 'undefined') || (a === null && b !== null)) return 1;
  return 0;
};

export const updateListFieldData = (objects = [], fields = []) => {
  const newObjectList = objects.map(obj => fields.find(f => f.list && obj.block_datas && obj.block_datas.includes(f.block_data_id)) && ({ ...obj, list_field_datas: fields.filter(f => f.list && obj.block_datas && obj.block_datas.includes(f.block_data_id)).map(f => f.id) })).filter(Boolean);
  const newObjects = {};
  newObjectList.forEach((obj) => {
    newObjects[obj.id] = obj;
  });
  return newObjects;
};

export const updateObjectsBlockDatas = (objects = [], blockDatas = []) => {
  const newObjectList = objects.map(obj => ({
    ...obj,
    block_datas: $.addToArray(obj.block_datas || [], blockDatas.filter(blockData =>
      blockData.object_data_id && obj.id && blockData.object_data_id === obj.id &&
      !(obj.block_datas && obj.block_datas.find(obd => obd === blockData.id))).map(blockData => blockData.id)),
  })).filter(Boolean);
  const newObjects = {};
  newObjectList.forEach((obj) => {
    newObjects[obj.id] = obj;
  });
  return newObjects;
};

export const getDateTimesFromObject = (entry, objectDatas, fieldDatas) => {
  if(!entry || !fieldDatas) return [];
  // const primaryDateFields = Object.values(fieldDatas).filter(fd => entry.primary_date_fields && entry.primary_date_fields.find(id => id === fd.id));
  const primaryCategories = Object.values(objectDatas).filter(obj => entry.primary_category_objects && entry.primary_category_objects.includes(obj.id));
  if(!primaryCategories) return [];
  const times = primaryCategories.map((primaryDateField) => {
    if(!primaryDateField.primary_date_fields || !primaryDateField.primary_date_fields[0] || !fieldDatas[primaryDateField.primary_date_fields[0]] || !fieldDatas[primaryDateField.primary_date_fields[0]].schedule) return null;
    return ({
      startTime: fieldDatas[primaryDateField.primary_date_fields[0]].schedule.start_time,
      endTime: fieldDatas[primaryDateField.primary_date_fields[0]].schedule.end_time,
      time_set: fieldDatas[primaryDateField.primary_date_fields[0]].schedule.time_set,
      date_set: fieldDatas[primaryDateField.primary_date_fields[0]].schedule.date_set,
      primaryCategory: primaryDateField.id,
    });
  });
  return times.filter(Boolean);
};

export const getDateTimesFromCategoryObject = (entry, objectDatas, fieldDatas) => {
  if(!entry || !fieldDatas) return [];
  if(!entry.primary_date_fields || !entry.primary_date_fields[0] || !fieldDatas[entry.primary_date_fields[0]] || !fieldDatas[entry.primary_date_fields[0]].schedule) return null;
  return ({
    startTime: fieldDatas[entry.primary_date_fields[0]].schedule.start_time,
    endTime: fieldDatas[entry.primary_date_fields[0]].schedule.end_time,
    time_set: fieldDatas[entry.primary_date_fields[0]].schedule.time_set,
    date_set: fieldDatas[entry.primary_date_fields[0]].schedule.date_set,
  });
};

export const processListFields = (entry, skipPrimary = true, project, fieldDatas) => {
  const values = {};
  const mergedFields = entry.list_field_datas ? (skipPrimary ? [] : [entry.primary_field]).concat(entry.list_field_datas) : [entry.primary_field];
  mergedFields.forEach((id) => {
    // TODO: implement better way of rendering a field
    const fieldData = fieldDatas[id];
    if((fieldData && fieldData.language && fieldData.language.id) === (project && project.primary_language && project.primary_language.id)) {
      values[fieldDatas[id].field_type_id] = fieldDatas[id];
    } else if(!skipPrimary && fieldData && fieldData.field_type_id) values[fieldData.field_type_id] = fieldData;
  });
  return values;
};

export const updateMenuItemsWithObjectType = (objectType, menuItems) => {
  const newMenuItem = { ...Object.values(menuItems).filter(m => m.object_type && m.object_type.id === objectType.id)[0] };
  newMenuItem.object_type = { ...newMenuItem.object_type, ...objectType };
  return newMenuItem;
};

export const sortedObjectValuesByLegends = (object, legends) => {
  const sortedObject = {};
  Object.keys(legends).forEach((i) => {
    Object.keys(object).forEach((k) => {
      if(object[k].field_type_id === legends[i].id) sortedObject[i] = object[k];
    });
    if(!sortedObject[i]) sortedObject[i] = { value: '' };
  });
  return sortedObject;
};

export const tagsComparator = tags => (a, b) => {
  if (!a.tags || !b.tags) {
    return nullComparator(a.tags, b.tags);
  }
  const tagStringA = Object.values(tags) && a.tags && a.tags.map(tag => Object.values(tags).find(t => t.id === tag.id).name).join(', ');
  const tagStringB = Object.values(tags) && b.tags && b.tags.map(tag => Object.values(tags).find(t => t.id === tag.id).name).join(', ');
  return (''.concat(tagStringA)).localeCompare(tagStringB);
};

export const filterInternalList = (internalList, fieldDatas, filter) => {
  let filters;
  let tempList = internalList.filter((entry) => {
    filters = [];
    const filterSelectOptions = Object.values(filter.selectOptions);
    let selected = false;
    if(filterSelectOptions.filter(options => options.length > 0).length > 0) {
      filterSelectOptions.forEach((selectOptions) => {
        selectOptions.forEach((options) => {
          entry.external_field_datas.forEach((fieldDataId) => {
            if(fieldDatas[fieldDataId].selected_options.filter(op => op.id === options.id).length > 0) selected = true;
          });
        });
      });
      filters.push(() => selected);
    }
    if(filters && filters.length) return applyFilters(filters);
    return filter && !filter.filterSelectOptions;
  });
  tempList = tempList.filter((entry) => {
    filters = [];
    let selected = false;
    if(filter && (filter.startDate || filter.endDate)) {
      entry.external_field_datas.forEach((fieldDataId) => {
        const startTime = fieldDatas[fieldDataId].schedule && fieldDatas[fieldDataId].schedule.start_time && YMD(moment(fieldDatas[fieldDataId].schedule.start_time));
        // const endTime = fieldDatas[fieldDataId].schedule.end_time && YMD(moment(fieldDatas[fieldDataId].schedule.end_time));
        if(!filter.endDate ? startTime >= filter.startDate : (startTime >= filter.startDate && YMD(moment(startTime)) <= filter.endDate)) selected = true;
      });
      filters.push(() => selected);
    }
    if(filters && filters.length) return applyFilters(filters);
    return filter && !filter.filterSelectOptions;
  });
  tempList = tempList.filter((entry) => {
    filters = [];
    let selected = false;
    if(filter.tags && filter.tags.length > 0) {
      filter.tags.forEach((tag) => {
        entry.external_field_datas.forEach((fieldDataId) => {
          if(fieldDatas[fieldDataId].tags.filter(t => t.id === tag.id).length > 0) selected = true;
        });
      });
      filters.push(() => selected);
    }
    if(filters && filters.length) return applyFilters(filters);
    return filter && !filter.filterSelectOptions;
  });
  return tempList;
};

export const filterInternalContactList = (cons, filter) =>
  cons.filter((con) => {
    const tags = con.person && con.person.tags && con.person.tags.map(t => t.id);
    const titleTags = con.title_tags && con.title_tags.map(t => t.id);
    const intersect = filter.exactMatch ? $.intersectAll : $.intersect;
    const filters = [];
    if (filter.tags && filter.tags.length) {
      filters.push(() => !!intersect(filter.tags, tags));
    }
    if (filter.titleTags && filter.titleTags.length) {
      filters.push(() => !!intersect(filter.titleTags, titleTags));
    }
    if(filters.length) return applyFilters(filters);
    return filter && !filter.filterSelectOptions;
  });

export const filterInternalScheduleList = (internalList, filter, mainObject) =>
  internalList.filter((entry) => {
    const filters = [];
    const schedule = entry.schedulingField && entry.schedulingField.schedule;
    if(filter && (filter.startDate || filter.endDate)) {
      if(schedule && schedule.start_time) {
        filters.push(() => !!(!filter.endDate ? schedule.start_time >= filter.startDate :
          (schedule.start_time >= filter.startDate && YMD(moment(schedule.start_time)) <= filter.endDate)));
      } else filters.push(() => false);
    }
    if(filter && filter.categories.length > 0) {
      const relatedObject = schedule && schedule.object_datas && schedule.object_datas.find(obj => obj && obj.object_type && obj.object_type.id !== mainObject.object_type_id);
      filters.push(() => !!(relatedObject && filter.categories.find(cat => (relatedObject.id === cat))));
    }
    if(filter && filter.titles && filter.titles.length > 0) {
      const scheduleTitle = schedule && schedule.schedule_title;
      filters.push(() => !!(scheduleTitle && filter.titles.find(title => title === scheduleTitle)));
    }
    if(filters && filters.length) return applyFilters(filters);
    return filter && !filter.filterSelectOptions;
  });

export const resetDate = (schedule) => {
  const newSchedule = { ...schedule };
  if(newSchedule.time_set) {
    const newStartDate = moment();
    newStartDate.hour(moment(schedule.start_time).hour());
    newStartDate.minute(moment(schedule.start_time).minute());
    newSchedule.start_time = newStartDate;
  }
  if(newSchedule.time_set && newSchedule.end_time) {
    const newEndDate = moment();
    newEndDate.hour(moment(schedule.end_time).hour());
    newEndDate.minute(moment(schedule.end_time).minute());
    newSchedule.end_time = newEndDate;
  }
  return newSchedule;
};

export const resetTime = (schedule) => {
  const newSchedule = { ...schedule };
  if(newSchedule.date_set) {
    const newStartDate = moment(schedule.start_time);
    newStartDate.hour(0);
    newStartDate.minute(0);
    newSchedule.start_time = newStartDate;
  }
  if(newSchedule.date_set && newSchedule.end_time) {
    const newEndDate = moment(schedule.end_time);
    newEndDate.hour(0);
    newEndDate.minute(0);
    newSchedule.end_time = newEndDate;
  }
  return newSchedule;
};

export const stringIsTruthy = (val) => {
  const value = val && val.toLowerCase();
  return !!(
    value === 'yes' ||
    value === 'true' ||
    value === 'on' ||
    value === '1'
  );
};

/**
* The following function searches a selected object(mainObject) for a list of objects containing fields that might conflict with the data that is being submitted.
* Basically what we are doing is checking if there is a conflict in another list field for the same sub-object type in the selected object.
* Currently it checks for the case where there is a combined conflict in time and in quantities (a number field).
* This should be expanded to also handle the simpler cases of a total quantity being reached or simple scheduling conflicts.
*/
async function checkForSubObjectConflicts(objectDatas, fieldDatas, fieldTypes, subObjectBlockTypeId, mainObject, fieldsToCheck) {
  const checkConflictingSchedule = (field1, field2) => isOverlapping(moment(field1.schedule.start_time), moment(field1.schedule.end_time), moment(field2.schedule.start_time), moment(field2.schedule.end_time));
  const findCombinedConflict = (potentialConflicts, conflictData, type) => {
    let hasConflict = false;
    if(type === 'SCHEDULENUMBER') {
      const start = conflictData.schedule.start_time;
      const end = conflictData.schedule.end_time;
      const minutes = enumerateMinutesBetweenDates(moment(start), moment(end));
      const potentialConflictsMomentized = potentialConflicts.map(conflict => (
        { ...conflict, schedule: { start_time: moment(conflict.schedule.start_time), end_time: moment(conflict.schedule.end_time) } }
      ));
      for (let i = 0; i < minutes.length; i += 1) {
        let numberLimit = conflictData.number;
        const minute = minutes[i];
        for (let j = 0; j < potentialConflictsMomentized.length; j += 1) {
          const conflict = potentialConflictsMomentized[j];
          numberLimit += isDateTimeInInterval(minute, minute, conflict.schedule.start_time, conflict.schedule.end_time) ? conflict.number : 0;
        }
        if(numberLimit > conflictData.maximum) {
          hasConflict = true;
          break;
        }
      }
    }
    return hasConflict;
  };
  let conflictFound = false;
  const scheduleFieldToCheck = fieldsToCheck.find(field => field.can_cause_conflict && field.input_type === I.SCHEDULING);
  const numberFieldToCheck = fieldsToCheck.find(field => field.can_cause_conflict && field.input_type === I.NUMBER);
  if(!scheduleFieldToCheck && !numberFieldToCheck) return false;
  const numberFieldParent = IdMapValues(fieldDatas).find(fieldData =>
    fieldData.field_type_id === (numberFieldToCheck && numberFieldToCheck.field_type_parent) &&
    mainObject.block_datas.includes(fieldData.block_data_id));
  const listField = IdMapValues(fieldDatas).find(fieldData =>
    mainObject.block_datas &&
    mainObject.block_datas.includes(fieldData.block_data_id) &&
    fieldTypes[fieldData.field_type_id] &&
    fieldTypes[fieldData.field_type_id].sub_object_block_type &&
    fieldTypes[fieldData.field_type_id].sub_object_block_type.id === subObjectBlockTypeId);
  if(scheduleFieldToCheck && numberFieldToCheck && listField) {
    const conflictIntervals = [];
    listField.object_datas.forEach((subObjectId) => {
      const subObject = objectDatas[subObjectId];
      const subObjectScheduleField = IdMapValues(fieldDatas).find(fieldData =>
        subObject.index_field_datas.includes(fieldData.id) &&
        fieldTypes[fieldData.field_type_id].input_type === I.SCHEDULING &&
        fieldTypes[fieldData.field_type_id].can_cause_conflict);
      const hasConflict = subObjectScheduleField && checkConflictingSchedule(scheduleFieldToCheck, subObjectScheduleField);
      if(hasConflict) {
        const subObjectNumberField = IdMapValues(fieldDatas).find(fieldData =>
          subObject.index_field_datas.includes(fieldData.id) &&
          fieldTypes[fieldData.field_type_id].input_type === I.NUMBER &&
          fieldTypes[fieldData.field_type_id].can_cause_conflict);
        if(subObjectScheduleField && subObjectNumberField) conflictIntervals.push({ schedule: subObjectScheduleField.schedule, number: parseInt(subObjectNumberField.value, 10) });
      }
    });
    conflictFound = findCombinedConflict(conflictIntervals, { schedule: scheduleFieldToCheck.schedule, number: parseInt(numberFieldToCheck.value, 10), maximum: parseInt(numberFieldParent ? numberFieldParent.value : numberFieldToCheck.number_max, 10) }, 'SCHEDULENUMBER');
  }
  return conflictFound;
}

/**
* This function is called when a field is marked as being able to cause a conflict.
* It attempts to find a selected object in the current block and looks for conflict-causing fields
* in the linked object. If a conflict is found then the same error is raised as in the case of
* primary category conflicts.
*/
export async function checkForConflictAndSave(
  preparedObjects,
  preparedFieldsArray = [],
  objectTypes,
  fieldTypes,
  objectDatas,
  fieldDatas,
  blockType,
  object,
  updateOrCreateBlock,
  updateObject,
  raisePrimaryCategoryConflicts,
  isExternallyAccessed,
) {
  const submitData = () => {
    updateOrCreateBlock(object.id, isExternallyAccessed);
    if(preparedObjects && Object.values(preparedObjects).length) {
      updateObject(false, isExternallyAccessed);
    }
  };
  const selectedObjectField = preparedFieldsArray.find(f =>
    f.input_type === I.SELECTOBJECT &&
    f.can_cause_conflict &&
    f.object_datas);
  if(!selectedObjectField) {
    submitData();
    return;
  }
  const selectedObject = selectedObjectField.object_datas[0];
  const fullSelectedObject = objectDatas[selectedObject.id];
  const conflictExists = await checkForSubObjectConflicts(
    objectDatas,
    fieldDatas,
    fieldTypes,
    blockType && blockType.id,
    fullSelectedObject,
    preparedFieldsArray,
  );
  if(conflictExists) {
    const conflictObject = preparedObjects && Object.values(preparedObjects) && Object.values(preparedObjects).find(obj => obj.check_for_linked_object_conflict);
    raisePrimaryCategoryConflicts(submitData, [], conflictObject && objectTypes[conflictObject.object_type_id]);
  }
  else submitData();
}

export const formsFilter = (objectTypes, fieldTypes, blockTypes, listType) => {
  const filters = [];
  if(objectTypes) {
    Object.values(objectTypes)
      .filter(objectType => objectType.type === listType)
      .forEach((objectType) => {
        const filter = { id: objectType.id, title: objectType.text, items: [] };
        const internalBlockTypes = objectType && objectType.block_types ?
          objectType.block_types.map(bt => blockTypes[bt]) : [];
        const externalBlockTypes = objectType && objectType.external_block_types ?
          objectType.external_block_types.map(bt => blockTypes[bt]) : [];

        const allBlockTypes = internalBlockTypes.concat(externalBlockTypes);
        let listFieldTypes = [];
        if(allBlockTypes && allBlockTypes.length) {
          allBlockTypes.forEach((abt) => {
            listFieldTypes = listFieldTypes.concat(abt.field_types);
          });
        }
        if (listFieldTypes) {
          const optionsField = [];
          listFieldTypes.forEach((typeId) => {
            const fieldType = fieldTypes[typeId];
            if(fieldType && (fieldType.input_type === I.SELECT || fieldType.input_type === I.SELECTMULTIPLE)) {
              optionsField[optionsField.length] = { options: fieldType.select_options, text: fieldType.text, id: fieldType.field_type_id };
            }
            filter.items = optionsField;
          });
          filters.push(filter);
        }
      });
  }
  return filters;
};
