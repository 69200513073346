// @flow
const C = {
  // used to build upp containers such as inputassignments and managetasks
  INVITE: 'INVITE',
  ASSIGN: 'ASSIGN',
  SUPERVISE: 'SUPERVISE',
  CONTACT: 'CONTACT',

  ACCEPTED: 'ACCEPTED',
  INVITED: 'INVITED',
  ASSIGNED: 'ASSIGNED',
  DECLINED: 'DECLINED',
  REJECTED: 'REJECTED',
  // If the project has enabled confirm_task_assignment, the user will have to accept if assigned.
  PENDINGASSIGNED: 'PENDINGASSIGNED',

  INVITATIONTITLES: {
    ACCEPTED: 'accepted',
    INVITED: 'invited',
    ASSIGNED: 'assigned',
    DECLINED: 'declined',
    REJECTED: 'rejected',
    PENDINGASSIGNED: 'pending confirmation',
  },
  FIRST: 'FIRST',
  SECOND: 'SECOND',
};

export default C;
