import request from 'axios';

let API_URL = '/api/v1/';
let API_URL_V2 = '/api/v2/';

// if (process.env.NODE_ENV === "development") {
//   API_URL = "http://localhost:8000/api/v1/";
//   API_URL_V2 = "http://localhost:8000/api/v2/";
// }

const authHeaders = () => {
  const headers = {};
  if (localStorage.getItem('access_token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
  }

  if (localStorage.getItem('X-JETTY-API-TOKEN')) {
    headers['X-JETTY-API-TOKEN'] = localStorage.getItem('X-JETTY-API-TOKEN');
  }
  return headers;
};

export default {
  login(username, password) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}auth/login`,
      responseType: 'json',
      data: { username, password },
    });
  },

  logout() {
    return request({
      method: 'DELETE',
      url: `${API_URL_V2}auth/logout`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  validateToken() {
    return request({
      method: 'GET',
      url: `${API_URL_V2}auth/validate_token`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getLastClient(username) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}auth/last_client/${username}`,
      responseType: 'json',
    });
  },

  setProject(clientId, projectId) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}users/me/set_project`,
      responseType: 'json',
      data: { project_id: projectId },
      headers: authHeaders(),
    });
  },

  setClient(clientId) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}accounts/me/set_client`,
      responseType: 'json',
      data: { client_id: clientId },
      headers: authHeaders(),
    });
  },

  getCurrentAccount() {
    return request({
      method: 'GET',
      url: `${API_URL_V2}accounts/me`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getCurrentUser() {
    //eslint-disable-line
    return request({
      method: 'GET',
      url: `${API_URL_V2}users/me`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getProjectSettings(clientId, projectId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/settings`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getProjectEconomySettings(clientId, projectId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/settings/economy`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getProject(clientId, projectId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getObjectTypes(clientId, projectId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/object_types/`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getObjects(clientId, projectId, type, page = 1, pageSize, filter) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}object_types/${type}/object_datas`,
      responseType: 'json',
      headers: authHeaders(),
      params: { page, pageSize, ...filter },
    });
  },

  getObjectCategories(projectId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/object_datas/categories`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getListObjects(
    clientId,
    projectId,
    type,
    page = 1,
    pageSize,
    filter,
    parentId,
  ) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}object_datas/${parentId}/list_object_datas`,
      responseType: 'json',
      headers: authHeaders(),
      params: { page, pageSize, ...filter },
    });
  },

  getSubObjects(clientId, projectId, type, page = 1, pageSize, filter) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}object_types/${type}/sub_object_datas`,
      responseType: 'json',
      headers: authHeaders(),
      params: { page, pageSize, ...filter },
    });
  },

  getObjectCounts(clientId, projectId, type, filter) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}object_types/${type}/object_datas/counts`,
      responseType: 'json',
      headers: authHeaders(),
      params: filter,
    });
  },

  getObject(clientId, projectId, id) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}object_datas/${id}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  postObject(clientId, projectId, type, blocks) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}object_types/${type}/object_datas`,
      responseType: 'json',
      headers: authHeaders(),
      data: { block_datas: blocks },
    });
  },

  putObject(clientId, projectId, objectId, data = {}) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}object_datas/${objectId}`,
      responseType: 'json',
      headers: authHeaders(),
      data: { id: objectId, ...data },
    });
  },

  postBlock(clientId, projectId, block) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}projects/${projectId}/block_datas/`,
      responseType: 'json',
      headers: authHeaders(),
      data: { ...block },
    });
  },

  toggleBlockDataTokenUser(clientId, projectId, id) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}block_datas/${id}/toggle_token_user`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  putBlock(clientId, projectId, id, block) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}block_datas/${id}`,
      responseType: 'json',
      headers: authHeaders(),
      data: { ...block },
    });
  },

  deleteObject(clientId, projectId, id) {
    return request({
      method: 'DELETE',
      url: `${API_URL_V2}object_datas/${id}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  setObjectStatus(clientId, projectId, id, statusId) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}object_datas/${id}`,
      responseType: 'json',
      headers: authHeaders(),
      data: { id, status: { id: statusId } },
    });
  },

  autocompleteTags(clientId, projectId, query, tagGroupId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/tags/autocomplete/${tagGroupId}/${query}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getTags(clientId, projectId, groupId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/tags/`,
      responseType: 'json',
      headers: authHeaders(),
      params: { groupId },
    });
  },

  getTagGroups(clientId, projectId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/tag_groups/`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getPeople(clientId, projectId, staff = false, page = 1, filter) {
    const peopleType = staff ? 'staff' : '';
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/people/${peopleType}`,
      responseType: 'json',
      headers: authHeaders(),
      params: { page, ...filter },
    });
  },

  getPerson(clientId, projectId, id) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}people/${id}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  postPerson(clientId, projectId, person) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}projects/${projectId}/people`,
      responseType: 'json',
      headers: authHeaders(),
      data: person,
    });
  },

  postUser(projectId, user) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}projects/${projectId}/users`,
      responseType: 'json',
      headers: authHeaders(),
      data: user,
    });
  },

  autocompletePeople(clientId, projectId, query, staff = false, filter) {
    const autocompleteType = staff ? 'autocompletestaff' : 'autocomplete';
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/people/${autocompleteType}/${query}`,
      params: { ...filter },
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  putPerson(clientId, projectId, person) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}people/${person.id}`,
      responseType: 'json',
      headers: authHeaders(),
      data: person,
    });
  },

  deletePerson(clientId, projectId, id) {
    return request({
      method: 'DELETE',
      url: `${API_URL_V2}people/${id}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  notifyPeople(
    projectId,
    account,
    ids,
    blockDataId,
    objectDataId,
    taskId,
    type = null,
    note,
  ) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}projects/${projectId}/notify_users`,
      responseType: 'json',
      headers: authHeaders(),
      data: {
        account,
        accounts: ids,
        project: projectId,
        blockData: blockDataId,
        objectData: objectDataId,
        task: taskId,
        type,
        note,
      },
    });
  },

  getAccountNotifications(projectId, accountId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/accounts/${accountId}/notifications`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getAccountNotificationsCount(projectId, accountId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/accounts/${accountId}/notifications/count`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  updateNotification(projectId, accountId, notificationId, data) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}projects/${projectId}/accounts/${accountId}/notifications/${notificationId}`,
      responseType: 'json',
      headers: authHeaders(),
      data,
    });
  },

  clearNotifications(projectId, accountId) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}projects/${projectId}/accounts/${accountId}/notifications/clear`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getTasks(clientId, projectId, page = 1, filter) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/tasks/`,
      responseType: 'json',
      headers: authHeaders(),
      params: { page, ...filter },
    });
  },

  getTask(clientId, projectId, id) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}tasks/${id}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  postTask(clientId, projectId, task) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}projects/${projectId}/tasks/`,
      responseType: 'json',
      headers: authHeaders(),
      data: { ...task, project: { id: projectId } },
    });
  },

  putTask(clientId, projectId, id, task) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}tasks/${id}`,
      responseType: 'json',
      headers: authHeaders(),
      data: { ...task, project: { id: projectId } },
    });
  },

  deleteTask(clientId, projectId, id) {
    return request({
      method: 'DELETE',
      url: `${API_URL_V2}tasks/${id}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getCalendarSchedule(clientId, projectId, objectTypeId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/schedules/calendar`,
      responseType: 'json',
      headers: authHeaders(),
      params: { objectType: objectTypeId },
    });
  },

  getSchedulesOnObjectData(clientId, projectId, objectDataId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/object_datas/${objectDataId}/schedules`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  uploadFile(clientId, projectId, file) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}projects/${projectId}/files/`,
      responseType: 'json',
      headers: authHeaders(),
      data: file,
    });
  },

  putFile(clientId, projectId, fileId, file) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}files/${fileId}`,
      responseType: 'json',
      headers: authHeaders(),
      data: file,
    });
  },

  putFileMetadata(file) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}files/metadata/${file.id}`,
      responseType: 'json',
      headers: authHeaders(),
      data: file,
    });
  },

  getFiles(clientId, projectId, page = 1, filter) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/files/`,
      responseType: 'json',
      headers: authHeaders(),
      params: { page, ...filter },
    });
  },

  getFile(clientId, projectId, fileId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/file/${fileId}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  deleteFile(clientId, projectId, fileId) {
    return request({
      method: 'DELETE',
      url: `${API_URL_V2}files/${fileId}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getGlobalSchedules(clientId, projectId, page = 1, filter) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/schedules/global`,
      responseType: 'json',
      headers: authHeaders(),
      params: { page, ...filter },
    });
  },

  getSchedule(clientId, projectId, id) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}schedules/${id}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  postSchedule(clientId, projectId, schedule) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}projects/${projectId}/schedules/`,
      responseType: 'json',
      headers: authHeaders(),
      data: schedule,
    });
  },

  putSchedule(clientId, projectId, id, schedule) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}schedules/${schedule.id}`,
      responseType: 'json',
      headers: authHeaders(),
      data: schedule,
    });
  },

  deleteSchedule(clientId, projectId, id) {
    return request({
      method: 'DELETE',
      url: `${API_URL_V2}schedules/${id}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getPosts(siteId) {
    return request({
      method: 'GET',
      url: `${API_URL}cms/sites/${siteId}/posts`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getPost(postId) {
    return request({
      method: 'GET',
      url: `${API_URL}cms/posts/${postId}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getPostTypes() {
    return request({
      method: 'GET',
      url: `${API_URL}cms/post_types`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getSite(siteId) {
    return request({
      method: 'GET',
      url: `${API_URL}cms/sites/${siteId}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  putSite(site) {
    return request({
      method: 'put',
      url: `${API_URL}cms/sites/${site.id}`,
      responseType: 'json',
      headers: authHeaders(),
      data: site,
    });
  },

  postPost(post) {
    return request({
      method: 'POST',
      url: `${API_URL}cms/posts/`,
      responseType: 'json',
      headers: authHeaders(),
      data: post,
    });
  },

  putPost(post) {
    return request({
      method: 'PUT',
      url: `${API_URL}cms/posts/${post.id}`,
      responseType: 'json',
      headers: authHeaders(),
      data: post,
    });
  },

  deletePost(postId) {
    return request({
      method: 'DELETE',
      url: `${API_URL}cms/posts/${postId}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getPostLinks(siteId) {
    return request({
      method: 'GET',
      url: `${API_URL}cms/sites/${siteId}/post_links`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  putMenu(menu) {
    return request({
      method: 'PUT',
      url: `${API_URL}cms/menus/${menu.id}`,
      responseType: 'json',
      headers: authHeaders(),
      data: menu,
    });
  },

  postMenu(menu) {
    return request({
      method: 'POST',
      url: `${API_URL}cms/menus/`,
      responseType: 'json',
      headers: authHeaders(),
      data: menu,
    });
  },

  setCmsUserPermission(cmsUserId, postId) {
    return request({
      method: 'POST',
      url: `${API_URL}cms/users/${cmsUserId}/set_permission`,
      responseType: 'json',
      headers: authHeaders(),
      data: {
        post_id: postId,
      },
    });
  },

  unsetCmsUserPermission(cmsUserId, postId) {
    return request({
      method: 'POST',
      url: `${API_URL}cms/users/${cmsUserId}/unset_permission`,
      responseType: 'json',
      headers: authHeaders(),
      data: {
        post_id: postId,
      },
    });
  },

  uploadTokenApiFile(projectId, file) {
    return request({
      method: 'POST',
      url: `/token_api/v1/projects/${projectId}/files/`,
      responseType: 'json',
      headers: authHeaders(),
      data: file,
    });
  },

  deleteTokenApiFile(fileId) {
    return request({
      method: 'DELETE',
      url: `/token_api/v1/files/${fileId}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  autocompleteTokenApiTags(projectId, query, tagGroupId) {
    return request({
      method: 'GET',
      url: `/token_api/v1/projects/${projectId}/tags/autocomplete/${tagGroupId}/${query}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getTokenApiObject(clientId, projectId, id) {
    return request({
      method: 'GET',
      url: `/token_api/v1/object_datas/${id}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  postTokenApiObject(clientId, projectId, type, blocks, additionalData) {
    return request({
      method: 'POST',
      url: `/token_api/v1/object_types/${type}/object_datas`,
      responseType: 'json',
      headers: authHeaders(),
      data: { block_datas: blocks, ...additionalData },
    });
  },

  putTokenApiObject(clientId, projectId, objectId, data = {}) {
    return request({
      method: 'PUT',
      url: `/token_api/v1/object_datas/${objectId}`,
      responseType: 'json',
      headers: authHeaders(),
      data: { id: objectId, ...data },
    });
  },

  getTokenUserListObjects(
    clientId,
    projectId,
    parentId,
    page = 1,
    pageSize,
    filter,
  ) {
    return request({
      method: 'GET',
      url: `/token_api/v1/object_datas/${parentId}/list_object_datas`,
      responseType: 'json',
      headers: authHeaders(),
      params: { page, pageSize, ...filter },
    });
  },

  putTokenApiBlock(clientId, projectId, id, block) {
    return request({
      method: 'PUT',
      url: `/token_api/v1/block_datas/${id}`,
      responseType: 'json',
      headers: authHeaders(),
      data: { ...block },
    });
  },

  getTokenUser() {
    return request({
      method: 'GET',
      url: '/token_api/v1/users/me',
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  postTokenUserBlock(clientId, projectId, block) {
    return request({
      method: 'POST',
      url: '/token_api/v1/users/me/block_datas',
      responseType: 'json',
      headers: authHeaders(),
      data: { ...block },
    });
  },

  getTokenUserObjectType() {
    return request({
      method: 'GET',
      url: '/token_api/v1/users/me/object_type',
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getTokenUserObjectData() {
    return request({
      method: 'GET',
      url: '/token_api/v1/users/me/object_data',
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  submitTokenUserObjectData(
    senderName,
    senderEmail,
    mailTos,
    shouldBeActive,
    isFormsSubmit,
  ) {
    return request({
      method: 'POST',
      url: '/token_api/v1/users/me/object_datas',
      responseType: 'json',
      headers: authHeaders(),
      data: {
        sender_name: senderName,
        sender_mail: senderEmail,
        mail_to: mailTos,
        active: shouldBeActive,
        is_forms_submit: isFormsSubmit,
      },
    });
  },

  putTokenUser(clientId, projectId, tokenUser) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}token_users/${tokenUser.id}`,
      responseType: 'json',
      headers: authHeaders(),
      data: tokenUser,
    });
  },

  getRoles(clientId, projectId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/roles/`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  searchObjects(clientId, projectId, query, date1, date2) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}search/${projectId}/search/`,
      responseType: 'json',
      headers: authHeaders(),
      params: { query, date1, date2 },
    });
  },

  getDepartments(clientId, projectId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/departments/`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  updateDepartments(projectId, departments) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}projects/${projectId}/departments/`,
      responseType: 'json',
      headers: authHeaders(),
      data: departments,
    });
  },

  getPermissions(clientId, projectId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/permissions/`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getUsers(projectId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/users`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getUser(userId, projectId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/users/${userId}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  updateUser(user) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}users/${user.id}`,
      responseType: 'json',
      headers: authHeaders(),
      data: user,
    });
  },

  updateAccount(account) {
    const { id, ...data } = account;
    return request({
      method: 'PUT',
      url: `${API_URL_V2}accounts/${account.id}`,
      responseType: 'json',
      headers: authHeaders(),
      data,
    });
  },

  updatePerson(person) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}people/${person.id}`,
      responseType: 'json',
      headers: authHeaders(),
      data: person,
    });
  },

  generateNewPassword(accountId) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}accounts/${accountId}/generate_new_password`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  linkMenuToPosts(menuId, posts) {
    return request({
      method: 'POST',
      url: `${API_URL}cms/menus/${menuId}/link_posts`,
      responseType: 'json',
      headers: authHeaders(),
      data: posts,
    });
  },

  putRole(role) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}roles/${role.id}`,
      responseType: 'json',
      headers: authHeaders(),
      data: role,
    });
  },

  postRole(projectId, role) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}projects/${projectId}/roles`,
      responseType: 'json',
      headers: authHeaders(),
      data: role,
    });
  },

  acceptTerms() {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}accounts/me/accept_terms`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  setObjectPublished(clientId, projectId, id, published) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}object_datas/${id}`,
      responseType: 'json',
      headers: authHeaders(),
      data: { id, is_published: published },
    });
  },

  getClients() {
    return request({
      method: 'GET',
      url: `${API_URL_V2}clients/`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  fetchReportTypes(clientId, projectId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/reports/types`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getProjects(clientId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}clients/${clientId}/users/me/projects`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  fetchReport(clientId, projectId, reportTypeId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/reports/generate/${reportTypeId}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  changePassword(oldPassword, newPassword, newPasswordRepeat) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}accounts/me/change_password`,
      headers: authHeaders(),
      data: {
        oldPassword,
        newPassword,
        newPasswordRepeat,
      },
    });
  },

  postComment(projectId, comment) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}projects/${projectId}/comments/`,
      responseType: 'json',
      headers: authHeaders(),
      data: comment,
    });
  },

  putComment(comment) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}comments/${comment.id}`,
      responseType: 'json',
      headers: authHeaders(),
      data: comment,
    });
  },

  deleteComment(commentId) {
    return request({
      method: 'DELETE',
      url: `${API_URL_V2}comments/${commentId}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  updateProject(projectId, project) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}projects/${projectId}`,
      headers: authHeaders(),
      data: { ...project },
    });
  },

  postTag(projectId, tag) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}projects/${projectId}/tags`,
      headers: authHeaders(),
      data: tag,
    });
  },

  updateTags(projectId, tags) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}projects/${projectId}/tags/`,
      headers: authHeaders(),
      data: tags,
    });
  },

  updateJobRoles(projectId, jobRoles) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}projects/${projectId}/job_roles/`,
      headers: authHeaders(),
      data: jobRoles,
    });
  },

  getFilesIndex(projectId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/files_index`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getCompanies(clientId, projectId, page = 1, filter) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/companies/`,
      responseType: 'json',
      headers: authHeaders(),
      params: { page, ...filter },
    });
  },

  getCompany(clientId, projectId, id) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}companies/${id}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  postCompany(clientId, projectId, company) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}projects/${projectId}/companies`,
      responseType: 'json',
      headers: authHeaders(),
      data: company,
    });
  },

  autocompleteCompanies(clientId, projectId, query) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/companies/autocomplete/${query}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  postObjectType(objectType) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}object_types`,
      headers: authHeaders(),
      data: objectType,
    });
  },

  deleteObjectType(id) {
    return request({
      method: 'DELETE',
      url: `${API_URL_V2}object_types/${id}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  putObjectType(objectType) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}object_types/${objectType.id}`,
      headers: authHeaders(),
      data: objectType,
    });
  },

  getObjectType(id) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}object_types/${id}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getBlockType(id) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}block_types/${id}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  putBlockType(blockType) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}block_types/${blockType.id}`,
      headers: authHeaders(),
      data: blockType,
    });
  },

  postBlockType(blockType) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}block_types`,
      headers: authHeaders(),
      data: blockType,
    });
  },

  deleteBlockType(id) {
    return request({
      method: 'DELETE',
      url: `${API_URL_V2}block_types/${id}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  putCompany(clientId, projectId, company) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}companies/${company.id}`,
      responseType: 'json',
      headers: authHeaders(),
      data: company,
    });
  },

  deleteCompany(clientId, projectId, id) {
    return request({
      method: 'DELETE',
      url: `${API_URL_V2}companies/${id}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  deleteAddress(id) {
    return request({
      method: 'DELETE',
      url: `${API_URL_V2}addresses/${id}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  deleteContactLog(id) {
    return request({
      method: 'DELETE',
      url: `${API_URL_V2}contact_logs/${id}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getMenuItems(projectId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/menu_items/`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  putUserPermissions(userId, projectId, permissions) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}users/${userId}/permissions`,
      responseType: 'json',
      headers: authHeaders(),
      data: {
        permissions,
        project_id: projectId,
      },
    });
  },

  putRolePermissions(roleId, permissions) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}roles/${roleId}/permissions`,
      responseType: 'json',
      headers: authHeaders(),
      data: {
        permissions,
      },
    });
  },

  fetchContracts(projectId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/contracts`,
      headers: authHeaders(),
    });
  },

  fetchContractTemplates(projectId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/contract_templates`,
      headers: authHeaders(),
    });
  },
  postContractTemplate(projectId, template) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}projects/${projectId}/contract_templates`,
      responseType: 'json',
      headers: authHeaders(),
      data: template,
    });
  },

  putContractTemplate(templateId, template) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}contracts/templates/${templateId}`,
      responseType: 'json',
      headers: authHeaders(),
      data: template,
    });
  },

  putContract(contractId, contract) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}contracts/${contractId}`,
      responseType: 'json',
      headers: authHeaders(),
      data: contract,
    });
  },

  generateContractPdf(data, contractId) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}contracts/${contractId}/pdf`,
      responseType: 'json',
      headers: authHeaders(),
      data,
    });
  },

  startContractDocumentSigning(contractId, contractPdfId) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}contracts/${contractId}/pdf/${contractPdfId}/start`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getVersion() {
    return request({
      method: 'GET',
      url: `${API_URL_V2}accounts/me/get_version`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },
  getCurrencies() {
    return request({
      method: 'GET',
      url: `${API_URL_V2}currencies/`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },
  getEconomies(objectDataId = null, projectId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/economies`,
      responseType: 'json',
      headers: authHeaders(),
      data: { objectDataId },
    });
  },

  postEconomy(projectId, economy) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}projects/${projectId}/economies/`,
      responseType: 'json',
      headers: authHeaders(),
      data: economy,
    });
  },
  removeEconomyCategory(projectId, categoryId) {
    return request({
      method: 'DELETE',
      url: `${API_URL_V2}projects/${projectId}/economy_category/${categoryId}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  deleteProjectInfo(projectId, infoIds) {
    return request({
      method: 'DELETE',
      url: `${API_URL_V2}projects/${projectId}/project_information`,
      responseType: 'json',
      headers: authHeaders(),
      data: infoIds,
    });
  },

  exportFormsList(objectTypeTitle, objectTypeId, filter, listFieldId) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}projects/forms_export`,
      responseType: 'json',
      headers: authHeaders(),
      params: { objectTypeTitle, objectTypeId, ...filter, listFieldId },
    });
  },

  deleteTaskReport(reportId) {
    return request({
      method: 'DELETE',
      url: `${API_URL_V2}task_reports/${reportId}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  putTaskReport(report) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}task_reports/${report.id}`,
      responseType: 'json',
      headers: authHeaders(),
      data: { ...report },
    });
  },

  postTaskReport(projectId, taskId, report) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}projects/${projectId}/tasks/${taskId}/reports/`,
      responseType: 'json',
      headers: authHeaders(),
      data: { ...report },
    });
  },

  clearCache(siteId, userId) {
    return request({
      method: 'GET',
      url: `${API_URL}cms/sites/${siteId}/clear_cache/${userId}`,
      headers: authHeaders(),
    });
  },

  logSearch(projectId, query, type) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}search/${projectId}/log_search/${type}`,
      responseType: 'json',
      headers: authHeaders(),
      params: { query },
    });
  },

  sendResetPasswordLink(username) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}accounts/send_reset_password/${username}`,
      responseType: 'json',
    });
  },

  fetchUserFromResetToken(token) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}accounts/user_reset/${token}`,
      responseType: 'json',
    });
  },

  resetPassword(userId, password, token) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}accounts/reset_password/${userId}`,
      responseType: 'json',
      data: {
        password,
        token,
      },
    });
  },

  personContactConnection(persId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}contacts/person/${persId}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  companyContactConnection(persId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}contacts/company/${persId}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  deletePersonAndConnections(persId, contacts) {
    return request({
      method: 'DELETE',
      url: `${API_URL_V2}people/withConnections/${persId}`,
      responseType: 'json',
      headers: authHeaders(),
      params: { contacts },
    });
  },

  deleteCompanyAndConnections(compId, contacts) {
    return request({
      method: 'DELETE',
      url: `${API_URL_V2}companies/withConnections/${compId}`,
      responseType: 'json',
      headers: authHeaders(),
      params: { contacts },
    });
  },

  putObjectDuplicate(clientId, projectId, objectId, data = {}) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}object_datas/duplicate/${objectId}`,
      responseType: 'json',
      headers: authHeaders(),
      data: { id: objectId, ...data },
    });
  },

  stripeChargeAccount(token) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}stripe/charge`,
      responseType: 'json',
      headers: authHeaders(),
      params: { token },
    });
  },

  createStripePaymentIntent(amount, currency) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}stripe/create/paymentIntent`,
      responseType: 'json',
      headers: authHeaders(),
      params: { amount, currency },
    });
  },

  updateStripePaymentIntent(paymentId, amount) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}stripe/update/paymentIntent`,
      responseType: 'json',
      headers: authHeaders(),
      data: { paymentId, amount },
    });
  },

  stripeChargePaymentIntent(intentId) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}stripe/charge/paymentIntent`,
      responseType: 'json',
      headers: authHeaders(),
      params: { intentId },
    });
  },

  fetchStripeProducts() {
    return request({
      method: 'GET',
      url: `${API_URL_V2}stripe/products`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  fetchSubscriptionPeriods() {
    return request({
      method: 'GET',
      url: `${API_URL_V2}stripe/subscriptionPeriods`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  fetchSubscriptions(projectId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}stripe/subscriptions/${projectId}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  postSubscriptionPlan(projectId, subscription, plan) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}stripe/${projectId}/subscription_plan`,
      responseType: 'json',
      headers: authHeaders(),
      params: {
        ...plan,
        productId: subscription.product && subscription.product.id,
        product: subscription.product.stripeId,
      },
    });
  },

  fetchBillingInfo(clientId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}stripe/billing_info/${clientId}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  postBillingInfo(projectId, billingInfo, address) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}stripe/${projectId}/billing_info`,
      responseType: 'json',
      headers: authHeaders(),
      params: { ...billingInfo.customer, ...address },
    });
  },

  updateBillingInfo(billingInfoId, billingInfo, address) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}stripe/billing_info/${billingInfoId}`,
      responseType: 'json',
      headers: authHeaders(),
      params: { ...billingInfo.customer, ...address },
    });
  },

  stripeCreateCustomer(projectId, token) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}stripe/${projectId}/customer`,
      responseType: 'json',
      headers: authHeaders(),
      params: { token },
    });
  },

  createSubscription(projectId, productId) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}stripe/${projectId}/subscription/${productId}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  unsubscribe(subscriptionId) {
    return request({
      method: 'DELETE',
      url: `${API_URL_V2}stripe/subscription/${subscriptionId}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  fetchSubscriptionIntent(subscriptionId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}stripe/subscription_intent`,
      responseType: 'json',
      headers: authHeaders(),
      params: { subscriptionId },
    });
  },

  fetchInvoices(clientId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}stripe/${clientId}/invoices`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  setMenuItemClicked(menuItemId) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}projects/menu_items/${menuItemId}/track_count`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  importDataFromExcel(
    importDatas,
    importFields,
    projectId,
    objectTypeId,
    importDateFormat,
  ) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}projects/${projectId}/import_data`,
      responseType: 'json',
      headers: authHeaders(),
      data: {
        importDatas,
        importFields,
        objectTypeId,
        importDateFormat,
      },
    });
  },

  createReoccuringObject(objectId, startDates, endDates, reoccuringObject) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}reoccuring_objects/create_reoccuring/${objectId}`,
      responseType: 'json',
      headers: authHeaders(),
      params: {
        startDates,
        endDates,
        ...reoccuringObject,
      },
    });
  },

  matchImportHeadersToFieldTypes(headers, projectId, objectTypeId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/import_data/fields`,
      responseType: 'json',
      headers: authHeaders(),
      params: { headers, objectTypeId },
    });
  },

  fetchImportObjectsIfRunDone(importJobId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/import_check_job/${importJobId}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  matchImportHeadersToPeople(headers, projectId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/import_people/fields`,
      responseType: 'json',
      headers: authHeaders(),
      params: { headers },
    });
  },

  importPeopleFromExcel(importDatas, importFields, projectId) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}projects/${projectId}/import_people`,
      responseType: 'json',
      headers: authHeaders(),
      data: {
        importDatas,
        importFields,
      },
    });
  },

  matchImportHeadersToCompanies(headers, projectId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/import_companies/fields`,
      responseType: 'json',
      headers: authHeaders(),
      params: { headers },
    });
  },

  importCompaniesFromExcel(importDatas, importFields, projectId) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}projects/${projectId}/import_companies`,
      responseType: 'json',
      headers: authHeaders(),
      data: {
        importDatas,
        importFields,
      },
    });
  },

  matchImportHeadersToBlocks(headers, projectId, blockTypeId) {
    return request({
      url: `${API_URL_V2}projects/${projectId}/import_block/fields`,
      responseType: 'json',
      headers: authHeaders(),
      params: { headers, blockTypeId },
    });
  },

  importBlockFromExcel(
    importExcelBlock,
    importFieldsBlock,
    blockTypeId,
    objectDataId,
    projectId,
    importDateFormat,
  ) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}projects/${projectId}/import_block`,
      responseType: 'json',
      headers: authHeaders(),
      data: {
        importDatas: importExcelBlock,
        importFields: importFieldsBlock,
        blockTypeId,
        objectDataId,
        importDateFormat,
      },
    });
  },

  fetchReoccuringIfRunDone(reoccuringGroupId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}reoccuring_objects/group/${reoccuringGroupId}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  findPersonByEmail(email, projectId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/person_by_email`,
      responseType: 'json',
      headers: authHeaders(),
      params: {
        email,
      },
    });
  },

  connectPerson(person, projectId, objectId) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}people/${person}/person_connect_object`,
      responseType: 'json',
      headers: authHeaders(),
      params: {
        objectId,
        projectId,
      },
    });
  },

  sendBulkEmail(emailData) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}people/bulk_email`,
      responseType: 'json',
      headers: authHeaders(),
      data: {
        ...emailData,
      },
    });
  },

  getBadgeFrameworks(projectId) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}projects/${projectId}/badge_frameworks/`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  getBadgeFramework(projectId, id) {
    return request({
      method: 'GET',
      url: `${API_URL_V2}badge_frameworks/${id}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  postBadgeFramework(clientId, projectId, badgeFramework) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}projects/${projectId}/badge_frameworks`,
      responseType: 'json',
      headers: authHeaders(),
      data: badgeFramework,
    });
  },

  putBadgeFramework(clientId, projectId, badgeFramework) {
    return request({
      method: 'PUT',
      url: `${API_URL_V2}badge_frameworks/${badgeFramework.id}`,
      responseType: 'json',
      headers: authHeaders(),
      data: badgeFramework,
    });
  },

  deleteBadgeFramework(clientId, projectId, id) {
    return request({
      method: 'DELETE',
      url: `${API_URL_V2}badge_frameworks/${id}`,
      responseType: 'json',
      headers: authHeaders(),
    });
  },

  createEvent(eventData, projectId) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}projects/${projectId}/events`,
      responseType: 'json',
      headers: authHeaders(),
      data: {
        ...eventData,
      },
    });
  },

  signupAccount(account) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}accounts/signup`,
      responseType: 'json',
      headers: authHeaders(),
      data: account,
    });
  },

  postProject(project, clientId) {
    return request({
      method: 'POST',
      url: `${API_URL_V2}clients/${clientId}/projects`,
      responseType: 'json',
      headers: authHeaders(),
      data: project,
    });
  },
};
