// @flow
import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';

import DeleteNotice from '../components/DeleteNotice';
import * as authActions from '../actions/authActions';
import * as environmentActions from '../actions/environmentActions';
import O from '../constants/objectTypes';
import requestManager from '../../libs/requestManager';
import { getClientProjectOffsetHoursForDate } from '../components/calendar/DateUtils';
import type { Project, ProjectSettings } from '../entities/FlowTypes';
import LoaderOverlay from '../components/shared/LoaderOverlay';

import s from './App.module.scss';

import LoginContainer from '../containers/LoginContainer';
import TokenUserContainer from '../containers/TokenUserContainer';
import Notice from '../components/shared/Notice';
import ResetPasswordContainer from '../containers/ResetPasswordContainer';

const HomeContainer = lazy(() => import('../containers/HomeContainer'));
const DashboardContainer = lazy(() =>
  import('../containers/DashboardContainer'),
);
const ObjectsContainer = lazy(() => import('../containers/ObjectsContainer'));
const ObjectContainer = lazy(() => import('../containers/ObjectContainer'));
const ExternalObjectContainer = lazy(() =>
  import('../containers/ExternalObjectContainer'),
);
const ManageTasksContainer = lazy(() =>
  import('../containers/ManageTasksContainer'),
);
const TasksContainer = lazy(() => import('../containers/TasksContainer'));
const PeopleContainer = lazy(() => import('../containers/PeopleContainer'));
const PersonContainer = lazy(() => import('../containers/PersonContainer'));
const StaffContainer = lazy(() => import('../containers/StaffContainer'));
const FilesContainer = lazy(() => import('../containers/FilesContainer'));
const CmsContainer = lazy(() => import('../containers/CmsContainer'));
const CmsSettingsContainer = lazy(() =>
  import('../containers/CmsSettingsContainer'),
);
const PostContainer = lazy(() => import('../containers/PostContainer'));
const GlobalScheduleContainer = lazy(() =>
  import('../containers/GlobalScheduleContainer'),
);
const TemplatesContainer = lazy(() =>
  import('../containers/setup/TemplatesContainer'),
);
const TemplateContainer = lazy(() =>
  import('../containers/setup/TemplateContainer'),
);
const UsersContainer = lazy(() => import('../containers/setup/UsersContainer'));
const UserContainer = lazy(() => import('../containers/setup/UserContainer'));
const ProjectContainer = lazy(() =>
  import('../containers/setup/ProjectContainer'),
);
const EconomyContainer = lazy(() =>
  import('../containers/setup/EconomyContainer'),
);
const ObjectEditorContainer = lazy(() =>
  import('../containers/setup/ObjectEditorContainer'),
);
const ReportsContainer = lazy(() => import('../containers/ReportsContainer'));
const AccountContainer = lazy(() => import('../containers/AccountContainer'));
const TaskContainer = lazy(() => import('../containers/TaskContainer'));
const CompaniesContainer = lazy(() =>
  import('../containers/CompaniesContainer'),
);
const CompanyContainer = lazy(() => import('../containers/CompanyContainer'));
const FormsContainer = lazy(() => import('../containers/FormsContainer'));
const BaseHomeContainer = lazy(() => import('../containers/BaseHomeContainer'));
const ContractTemplateContainer = lazy(() =>
  import('../containers/contracts/ContractTemplateContainer'),
);
const GlobalContractsContainer = lazy(() =>
  import('../containers/contracts/GlobalContractsContainer'),
);
const GlobalEconomyContainer = lazy(() =>
  import('../containers/economy/GlobalEconomyContainer'),
);
const JettyAccountContainer = lazy(() =>
  import('../containers/JettyAccountContainer'),
);
const BadgeFrameworksContainer = lazy(() =>
  import('../containers/setup/BadgeFrameworksContainer'),
);
const BadgeFrameworkContainer = lazy(() =>
  import('../containers/setup/BadgeFrameworkContainer'),
);
const CreateProjectContainer = lazy(() =>
  import('../containers/setup/CreateProjectContainer'),
);

type PropTypes = {
  theme: string,
  loggedIn: boolean,
  freshUser?: boolean,
  project: Project,
  projectSettings: ProjectSettings,
  oldProjectSettings: ProjectSettings,
  location: any,
  history: any,
  showLoader: boolean,
  solidLoader: boolean,
  showNotice: boolean,
  noticeMessage: string,
  noticeType: any,
  closeNotice: () => void,
  setTheme: (string) => void,
  validateToken: () => void,
  fetchAccountNotifications: (number) => void,
  fetchVersion: () => void,
};

type StateTypes = {
  wentDeeper: boolean,
};

const mapStateToProps = (state) => {
  const { loggedIn, account, freshUser } = state.authStore;

  return {
    loggedIn,
    account,
    freshUser,
    currentObject: state.objectsState.currentObject,
    ...state.environmentState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...authActions, ...environmentActions }, dispatch);

const getPathDepth = (location) => (location || {}).pathname.split('/').length;

class App extends Component<PropTypes, StateTypes> {
  constructor(props: PropTypes) {
    super(props);
    this.state = {
      wentDeeper: false,
    };
  }

  unlisten = () => {};

  componentDidMount() {
    const {
      validateToken,
      fetchAccountNotifications,
      project,
      fetchVersion,
      location,
      projectSettings,
    } = this.props;
    const startsAtForms = location.pathname.includes('/f/');
    if (projectSettings && projectSettings.timezone) {
      window.timezoneOverride = projectSettings.timezone;
      moment.tz.setDefault(projectSettings.timezone);
      window.clientProjectOffsetHours = getClientProjectOffsetHoursForDate(
        moment.now(),
      );
    }
    window.clientTimezone = moment.tz.guess();
    if (
      !startsAtForms &&
      localStorage.getItem('access_token') &&
      !localStorage.getItem('X-JETTY-API-TOKEN')
    ) {
      fetchVersion();
      validateToken();
    }

    this.unlisten = this.props.history.listen(() => {
      if (project && !startsAtForms) {
        fetchAccountNotifications(project.id);
      }
    });
    const defaultTheme =
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light';
    const startTheme = localStorage.getItem('theme') || defaultTheme;
    this.props.setTheme(startTheme);
    if (document.body) document.body.classList.add(startTheme);
  }

  componentWillReceiveProps(nextProps) {
    const {
      theme,
      loggedIn,
      setTheme,
      project,
      projectSettings: oldProjectSettings,
    } = this.props;
    const {
      theme: newTheme,
      loggedIn: newLoggedIn,
      project: newProject,
      projectSettings,
    } = nextProps;
    if (newTheme && newTheme !== theme) {
      if (document.body && this.props.theme)
        document.body.classList.remove(this.props.theme);
      if (document.body) {
        document.body.classList.add(nextProps.theme);
        this.setBackground(nextProps.theme);
      }
    }
    if (newLoggedIn !== loggedIn && !newLoggedIn) {
      const startTheme = localStorage.getItem('theme') || 'dark';
      setTheme(startTheme);
    }
    if (
      (project !== newProject && projectSettings && projectSettings.timezone) ||
      (oldProjectSettings &&
        projectSettings &&
        projectSettings.timezone &&
        oldProjectSettings.timezone !== projectSettings.timezone)
    ) {
      window.timezoneOverride = projectSettings.timezone;
      moment.tz.setDefault(projectSettings.timezone);
      window.clientProjectOffsetHours = getClientProjectOffsetHoursForDate(
        moment.now(),
      );
    }
    if (this.props.location !== nextProps.location) {
      this.handleLocationChange(this.props.location, nextProps.location);
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  setBackground = (theme) => {
    if (document.body) {
      if (theme === 'light') document.body.style.backgroundColor = '#efefef';
      else document.body.style.backgroundColor = 'rgb(34, 34, 34)';
    }
  };

  handleLocationChange(currentLocation, nextLocation) {
    const { project } = this.props;
    const currentPathDepth = getPathDepth(currentLocation);
    const nextPathDepth = getPathDepth(nextLocation);
    if (nextPathDepth - currentPathDepth > 0)
      this.setState({ wentDeeper: true });
    else if (nextPathDepth - currentPathDepth < 0)
      this.setState({ wentDeeper: false });
    if (project) {
      requestManager.createEvent(
        {
          current_location: nextLocation.pathname,
          previous_location: currentLocation.pathname,
        },
        project.id,
      );
    }
  }

  render() {
    const {
      loggedIn,
      freshUser,
      showLoader,
      solidLoader,
      showNotice,
      noticeMessage,
      noticeType,
      closeNotice,
      theme,
      location,
    } = this.props;
    const { wentDeeper } = this.state;

    const isGetStarted =
      window.location.hostname.split('.')[0] === 'getstarted';
    const isMy = window.location.hostname.split('.')[0] === 'my';

    return (
      <div className={s.wrapper}>
        <Suspense
          fallback={
            <LoaderOverlay
              solid={solidLoader}
              theme={theme}
              showLoader={showLoader}
            />
          }
        >
          <DeleteNotice />
          <Switch>
            <Route exact path="/login" component={LoginContainer} />

            {!isGetStarted && !isMy && (
              <Route exact path="/signup" component={CreateProjectContainer} />
            )}

            {isGetStarted && (
              <>
                <Route exact path="/" component={CreateProjectContainer} />
                <Redirect to="/" />
              </>
            )}

            <Redirect exact path="/" to="/dashboard" />

            <Route
              path="/o/:userToken"
              render={(props) => (
                <TokenUserContainer {...props}>
                  <HomeContainer {...props} tokenUserSideBar>
                    <Route component={ObjectContainer} />
                  </HomeContainer>
                </TokenUserContainer>
              )}
            />

            <Route
              path="/f/:userToken"
              render={(props) => (
                <BaseHomeContainer {...props} startTheme="light">
                  <Route component={FormsContainer} />
                </BaseHomeContainer>
              )}
            />

            <Route
              path="/a/:userToken"
              render={(props) => (
                <TokenUserContainer {...props}>
                  <Route component={ExternalObjectContainer} />
                </TokenUserContainer>
              )}
            />

            <Route
              path="/reset-password/:resetToken"
              render={(props) => (
                <BaseHomeContainer {...props} startTheme="light">
                  <Route component={ResetPasswordContainer} />
                </BaseHomeContainer>
              )}
            />

            <Route
              path="/"
              render={(props) => {
                if (loggedIn) {
                  return (
                    <HomeContainer {...props}>
                      <TransitionGroup>
                        <CSSTransition
                          key={location.key}
                          classNames={{
                            enter: wentDeeper
                              ? s.pageSliderLeftEnter
                              : s.pageSliderRightEnter,
                            enterActive: wentDeeper
                              ? s.pageSliderLeftEnterActive
                              : s.pageSliderRightEnterActive,
                            exit: wentDeeper
                              ? s.pageSliderLeftExit
                              : s.pageSliderRightExit,
                            exitActive: wentDeeper
                              ? s.pageSliderLeftExitActive
                              : s.pageSliderRightExitActive,
                          }}
                          timeout={500}
                        >
                          <section className={s.routeSection}>
                            <Switch key={location.key} location={location}>
                              <Route
                                path="/dashboard"
                                component={DashboardContainer}
                              />
                              <Route
                                path="/people/:personId"
                                component={PersonContainer}
                              />
                              <Route
                                path="/people"
                                component={PeopleContainer}
                              />
                              <Route
                                path="/companies/:companyId"
                                component={CompanyContainer}
                              />
                              <Route
                                path="/companies"
                                component={CompaniesContainer}
                              />
                              <Route
                                path="/files"
                                exact
                                component={FilesContainer}
                              />
                              <Route
                                path="/files/:fileId"
                                component={FilesContainer}
                              />
                              <Route
                                path="/setup/templates"
                                exact
                                component={TemplatesContainer}
                              />
                              <Route
                                path="/setup/templates/:templateId"
                                exact
                                component={TemplateContainer}
                              />
                              <Route
                                path="/setup/users"
                                exact
                                component={UsersContainer}
                              />
                              <Route
                                path="/setup/users/:userId"
                                component={UserContainer}
                              />
                              <Route
                                path="/setup/project"
                                component={ProjectContainer}
                              />
                              <Route
                                path="/setup/economy"
                                component={EconomyContainer}
                              />
                              <Route
                                path="/setup/forms"
                                exact
                                render={(p) => (
                                  <ObjectEditorContainer
                                    {...p}
                                    layoutObjectType={O.FORMOBJ}
                                  />
                                )}
                              />
                              <Route
                                path="/setup/forms/:objectType"
                                render={(p) => (
                                  <ObjectEditorContainer
                                    {...p}
                                    layoutObjectType={O.FORMOBJ}
                                  />
                                )}
                              />
                              <Route
                                path="/setup/object-editor"
                                exact
                                render={(p) => (
                                  <ObjectEditorContainer
                                    {...p}
                                    layoutObjectType={O.COREOBJ}
                                  />
                                )}
                              />
                              <Route
                                path="/setup/object-editor/:objectType"
                                render={(p) => (
                                  <ObjectEditorContainer
                                    {...p}
                                    layoutObjectType={O.COREOBJ}
                                  />
                                )}
                              />
                              <Route
                                path="/setup/contract-templates"
                                exact
                                component={ContractTemplateContainer}
                              />
                              <Route
                                path="/setup/contract-templates/:templateId"
                                component={ContractTemplateContainer}
                              />
                              <Route
                                path="/setup/badge-frameworks/:badgeFrameworkId"
                                component={BadgeFrameworkContainer}
                              />
                              <Route
                                path="/setup/badge-frameworks"
                                component={BadgeFrameworksContainer}
                              />
                              <Route
                                path="/contracts"
                                component={GlobalContractsContainer}
                              />
                              <Route
                                path="/economy"
                                component={GlobalEconomyContainer}
                              />
                              <Route
                                path="/cms"
                                exact
                                component={CmsContainer}
                              />
                              <Route
                                path="/cms/settings"
                                exact
                                component={CmsSettingsContainer}
                              />
                              <Route
                                path="/cms/:postTypeSlug/:postId"
                                component={PostContainer}
                              />
                              <Route
                                path="/tasks/:taskId"
                                component={TaskContainer}
                              />
                              <Route path="/tasks" component={TasksContainer} />
                              <Route
                                path="/staffing/staff"
                                component={StaffContainer}
                              />
                              <Route
                                path="/staffing/manage-tasks"
                                component={ManageTasksContainer}
                              />
                              <Route
                                path="/scheduling"
                                component={GlobalScheduleContainer}
                              />
                              <Route
                                path="/reports"
                                component={ReportsContainer}
                              />
                              <Route
                                path="/account"
                                component={AccountContainer}
                              />
                              <Route
                                path="/jetty-account"
                                component={JettyAccountContainer}
                              />
                              <Route
                                path="/:objectType/:objectId"
                                component={ObjectContainer}
                              />
                              <Route
                                path="/:objectType"
                                component={ObjectsContainer}
                              />
                            </Switch>
                          </section>
                        </CSSTransition>
                      </TransitionGroup>
                    </HomeContainer>
                  );
                }

                return <LoginContainer />;
              }}
            />
          </Switch>
          <LoaderOverlay
            solid={solidLoader}
            theme={theme}
            showLoader={showLoader}
          />
          <TransitionGroup>
            {showNotice && (
              <CSSTransition
                classNames={{
                  appear: s.noticeEnter,
                  appearActive: s.noticeEnterActive,
                  enter: s.noticeEnter,
                  enterActive: s.noticeEnterActive,
                  exit: s.noticeExit,
                  exitActive: s.noticeExitActive,
                }}
                timeout={100}
              >
                <div className={s.noticeWrapper}>
                  <Notice
                    type={noticeType}
                    text={noticeMessage}
                    closeAction={closeNotice}
                  />
                </div>
              </CSSTransition>
            )}
          </TransitionGroup>
        </Suspense>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
