import C from '../constants/constants';
import $ from '../../libs/reduxOperations';


export const initialState = {
  people: {},
  roles: {},
  departments: {},
  staff: {},
  pending: false,
  isCreating: false,
  isEditingPerson: false,
  nextPage: 1,
  currentPage: 1,
  paginationReachedBottom: false,
  preparedPerson: {},
  peopleAutocompleteResults: [],
  organizerPeopleAutocompleteResults: [],
  engagerPeopleAutocompleteResults: [],
  staffAutocompleteResults: [],
  selectedPeople: [],
  users: {},
  personContacts: [],
  personToBeDeleted: null,
  importFieldsPeople: null,
  importPeopleData: null,
  objectPersonFound: null,
};

export default function peopleReducer(state = initialState, action) {
  switch (action.type) {
    case C.FETCH_PEOPLE_SUCCESS:
      return {
        ...state,
        people: $.addOrUpdateObjectWithObjects(state.people, action.people),
        pending: false,
      };

    case C.FETCH_STAFF_SUCCESS:
      return {
        ...state,
        people: $.addOrUpdateObjectWithObjects(state.people, action.people),
        users: $.addOrUpdateObjectWithObjects(state.users, action.users),
        currentPage: action.reset || action.withFilter ? 1 : state.nextPage,
        nextPage: action.reset || action.withFilter ? 1 : state.nextPage + 1,
        pending: false,
      };

    case C.FETCH_PERSON_SUCCESS:
      return {
        ...state,
        people: $.addOrUpdateObjectWithObjects(state.people, action.people),
        users: $.addOrUpdateObjectWithObjects(state.users, action.users),
        pending: false,
      };

    case C.CREATE_PERSON_SUCCESS:
    case C.CREATE_SETUP_PERSON_SUCCESS:
      return {
        ...state,
        people: $.addOrUpdateObjectWithObjects(state.people, action.people),
        users: $.addOrUpdateObjectWithObjects(state.users, action.users),
        isCreating: false,
        isEditingPerson: false,
        pending: false,
        preparedPerson: {},
      };

    case C.UPDATE_PERSON_SUCCESS:
    case C.UPDATE_SETUP_PERSON_SUCCESS:
      return {
        ...state,
        people: $.addOrUpdateObjectWithObjects(state.people, action.people),
        users: $.addOrUpdateObjectWithObjects(state.users, action.users),
        pending: false,
        preparedPerson: {},
        isEditingPerson: false,
        isCreating: false,
      };

    case C.DELETE_PERSON_SUCCESS:
      return {
        ...state,
        pending: false,
        people: $.removeItemWithIdFromList(state.people, action.objectId),
      };

    case C.FETCH_PROJECT_SETTINGS_SUCCESS:
      return {
        ...state,
        roles: $.addOrUpdateObjectWithObjects(state.roles, action.roles),
        departments: $.addOrUpdateObjectWithObjects(state.departments, action.departments),
      };

    case C.FETCH_PEOPLE_START:
    case C.FETCH_STAFF_START:
      return {
        ...state,
        pending: true,
        selectedPeople: [],
      };
    case C.FETCH_PERSON_START:
    case C.CREATE_PERSON_START:
    case C.UPDATE_PERSON_START:
    case C.DELETE_PERSON_START:
      return {
        ...state,
        pending: true,
      };

    case C.FETCH_PEOPLE_FAIL:
    case C.FETCH_STAFF_FAIL:
    case C.FETCH_PERSON_FAIL:
    case C.CREATE_PERSON_FAIL:
    case C.UPDATE_PERSON_FAIL:
    case C.DELETE_PERSON_FAIL:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case C.BEGIN_VIEWING_PERSON:
      return {
        ...state,
        isViewingPerson: true,
        isCreating: false,
        preparedPerson: action.person,
      };
    case C.ABORT_VIEWING_PERSON:
      return {
        ...state,
        isViewingPerson: false,
        isCreating: false,
        preparedPerson: {},
      };
    case C.BEGIN_EDIT_PERSON:
      return {
        ...state,
        isCreating: action.isCreating,
        isEditingPerson: true,
        preparedPerson: action.person,
      };

    case C.ABORT_EDIT_PERSON:
      return {
        ...state,
        isCreating: false,
        isEditingPerson: false,
        preparedPerson: {},
      };

    case C.PREPARE_PERSON:
      return {
        ...state,
        preparedPerson: action.person,
      };

    case C.AUTOCOMPLETE_PEOPLE_START:
      return { ...state, pending: true, request: C.AUTOCOMPLETE_PEOPLE };
    case C.AUTOCOMPLETE_PEOPLE_FAIL:
      return {
        ...state, pending: false, request: null, error: action.error,
      };
    case C.AUTOCOMPLETE_PEOPLE_SUCCESS:
      return {
        ...state, pending: false, request: null, peopleAutocompleteResults: action.results,
      };
    case C.AUTOCOMPLETE_ORGANIZER_PEOPLE_SUCCESS:
      return {
        ...state, organizerPeopleAutocompleteResults: action.results,
      };
    case C.AUTOCOMPLETE_ENGAGER_PEOPLE_SUCCESS:
      return {
        ...state, engagerPeopleAutocompleteResults: action.results,
      };
    case C.AUTOCOMPLETE_STAFF_START:
      return { ...state, pending: true, request: C.AUTOCOMPLETE_STAFF };
    case C.AUTOCOMPLETE_STAFF_FAIL:
      return {
        ...state, pending: false, request: null, error: action.error,
      };
    case C.AUTOCOMPLETE_STAFF_SUCCESS:
      return {
        ...state, pending: false, request: null, staffAutocompleteResults: action.results,
      };

    case C.CLEAR_SELECT_PEOPLE:
      return {
        ...state,
        selectedPeople: [],
      };

    case C.SELECT_PERSON:
      return {
        ...state,
        selectedPeople: $.addToArray(state.selectedPeople, action.person),
      };
    case C.DESELECT_PERSON:
      return {
        ...state,
        selectedPeople: $.removeFromArray(state.selectedPeople, action.person),
      };
    case C.TOGGLE_SELECT_PERSON:
      return {
        ...state,
        selectedPeople: $.addOrRemoveFromArray(state.selectedPeople, action.person),
      };

    case C.TOGGLE_ASSIGN_MODE:
      return action.assignMode ? {
        ...state,
      } : {
        ...state,
        selectedPeople: [],
      };

    case C.ASSIGN_TASKS_SUCCESS:
    case C.FETCH_TASKS_SUCCESS:
    case C.FETCH_TASK_SUCCESS:
    case C.CREATE_TASK_SUCCESS:
    case C.FETCH_OBJECTS_SUCCESS:
    case C.FETCH_OBJECT_TYPES_SUCCESS:
    case C.FETCH_OBJECT_SUCCESS:
    case C.CREATE_BLOCK_SUCCESS:
    case C.UPDATE_BLOCK_SUCCESS:
    case C.FETCH_FILES_SUCCESS:
      return {
        ...state,
        people: $.addOrUpdateObjectWithObjects(state.people, action.people),
        users: $.addOrUpdateObjectWithObjects(state.users, action.users),
        roles: $.addOrUpdateObjectWithObjects(state.roles, action.roles),
      };

    case C.UPDATE_TASK_SUCCESS:
      return {
        ...state,
        people: $.addOrUpdateObjectWithObjects(state.people, action.people),
        users: $.addOrUpdateObjectWithObjects(state.users, action.users),
        roles: $.addOrUpdateObjectWithObjects(state.roles, action.roles),
        selectedPeople: [],
      };

    case C.FETCH_PERSON_CONTACTS_SUCCESS:
      return {
        ...state,
        personToBeDeleted: action.person,
        personContacts: action.contacts,
      };

    case C.ABORT_DELETE_PERSON:
      return {
        ...state,
        personToBeDeleted: null,
        personContacts: [],
      };

    case C.DELETE_PERSON_AND_CONNECTIONS_START:
      return {
        ...state,
        pending: true,
      };

    case C.DELETE_PERSON_AND_CONNECTIONS_SUCCESS:
      return {
        ...state,
        personToBeDeleted: null,
        personContacts: [],
        pending: false,
        people: $.removeFromObjectById(state.people, action.person),
      };

    case C.DELETE_PERSON_AND_CONNECTIONS_FAIL:
      return {
        ...state,
        pending: false,
      };

    case C.DEACTIVATE_FIELDS_EDIT_MODE:
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        peopleAutocompleteResults: [],
      };

    case C.IMPORT_PEOPLE_FIELDS_CREATED:
    case C.PREPARE_IMPORT_FIELDS_PEOPLE:
      return {
        ...state,
        importFieldsPeople: action.importFields,
      };

    case C.PREPARE_PEOPLE_IMPORT_DATA:
      return {
        ...state,
        importPeopleData: action.importPeopleData,
      };

    case C.IMPORT_EXCEL_PEOPLE_SUCCESS:
      return {
        ...state,
        people: $.addOrUpdateObjectWithObjects(state.people, action.people),
        pending: false,
        importFieldsPeople: null,
        importPeopleData: null,
      };

    case C.FIND_PERSON_BY_EMAIL_SUCCESS:
    case C.FIND_PERSON_BY_EMAIL_FAIL:
    case C.UNSET_OBJECT_PERSON:
    case C.CONNECT_PERSON_OBJECT_SUCCESS:
    case C.CONNECT_PERSON_OBJECT_FAIL:
      return {
        ...state,
        objectPersonFound: action.objectPersonFound,
      };

    default:
      return state;
  }
}
