// @flow
import React from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import DOMPurify from 'dompurify';
import type { Uuid } from 'App/entities/FlowTypes';
// $FlowFixMe
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import translations from 'libs/translations.json';

export const isDate = (d: any) => Object.prototype.toString.call(d) === '[object Date]' && !Number.isNaN(d.getTime()); // eslint-disable-line import/prefer-default-export

export const hexToRgbA = (hex: string, opacity: number) => {
  let c;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if(c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join(''); // eslint-disable-line
    // $FlowFixMe
    return 'rgba(' + [(c>>16)&255, (c>>8)&255, c&255].join(',') + ',' + opacity + ')'; // eslint-disable-line
  }
  throw new Error('Bad Hex');
};

export const isImage = (name: string) => {
  if(!name) return false;
  const ending = name.substr(-3);
  return (
    ending === 'jpg' ||
    ending === 'png' ||
    ending === 'gif' ||
    ending === 'peg' ||
    ending === 'svg'
  );
};

export const isVideo = (name: string) => {
  if(!name) return false;
  const ending = name.substr(-3);
  return (
    ending === 'mov' ||
    ending === 'mp4'
  );
};

export const getFileType = (name: string) => {
  if(isImage(name)) {
    return 'img';
  } if(isVideo(name)) {
    return 'vid';
  }
  return 'doc';
};

export const getPrettyFileType = (name: string) => {
  switch(name.substr(-3)) {
    case 'jpg':
    case 'png':
    case 'svg':
    case 'tif':
    case 'peg':
    case 'bmp':
      return 'image';
    case 'pdf':
      return 'pdf document';
    case 'doc':
    case 'ocx':
      return 'word document';
    case 'xls':
    case 'lsx':
      return 'excel document';
    case 'ppt':
    case 'ptx':
      return 'powerpoint document';
    case 'mov':
    case 'mp4':
      return 'video clip';
    case 'txt':
      return 'text file';
    default:
      return 'file';
  }
};

export const hashString = (str: string) => {
  let hash = 0;
  let i;
  let chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i += 1) {
    chr = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr; // eslint-disable-line
    hash |= 0; // eslint-disable-line
  }
  return hash;
};

export const sanitizeHTML = (unsanitizedHtml: any, purifyOptions?: any) => {
  const decodeHtml = (sanitizedHtml) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = sanitizedHtml;
    return txt.value;
  };
  return DOMPurify.sanitize(decodeHtml(unsanitizedHtml), purifyOptions);
};

export const renderSanitizedHTML = (unsanitizedHtml: any) =>
  <span dangerouslySetInnerHTML={{ __html: sanitizeHTML(unsanitizedHtml) }} />; // eslint-disable-line

export function uuid(): Uuid {
  return ('xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0; // eslint-disable-line
    const v = c === 'x' ? r : (r & 0x3 | 0x8); // eslint-disable-line
    return v.toString(16);
  }));
}

export function isUuid(maybeUuid: string): boolean {
  return /^.{8}-.{4}-4.{3}-.{4}-.{12}$/.test(maybeUuid);
}

/**
 * If an entity has an id that is an uuid,
 * returns the entity without the id field.
 */
export function removeUuidFromEntity(entity: any): any {
  if (!entity.id) return entity;
  if (!isUuid(entity.id)) return entity;
  const { id, ...rest } = entity;
  return rest;
}

export const flattenEntities = (nd: any) => ({
  objectDatas: nd.entities.object_data || {},
  blockDatas: nd.entities.block_data || {},
  blockTypes: nd.entities.block_type || {},
  fieldDatas: nd.entities.field_data || {},
  fieldTypes: nd.entities.field_type || {},
  people: nd.entities.person || {},
  projects: nd.entities.project || {},
  selectOptions: nd.entities.select_option || {},
  statuses: nd.entities.status || {},
  tags: nd.entities.tag || {},
  tagGroups: nd.entities.tag_group || {},
  files: nd.entities.file || {},
  departments: nd.entities.department || {},
  roles: nd.entities.role || {},
  objectTypes: nd.entities.object_type || {},
  setting: nd.entities.setting || {},
  assignments: nd.entities.assignment || {},
  users: nd.entities.user || {},
  tasks: nd.entities.task || {},
  globalSchedules: nd.entities.global_schedule || {},
  jobRoles: nd.entities.job_role || {},
  comments: nd.entities.comment || {},
  badgeTypes: nd.entities.badge_type,
  badgeFrameworks: nd.entities.badge_framework,
  badgeFrameworkSections: nd.entities.badge_framework_section,
});

export function richTextToString(richText: EditorState): string {
  const content = richText.getCurrentContent();
  if (!content.hasText()) return '';

  return sanitizeHTML(stateToHTML(content), {
    ALLOWED_TAGS: ['p', 'strong', 'em', 'u', 'code', 'h1', 'h2', 'h3', 'ul', 'ol', 'li', 'a'],
  });
}

export function maybeRichTextToString(maybeRichText: any): string {
  if (!maybeRichText) return '';
  if (maybeRichText instanceof EditorState) {
    const content = maybeRichText.getCurrentContent();
    return richTextToString(content);
  }
  if (typeof maybeRichText === 'string') return maybeRichText;
  // ¯\_(ツ)_/¯
  return maybeRichText.toString();
}

/**
 * Performs Reacts shallow props/states comparison but
 * removes some properties from the comparison, which
 * can(should) be checked manually for changes.
 */
export function partialShallowCompare<P, S>(component: React.Component<P, S>, nextProps: P, nextState: any, skipProps: string[]) {
  const currPropsCopy = { ...component.props };
  const nextPropsCopy = { ...nextProps };

  skipProps.forEach(p => delete currPropsCopy[p]);
  skipProps.forEach(p => delete nextPropsCopy[p]);

  return shallowCompare({ props: currPropsCopy, state: component.state }, nextPropsCopy, nextState);
}

/**
* Get the info domain for external parts of the system.
*/
export function getInfoDomain() {
  let infoDomain = window.location.host;
  if(window.location.host.indexOf('tools.jetty2') > -1) {
    infoDomain = window.location.host.replace('tools.jetty2', 'infojetty2');
  } else if (window.location.host.indexOf('beta.jetty2') > -1) {
    infoDomain = window.location.host.replace('beta.jetty2', 'beta.infojetty2');
  }
  return infoDomain;
}

export const maybePluralize = (noun: string, suffix: string = 's') =>
  `${noun}${suffix}`;

export const maybeSingularize = (noun: string, suffix: string = 's') =>
  noun.substring(0, noun.length - suffix.length);

export const validationEmail = (value: string) => {
  const regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line
  return regex.test(value.trim().toLowerCase());
};

export function clamp(num: number, min: number, max: number) {
  return num <= min ? min : num >= max ? max : num; // eslint-disable-line
}

export function capitalize(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function blobToBase64(blob: Blob): Promise<any> {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
}

// Javascript is the most beautiful language
export function mmToPixels(mm?: number, multiplier?: number) {
  if(mm !== mm) return 0; //eslint-disable-line
  return ((mm || 0) * 3.7795275591) * (multiplier || 1);
}

export function pixelsToMm(pixels?: number) {
  if(pixels !== pixels) return 0; //eslint-disable-line
  return (pixels || 0) * 0.2645833333;
}

export function pseudoRandomNumber(length: number): number {
  const number = Date.now() * Math.random();
  return parseInt(number.toString().replace('.', '').slice(0, length), 10);
}

export function shallowEqual(a: Object, b: Object) {
  for(const key in Object.keys(a)) {
    if(!(key in b) || a[key] !== b[key]) {
      return false;
    }
  }
  for(const key in Object.keys(b)) {
    if(!(key in a) || a[key] !== b[key]) {
        return false;
    }
  }
  return true;
}

/**
 * Lookup a key (e.g. "input_user_info.title_full_name") in translations.json,
 * and falls back to english (or provided key) when translation is not found
 */
export function translate(language: string, key: string): ?string {
  function translateInner(keys: string[], dict) {
    const head = keys[0]

    if (!dict || !(head in dict)) {
      // retry in english
      return language === "en" ? undefined : translate("en", key);
    }

    return keys.length === 1 ? dict[head] : translateInner(keys.slice(1), dict[head])
  }

  return translateInner(key.split("."), translations[language ?? "en"]) || key
}