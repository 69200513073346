import { objectType, blockType, tag, fieldType, status, tagGroup, badgeType } from './headers';

objectType.define({
  block_types: [blockType],
  external_block_types: [blockType],
  children: [objectType],
  parent: objectType,
  statuses: [status],
  default_status: status,
  disabled_status: status,
  summary_field_types: [fieldType],
  primary_category_object: objectType,
  primary_tag_group: tagGroup,
  tags: [tag],
  list_field_types: [fieldType],
  external_status: status,
  publishable_status: status,
  badge_types: [badgeType],
});

export default objectType;
