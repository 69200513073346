// @flow
import { uuid } from 'libs/utils';
import C from '../constants/constants';
import $ from '../../libs/reduxOperations';
import type { TagsState } from '../entities/FlowTypes';

export const initialState: TagsState = {
  tagAutocompleteResults: [],
  tag: {},
  tagname: null,
  tags: {},
  tagGroups: {},
  preparedTags: {},
  preparedTagGroups: {},
};

export default function tagsReducer(state: TagsState = initialState, action: any) {
  switch(action.type) {
    case C.AUTOCOMPLETE_TAGS_START:
      return {
        ...state, pending: true, request: C.AUTOCOMPLETE_TAGS, tagname: action.tagname,
      };
    case C.AUTOCOMPLETE_TAGS_FAIL:
      return {
        ...state, pending: false, request: C.AUTOCOMPLETE_TAGS, error: action.error,
      };
    case C.AUTOCOMPLETE_TAGS_SUCCESS:
      return {
        ...state, pending: false, request: C.AUTOCOMPLETE_TAGS, tagAutocompleteResults: action.tagAutocompleteResults,
      };
    case C.FETCH_TAGS_START:
      return { ...state, pending: true };
    case C.FETCH_TAGS_SUCCESS:
      return {
        ...state,
        pending: false,
        tags: $.addOrUpdateObjectWithObjects<any>(state.tags, action.tags),
        tagGroups: $.addOrUpdateObjectWithObjects<any>(state.tagGroups, action.tagGroups),
      };
    case C.FETCH_TAGS_FAIL:
      return { ...state, pending: false };
    case C.DEACTIVATE_FIELDS_EDIT_MODE:
      return { ...state, tagAutocompleteResults: [] };
    case C.ABORT_EDIT_SCHEDULE:
    case C.CREATE_SCHEDULE_SUCCESS:
    case C.PREPARE_FIELD:
      return { ...state, tag: null, tagname: null };
    case C.CREATE_BLOCK_SUCCESS:
    case C.UPDATE_BLOCK_SUCCESS:
    case C.UPDATE_OBJECT_SUCCESS:
      return {
        ...state,
        tag: null,
        tagname: null,
        tags: $.addOrUpdateObjectWithObjects<any>(state.tags, action.tags),
        tagGroups: $.addOrUpdateObjectWithObjects<any>(state.tagGroups, action.tagGroups),
      };
    case C.FETCH_PROJECT_SETTINGS_SUCCESS:
    case C.FETCH_TASKS_SUCCESS:
    case C.FETCH_TASK_SUCCESS:
    case C.CREATE_TASK_SUCCESS:
    case C.UPDATE_TASK_SUCCESS:
    case C.FETCH_OBJECTS_SUCCESS:
    case C.FETCH_OBJECT_TYPES_SUCCESS:
    case C.FETCH_OBJECT_SUCCESS:
    case C.FETCH_TAG_GROUPS_SUCCESS:
    case C.UPDATE_TAGS_SUCCESS:
      return {
        ...state,
        tags: $.addOrUpdateObjectWithObjects<any>(state.tags, action.tags),
        tagGroups: $.addOrUpdateObjectWithObjects<any>(state.tagGroups, action.tagGroups),
      };

    case C.CREATE_TAGS_SUCCESS:
      return {
        ...state,
        // $FlowFixMe
        tags: $.addObjectToObject(state.tags, action.tag),
        tagGroups: $.updateObjectById<any>(
          state.tagGroups,
          action.tagGroupId,
          {
            ...state.tagGroups[action.tagGroupId],
            tags: ((state.tagGroups[action.tagGroupId] && state.tagGroups[action.tagGroupId].tags) || []).concat(action.tag.id),
          },
        ),
      };

    case C.AUTOCOMPLETE_TAGS_CLEAR:
      return {
        ...state,
        tagAutocompleteResults: [],
      };

    case C.RENAME_TAG:
      return {
        ...state,
        preparedTags: { ...state.preparedTags, [action.tagId]: { ...{ ...state.tags, ...state.preparedTags }[action.tagId], name: action.name } },
      };

    case C.DISABLE_TAG:
      return {
        ...state,
        preparedTags: { ...state.preparedTags, [action.tagId]: { ...{ ...state.tags, ...state.preparedTags }[action.tagId], disabled: true } },
      };

    case C.CREATE_MERGE_TAG: {
      if (action.tagIds.length === 0) return state;
      const newId = uuid();
      const groupId = { ...state.tags, ...state.preparedTags }[action.tagIds[0]].tag_group_id;
      const group = { ...state.tagGroups, ...state.preparedTagGroups }[groupId];
      const tags = { ...state.tags, ...state.preparedTags };
      const mergedFrom = action.tagIds.flatMap(id => tags[id].mergedFrom || [id]);
      const newName = mergedFrom.map(id => tags[id].name).join(', ');
      const { project } = tags[action.tagIds[0]];
      return {
        ...state,
        preparedTags: { ...state.preparedTags, [newId]: { id: newId, name: newName, mergedFrom, tag_group_id: groupId, project } },
        preparedTagGroups: { ...state.preparedTagGroups, [groupId]: { ...group, tags: [...group.tags, newId] } },
      };
    }

    case C.UNPREPARE_TAGS:
      return {
        ...state,
        preparedTags: {},
        preparedTagGroups: {},
      };

    default:
      return state;
  }
}
