import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as tokenUserActions from '../actions/tokenUserActions';
import * as objectsActions from '../actions/objectsActions';
import * as environmentActions from '../actions/environmentActions';

import s from './TokenUserContainer.module.scss';

const mapStateToProps = state => ({ ...state.authStore, ...state.baseObjectsState, ...state.tokenUserState });

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    ...tokenUserActions,
    ...objectsActions,
    ...environmentActions,
  }, dispatch);

class TokenUserContainer extends Component {
  componentDidMount() {
    const { getTokenUser, purgeLocalState, setTheme, setExternallyAccessed } = this.props;
    const { userToken } = this.props.match.params;
    purgeLocalState();
    getTokenUser(userToken);
    setTheme('light');
    setExternallyAccessed(true);
  }

  render() {
    const {
      children,
      user,
      tokenUser,
    } = this.props;

    if(!user || !tokenUser) return <div />;

    return (
      <div className={s.wrapper}>
        {children}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TokenUserContainer);
