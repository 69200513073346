// @flow
import C from '../constants/constants';
import type { ActionType } from '../actions/stripeActions';
import type { StripeState } from '../entities/FlowTypes';
import $ from '../../libs/reduxOperations';

export const initialState: StripeState = {
  paymentIntent: null,
  billingInformation: null,
  preparedBillingInformation: null,
  stripeProducts: {},
  subscriptionPeriods: null,
  subscriptions: {},
  preparedPricingPlan: null,
  stripe: null,
  invoices: null,
  isEditingBillingInfo: false,
  isEditingProduct: false,
};

export default function stripeReducer(
  state: StripeState = initialState,
  action: ActionType,
): StripeState {
  switch (action.type) {
    case C.GET_PAYMENTINTENT_SUCCESS:
      return {
        ...state,
        paymentIntent: action.intent,
      };

    case C.PREPARE_BILLING_INFORMATION:
      return {
        ...state,
        preparedBillingInformation: action.preparedBillingInformation,
      };

    case C.FETCH_STRIPE_PRODUCTS_START:
      return {
        ...state,
        fetchingStripeProducts: true,
      };

    case C.FETCH_STRIPE_PRODUCTS_FAIL:
      return {
        ...state,
        fetchingStripeProducts: false,
      };

    case C.FETCH_STRIPE_PRODUCTS_SUCCESS:
      return {
        ...state,
        stripeProducts: action.products,
        fetchingStripeProducts: false,
      };

    case C.FETCH_SUBSCRIPTION_PERIODS_SUCCESS:
      return {
        ...state,
        subscriptionPeriods: action.subscriptionPeriods,
      };

    case C.SET_STRIPE_PRODUCT:
      return {
        ...state,
        stripeProduct: action.stripeProduct,
        preparedStripeProduct: null,
      };

    case C.SET_STRIPE_BILLING_INFORMATION:
      return {
        ...state,
        // preparedBillingInformation: null,
      };

    case C.FETCH_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: action.subscriptions || {},
      };

    case C.CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [action.subscription.id]: action.subscription,
        },
        createSubscriptionPending: null,
      };

    case C.CREATE_SUBSCRIPTION_START:
      return {
        ...state,
        createSubscriptionPending: action.productId,
      };

    case C.CREATE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        createSubscriptionPending: null,
      };

    case C.UPDATE_SUBSCRIPTION_SUCCESS:
    case C.UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        subscriptions: $.updateObjectById(
          state.subscriptions,
          action.subscription.id,
          action.subscription,
        ),
        preparedStripeProduct: null,
      };

    case C.CREATE_BILLING_INFO_START:
    case C.UPDATE_BILLING_INFO_START:
      return {
        ...state,
        isSavingBillingInfo: true,
      };

    case C.CREATE_BILLING_INFO_FAIL:
    case C.UPDATE_BILLING_INFO_FAIL:
      return {
        ...state,
        isSavingBillingInfo: false,
      };

    case C.CREATE_BILLING_INFO_SUCCESS:
    case C.UPDATE_BILLING_INFO_SUCCESS:
      return {
        ...state,
        billingInformation: $.addOrUpdateObject(
          state.billingInformation,
          action.billingInfo,
        ),
        isEditingBillingInfo: false,
        preparedBillingInformation: null,
        isSavingBillingInfo: false,
      };

    case C.FETCH_BILLING_INFO_SUCCESS:
      return {
        ...state,
        billingInformation: action.billingInfo,
      };

    case C.PREPARE_STRIPE_PLAN:
      return {
        ...state,
        preparedPricingPlan: action.preparedplan,
      };

    case C.PREPARE_STRIPE:
      return {
        ...state,
        stripe: action.stripe,
      };

    case C.CREATE_SUBSCRIPTION_PLAN_SUCCESS:
    case C.UPDATE_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        subscriptions: $.addOrUpdateObjectWithObjects(
          state.subscriptions,
          action.subscription,
        ),
        stripeProducts:
          action.product && state.stripeProducts
            ? $.updateObjectById(
                state.stripeProducts,
                action.product.id,
                action.product,
              )
            : state.stripeProducts,
        isEditingProduct: false,
      };

    case C.FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.invoices,
      };

    case C.EDIT_STRIPE_PROUDCT:
      return {
        ...state,
        isEditingProduct: action.isEditing,
      };

    case C.EDIT_STRIPE_BILLING:
      return {
        ...state,
        isEditingBillingInfo: action.isEditing,
      };

    case C.SET_STRIPE_PLAN_FAIL:
      return {
        ...state,
        showNotice: true,
        noticeMessage: action.error.message,
        noticeType: 'error',
      };

    case C.CREATE_STRIPE_CUSTOMER_START:
      return {
        ...state,
        isCreatingStripeCustomer: true,
      };

    case C.CREATE_STRIPE_CUSTOMER_FAIL:
      return {
        ...state,
        isCreatingStripeCustomer: false,
      };

    case C.CREATE_STRIPE_CUSTOMER_SUCCESS:
      return {
        ...state,
        isCreatingStripeCustomer: false,
      };

    default:
      return state;
  }
}
