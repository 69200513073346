// @flow
/* eslint no-use-before-define: 0 */

import type {
  Id,
  Badge,
  BadgeFramework,
  BadgeFrameworkSection,
  BadgeType,
  BlockType,
  Entities,
  FieldType,
  File,
  IdMap,
  ObjectType,
  Person,
  Prepared,
  PreparedEntities,
  SelectOption,
  Status,
  Tag,
  Translation,
  User,
} from 'App/entities/FlowTypes';
import { maybeRichTextToString, removeUuidFromEntity, isUuid } from 'libs/utils';
import { prepareFiles } from 'App/actions/actionUtils';
import O from 'App/constants/objectTypes';
import I from 'App/constants/inputTypes';
import F from 'App/constants/fieldTypeSettings';

type AdaptaionContext = {
  project: Id,
};

// adapted types are used to rewrite the types of some fields

type AdaptedObjectType = {
  ...Prepared<ObjectType>,
  block_types?: AdaptedBlockType[],
  external_block_types?: AdaptedBlockType[],
  trans_short?: { text: string },
  primary_field?: ?any,
  badge_types?: BadgeType[],
  badge_object_type?: ObjectType,
};

type AdaptedBlockType = {
  ...Prepared<BlockType>,
  field_types?: AdaptedFieldType[],
  external_field_types?: AdaptedFieldType[],
  departments?: { id: Id }[],
  trans_short?: { text: string },
};

type AdaptedFieldType = {
  ...Prepared<FieldType>,
  sub_object_block_type?: AdaptedBlockType,
  object_type?: AdaptedObjectType,
  active?: boolean,
  trans_short?: { text: string },
  pendingFiles?: File[],
  file?: ?File,
  is_filterable?: boolean,
};

type AdaptedStatus = {
  ...Prepared<Status>,
  id?: any,
  trans_short?: { text: string },
  temp_id?: boolean,
}

type AdaptedFile = {
  ...Prepared<File>,
  id?: any,
  external?: boolean,
  financial?: boolean,
  alt_text?: string,
  tags?: Tag[],
  copyright?: any,
  restrictions?: any,
  crop_x?: number,
  crop_y?: number,
  crop_width?: number,
  crop_height?: number,
}

export async function adaptBlockType(context: AdaptaionContext, blockTypeId: Id, entities: Entities, prepEntities: PreparedEntities): Promise<AdaptedBlockType> {
  const { objectTypes, blockTypes, fieldTypes } = entities;
  const { preparedObjectTypes, preparedBlockTypes, preparedFieldTypes } = prepEntities;

  async function innerAdaptObjectType(id: Id): Promise<AdaptedObjectType> {
    const prepared = preparedObjectTypes[id];
    if (!prepared) return { id };
    const merged: ObjectType = { ...objectTypes[id], ...prepared };

    // updating a block should only affect sub-objects in that block
    if (merged.type === O.COREOBJ) return { id };

    const adapted: AdaptedObjectType = removeUuidFromEntity({ id });

    // TODO: add more fields than can be updated
    if (merged.block_types) adapted.block_types = await Promise.all((merged.block_types.map(innerAdaptBlockType)));
    if (merged.type) adapted.type = merged.type;
    adapted.project = { id: context.project };

    return adapted;
  }

  async function innerAdaptBlockType(id: Id): Promise<AdaptedBlockType> {
    const prepared = preparedBlockTypes[id];
    if (!prepared) return { id };
    const merged: BlockType = { ...blockTypes[id], ...prepared };

    const adapted: AdaptedBlockType = removeUuidFromEntity({ id });

    // TODO: add more fields than can be updated
    if (prepared.active !== undefined) adapted.active = prepared.active;
    // TODO: this is a hack
    if (prepared.text !== undefined) adapted.trans_short = { project: { id: context.project }, text: prepared.text };
    if (prepared.order_index !== undefined) adapted.order_index = prepared.order_index;
    if (prepared.departments) adapted.departments = (prepared.departments.map(depId => ({ id: depId })): { id: Id }[]);
    if (merged.field_types) adapted.field_types = (await Promise.all((merged.field_types.map<Promise<?AdaptedFieldType>>(innerAdaptFieldType).filter(Boolean)))).filter(Boolean);
    if (merged.external_field_types) adapted.external_field_types = (merged.external_field_types.map(innerAdaptFieldType).filter(Boolean): AdaptedFieldType[]);
    if (prepared.special_type) adapted.special_type = prepared.special_type;

    return adapted;
  }

  async function innerAdaptFieldType(id: Id): Promise<?AdaptedFieldType> {
    const prepared = preparedFieldTypes[id];
    if (!prepared) return { id };
    if (prepared.deleted) return null;
    const merged = { ...fieldTypes[id], ...prepared };

    const adapted: AdaptedFieldType = removeUuidFromEntity({ id });

    // TODO: add more fields than can be updated
    if (prepared.select_options && (prepared.input_type === I.SELECT || prepared.input_type === I.SELECTMULTIPLE)) {
      adapted.select_options = prepared.select_options
        .filter(so => so.value && so.value.trim() !== '').map(so => adaptSelectOptions(id, removeUuidFromEntity(so)));
    }
    if (merged.object_type) adapted.object_type = await innerAdaptObjectType(merged.object_type);
    if (merged.sub_object_block_type) adapted.sub_object_block_type = await innerAdaptBlockType(merged.sub_object_block_type.id);
    if (prepared.active !== undefined) adapted.active = prepared.active;
    if (prepared.text !== undefined) adapted.trans_short = { text: prepared.text };
    if (prepared.placeholder !== undefined && F[prepared.input_type].has_placeholder) adapted.placeholder_translation = { text: prepared.placeholder };
    if (prepared.input_type) adapted.input_type = prepared.input_type;
    if (prepared.number_min !== undefined) adapted.number_min = prepared.number_min;
    if (prepared.number_max) adapted.number_max = prepared.number_max;
    if (prepared.multi_date !== undefined) adapted.multi_date = prepared.multi_date;
    if (prepared.multi_time !== undefined) adapted.multi_time = prepared.multi_time;
    if (prepared.is_single_tag !== undefined) adapted.is_single_tag = prepared.is_single_tag;
    if (prepared.order_index) adapted.order_index = prepared.order_index;
    if (prepared.size) adapted.size = prepared.size;
    if (prepared.list_size && parseInt(prepared.list_size, 10)) adapted.list_size = prepared.list_size;
    if (prepared.nested_list_size && parseInt(prepared.nested_list_size, 10)) adapted.nested_list_size = prepared.nested_list_size;
    if (prepared.list !== undefined && F[prepared.input_type].has_preview) adapted.list = prepared.list;
    if (prepared.list_nested !== undefined && F[prepared.input_type].has_preview) adapted.list_nested = prepared.list_nested;
    if (prepared.required !== undefined && F[prepared.input_type].has_required) adapted.required = prepared.required;
    if (prepared.input_type === I.TAG || prepared.tag_group) adapted.tag_group = { ...prepared.tag_group, project: { id: context.project } };
    if (prepared.description) adapted.description = maybeRichTextToString(prepared.description);
    if (prepared.conditional_field_type) adapted.conditional_field_type = prepared.conditional_field_type;
    if (prepared.conditional_select_option) adapted.conditional_select_option = prepared.conditional_select_option;
    if (prepared.primary_field !== undefined) adapted.primary_field = prepared.primary_field;
    if (prepared.max_length === undefined) adapted.max_length = null;
    if (prepared.input_type === I.DISPLAYIMAGE) {
      if (prepared.pendingFiles && prepared.pendingFiles.length) {
        const files = (await prepareFiles({ projectId: context.project }, prepared.pendingFiles)).map(fid => ({ id: fid }));
        adapted.file = files[0]; // eslint-disable-line
      } else {
        adapted.file = null;
      }
    }
    if (prepared.economy_type !== undefined) adapted.economy_type = prepared.economy_type;
    if (prepared.is_single_file !== undefined) adapted.is_single_file = prepared.is_single_file;
    if (prepared.is_filterable !== undefined) adapted.is_filterable = prepared.is_filterable;

    return adapted;
  }

  return innerAdaptBlockType(blockTypeId);
}

function adaptSelectOptions(fieldTypeId: any, selectOption: SelectOption): SelectOption {
  const adapted: any = { ...selectOption };
  if (!adapted.id) { // is a new select option
    if (isUuid(fieldTypeId)) {
      delete adapted.field_type;
    } else {
      adapted.field_type = { id: fieldTypeId }; // the fieldtype can also be new
    }
  }
  return adapted;
}

function adaptTranslation(translation: Translation, translationId?: Id) {
  const adaptedTranslation = { text: maybeRichTextToString(translation.text) };
  // $FlowFixMe
  if(translationId) adaptedTranslation.id = translationId;
  return adaptedTranslation;
}

export function adaptObjectType(prepObjectType: Prepared<ObjectType>, statuses: IdMap<Status>): any { // eslint-disable-line
  // TODO: add more fields from objTyp to adaptedObjType
  const adaptedObjType: AdaptedObjectType = {
    id: prepObjectType.id,
  };

  if (prepObjectType.text !== undefined) adaptedObjType.trans_short = { text: prepObjectType.text };
  if (prepObjectType.text !== undefined) {
    let url = prepObjectType.text.toLowerCase().split(' ').join('+');
    url = url.split('?').join('+');
    adaptedObjType.url = url;
  }
  if (prepObjectType.show_contracts) adaptedObjType.show_contracts = prepObjectType.show_contracts;
  if (prepObjectType.should_mail_on_submit !== undefined) adaptedObjType.should_mail_on_submit = prepObjectType.should_mail_on_submit;
  if (prepObjectType.has_custom_submit_email !== undefined) adaptedObjType.has_custom_submit_email = prepObjectType.should_mail_on_submit;
  if (prepObjectType.has_bulk_email_sending !== undefined) adaptedObjType.has_bulk_email_sending = prepObjectType.has_bulk_email_sending;
  if (prepObjectType.default_status) {
    if (isUuid(prepObjectType.default_status)) {
      adaptedObjType.default_status = { temp_id: prepObjectType.default_status };
    } else {
      adaptedObjType.default_status = { id: prepObjectType.default_status };
    }
  }
  if (prepObjectType.default_status) adaptedObjType.default_status = adaptStatus(prepObjectType.default_status);
  if (prepObjectType.disabled_status) adaptedObjType.disabled_status = adaptStatus(prepObjectType.disabled_status);
  if (prepObjectType.statuses) adaptedObjType.statuses = prepObjectType.statuses.map(s => statuses[s] && adaptStatuses(statuses[s]));
  if (prepObjectType.primary_field_id) adaptedObjType.primary_field = { id: prepObjectType.primary_field_id };

  if (prepObjectType.token_user_description &&
    prepObjectType.token_user_description.text &&
    prepObjectType.token_user_description_translation_id) adaptedObjType.token_user_description = adaptTranslation(prepObjectType.token_user_description, prepObjectType.token_user_description_translation_id);

  if (prepObjectType.mail_submit_content_translation &&
    prepObjectType.mail_submit_content_translation.text) adaptedObjType.mail_submit_content_translation = adaptTranslation(prepObjectType.mail_submit_content_translation, prepObjectType.mail_submit_content_translation_id);

  if (prepObjectType.mail_submit_title_translation &&
    prepObjectType.mail_submit_title_translation.text) adaptedObjType.mail_submit_title_translation = adaptTranslation(prepObjectType.mail_submit_title_translation, prepObjectType.mail_submit_title_translation_id);

  if (prepObjectType.mail_submit_subject_translation &&
    prepObjectType.mail_submit_subject_translation.text) adaptedObjType.mail_submit_subject_translation = adaptTranslation(prepObjectType.mail_submit_subject_translation, prepObjectType.mail_submit_subject_translation_id);

  if (prepObjectType.mail_bulk_content_translation &&
    prepObjectType.mail_bulk_content_translation.text) adaptedObjType.mail_bulk_content_translation = adaptTranslation(prepObjectType.mail_bulk_content_translation, prepObjectType.mail_bulk_content_translation_id);

  if (prepObjectType.mail_bulk_footer_translation &&
    prepObjectType.mail_bulk_footer_translation.text) adaptedObjType.mail_bulk_footer_translation = adaptTranslation(prepObjectType.mail_bulk_footer_translation, prepObjectType.mail_bulk_footer_translation_id);

  if (prepObjectType.mail_bulk_title_translation &&
    prepObjectType.mail_bulk_title_translation.text) adaptedObjType.mail_bulk_title_translation = adaptTranslation(prepObjectType.mail_bulk_title_translation, prepObjectType.mail_bulk_title_translation_id);

  if (prepObjectType.mail_bulk_subject_translation &&
    prepObjectType.mail_bulk_subject_translation.text) adaptedObjType.mail_bulk_subject_translation = adaptTranslation(prepObjectType.mail_bulk_subject_translation, prepObjectType.mail_bulk_subject_translation_id);

  if (prepObjectType.language) adaptedObjType.language = prepObjectType.language;
  if (prepObjectType.has_badges !== undefined) adaptedObjType.has_badges = prepObjectType.has_badges;
  if (prepObjectType.accepts_submissions !== undefined) adaptedObjType.accepts_submissions = prepObjectType.accepts_submissions;
  if (prepObjectType.max_submissions !== undefined) adaptedObjType.max_submissions = prepObjectType.max_submissions;
  if (prepObjectType.badge_types) adaptedObjType.badge_types = prepObjectType.badge_types.map(bt => adaptBadgeType(bt));
  if (prepObjectType.badge_object_type) adaptedObjType.badge_object_type = prepObjectType.badge_object_type;

  return adaptedObjType;
}

function adaptStatus(status: any): AdaptedStatus {
  let adapted: AdaptedStatus;
  if (isUuid(status)) {
    adapted = { temp_id: status };
  } else {
    adapted = { id: status };
  }
  return adapted;
}

function adaptStatuses(status: Status): AdaptedStatus {
  const adapted: AdaptedStatus = { id: removeUuidFromEntity(status).id };
  if (isUuid(status.id)) {
    adapted.temp_id = status.id;
  }
  if (status.text !== undefined) adapted.trans_short = { text: status.text };
  if (status.order !== undefined) adapted.order = status.order;
  if (status.color !== undefined) adapted.color = status.color;
  return adapted;
}

export function adaptFile(file: File, isUpdating?: boolean = false): AdaptedFile {
  const newFile: File = {
    id: file.id,
    name: file.name,
    base64: file.base64,
    external: file.external,
    financial: file.financial,
    alt_text: file.alt_text,
    copyright: file.copyright,
    restrictions: file.restrictions,
    crop_x: file.crop_x,
    crop_y: file.crop_y,
    crop_width: file.crop_width,
    crop_height: file.crop_height,
    height: file.height,
    width: file.width,
    file_size: file.file_size,
  };
  if(!isUpdating && file.object_datas) newFile.object_datas = file.object_datas;
  if(file.tags) {
    const tempTags: Array<Tag> = file.tags && file.tags.map(tag => (tag.id ? { id: tag.id } : {
      id: tag.id,
      name: tag.name,
      project: tag.project,
      tag_group: tag.tag_group,
    }));
    newFile.tags = tempTags;
  }
  return newFile;
}

export function adaptFileCrop(file: File): AdaptedFile {
  const newFile: File = {
    external: file.external,
    financial: file.financial,
    alt_text: file.alt_text,
    copyright: file.copyright,
    restrictions: file.restrictions,
    crop_x: file.crop_x,
    crop_y: file.crop_y,
    crop_width: file.crop_width,
    crop_height: file.crop_height,
  };
  // if(file.tags) {
  //   const tempTags: Array<Tag> = file.tags && file.tags.map(tag => (tag.id ? { id: tag.id } : {
  //     id: tag.id,
  //     name: tag.name,
  //     project: tag.project,
  //     tag_group: tag.tag_group,
  //   }));
  //   newFile.tags = tempTags;
  // }
  return newFile;
}

export function adaptTag(t: any, pid: Id) {
  const tag: any = {
    name: t.name,
    project: { id: pid },
  };
  if(t.tag_group && t.tag_group.id) tag.tag_group = { id: ((t.tag_group && t.tag_group.id) || t.tag_group_id || t.tag_group) };
  if (t.disabled !== undefined) tag.disabled = t.disabled;
  if (t.id) tag.id = t.id;

  return tag;
}

export function adaptPerson(p: Person, pid: Id) {
  const person = {
    ...p,
    project: { id: pid },
  };
  if(p.user) person.user = { id: p.user.id || p.user };
  if(p.id) person.id = p.id;
  if(p.title_tags && p.title_tags.length) person.title_tags = (p.title_tags.filter((tt: Tag) => Object.values(tt).length): Array<Tag>);
  if(p.tags && p.tags.length) person.tags = (p.tags.filter((tt: Tag) => Object.values(tt).length): Array<Tag>);

  return person;
}

export function adaptUser(u: User) {
  // $FlowFixMe
  const { permissions, client, projects, last_project, ...user } = { //eslint-disable-line
    ...u,
    roles: (u.roles && u.roles.map<*>((r: any) => ({ id: r.id || r }))),
  };

  if(u.id) user.id = u.id;

  return user;
}

export async function adaptBadgeFramework(bf: BadgeFramework, ids: any) {
  const badgeFramework = { ...bf };
  if(bf.pendingBackgroundImage && bf.pendingBackgroundImage.length) badgeFramework.background_image = { id: (await prepareFiles(ids, bf.pendingBackgroundImage, false))[0] };
  if(bf.pendingLogo && bf.pendingLogo.length) badgeFramework.logo = { id: (await prepareFiles(ids, bf.pendingLogo, false))[0] };
  if(bf.badge_framework_sections && bf.badge_framework_sections.length) badgeFramework.badge_framework_sections = await Promise.all(bf.badge_framework_sections.map<*>(bfs => adaptBadgeFrameworkSection(bfs, ids)));
  return badgeFramework;
}

export async function adaptBadgeFrameworkSection(bfs: BadgeFrameworkSection, ids: any) {
  if(typeof bfs === 'number') return { id: bfs };
  const badgeFrameworkSection = { ...bfs };
  if(bfs.pendingFiles && bfs.pendingFiles.length) badgeFrameworkSection.background_image = { id: (await prepareFiles(ids, bfs.pendingFiles, false))[0] };
  return badgeFrameworkSection;
}

export function adaptBadgeType(bt: BadgeType) {
  const badgeType = { ...bt };
  if(bt.badge_framework) badgeType.badge_framework = { id: bt.badge_framework.id };
  if(bt.connected_field_types) badgeType.connected_field_types = bt.connected_field_types.map(cft => ({ ...cft, field_type: { id: cft.field_type.id }, badge_framework_section: { id: cft.badge_framework_section.id } }));
  return badgeType;
}

export async function adaptBadge(b: Badge, ids: any) {
  const badge = { ...b };
  if(b.pendingFile) badge.file = { id: (await prepareFiles(ids, [b.pendingFile], false))[0] };
  return badge;
}
