// @flow
import E from '../constants/economy';
import C from '../constants/constants';
import $ from '../../libs/reduxOperations';
import type { ActionType } from '../actions/economyActions';
import type { EconomyState, FieldData } from '../entities/FlowTypes';
import { IdMapValues } from '../entities/FlowTypes';

export const initialState: EconomyState = {
  economies: {},
  preparedEconomies: [],
  preparedEconomy: {},
  isEditingEconomy: false,
};

export default function EconomiesReducer(state: EconomyState = initialState, action: ActionType): EconomyState {
  switch (action.type) {
    case E.FETCH_ECONOMIES_START:
      return {
        ...state,
      };

    case E.FETCH_ECONOMIES_SUCCESS:
      return {
        ...state,
        economies: $.addOrUpdateObjectWithObjects(state.economies, action.economies),
        preparedEconomies: IdMapValues($.addOrUpdateObjectWithObjects(state.economies, action.economies)),
      };

    case E.PREPARE_ECONOMIES:
      return {
        ...state,
        preparedEconomies: action.preparedEconomies,
      };

    case E.PREPARE_ECONOMY:
      return {
        ...state,
        preparedEconomies: $.addOrUpdateObjectInArrayById(state.preparedEconomies, action.preparedEconomy),
        preparedEconomy: action.preparedEconomy,
      };

    case C.DEACTIVATE_FIELDS_EDIT_MODE:
      return {
        ...state,
        preparedEconomies: state.economies ? IdMapValues(state.economies) : [],
      };

    case C.CREATE_BLOCK_SUCCESS:
    case C.UPDATE_BLOCK_SUCCESS: {
      const newEconomies = IdMapValues(action.fieldDatas)
        .filter((fd: FieldData) => (fd && fd.economies && fd.economies.length))
        .reduce((acc, fd) => acc.concat(fd.economies), [])
        .reduce((acc, economy) => ({
          ...acc,
          [economy.id]: economy,
        }), {});
      return {
        ...state,
        economies: newEconomies && $.addOrUpdateObjectWithObjects(state.economies, newEconomies),
      };
    }

    case E.BEGIN_EDIT_ECONOMY:
      return {
        ...state,
        isEditingEconomy: true,
        preparedEconomies: [action.economy],
        preparedEconomy: action.economy,
      };

    case E.ABORT_EDIT_ECONOMY:
      return {
        ...state,
        isEditingEconomy: false,
        preparedEconomies: [],
        preparedEconomy: {},
      };

    case E.CREATE_OR_UPDATE_ECONOMY_SUCCESS:
      return {
        ...state,
        economies: $.addOrUpdateObjectWithObjects(state.economies, action.economies),
      };

    case E.FETCH_ECONOMIES_FAIL:
      return state;
    default:
      return state;
  }
}
