import React from 'react';

import s from './Title.module.scss';

const Title = ({
  children,
  edit,
  h1,
  h2,
  h3,
  h4,
  light,
  h3External,
  subtitle,
  tiny,
  inline = false,
  invalid = false,
}) => (
  <div className={`${s.wrapper} ${edit ? s.edit : ''} ${inline ? s.inline : ''} ${children ? s.hasChildren : ''}`}>
    { h1 &&
      <h1 className={`${s.h1}  ${invalid ? 'input-invalid' : ''}`}>{children}</h1>
    }
    { h2 &&
      <h2 className={`${s.h2} ${light ? s.light : ''} ${invalid ? 'input-invalid' : ''}`}>{children}</h2>
    }
    { h3 &&
      <h3 className={`${s.h3} ${light ? s.light : ''}  ${invalid ? 'input-invalid' : ''}`}>{children}</h3>
    }
    { h3External &&
      <h3 className={`${s.h3external}  ${invalid ? 'input-invalid' : ''}`}>{children}</h3>
    }
    { h4 &&
      <h4 className={`${s.h4} ${invalid ? 'input-invalid' : ''} ${light ? s.light : ''}`}>{children}</h4>
    }
    { subtitle &&
      <span className={s.subtitle}>{children}</span>
    }
    { tiny &&
      <span className={s.tiny}>{children}</span>
    }
  </div>
);

export default Title;
