// @flow
import { normalize } from 'normalizr';
import { PURGE } from 'redux-persist';
import * as Sentry from '@sentry/browser';
import { flattenEntities, isUuid, maybeRichTextToString } from 'libs/utils';
import { push } from 'connected-react-router';
import { useHistory } from 'react-router-dom';
import type {
  Prepared,
  Department,
  Id,
  Project,
  ProjectInformation,
} from 'App/entities/FlowTypes';
import { adaptTag } from '../../libs/adapters';
import C from '../constants/constants';
import requestManager from '../../libs/requestManager';
import { getIds } from './actionUtils';
import { client, department, project } from '../entities/headers';
import objectType from '../entities/ObjectType';
import setting from '../entities/Setting';
import { fetchMenuItems } from './environmentActions';

import { isDate } from '../components/calendar/DateUtils';

const flattenSettings = (normalizedSetting) => ({
  departments: normalizedSetting.entities.department,
  roles: normalizedSetting.entities.role,
  tagGroups: normalizedSetting.entities.tag_group,
  tags: normalizedSetting.entities.tag,
  objectTypes: normalizedSetting.entities.object_type,
  setting: normalizedSetting.entities.setting,
  project: normalizedSetting.entities.project,
});

function getUser(dispatch: any) {
  dispatch({ type: C.FETCH_MY_USER_START });
  return requestManager
    .getCurrentUser()
    .then((response) =>
      dispatch({ type: C.FETCH_MY_USER_SUCCESS, user: response.data }),
    )
    .catch((error) => {
      /**
       * If the response when fetching a user is 404, it is likely that the account has only gone through the
       * first step of signup. In that case no user has been created yet, so it should be redirected back to signup.
       */
      if (
        error?.response?.status === 404 &&
        window.location.pathname !== '/signup'
      ) {
        if (window.location.hostname.split('.')[0] === 'my') {
          window.location.href = 'getstarted.jetty.se';
        }
        if (
          window.location.hostname.split('.')[0] !== 'my' &&
          window.location.hostname.split('.')[0] !== 'getstarted'
        ) {
          window.location.href = '/signup';
        }
      }

      return dispatch({ type: C.FETCH_MY_USER_FAIL, error, avoidNotice: true });
    });
}

// Get current user is usable when permissions is changed and needs to be refreshed.
export function getCurrentUser() {
  return getUser;
}

export function afterAuthenticationSuccess(
  dispatch: any,
  getState: any,
  skipLoader: boolean,
) {
  dispatch({ type: C.FETCH_ACCOUNT_START });
  dispatch({ type: C.FETCH_MY_USER_START });
  dispatch({ type: C.FETCH_CLIENTS_START });
  dispatch({ type: C.FETCH_PROJECTS_START });
  dispatch({ type: C.FETCH_VERSION_START });
  if (!skipLoader) {
    dispatch({ type: C.SHOW_LOADER, solid: true });
  }

  Promise.all([
    requestManager
      .getCurrentAccount()
      .then((response) =>
        dispatch({ type: C.FETCH_ACCOUNT_SUCCESS, account: response.data }),
      )
      .catch((error) =>
        dispatch({ type: C.FETCH_ACCOUNT_FAIL, error, avoidNotice: true }),
      ),

    getUser(dispatch),

    requestManager
      .getClients()
      .then((response) =>
        dispatch({
          type: C.FETCH_CLIENTS_SUCCESS,
          clients: normalize(response.data, [client]).entities.client,
        }),
      )
      .catch((error) =>
        dispatch({ type: C.FETCH_CLIENTS_FAIL, error, avoidNotice: true }),
      ),
  ])
    .then(() => {
      const { client_last: clientId, username } = getState().authStore.account;
      Sentry.setUser({ email: username });

      return Promise.all([
        requestManager
          .getProjects(clientId)
          .then((response) => {
            const normalized = normalize(response.data, [project]);

            dispatch({
              type: C.FETCH_PROJECTS_SUCCESS,
              projects: normalized.entities.project,
              languages: normalized.entities.language,
              jobRoles: normalized.entities.job_role,
            });
          })
          .catch((error) =>
            dispatch({ type: C.FETCH_PROJECTS_FAIL, error, avoidNotice: true }),
          ),
      ]);
    })
    .then(() => {
      dispatch({ type: C.FETCH_PROJECT_SETTINGS_START });
      dispatch({ type: C.FETCH_USER_DEPARTMENTS_START });
      dispatch({ type: C.FETCH_OBJECT_TYPES_START });
      dispatch({ type: C.FETCH_NOTIFICATIONS_START });
      dispatch({ type: C.FETCH_NOTIFICATIONS_COUNT_START });

      const ids = getIds(getState);

      return Promise.all([
        requestManager
          .getProjectSettings(ids.clientId, ids.projectId)
          .then((data) => {
            const nd = normalize(data.data, setting);
            const reduxEntities = flattenSettings(nd);
            dispatch({
              type: C.FETCH_PROJECT_SETTINGS_SUCCESS,
              ...reduxEntities,
            });
          })
          .catch((error) =>
            dispatch({ type: C.FETCH_PROJECT_SETTINGS_FAIL, error }),
          ),

        requestManager
          .getAccountNotifications(ids.projectId, ids.accountId)
          .then((data) => {
            dispatch({
              type: C.FETCH_NOTIFICATIONS_SUCCESS,
              notifications: data.data,
            });
          })
          .catch((error) =>
            dispatch({ type: C.FETCH_NOTIFICATIONS_FAIL, error }),
          ),

        requestManager
          .getAccountNotificationsCount(ids.projectId, ids.accountId)
          .then((data) => {
            dispatch({
              type: C.FETCH_NOTIFICATIONS_COUNT_SUCCESS,
              notificationsCount: data.data,
            });
          })
          .catch((error) =>
            dispatch({ type: C.FETCH_NOTIFICATIONS_COUNT_FAIL, error }),
          ),

        dispatch(fetchMenuItems()),

        requestManager
          .getObjectTypes(ids.clientId, ids.projectId)
          .then((data) => {
            const nd = normalize(data.data, [objectType]);
            dispatch({
              type: C.FETCH_OBJECT_TYPES_SUCCESS,
              objectTypes: nd.entities.object_type,
              fieldTypes: nd.entities.field_type,
              blockTypes: nd.entities.block_type,
              externalBlockTypes: nd.entities.external_block_type,
              departments: nd.entities.department,
              statuses: nd.entities.status,
              tags: nd.entities.tag,
              selectOptions: nd.entities.select_option,
              badgeTypes: nd.entities.badge_type,
              badgeFrameworks: nd.entities.badge_framework,
              badgeFrameworkSections: nd.entities.badge_framework_section,
            });
          })
          .catch((error) => {
            dispatch({ type: C.FETCH_OBJECT_TYPES_FAIL, error });
          }),
      ]).then(() => {
        dispatch({ type: C.LOGIN_SUCCESS });
        if (!skipLoader) {
          dispatch({ type: C.HIDE_LOADER, solid: false });
        }
      });
    })
    .catch(() =>
      !skipLoader ? dispatch({ type: C.HIDE_LOADER, solid: false }) : null,
    );
}

export function login(
  email: any,
  password: any,
  skipAfterAction: boolean,
  skipLoader: boolean,
) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: C.LOGIN_START });

    if (!skipLoader) {
      dispatch({ type: C.SHOW_LOADER });
    }

    requestManager
      .login(email, password)
      .then((loginData) => {
        dispatch({ type: PURGE, result: () => null });
        localStorage.setItem('access_token', loginData.data.token);
        localStorage.removeItem('X-JETTY-API-TOKEN');

        if (!skipLoader) {
          dispatch({ type: C.HIDE_LOADER });
        }

        if (!skipAfterAction) {
          afterAuthenticationSuccess(dispatch, getState, skipLoader);
        } else {
          dispatch({ type: C.LOGIN_SUCCESS });
        }
      })
      .catch((error) => {
        dispatch({ type: C.LOGIN_FAIL, error });

        if (!skipLoader) {
          dispatch({ type: C.HIDE_LOADER });
        }
      });
  };
}

export function validateToken() {
  return (dispatch: any, getState: any) => {
    afterAuthenticationSuccess(dispatch, getState);
  };
}

export function logout(disableRedirect) {
  return (dispatch: any) => {
    localStorage.removeItem('access_token');
    if (process.env.NODE_ENV !== 'development') {
      Sentry.configureScope((scope) => scope.setUser(null));
    }
    dispatch({ type: PURGE, result: () => null });
    dispatch({ type: C.LOGOUT });

    if (!disableRedirect) {
      dispatch(push('/dashboard'));
    }

    dispatch({ type: PURGE, result: () => null });
  };
}

export function setEmail(email: any) {
  return (dispatch: any) => {
    dispatch({ type: C.SET_EMAIL, email });
  };
}

export function getLastClient(username: any) {
  return (dispatch: any) => {
    dispatch({ type: C.FETCH_USER_CLIENT_START });
    dispatch({ type: C.SHOW_LOADER });

    requestManager
      .getLastClient(username)
      .then((data) => {
        dispatch({
          type: C.FETCH_USER_CLIENT_SUCCESS,
          data: data.data,
        });
        dispatch({ type: C.HIDE_LOADER });
      })
      .catch((error) => {
        dispatch({ type: C.FETCH_USER_CLIENT_FAIL, error });
        dispatch({ type: C.HIDE_LOADER });
      });
  };
}

export function setProject(projectId: any) {
  return (dispatch: any, getState: any) => {
    const ids = getIds(getState);
    dispatch({ type: C.SET_PROJECT_START });
    dispatch({ type: C.SHOW_LOADER, solid: true });

    requestManager
      .setProject(ids.clientId, projectId)
      .then((data) => {
        dispatch({ type: PURGE, result: () => null });
        dispatch({ type: C.SET_PROJECT_SUCCESS, user: data.data });
        dispatch({ type: C.HIDE_LOADER, solid: false });
        afterAuthenticationSuccess(dispatch, getState);
        dispatch(push('/dashboard'));
        dispatch({ type: PURGE, result: () => null });
      })
      .catch((error) => {
        dispatch({ type: C.SET_PROJECT_FAIL, error });
        dispatch({ type: C.HIDE_LOADER, solid: false });
      });
  };
}

export function setClient(clientId: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: C.SET_CLIENT_START });
    dispatch({ type: C.SHOW_LOADER, solid: true });

    requestManager
      .setClient(clientId)
      .then((data) => {
        dispatch({ type: PURGE, result: () => null });
        dispatch({ type: C.SET_CLIENT_SUCCESS, account: data.data });
        dispatch({ type: C.HIDE_LOADER, solid: false });
        afterAuthenticationSuccess(dispatch, getState);
        dispatch(push('/dashboard'));
        dispatch({ type: PURGE, result: () => null });
      })
      .catch((error) => {
        dispatch({ type: C.SET_CLIENT_FAIL, error });
        dispatch({ type: C.HIDE_LOADER, solid: false });
      });
  };
}

export function acceptTerms() {
  return (dispatch: any, getState: any) => {
    const { termsAccepted } = getState().authStore;
    if (termsAccepted) {
      dispatch({ type: C.ACCEPT_TERMS_START });
      dispatch({ type: C.SHOW_LOADER });
      requestManager
        .acceptTerms()
        .then((data) => {
          dispatch({ type: C.ACCEPT_TERMS_SUCCESS, account: data.data });
          dispatch({ type: C.HIDE_LOADER });
        })
        .catch((error) => {
          dispatch({ type: C.ACCEPT_TERMS_FAIL, error });
          dispatch({ type: C.HIDE_LOADER });
        });
    }
  };
}

export const toggleTermsAccept = () => (dispatch: any) =>
  dispatch({ type: C.TOGGLE_TERMS_ACCEPT });

export function prepareProject(preparedProject: Prepared<Project>) {
  return (dispatch: any) =>
    dispatch({ type: C.PREPARE_PROJECT, project: preparedProject });
}

export function updateProject(
  projectId: Id,
  preparedProject: Prepared<Project>,
) {
  return async (dispatch: any, getState: any) => {
    const { clientId } = getIds(getState);

    const adaptProjInfo = (projId) => (projInfo) => {
      const adaptedInfo: any = {
        title: projInfo.title,
        order_index: projInfo.order_index,
        project: { id: projId },
      };

      if (projInfo.id && !isUuid((projInfo.id: any)))
        adaptedInfo.id = projInfo.id;
      if (projInfo.primary_language)
        adaptedInfo.primary_language = {
          id: projInfo.primary_language.id || projInfo.primary_language,
        };
      if (projInfo.value) {
        adaptedInfo.value = maybeRichTextToString(projInfo.value);
      }

      return adaptedInfo;
    };

    const adaptProjectSettings = (projSettings) => {
      const adaptedSettings: any = {
        person_title_is_multiselect: projSettings.person_title_is_multiselect,
        has_two_letter_country_code: projSettings.has_two_letter_country_code,
        contract_pdf_dimension: projSettings.contract_pdf_dimension,
        contract_pdf_is_hole_punched: projSettings.contract_pdf_is_hole_punched,
        digital_signing_is_active: projSettings.digital_signing_is_active,
        has_am_pm_format: projSettings.has_am_pm_format,
        has_reoccuring: projSettings.has_reoccuring,
      };

      if (projSettings.id) adaptedSettings.id = projSettings.id;
      if (projSettings.contracts_is_active)
        adaptedSettings.contracts_is_active = projSettings.contracts_is_active;

      return adaptedSettings;
    };

    const adaptExchangeRate = (exchangeRate) => {
      const adaptedExchangeRate: any = {};
      if (exchangeRate.id) adaptedExchangeRate.id = exchangeRate.id;
      if (projectId) adaptedExchangeRate.project = { id: projectId };
      if (exchangeRate.from_currency)
        adaptedExchangeRate.from_currency = {
          id: exchangeRate.from_currency.id,
        };
      if (exchangeRate.to_currency)
        adaptedExchangeRate.to_currency = { id: exchangeRate.to_currency.id };
      if (exchangeRate.rate) adaptedExchangeRate.rate = exchangeRate.rate;

      return adaptedExchangeRate;
    };

    const adaptCurrency = (currency) => {
      const adaptedCurrency: any = {};
      if (currency.id) adaptedCurrency.id = currency.id;

      return adaptedCurrency;
    };

    const adaptEconomyCategory = (category, economySettingId) => {
      const adaptedCategory: any = {
        project: { id: projectId },
      };
      if (economySettingId)
        adaptedCategory.economy_setting = { id: economySettingId };
      if (category.id && !isUuid((category.id: any)))
        adaptedCategory.id = category.id;
      if (category.name) adaptedCategory.name = category.name;
      if (category.type) adaptedCategory.type = category.type;
      if (category.class) adaptedCategory.class = category.class;
      if (category.settlement_class)
        adaptedCategory.settlement_class = category.settlement_class;

      return adaptedCategory;
    };

    const adaptEconomySetting = (economySetting) => {
      const adaptedEconomySetting: any = {};
      if (economySetting.id) adaptedEconomySetting.id = economySetting.id;
      if (projectId) adaptedEconomySetting.project = { id: projectId };
      if (economySetting.vat) adaptedEconomySetting.vat = economySetting.vat;
      if (economySetting.currencies)
        adaptedEconomySetting.currencies = economySetting.currencies.map(
          (currency) => adaptCurrency(currency),
        );
      if (economySetting.primary_currency)
        adaptedEconomySetting.primary_currency = {
          id: economySetting.primary_currency.id,
        };
      if (economySetting.categories)
        adaptedEconomySetting.categories = economySetting.categories.map(
          (category) => adaptEconomyCategory(category, economySetting.id),
        );
      if (economySetting.account_tags && economySetting.account_tags.length)
        adaptedEconomySetting.account_tags = economySetting.account_tags.map(
          (t) => adaptTag(t, projectId),
        );
      if (
        economySetting.costcenter_tags &&
        economySetting.costcenter_tags.length
      )
        adaptedEconomySetting.costcenter_tags = economySetting.costcenter_tags.map(
          (t) => adaptTag(t, projectId),
        );
      if (
        economySetting.counterpart_tags &&
        economySetting.counterpart_tags.length
      )
        adaptedEconomySetting.counterpart_tags = economySetting.counterpart_tags.map(
          (t) => adaptTag(t, projectId),
        );
      return adaptedEconomySetting;
    };

    const adaptProject = async (proj) => {
      const adaptedProject: any = {
        id: projectId,
        name: proj.name,
        gps: proj.gps,
        address: proj.address,
      };

      if (isDate(proj.start_at)) adaptedProject.start_at = proj.start_at;
      if (isDate(proj.end_at)) adaptedProject.end_at = proj.end_at;
      if (isDate(proj.prod_start_at))
        adaptedProject.prod_start_at = proj.prod_start_at;
      if (isDate(proj.prod_end_at))
        adaptedProject.prod_end_at = proj.prod_end_at;
      if (proj.primary_people !== undefined)
        adaptedProject.primary_people = proj.primary_people.map((p) => ({
          id: p.id,
        }));
      if (proj.project_information !== undefined)
        adaptedProject.project_information = proj.project_information.map(
          adaptProjInfo(proj.id),
        );
      if (proj.economy_setting !== undefined)
        adaptedProject.economy_setting = adaptEconomySetting(
          proj.economy_setting,
        );
      if (proj.exchange_rates !== undefined)
        adaptedProject.exchange_rates = proj.exchange_rates.map((rate) =>
          adaptExchangeRate(rate),
        );
      if (proj.image_ratios !== undefined)
        adaptedProject.image_ratios = proj.image_ratios;

      if (proj.logotype && !proj.logotype.id) {
        const result = await requestManager.uploadFile(
          clientId,
          projectId,
          proj.logotype,
        );
        adaptedProject.logotype = { id: result.data.id };
      }

      if (proj.setting) {
        adaptedProject.setting = adaptProjectSettings(proj.setting);
      }

      return adaptedProject;
    };

    try {
      const adaptedProject = await adaptProject(preparedProject);
      const response = await requestManager.updateProject(
        projectId,
        adaptedProject,
      );
      const nd = normalize(response.data, project);
      const reduxEntities = flattenEntities(nd);
      dispatch({
        type: C.UPDATE_PROJECT_SUCCESS,
        ...reduxEntities,
        id: projectId,
      });
    } catch (error) {
      dispatch({ type: C.UPDATE_PROJECT_FAIL, error });
    }
  };
}

export function prepareDepartment(preparedDepartment: Prepared<Department>) {
  return (dispatch: any) =>
    dispatch({ type: C.PREPARE_DEPARTMENT, department: preparedDepartment });
}

export function unprepareDepartments() {
  return (dispatch: any) => dispatch({ type: C.UNPREPARE_DEPARTMENTS });
}

export function updateDepartments(departments: Department[]) {
  const adaptDepartment = (d) => {
    const ad = {};
    if (d.id) ad.id = d.id;
    // TODO: this is a hack
    if (d.text) ad.trans_short = { text: d.text };
    return ad;
  };

  return async (dispatch: any, getState: any) => {
    try {
      const { projectId } = getIds(getState);
      const adaptedDepartments = departments.map(adaptDepartment);
      const response = await requestManager.updateDepartments(
        projectId,
        adaptedDepartments,
      );
      const nd = normalize(response.data, [department]);
      const reduxEntities = flattenEntities(nd);
      dispatch({ type: C.UPDATE_DEPARTMENTS_SUCCESS, ...reduxEntities });
    } catch (error) {
      dispatch({ type: C.UPDATE_DEPARTMENTS_FAIL, error });
    }
  };
}

export function fetchAccountNotifications(projectId: Id) {
  return (dispatch: any) => {
    dispatch({ type: C.FETCH_NOTIFICATIONS_START });
    requestManager
      .getCurrentAccount()
      .then((response) => {
        const accountId = response.data && response.data.id;
        requestManager
          .getAccountNotifications(projectId, accountId)
          .then((data) => {
            dispatch({
              type: C.FETCH_NOTIFICATIONS_SUCCESS,
              notifications: data.data,
            });
          })
          .catch((error) =>
            dispatch({ type: C.FETCH_NOTIFICATIONS_FAIL, error }),
          );
      })
      .catch((error) =>
        dispatch({ type: C.FETCH_ACCOUNT_FAIL, error, avoidNotice: true }),
      );
  };
}

export function updateNotification(notificationId: Id, data: any) {
  return (dispatch: any, getState: any) => {
    const ids = getIds(getState);
    dispatch({ type: C.UPDATE_NOTIFICATION_START });
    requestManager
      .updateNotification(ids.projectId, ids.accountId, notificationId, data)
      .then(() => {
        dispatch({
          type: C.UPDATE_NOTIFICATION_SUCCESS,
        });
      })
      .catch((error) => dispatch({ type: C.UPDATE_NOTIFICATION_FAIL, error }));
  };
}

export function clearNotifications() {
  return (dispatch: any, getState: any) => {
    const ids = getIds(getState);
    dispatch({ type: C.CLEAR_NOTIFICATIONS_START });
    requestManager
      .clearNotifications(ids.projectId, ids.accountId)
      .then(() => {
        dispatch({
          type: C.CLEAR_NOTIFICATIONS_SUCCESS,
        });
      })
      .catch((error) => dispatch({ type: C.CLEAR_NOTIFICATIONS_FAIL, error }));
  };
}

export function deleteProjectInfo(projectInfoIds: Array<ProjectInformation>) {
  return async (dispatch: any, getState: any) => {
    const ids = getIds(getState);
    dispatch({ type: C.DELETE_PROJECTINFO_START });
    requestManager
      .deleteProjectInfo(ids.projectId, { infoIds: projectInfoIds })
      .then(() => {
        dispatch({ type: C.DELETE_PROJECTINFO_SUCCESS });
      })
      .catch((error) => dispatch({ type: C.DELETE_PROJECTINFO_FAIL, error }));
  };
}

export function sendResetPasswordLink(username: string) {
  return (dispatch: any) => {
    requestManager
      .sendResetPasswordLink(username)
      .then(() => {
        dispatch({ type: C.RESET_PASSWORD_LINK_SENT });
      })
      .catch((error) => dispatch({ type: C.RESET_PASSWORD_LINK_FAIL, error }));
  };
}

export function fetchUserFromResetToken(token: string) {
  return (dispatch: any) => {
    requestManager
      .fetchUserFromResetToken(token)
      .then((data) => {
        const user = data.data;
        dispatch({
          type: C.FETCH_USER_FROM_RESET_TOKEN_SUCCESS,
          user,
          passwordResetOpen: true,
        });
      })
      .catch((error) =>
        dispatch({ type: C.FETCH_USER_FROM_RESET_TOKEN_FAIL, error }),
      );
  };
}

export function resetPassword(userId: Id, password: string, token: string) {
  return (dispatch: any) => {
    dispatch({ type: C.RESET_PASSWORD_START });
    requestManager
      .resetPassword(userId, password, token)
      .then(() => {
        dispatch({ type: C.RESET_PASSWORD_SUCCESS });
      })
      .catch((error) => dispatch({ type: C.RESET_PASSWORD_FAIL, error }));
  };
}
