import { objectData, status, fieldData, blockData, objectType, tag, file } from './headers';

objectData.define({
  aggregate_block: blockData,
  block_datas: [blockData],
  parent_object_datas: [objectData],
  object_type: objectType,
  primary_category_objects: [objectData],
  inner_primary_category_objects: [objectData],
  primary_date_fields: [fieldData],
  primary_field: fieldData,
  primary_field_id: fieldData,
  primary_tags: [tag],
  tags: [tag],
  status,
  summary_fields: [fieldData],
  files: [file],
  external_field_datas: [fieldData],
  list_field_datas: [fieldData],
  is_primary_category_for_objects: [objectData],
  publish_date_field: fieldData,
  index_field_datas: [fieldData],
  select_field_datas: [fieldData],
});

export default objectData;
