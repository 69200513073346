// @flow
const C = {
  ECONOMIES: 'ECONOMIES',
  INTERNAL_ECONOMIES: 'INTERNAL_ECONOMIES',
  FETCH_CURRENCIES_START: 'FETCH_CURRENCIES_START',
  FETCH_CURRENCIES_SUCCESS: 'FETCH_CURRENCIES_SUCCESS',
  FETCH_CURRENCIES_FAIL: 'FETCH_CURRENCIES_FAIL',
  ECONOMY_CLASSES: {
    COST: { name: 'COST', value: 'COST (-)' },
    REVENUE: { name: 'REVENUE', value: 'Revenue (+)' },
  },
  ECONOMY_SETTLEMENT_CLASSES: {
    COST: { name: 'COST', value: 'Cost (-)' },
    REVENUE: { name: 'REVENUE', value: 'Revenue (+)' },
    NOT_APPLICABLE: { name: 'NOT_APPLICABLE', value: 'Not Applicable' },
  },
  ECONOMY_TYPES: {
    BUDGET: { name: 'BUDGET', value: 'Budget' },
    ACTUAL: { name: 'ACTUAL', value: 'Actual' },
    // EXTERNAL_PRICE: { name: 'EXTERNAL_PRICE', value: 'External Price' },
  },
  ECONOMY_HIDDEN_TYPES: {
    FEES: ['EXTERNAL_PRICE'],
    FEE: ['EXTERNAL_PRICE'],
    BACKLINE: ['EXTERNAL_PRICE'],
    ACCOMMODATIONS: ['EXTERNAL_PRICE'],
    DETAILS: ['EXTERNAL_PRICE'],
    DEALS: ['EXTERNAL_PRICE'],
    RESOURCES: [],
  },
  COST_CARRIED_BY: {
    ARTIST: { name: 'ARTIST', value: 'Artist' },
    ORGANIZER: { name: 'ORGANIZER', value: 'Organizer' },
  },
  ECONOMY_FIELD_TYPES: {
    FEES: { name: 'FEES', value: 'Fees', blockName: 'Fees' },
    FEE: { name: 'FEE', value: 'Fee', blockName: 'Fee' },
    DEALS: { name: 'DEALS', value: 'Amount', blockName: 'Deals' },
    BACKLINE: { name: 'BACKLINE', value: 'Amount', blockName: 'Backline Rentals' },
    DETAILS: { name: 'DETAILS', value: 'Amount', blockName: 'Details' },
    ACCOMMODATIONS: { name: 'ACCOMMODATIONS', value: 'Amount', blockName: 'Accommodation' },
    RESOURCES: { name: 'RESOURCES', value: 'Price', blockName: 'Resource' },
  },
  ECONOMY_FIELD_PLACEHOLDERS: {
    NAME: 'Name',
    AMOUNT: 'Amount',
    CURRENCY: 'Currency',
    COST_CARRIED_BY: 'Cost Carried By',
    TYPE: 'Type',
    CLASS: 'Class',
    SETTLEMENT_BEHAVIOUR: 'Settlement Behaviour',
    VAT: 'VAT',
    EXCHANGE_RATE: 'Exchange Rate',
    CONNECTED_TO: 'Connected to',
    DATE: 'Date',
  },
  ECONOMY_HIDDEN_FIELDS: {
    FEES: ['Name', 'Type', 'Class', 'Settlement Behaviour', 'Cost Carried By', 'Connected to', 'Date'],
    FEE: ['Name', 'Type', 'Class', 'Settlement Behaviour', 'Cost Carried By', 'Connected to', 'Date'],
    BACKLINE: ['Connected to'],
    ACCOMMODATIONS: ['Connected to'],
    DETAILS: ['Date'],
    DEALS: ['Date', 'Type', 'Settlement Behaviour'],
    RESOURCES: ['Name', 'Class', 'Settlement Behaviour', 'Cost Carried By', 'Connected to', 'Date'],
  },
  FETCH_ECONOMIES_START: 'FETCH_ECONOMIES_START',
  FETCH_ECONOMIES_SUCCESS: 'FETCH_ECONOMIES_SUCCESS',
  FETCH_ECONOMIES_FAIL: 'FETCH_ECONOMIES_FAIL',
  PREPARE_ECONOMIES: 'PREPARE_ECONOMIES',
  PREPARE_ECONOMY: 'PREPARE_ECONOMY',
  ORDER_BY_ECONOMY_TITLE: 'ORDER_BY_ECONOMY_TITLE',
  ORDER_BY_CATEGORY: 'ORDER_BY_CATEGORY',
  ORDER_BY_CONNECTED_TO: 'ORDER_BY_CONNECTED_TO',
  ORDER_BY_AMOUNT: 'ORDER_BY_AMOUNT',
  ORDER_BY_ACTUAL: 'ORDER_BY_ACTUAL',
  ORDER_BY_BUDGET: 'ORDER_BY_BUDGET',
  ORDER_BY_ECONOMY_NAME: 'ORDER_BY_ECONOMY_NAME',
  ORDER_BY_SETTLEMENT: 'ORDER_BY_SETTLEMENT',
  ORDER_BY_DATE: 'ORDER_BY_DATE',
  ORDER_BY_APPROVED: 'ORDER_BY_APPROVED',
  ORDER_BY_CURRENCY: 'ORDER_BY_CURRENCY',
  ORDER_BY_COST_CARRIED_BY: 'ORDER_BY_COST_CARRIED_BY',
  BEGIN_EDIT_ECONOMY: 'BEGIN_EDIT_ECONOMY',
  ABORT_EDIT_ECONOMY: 'ABORT_EDIT_ECONOMY',
  CREATE_OR_UPDATE_ECONOMY_START: 'CREATE_OR_UPDATE_ECONOMY_START',
  CREATE_OR_UPDATE_ECONOMY_SUCCESS: 'CREATE_OR_UPDATE_ECONOMY_SUCCESS',
  CREATE_OR_UPDATE_ECONOMY_FAIL: 'CREATE_OR_UPDATE_ECONOMY_FAIL',
  REMOVE_ECONOMY_CATEGORY_START: 'REMOVE_ECONOMY_CATEGORY_START',
  REMOVE_ECONOMY_CATEGORY_SUCCESS: 'REMOVE_ECONOMY_CATEGORY_SUCCESS',
  REMOVE_ECONOMY_CATEGORY_FAIL: 'REMOVE_ECONOMY_CATEGORY_FAIL',
  ECONOMY_FIELD_LEGENDS: {
    TITLE: { id: 0, text: 'Name', sortingKey: 'ORDER_BY_ECONOMY_TITLE', width: 1, orderIndex: 0, order: 'ASC', type: 'name', excludeEconomyType: 'ACCOMMODATIONS' },
    AMOUNT: { id: 1, text: 'Amount', sortingKey: 'ORDER_BY_AMOUNT', width: 1, orderIndex: 1, order: 'ASC', type: 'amount' },
    CURRENCY: { id: 2, text: 'Currency', sortingKey: 'ORDER_BY_CURRENCY', width: 1, orderIndex: 2, order: 'ASC', type: 'currency' },
    COST_CARRIED_BY: { id: 3, text: 'Cost carried by', sortingKey: 'ORDER_BY_COST_CARRIED_BY', width: 1, orderIndex: 3, order: 'ASC', type: 'cost_carried_by', excludeEconomyType: 'ACCOMMODATIONS' },
    CONNECTED_TO: { id: 4, text: 'Connected to', sortingKey: 'ORDER_BY_CONNECTED_TO', width: 1, orderIndex: 4, order: 'ASC', type: 'connected_to', economyType: 'DEALS', excludeEconomyType: 'ACCOMMODATIONS' },
  },
};

export default C;
