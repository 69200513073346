import React, { Component } from 'react';
import s from './TextScroll.module.scss';

class TextScroll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wrapper: null,
      text: null,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ wrapper: this.wrapper, text: this.text });
  }

  render() {
    const { children, active, isBigList = false } = this.props;
    const { wrapper, text } = this.state;
    let scrolling = false;

    if(wrapper && text) {
      scrolling = (text.getBoundingClientRect().width) > wrapper.getBoundingClientRect().width;
    }

    return (
      <span
        ref={(r) => { this.wrapper = r; }}
        className={`${s.wrapper} ${scrolling && s.scrolling} ${active && s.active} ${isBigList ? s.chromeFix : ''}`}
      >
        <span className={s.text1} ref={(r) => { this.text = r; }} >{children}</span>
        <span className={s.text2}>{children}</span>
        <span className={s.text3}>{children}</span>
      </span>
    );
  }
}

export default TextScroll;
