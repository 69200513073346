import requestManager from '../../libs/requestManager';

export function getIds(getState) { // eslint-disable-line
  const { user: { last_project: lastProjectId, client }, account: { id: accountId } } = getState().authStore;
  return { projectId: lastProjectId, clientId: client, accountId };
}

// Find the list-field that holds the selected type.
function findSubObjectList(objectType, dataType) {
  const listFields = objectType.block_types.reduce((acc, blockType) => acc.concat(blockType.field_types.find(fieldType => fieldType.sub_object_block_type && fieldType.sub_object_block_type.field_types.find(innerField => innerField.holds_type && innerField.select_options[0] && innerField.select_options[0].value === dataType.value)) || []), []);
  const listField = listFields[0] || objectType.block_types.reduce((acc, blockType) => acc.concat(blockType.field_types.find(fieldType => fieldType.sub_object_block_type && fieldType.sub_object_block_type.is_default) || []), [])[0];
  return listField;
}

export function insertInNestedObject(dataToInsert, subObjects, dataType, objectTypes) {
  const updatedObjects = [];
  subObjects.forEach((subObj) => {
    const dataObject = { ...subObj };
    const objectType = objectTypes.find(type => dataObject.object_type_id === type.object_type_id);
    const listField = findSubObjectList(objectType, dataType);
    const listDataFields = dataObject.block_datas.reduce((acc, block) => acc.concat(block.field_datas ? block.field_datas.find(field => field.field_type_id === listField.field_type_id) || [] : []), []);
    const listDataField = listDataFields && listDataFields[0] ? { ...listDataFields[0], field_type: { id: listDataFields[0].field_type_id } } : { field_type: { id: listField.field_type_id }, field_type_id: listField.field_type_id };
    const dataBlock = dataObject.block_datas.find(block => block.field_datas.find(field => field.field_type_id === listDataField.field_type_id)) || objectType.block_types.find(block => block.field_types.find(field => field.field_type_id === listDataField.field_type_id));
    const newSubObj = {
      object_type: {
        id: listField.object_types && listField.object_types[0].object_type_id,
      },
      block_datas: [].concat({
        block_type: {
          id: listField.sub_object_block_type.block_type_id,
        },
        field_datas: [].concat({
          field_type: {
            id: listField.sub_object_block_type.field_types.find(field => field.holds_type).field_type_id,
          },
          schedule: dataToInsert,
        }),
      }),
    };
    if(listDataField) listDataField.object_datas = listDataField.object_datas ? listDataField.object_datas.map(objData => ({ id: objData.id })).concat(newSubObj) : [].concat(newSubObj);
    const updatedObject = {
      ...dataObject,
      block_datas: [].concat({
        ...dataBlock,
        block_type: {
          id: dataBlock.block_type_id,
        },
        field_datas: [].concat({
          ...listDataField,
        }),
      }),
    };
    updatedObjects.push(updatedObject);
  });
  return updatedObjects;
}

export function insertNestedObject(objectToInsert, parentObjects, dataType, objectTypes) {
  const updatedObjects = [];
  parentObjects.forEach((parentObj) => {
    const dataObject = { ...parentObj };
    const objectType = objectTypes.find(type => dataObject.object_type_id === type.object_type_id);
    // Find the list-field that holds the selected type.
    const listField = findSubObjectList(objectType, dataType);
    const listDataFields = dataObject.block_datas.reduce((acc, block) => acc.concat(block.field_datas ? block.field_datas.find(field => field.field_type_id === listField.field_type_id) || [] : []), []);
    const listDataField = listDataFields && listDataFields[0] ? { ...listDataFields[0], field_type: { id: listDataFields[0].field_type_id } } : { field_type: { id: listField.field_type_id }, field_type_id: listField.field_type_id };
    const dataBlock = dataObject.block_datas.find(block => block.field_datas.find(field => field.field_type_id === listDataField.field_type_id)) || objectType.block_types.find(block => block.field_types.find(field => field.field_type_id === listDataField.field_type_id));
    if(listDataField) listDataField.object_datas = listDataField.object_datas ? listDataField.object_datas.map(objData => ({ id: objData.id })).concat(objectToInsert) : [].concat(objectToInsert);
    const updatedObject = {
      ...dataObject,
      block_datas: [].concat({
        ...dataBlock,
        block_type: {
          id: dataBlock.block_type_id,
        },
        field_datas: [].concat({
          ...listDataField,
        }),
      }),
    };
    updatedObjects.push(updatedObject);
  });
  return updatedObjects;
}

export function getTargetListFieldType(fieldTypes, targetBlockTypeId) {
  return fieldTypes.find(fieldType => fieldType.sub_object_block_type && fieldType.sub_object_block_type.id === targetBlockTypeId);
}

export function getTargetFieldType(tag, fieldTypes) {
  return fieldTypes.find(fieldType => fieldType.holds_type && fieldType.default_tag && fieldType.default_tag.id === tag.id);
}

export function synchronizeRequests(requests) {
  return requests.reduce(
    (promise, req) => promise.then(result => new Promise((resolve, reject) => req(resolve, reject)).then(Array.prototype.concat.bind(result))),
    Promise.resolve([]),
  );
}

export async function prepareFiles(ids, files, external = false) {
  const uploadFile = async (file) => {
    let request;
    if (external) {
      request = requestManager.uploadTokenApiFile(ids.projectId, file);
    } else {
      request = requestManager.uploadFile(ids.clientId, ids.projectId, file);
    }
    const uploadedFile = await request;
    return uploadedFile.data.id;
  };

  if (files) {
    const newFileIds = await Promise.all(files.filter(f => !f.id).map(uploadFile));
    const existingFileIds = files.filter(f => f.id).map(f => f.id);
    return existingFileIds.concat(newFileIds);
  }
  return [];
}
