// @flow
const I = {
  LOADING: 'LOADING',
  DISPLAYTEXT: 'DISPLAYTEXT',
  TEXT: 'TEXT',
  LINK: 'LINK',
  LINK2: 'LINK2',
  TEXTAREA: 'TEXTAREA',
  SELECTMULTIPLE: 'SELECTMULTIPLE',
  NUMBER: 'NUMBER',
  SELECT: 'SELECT',
  SELECTOBJECT: 'SELECTOBJECT',
  SELECTPEOPLE: 'SELECTPEOPLE',
  SELECTSTAFF: 'SELECTSTAFF',
  LISTOBJECT: 'LISTOBJECT',
  LISTPEOPLE: 'LISTPEOPLE',
  DATE: 'DATE',
  TIME: 'TIME',
  DATETIME: 'DATETIME',
  TAG: 'TAG',
  PLACEHOLDER: 'PLACEHOLDER',
  COUNTRY: 'COUNTRY',
  INPUTOBJECT: 'INPUTOBJECT',
  MONEY: 'MONEY',
  PREVIEW: 'PREVIEW',
  LISTSCHEDULING: 'LISTSCHEDULING',
  SCHEDULING: 'SCHEDULING',
  PROJECTINFO: 'PROJECTINFO',
  DATEINFO: 'DATEINFO',
  FILES: 'FILES',
  PRIMARY_FILES: 'PRIMARY_FILES',
  PROJECTGENERAL: 'PROJECTGENERAL',
  PRIMARYCATEGORIES: 'PRIMARYCATEGORIES',
  PRIMARYCATEGORYSCHEDULE: 'PRIMARYCATEGORYSCHEDULE',
  SELECTMULTIPLETAG: 'SELECTMULTIPLETAG',
  SELECTTAG: 'SELECTTAG',
  PRIMARYTEXT: 'PRIMARYTEXT',
  EXTERNALCONTACTS: 'EXTERNALCONTACTS',
  EXTERNALSCHEDULING: 'EXTERNALSCHEDULING',
  RICHTEXT: 'RICHTEXT',
  LISTCONTRACTS: 'LISTCONTRACTS',
  ECONOMY: 'ECONOMY',
  LISTECONOMY: 'LISTECONOMY',
  DISPLAYIMAGE: 'DISPLAYIMAGE',
  SELECTCOMPANY: 'SELECTCOMPANY',
  SELECTBLOCKTYPETAG: 'SELECTBLOCKTYPETAG',
  CHECKBOX: 'CHECKBOX',
  DISPLAYPOPUP: 'DISPLAYPOPUP',
  LIST: 'LIST', // List input types most include LIST.
  SELECTSTATUS: 'SELECTSTATUS',
  USER: 'USER',
  SELECTBADGEFRAMEWORK: 'SELECTBADGEFRAMEWORK',
  SELECTBADGEOBJECT: 'SELECTBADGEOBJECT',
  COLOR: 'COLOR',
};

export default I;
