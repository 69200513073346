// @flow
import { schema } from 'normalizr';

export const account = new schema.Entity('account');
// export const blockData = new schema.Entity('block_data');
// export const blockType = new schema.Entity('block_type');
export const client = new schema.Entity('client');
export const currency = new schema.Entity('currency');
export const department = new schema.Entity('department');
export const economy = new schema.Entity('economy');
export const product = new schema.Entity('product');
export const subscription = new schema.Entity('subscription');
export const billingInformation = new schema.Entity('billing_information');
export const subsciptionPeriod = new schema.Entity('subsciption_periods');
// export const fieldData = new schema.Entity('field_data');
// export const fieldType = new schema.Entity('field_type');
export const language = new schema.Entity('language');
export const objectData = new schema.Entity('object_data');
export const objectType = new schema.Entity('object_type');
export const contact = new schema.Entity('contact');
export const person = new schema.Entity('person');
export const project = new schema.Entity('project', {});
export const role = new schema.Entity('role', {
  project,
});
export const selectOption = new schema.Entity('select_option');
export const selectOptionGroup = new schema.Entity('select_option_group');
export const status = new schema.Entity('status', {});
export const tag = new schema.Entity('tag');
export const tagGroup = new schema.Entity('tag_group', {
  tags: [tag],
});
export const setting = new schema.Entity('setting', {
  departments: [department],
  roles: [role],
  company_tag_group: tagGroup,
  company_type_tag_group: tagGroup,
  company_industry_tag_group: tagGroup,
  contact_log_type_tag_group_id: tagGroup,
  address_type_tag_group_id: tagGroup,
  person_title_tag_group_id: tagGroup,
  schedule_tag_group: tagGroup,
  schedule_title_tag_group: tagGroup,
  people_tag_group: tagGroup,
  task_tag_group: tagGroup,
  file_tag_group: tagGroup,
  people_object_type: objectType,
  task_object_types: [objectType],
  project,
});
export const jobRole = new schema.Entity('job_role');
export const user = new schema.Entity('user', {
  people: [person],
  roles: [role],
  job_roles: [jobRole],
});
jobRole.define({
  users_with_role: [user],
  task_job_roles: [{ task: jobRole }],
  project,
});
export const assignment = new schema.Entity('assignment', {
  user,
  assigned_by: user,
});
export const invitation = new schema.Entity('invitation', {
  user,
  invited_by: user,
});
export const file = new schema.Entity('file', {
  object_datas: [objectData],
  user,
});

export const fieldType = new schema.Entity('field_type', {
  object_type: objectType,
});

export const blockType = new schema.Entity('block_type', {
  field_types: [fieldType],
  external_field_types: [fieldType],
  departments: [department],
});

export const fieldData = new schema.Entity('field_data', {
  field_type: fieldType,
  object_datas: [objectData],
});
export const comment = new schema.Entity('comment', {
  user,
});
export const blockData = new schema.Entity('block_data', {
  block_type: blockType,
  field_datas: [fieldData],
  comments: [comment],
});
export const globalSchedule = new schema.Entity('global_schedule', {
  field_data: fieldData,
  primary_field: fieldData,
  selected_option: selectOption,
  primary_category_objects: [objectData],
  primary_date_fields: [fieldData],
  tags: [tag],
});
export const taskReport = new schema.Entity('task_report');
export const task = new schema.Entity('task', {
  object_datas: [objectData],
  assignments: [assignment],
  invitations: [invitation],
  contacts: [person],
  schedule: globalSchedule,
  required_job_roles: [{ job_role: jobRole }],
  comments: [comment],
  supervisors: [user],
  reports: [taskReport],
});
export const postType = new schema.Entity('post_type');
export const post = new schema.Entity('post');
post.define({
  files: [file],
  children: [post],
  post_type: postType,
  list_post_type: postType,
});
export const reportType = new schema.Entity('report_type');
export const company = new schema.Entity('company');
export const menuItem = new schema.Entity('menu_items');

export const contractTemplate = new schema.Entity('contract_template');

export const contract = new schema.Entity('contract');

export const importJob = new schema.Entity('import_jobs');

export const badgeFrameworkSection = new schema.Entity('badge_framework_section');
export const badgeFramework = new schema.Entity('badge_framework', {
  badge_framework_sections: [badgeFrameworkSection],
});
export const badgeType = new schema.Entity('badge_type', {
  badge_framework: badgeFramework,
});
