// @flow
import C from '../constants/constants';
import $ from '../../libs/reduxOperations';
import type { CmsState } from '../entities/FlowTypes';
import { IdMapValues, mapOverValues } from '../entities/FlowTypes';

export const initialState: CmsState = {
  posts: {},
  postTypes: {},
  pending: false,
  preparedPosts: [],
  preparedMenus: [],
  postLinks: [],
  site: null,
  preparedSite: null,
  cacheClearStatus: null,
};

const insertPostAndLinkParent = (statePosts, actionPosts) => {
  const newPosts = { ...statePosts, ...actionPosts };
  const newPost = IdMapValues(actionPosts)[0];
  if (newPost.parent) {
    const parentId = newPost.parent.id;
    newPosts[parentId] = { ...newPosts[parentId], children: newPosts[parentId].children.concat(newPost.id) };
  }
  return newPosts;
};

export default function tagsReducer(state: CmsState = initialState, action: any): CmsState {
  switch(action.type) {
    case C.FETCH_POSTS_START:
    case C.FETCH_POST_START:
    case C.FETCH_POST_TYPES_START:
    case C.CREATE_POST_START:
    case C.UPDATE_POST_START:
    case C.UPDATE_SITE_START:
      return {
        ...state,
        pending: true,
      };

    case C.FETCH_POSTS_SUCCESS:
      return {
        ...state,
        pending: false,
        posts: { ...state.posts, ...action.posts },
        postTypes: { ...state.postTypes, ...action.postTypes },
      };

    case C.FETCH_POST_TYPES_SUCCESS:
      return {
        ...state,
        pending: false,
        postTypes: { ...state.postTypes, ...action.postTypes },
      };

    case C.FETCH_POST_SUCCESS:
    case C.UPDATE_POST_SUCCESS:
      return {
        ...state,
        pending: false,
        preparedPosts: [],
        posts: { ...state.posts, ...action.posts },
      };

    case C.CREATE_POST_SUCCESS:
      return {
        ...state,
        pending: false,
        preparedPosts: [],
        posts: insertPostAndLinkParent(state.posts, action.posts),
      };

    case C.FETCH_POSTS_FAIL:
    case C.FETCH_POST_FAIL:
    case C.FETCH_POST_TYPES_FAIL:
    case C.CREATE_POST_FAIL:
    case C.UPDATE_POST_FAIL:
    case C.UPDATE_SITE_FAIL:
    case C.FETCH_POST_LINKS_FAIL:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    case C.PREPARE_SITE:
      return {
        ...state,
        preparedSite: action.preparedSite,
      };

    case C.UNPREPARE_SITE:
      return {
        ...state,
        preparedSite: null,
      };

    case C.PREPARE_POST:
      return {
        ...state,
        preparedPosts: $.addOrUpdateAttributesInObjectInArrayById(state.preparedPosts, action.preparedPost),
      };

    case C.UNPREPARE_POST:
      return {
        ...state,
        preparedPosts: $.removeFromArrayById(state.preparedPosts, action.unpreparedPost),
      };

    case C.DELETE_POST_SUCCESS:
      return {
        ...state,
        posts: mapOverValues(
          $.removeFromObjectById(state.posts, action.deletedPost),
          // prevent posts from having references to deleted children
          cmsPost => ({ ...cmsPost, children: cmsPost.children ? cmsPost.children.filter(cId => cId !== action.deletedPost.id) : [] }),
        ),
        // remove the deleted post from preparedPosts, and
        // remove the deleted post from children
        preparedPosts: state.preparedPosts
          .filter(pp => pp.id !== action.deletedPost.id)
          .map(pp => ({ ...pp, children: (pp.children || []).filter(pc => pc !== action.deletedPost.id) })),
      };
    case C.FETCH_SITE_SUCCESS:
    case C.UPDATE_SITE_SUCCESS:
      return {
        ...state,
        pending: false,
        site: action.site,
        preparedSite: null,
      };

    case C.TOGGLE_USER_PERMISSION_SUCCESS:
      return {
        ...state,
        site: { ...state.site, users: $.addOrUpdateListWithList(state.site && state.site.users, [action.user]) },
      };

    case C.UPDATE_MENU_SUCCESS:
      return {
        ...state,
        site: { ...state.site, menus: $.addOrUpdateListWithList(state.site && state.site.menus, [action.menu]) },
      };

    case C.CREATE_MENU_SUCCESS:
      return {
        ...state,
        site: { ...state.site, menus: $.addOrUpdateListWithList(state.site && state.site.menus, [action.menu]) },
      };

    case C.PREPARE_MENU:
      return {
        ...state,
        preparedMenus: $.addOrUpdateAttributesInObjectInArrayById(state.preparedMenus, action.preparedMenu),
      };

    case C.UNPREPARE_MENUS:
      return {
        ...state,
        preparedMenus: [],
      };

    case C.FETCH_POST_LINKS_SUCCESS:
      return {
        ...state,
        postLinks: action.postLinks,
      };

    case C.CLEAR_CACHE_SUCCESS:
      return {
        ...state,
        cacheClearStatus: C.CLEAR_CACHE_SUCCESS,
        site: {
          ...state.site,
          cache_cleared_at: action.cache_cleared_at,
        },
      };

    case C.CLEAR_CACHE_START:
      return {
        ...state,
        cacheClearStatus: C.CLEAR_CACHE_START,
      };

    case C.CLEAR_CACHE_FAIL:
      return {
        ...state,
        cacheClearStatus: C.CLEAR_CACHE_FAIL,
      };

    default:
      return state;
  }
}
