import C from '../constants/constants';
import O from '../constants/objectTypes';
import { uuid } from '../../libs/utils';
import { updateListFieldData, updateObjectsBlockDatas } from '../../libs/objectUtils';
import $ from '../../libs/reduxOperations';

export const initialState = {
  selectedObjects: [],
  objectsByType: {},
  // objectTypes: [],
  preparedFields: {},
  preparedBlocks: {},
  preparedObjects: {},
  isCreatingObject: false,
  pending: false,
  pendingCreateObject: false,
  nextPageByType: {},
  paginationReachedBottom: [],
  objectsAutocompleteResults: [],
  currentRequest: null,
  showLoader: false,
  currentObject: null,
  lastUpdatedBlockId: null,
  latestActivatedBlockId: null,
  pageSize: 50,
  currentPage: 1,
  nextPage: 1,
  primaryCategoryConflictExists: false,
  primaryCategoryConflictPending: false,
  conflictingObjects: [],
  newlyCreatedObjectType: null,
  objectWasUpdated: false,
  updatedBlockId: null,
  lastUpdatedParentBlockId: null,

  objectTypes: {},
  blockTypes: {},
  fieldTypes: {},
  objectDatas: {},
  blockDatas: {},
  fieldDatas: {},
  selectOptions: {},
  statuses: {},
  projects: {},
  objectCounts: null,
  jobRoles: {},
  preparedJobRoles: {},
  departments: {},
  preparedDepartments: {},

  isGeneratingPdf: false,
  duplicatedObject: {},
  isDuplicatingObject: false,
  latestCreatedObjectId: null,
  duplicateDateTimeChanged: false,
  initialTime: null,
  duplicateTimeDiff: { startTime: 0, endTime: 0 },
  importFields: null,
  importExcelData: null,
  importFieldsBlock: null,
  importExcelBlock: null,
  importBlockHeaders: null,
  preparedReoccuringObject: null,
  reoccuringModalOpen: false,
};

const removeFromArray = (arr, item) => {
  const index = arr.findIndex(elem => item === elem);
  if(index === -1) return arr;

  const arrCopy = [].concat(arr);
  arrCopy.splice(index, 1);
  return arrCopy;
};

const addToArray = (arr, item) => {
  const index = arr.findIndex(e => e === item);
  if(index === -1) return arr.concat(item);
  return arr;
};

// const addOrRemoveFromArray = (arr, item) => {
//   const removed = removeFromArray(arr, item);
//   const added = addToArray(arr, item);
//   if(removed.length !== arr.length) return removed;
//   return added;
// };

const categorizeObjectPages = (objectTypes) => {
  const pageTypes = {};
  Object.values(objectTypes).forEach((type) => {
    pageTypes[type.object_type_id] = 1;
  });
  return pageTypes;
};

const didPaginationReachedBottom = (paginationReachedBottom, objectType, objects = [], reset) => {
  if(reset) return removeFromArray(paginationReachedBottom, objectType);
  if(!objects.length) return addToArray(paginationReachedBottom, objectType);
  return paginationReachedBottom;
};

const removeFileFromObject = (objectsByType, file) => { // TODO almost identical to the one above. Refactor?
  let objects = [];
  Object.keys(objectsByType).forEach((type) => { objects = objects.concat(objectsByType[parseInt(type, 10)]); });
  const objectToUpdate = objects.find(object => object.files && object.files.length && object.files.find(f => f.id === file.id));
  if(!objectToUpdate) return objectsByType;
  const newObjectsByType = { ...objectsByType };
  const indexToUpdate = newObjectsByType[objectToUpdate.object_type_id].findIndex(object => objectToUpdate.id === object.id);
  const newObject = { ...objectToUpdate, files: $.removeFromArrayById(objectToUpdate.files, file) };
  newObjectsByType[objectToUpdate.object_type_id][indexToUpdate] = newObject;

  return newObjectsByType;
};

const removeFileFromField = (fields, file) => {
  const objectToUpdate = Object.values(fields).find(field => field.files && field.files.length && field.files.find(f => f.id === file.id));
  if (!objectToUpdate) return fields;
  const newObject = { ...objectToUpdate, files: $.removeFromArrayById(objectToUpdate.files, file) };
  return $.updateObjectById(fields, objectToUpdate.id, newObject);
};

const appendContractPdf = (preparedFields, pdf, contractId) => {
  const ids = Object.keys(preparedFields);
  const newFields = { ...preparedFields };
  ids.forEach((id) => {
    const field = newFields[id];
    if(field && field.contracts && field.contracts.length) {
      field.contracts.forEach((contract) => {
        if(contract && contract.id === contractId) {
          const contractIndex = newFields[id].contracts.findIndex(c => c.id === contractId);
          if(contractIndex > -1) newFields[id].contracts[contractIndex].contract_pdfs = (contract.contract_pdfs || []).concat(pdf);
        }
      });
    }
  });
  return newFields;
};

const updateContractPdf = (preparedFields, pdf, contractId) => {
  const ids = Object.keys(preparedFields);
  const newFields = { ...preparedFields };
  ids.forEach((id) => {
    const field = newFields[id];
    if(field && field.contracts && field.contracts.length) {
      field.contracts.forEach((contract) => {
        if(contract && contract.id === contractId) {
          const contractIndex = newFields[id].contracts.findIndex(c => c.id === contractId);
          if(contractIndex > -1) {
            const contractPdfIndex = newFields[id].contracts[contractIndex].contract_pdfs.findIndex(p => p.id === pdf.id);
            if(contractPdfIndex > -1) newFields[id].contracts[contractIndex].contract_pdfs[contractPdfIndex] = pdf;
          }
        }
      });
    }
  });
  return newFields;
};

export default function objectsReducer(state = initialState, action) {
  let prepObject = {};
  let prepMainObject = {};
  let prevObject = {};
  const prepObjects = [];
  let prepBlock = {};
  let newPrepBlock = {};
  let prepField = {};
  let prepFields = [];
  const updateObjects = {};
  const nextPageByType = { ...state.nextPageByType };
  let filteredObjectDatas = [];
  let stateChange = {};
  const newFieldDatas = {};
  const newObjectDatas = {};
  let objectDatasWithUpdatedListFields = [];
  let objectDatasWithUpdatedBlockDatas = [];
  const objectTypesWithUpdatedBlockTypes = [];
  let updatedBlocksArray = [];
  switch (action.type) {
    case C.ROUTED_TO_OBJECT_TYPE:
      return {
        ...state,
        newlyCreatedObjectType: null,
      };

    case C.SELECT_OBJECT:
      return {
        ...state,
        selectedObjects: addToArray(state.selectedObjects, action.object),
      };

    case C.DESELECT_OBJECT:
      return {
        ...state,
        selectedObjects: removeFromArray(state.selectedObjects, action.object),
      };

    case C.FETCH_OBJECTS_SUCCESS:
    case C.CREATE_IMPORT_DATA_SUCCESS:
    case C.IMPORT_EXCEL_BLOCK_SUCCESS:
    case C.CREATE_REOCCURING_SUCCESS:
      nextPageByType[action.objectType] = action.reset ? 2 : state.nextPageByType[action.objectType] + 1;
      filteredObjectDatas = action.reset ? Object.values(state.objectDatas)
        .filter(obj => (obj && obj.object_type !== action.objectType))
        .reduce((acc, obj) => ({
          ...acc,
          [obj.id]: obj,
        }), {}) : state.objectDatas;
      return {
        ...state,
        currentRequest: '',
        pending: false,
        nextPageByType,
        paginationReachedBottom:
          didPaginationReachedBottom(
            state.paginationReachedBottom,
            action.objectType,
            action.objectDatas ? Object.values(action.objectDatas) : [],
            action.reset,
          ),
        objectDatas: $.addOrUpdateObjectWithObjects(filteredObjectDatas, action.objectDatas),
        blockDatas: $.addOrUpdateObjectWithObjects(state.blockDatas, action.blockDatas),
        fieldDatas: $.addOrUpdateObjectWithObjects(state.fieldDatas, action.fieldDatas),
        statuses: $.addOrUpdateObjectWithObjects(state.statuses, action.statuses),
        selectOptions: $.addOrUpdateObjectWithObjects(state.selectOptions, action.selectOptions),
        projects: $.addOrUpdateObjectWithObjects(state.projects, action.projects),
      };

    case C.TOGGLE_SELECT_OBJECT:
      return {
        ...state,
        selectedObjects: $.addOrRemoveFromArrayById(state.selectedObjects, action.objectId),
      };

    case C.CREATE_INNER_OBJECT_DATA_SUCCESS:
      return {
        ...state,
        preparedBlocks: $.removeObjectFromObjectById(state.preparedBlocks, action.updatedPreparedBlockId),
        preparedFields: $.updateObjectById(state.preparedFields, action.updatedField.id, action.updatedField),
      };

    case C.GET_TOKEN_USER_OBJECT_DATA_SUCCESS:
    case C.GET_TOKEN_USER_OBJECT_TYPE_SUCCESS:
      return {
        ...state,
        objectTypes: $.addOrUpdateObjectWithObjects(state.objectTypes, action.objectTypes),
        blockTypes: $.addOrUpdateObjectWithObjects(state.blockTypes, action.blockTypes),
        fieldTypes: $.addOrUpdateObjectWithObjects(state.fieldTypes, action.fieldTypes),
        objectDatas: $.addOrUpdateObjectWithObjects(state.objectDatas, action.objectDatas),
        blockDatas: $.addOrUpdateObjectWithObjects(state.blockDatas, action.blockDatas),
        fieldDatas: $.addOrUpdateObjectWithObjects(state.fieldDatas, action.fieldDatas),

        selectOptions: { ...state.selectOptions, ...action.selectOptions },
      };

    case C.FETCH_OBJECT_TYPES_SUCCESS:
      return {
        ...state,
        nextPageByType: action.objectTypes ? categorizeObjectPages(action.objectTypes) : state.nextPageByType,
        pendingObjectTypes: false,
        showLoader: false,
        objectTypes: action.objectTypes,
        blockTypes: action.blockTypes,
        fieldTypes: action.fieldTypes,
        statuses: action.statuses,
        selectOptions: action.selectOptions,
        departments: action.departments,
      };

    case C.FETCH_PROJECT_SETTINGS_SUCCESS:
      return {
        ...state,
        // objectTypes: action.objectTypes,
        nextPageByType: action.objectTypes ? categorizeObjectPages(action.objectTypes) : state.nextPageByType,
        pendingObjectTypes: false,
        showLoader: false,

        objectTypes: { ...state.objectTypes, ...action.objectTypes },
        blockTypes: { ...state.blockTypes, ...action.blockTypes },
        fieldTypes: { ...state.fieldTypes, ...action.fieldTypes },
        statuses: { ...state.statuses, ...action.statuses },
        selectOptions: { ...state.selectOptions, ...action.selectOptions },
        departments: { ...state.departments, ...action.departments },
      };

    case C.FETCH_OBJECT_SUCCESS:
      return {
        ...state,
        objectDatas: $.addOrUpdateObjectWithObjects(state.objectDatas, action.objectDatas, action.reset),
        blockDatas: $.addOrUpdateObjectWithObjects(state.blockDatas, action.blockDatas),
        blockTypes: $.addOrUpdateObjectWithObjects(state.blockTypes, action.blockTypes),
        fieldDatas: $.addOrUpdateObjectWithObjects(state.fieldDatas, action.fieldDatas),
        statuses: $.addOrUpdateObjectWithObjects(state.statuses, action.statuses),
        selectOptions: $.addOrUpdateObjectWithObjects(state.selectOptions, action.selectOptions),
        projects: $.addOrUpdateObjectWithObjects(state.projects, action.projects),
        pending: false,
        currentRequest: null,
      };

    case C.CREATE_OBJECT_SUCCESS:
    case C.UPDATE_OBJECT_TYPE_SUCCESS:
      return {
        ...state,
        objectTypes: $.addOrUpdateObjectWithObjects(state.objectTypes, action.objectTypes, action.reset),
        objectDatas: $.addOrUpdateObjectWithObjects(state.objectDatas, action.objectDatas, action.reset),
        blockDatas: $.addOrUpdateObjectWithObjects(state.blockDatas, action.blockDatas),
        fieldDatas: $.addOrUpdateObjectWithObjects(state.fieldDatas, action.fieldDatas),
        statuses: $.addOrUpdateObjectWithObjects(state.statuses, action.statuses, action.reset),
        isCreatingObject: false,
        latestCreatedObjectId: action.objectId,
        preparedBlock: {},
        preparedFields: {},
        pending: false,
        pendingCreateObject: false,
        currentRequest: null,
        isDuplicatingObject: false,
      };


    case C.CREATE_OBJECT_TYPE_SUCCESS:
      return {
        ...state,
        objectTypes: $.addOrUpdateObjectWithObjects(state.objectTypes, action.objectTypes, action.reset),
        statuses: $.addOrUpdateObjectWithObjects(state.statuses, action.statuses, action.reset),
        isCreatingObject: false,
        newlyCreatedObjectType: action.rerouteOnSuccess ? action.objectTypeId : null,
        preparedBlock: {},
        preparedFields: {},
        pending: false,
        pendingCreateObject: false,
        currentRequest: null,
        isDuplicatingObject: false,
      };

    case C.CLOSE_CREATE_FORM:
      return {
        ...state,
        isCreatingObject: false,
        isDuplicatingObject: false,
      };

    case C.CREATE_SUB_OBJECT_START:
      return {
        ...state,
        currentRequest: C.CREATE_SUB_OBJECT,
      };

    case C.CREATE_SUB_OBJECT_FAIL:
      return {
        ...state,
        pending: false,
        currentRequest: null,
      };

    case C.CREATE_SUB_OBJECT_SUCCESS:
      return {
        ...state,
        objectDatas: $.addOrUpdateObjectWithObjects(state.objectDatas, action.objectDatas, action.reset),
        blockDatas: $.addOrUpdateObjectWithObjects(state.blockDatas, action.blockDatas),
        fieldDatas: $.addOrUpdateObjectWithObjects(state.fieldDatas, action.fieldDatas),
        pending: false,
        currentRequest: null,
      };

    case C.UPDATE_OBJECT_SUCCESS:
      return {
        ...state,
        objectDatas: $.addOrUpdateObjectWithObjects(state.objectDatas, action.objectDatas, action.reset),
        blockDatas: $.addOrUpdateObjectWithObjects(state.blockDatas, action.blockDatas),
        fieldDatas: $.addOrUpdateObjectWithObjects(state.fieldDatas, action.fieldDatas),
        unTouchedObject: { ...action.object },
        preparedBlock: {},
        preparedFields: {},
        pending: false,
        currentRequest: null,
        objectWasUpdated: true,
        updatedObjectId: action.updatedObjectId,
        preparedObjects: state.preparedObjects && $.removeObjectFromObjectById(state.preparedObjects, action.updatedObjectId),
        isDuplicatingObject: false,
      };


    case C.UPDATE_OBJECT_DUPLICATE_SUCCESS:
      return {
        ...state,
        objectDatas: $.addOrUpdateObjectWithObjects(state.objectDatas, action.objectDatas, action.reset),
        blockDatas: $.addOrUpdateObjectWithObjects(state.blockDatas, action.blockDatas),
        fieldDatas: $.addOrUpdateObjectWithObjects(state.fieldDatas, action.fieldDatas),
        unTouchedObject: { ...action.object },
        preparedBlock: {},
        preparedFields: {},
        pending: false,
        currentRequest: null,
        objectWasUpdated: true,
        updatedObjectId: action.updatedObjectId,
        preparedObjects: state.preparedObjects && $.removeObjectFromObjectById(state.preparedObjects, action.updatedObjectId),
        isDuplicatingObject: false,
        duplicatedObject: null,
        duplicateDateTimeChanged: false,
        initalTimes: null,
        duplicateTimeDiff: { startTime: 0, endTime: 0 },
      };

    case C.CREATE_BLOCK_SUCCESS:
    case C.UPDATE_BLOCK_SUCCESS:
    case C.TOGGLE_EXTERNAL_SUCCESS:
      objectDatasWithUpdatedListFields = action.fieldDatas && Object.values(action.fieldDatas).find(f => f.list) ?
        updateListFieldData(state.objectDatas && Object.values(state.objectDatas), action.fieldDatas && Object.values(action.fieldDatas)) : null;
      objectDatasWithUpdatedBlockDatas = action.blockDatas ? updateObjectsBlockDatas((state.objectDatas && Object.values(state.objectDatas)), action.blockDatas && Object.values(action.blockDatas)) : action.objectDatas;
      if (action.blockTypes && action.updatedBlockId) {
        Object.values(action.blockTypes).forEach((bt) => {
          const newObj = state.objectTypes[bt.object_type_id];
          if (bt.is_external) {
            newObj.external_block_types = $.addToArray(newObj.external_block_types || [], bt.block_type_id);
          } else if (bt.object_type_id) {
            newObj.block_types = $.addToArray(newObj.block_types || [], bt.block_type_id);
          }
          objectTypesWithUpdatedBlockTypes[bt.object_type_id] = newObj;
        });
      }
      return {
        ...state,
        objectDatas: $.addOrUpdateObjectWithObjects(state.objectDatas, objectDatasWithUpdatedListFields || objectDatasWithUpdatedBlockDatas, action.reset),
        blockDatas: $.addOrUpdateObjectWithObjects(state.blockDatas, action.blockDatas),
        fieldDatas: $.addOrUpdateObjectWithObjects(state.fieldDatas, action.fieldDatas),
        selectOptions: $.addOrUpdateObjectWithObjects(state.selectOptions, action.selectOptions),
        blockTypes: $.addOrUpdateObjectWithObjects(state.blockTypes, action.blockTypes),
        objectTypes: $.addOrUpdateObjectWithObjects(state.objectTypes, objectTypesWithUpdatedBlockTypes),
        fieldTypes: $.addOrUpdateObjectWithObjects(state.fieldTypes, action.fieldTypes),
        pending: false,
        currentRequest: null,
        blockWasUpdated: true,
        lastUpdatedBlockId: action.updatedBlockId,
        preparedBlocks: state.preparedBlocks && $.removeObjectFromObjectById(state.preparedBlocks, action.updatedBlockId),
      };

    case C.CREATE_COMMENT_SUCCESS:
      stateChange = {
        ...state,
        blockDatas: $.addOrUpdateObjectWithObjects(state.blockDatas, action.blockDatas),
      };
      updatedBlocksArray = action.blockDatas ? Object.values(action.blockDatas) : [];
      if(updatedBlocksArray && updatedBlocksArray.length) {
        stateChange.preparedBlocks = $.updateObjectById(state.preparedBlocks, updatedBlocksArray[0].id, updatedBlocksArray[0]);
      }
      return stateChange;

    case C.INSERT_SUB_OBJECT_SUCCESS:
      return {
        ...state,
        // objectsByType: updateBlock(state.objectsByType, state.objectTypes, action.object), // updateObject(state.objectsByType, state.objectTypes, action.object),
      };

    case C.FETCH_OBJECTS_START:
      return {
        ...state,
        pending: true,
        currentRequest: C.FETCH_OBJECTS,
        showLoader: !state.objectDatas,
      };
    case C.AUTOCOMPLETE_OBJECTS_START:
      return {
        ...state,
        primaryFieldValue: action.primaryFieldValue,
      };

    case C.FETCH_OBJECT_START:
    case C.FETCH_OBJECT_DUPLICATION_START:
      return {
        ...state,
        pending: true,
        showLoader: !state.objectDatas,
        currentRequest: C.FETCH_OBJECT,
      };
    case C.FETCH_OBJECT_TYPES_START:
      return {
        ...state,
        pendingObjectTypes: true,
        showLoader: !state.objectTypes,
      };
    case C.CREATE_OBJECT_DIRECTLY_START:
    case C.DELETE_OBJECT_START:
    case C.DELETE_OBJECT_TYPE_START:
    case C.DELETE_BLOCK_TYPE_START:
      return {
        ...state,
        pending: true,
      };
    case C.SET_OBJECT_STATUS_START:
      return {
        ...state,
        updatedObjectId: null,
      };
    case C.UPDATE_OBJECT_START:
      return {
        ...state,
        pending: true,
        currentRequest: C.UPDATE_OBJECT,
        updatedBlockId: action.blockId,
        objectWasUpdated: false,
        updatedObjectId: null,
      };
    case C.UPDATE_OBJECT_DUPLICATE_START:
      return {
        ...state,
        pending: true,
        currentRequest: C.UPDATE_OBJECT_DUPLICATE,
        updatedBlockId: action.blockId,
        objectWasUpdated: false,
      };
    case C.CREATE_OBJECT_START:
      return {
        ...state,
        pending: true,
        pendingCreateObject: true,
        currentRequest: C.CREATE_OBJECT,
        updatedObjectId: null,
      };
    case C.CREATE_OBJECT_TYPE_START:
      return {
        ...state,
        pending: true,
        isCreatingObject: true,
        currentRequest: C.CREATE_OBJECT_TYPE_START,
        isDuplicatingObject: false,
      };
    case C.UPDATE_OBJECT_TYPE_START:
      return {
        ...state,
        pending: true,
        currentRequest: C.UPDATE_OBJECT_TYPE_START,
      };
    case C.UPDATE_OR_CREATE_BLOCK_START:
      return {
        ...state,
        pending: true,
        currentRequest: C.UPDATE_OR_CREATE_BLOCK,
        updatedBlockId: action.blockId,
        objectWasUpdated: true,
        lastUpdatedParentBlockId: state.objectTypes[action.objectTypeId]
          && state.objectTypes[action.objectTypeId].type === O.COREOBJ ? action.blockId : state.lastUpdatedParentBlockId,
      };
    case C.TOGGLE_EXTERNAL_START:
      return {
        ...state,
        lastUpdatedBlockId: null,
      };
    case C.FETCH_OBJECT_TYPES_FAIL:
      return {
        ...state,
        pendingObjectTypes: false,
        showLoader: false,
        error: action.error,
      };

    case C.FETCH_OBJECTS_FAIL:
    case C.CREATE_OBJECT_DIRECTLY_FAIL:
    case C.DELETE_OBJECT_FAIL:
    case C.DELETE_OBJECT_TYPE_FAIL:
    case C.DELETE_BLOCK_TYPE_FAIL:
    case C.AUTOCOMPLETE_OBJECTS_FAIL:
    case C.SET_PRIMARY_FILE_FAIL:
    case C.CREATE_OBJECT_TYPE_FAIL:
    case C.UPDATE_OBJECT_TYPE_FAIL:
      return {
        ...state,
        pending: false,
        error: action.error,
        showLoader: false,
        isCreatingObject: false,
        isDuplicatingObject: false,
      };
    case C.FETCH_OBJECT_FAIL:
      return {
        ...state,
        pending: false,
        error: action.error,
        showLoader: false,
        objectDatas: $.removeObjectFromObjectById(state.objectDatas, action.objectId),
      };
    case C.UPDATE_OBJECT_FAIL:
      return {
        ...state,
        pending: false,
        currentRequest: C.UPDATE_OBJECT,
        error: action.error,
        updatedBlockId: null,
        lastUpdatedParentBlockId: null,
        objectWasUpdated: false,
      };
    case C.CREATE_OBJECT_FAIL:
      return {
        ...state,
        pending: false,
        pendingCreateObject: false,
        currentRequest: C.CREATE_OBJECT,
        error: action.error,
      };
    case C.CREATE_BLOCK_FAIL:
      return {
        ...state,
        pending: false,
        pendingCreateObject: false,
        currentRequest: C.CREATE_OBJECT,
        error: action.error,
      };

    case C.BEGIN_CREATE_OBJECT:
      return {
        ...state,
        isCreatingObject: !action.isDuplicatingObject,
        // preparedFields: action.block.field_types,
        preparedObjects: $.addObjectToObject(state.preparedObjects, { ...action.object }),
        preparedBlocks: $.addObjectToObject(state.preparedBlocks, { ...action.block }),
        isDuplicatingObject: false,
      };

    case C.ABORT_CREATE_OBJECT:
      return {
        ...state,
        isCreatingObject: false,
        preparedFields: {},
        preparedBlocks: {},
        preparedObjects: {},
        isDuplicatingObject: false,
        duplicatedObject: null,
        duplicateDateTimeChanged: false,
        initalTimes: null,
        duplicateTimeDiff: { startTime: 0, endTime: 0 },
      };

    case C.ADD_SUB_OBJECT:
      prepObject = { ...action.subObject };
      prepObject.is_being_created = true;
      prepBlock = action.subBlock || {};
      prepField = {
        ...(state.preparedFields[action.fieldId]
          || state.fieldDatas[action.fieldId]
          || Object.values(state.preparedFields).find(field => field.field_type_id === action.fieldTypeId)
          || { id: uuid(), is_being_created: true, field_type_id: action.fieldTypeId }) };
      prepField.object_datas = $.addToArray(prepField.object_datas || [], prepObject.id);
      prepObjects.push(prepObject);
      prevObject = { ...Object.values(state.preparedObjects).find(prepObj => prepObj.is_being_edited) };
      if(prevObject.id) {
        prevObject.is_being_edited = false;
        prepObjects.push(prevObject);
      }
      // outerBlock = { ...prepBlock, field_datas: $.addOrUpdateObjectInArrayById(prepBlock.field_datas, prepField) };
      return {
        ...state,
        preparedObjects: $.addArrayOfObjectsToObject(state.preparedObjects, prepObjects),
        preparedBlocks: $.addObjectToObject(state.preparedBlocks, prepBlock),
        preparedFields: $.addObjectToObject(state.preparedFields, prepField),
      };

    case C.PREPARE_FIELD:
      prepBlock = (state.preparedBlocks && action.blockId && state.preparedBlocks[action.blockId]) || (state.latestActivatedBlockId && state.preparedBlocks[state.latestActivatedBlockId]);
      prepField = action.field;
      if(prepBlock && prepBlock.block_type_id !== prepField.block_type_id) {
        return { ...state };
      }
      if(prepField && !prepField.id) {
        prepField.id = uuid();
        prepField.is_being_created = true;
        newPrepBlock = { ...prepBlock, field_datas: $.addToArray((prepBlock && prepBlock.field_datas) || [], prepField.id) };
      }
      return {
        ...state,
        preparedBlocks: newPrepBlock.id ? $.addObjectToObject(state.preparedBlocks, newPrepBlock) : state.preparedBlocks,
        preparedFields: $.addObjectToObject(state.preparedFields, prepField),
      };

    case C.PREPARE_BLOCK:
      return {
        ...state,
        preparedBlocks: $.addObjectToObject(state.preparedBlocks, {
          id: action.blockId || state.latestActivatedBlockId,
          is_being_created: !action.blockId || typeof action.blockId === 'string',
          ...action.blockExtra }),
      };

    case C.ACTIVATE_FIELDS_EDIT_MODE:
      if(action.block) {
        prepFields = Object.values({ ...state.fieldDatas, ...state.preparedFields }).filter(field => action.block.field_datas && action.block.field_datas.find(fieldId => fieldId === field.id));
        prepBlock = { ...action.block, field_datas: prepFields.map(prepF => prepF.id) };
      } else {
        prepBlock = null;
      }
      prepObject = { id: action.objectId };
      prevObject = { ...Object.values(state.preparedObjects).find(prepObj => prepObj.is_being_edited) };
      if(prepBlock && !prepBlock.id) {
        prepBlock.id = action.blockId || uuid();
        prepBlock.is_being_created = true;
      }
      if(prepObject.id) {
        prepObject.is_being_edited = true;
        prepObjects.push(prepObject);
      }
      if(prevObject.id) {
        prevObject.is_being_edited = false;
        prepObjects.push(prevObject);
      }
      stateChange = {
        ...state,
        updatedObjectId: null,
        lastUpdatedBlockId: null,
        lastUpdatedParentBlockId: null,
        preparedObjects: prepObject.id ? $.addArrayOfObjectsToObject(state.preparedObjects, prepObjects) : state.preparedObjects,
        latestActivatedBlockId: prepBlock.id,
      };
      if(action.resetLatestCreatedObject) state.latestCreatedObjectId = null;
      if(prepBlock) stateChange.preparedBlocks = $.addObjectToObject(state.preparedBlocks, prepBlock);
      return stateChange;

    case C.DEACTIVATE_FIELDS_EDIT_MODE:
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        preparedBlocks: {},
        preparedFields: {},
        preparedObjects: {},
        objectsAutocompleteResults: [],
        selectedObjects: [],
      };

      // case C.INCREASE_CURRENT_OBJECT_PAGE:
      //   return {
      //     ...state,
      //     currentPage: state.currentPage + 1,
      //   };

    case C.DELETE_OBJECT_TYPE_SUCCESS:
      return {
        ...state,
        pending: false,
        objectTypes: $.removeObjectFromObjectById(state.objectTypes, action.objectTypeId),
        selectedObjects: [],
      };

    case C.DELETE_BLOCK_TYPE_SUCCESS:
      updateObjects[action.objectTypeId] = { ...state.objectTypes[action.objectTypeId] };
      if (state.objectTypes[action.objectTypeId]) {
        if (state.objectTypes[action.objectTypeId].block_types) {
          if (state.objectTypes[action.objectTypeId].block_types.includes(action.blockTypeId)) {
            updateObjects[action.objectTypeId].block_types = $.removeFromArray(state.objectTypes[action.objectTypeId].block_types, action.blockTypeId);
          }
        } else if (state.objectTypes[action.objectTypeId].external_block_types) {
          if (state.objectTypes[action.objectTypeId].external_block_types.includes(action.blockTypeId)) {
            updateObjects[action.objectTypeId].external_block_types = $.removeFromArray(state.objectTypes[action.objectTypeId].external_block_types, action.blockTypeId);
          }
        }
      }
      return {
        ...state,
        pending: false,
        objectTypes: $.addOrUpdateObjectWithObjects(state.objectTypes, updateObjects),
        blockTypes: $.removeObjectFromObjectById(state.blockTypes, action.blockTypeId),
        selectedObjects: [],
      };

    case C.DELETE_OBJECT_SUCCESS:
      Object.values(state.fieldDatas).forEach((fd) => {
        let newFd = { ...fd };
        if(fd.object_datas) {
          newFd = { ...fd, object_datas: $.removeFromArray(fd.object_datas || [], action.objectId) };
        }
        newFieldDatas[newFd.id] = newFd;
      });
      Object.values(state.objectDatas).forEach((od) => {
        let newOd = { ...od };
        if(od.primary_category_objects) {
          newOd = { ...od, primary_category_objects: $.removeFromArray(od.primary_category_objects || [], action.objectId) };
        }
        newObjectDatas[newOd.id] = newOd;
      });
      return {
        ...state,
        pending: false,
        objectDatas: $.removeObjectFromObjectById(newObjectDatas, action.objectId),
        fieldDatas: newFieldDatas,
        selectedObjects: [],
      };

    case C.AUTOCOMPLETE_OBJECTS_SUCCESS:
      return {
        ...state,
        objectsAutocompleteResults: action.objectsAutocompleteResults,
      };
    case C.CREATE_OBJECT_DIRECTLY_SUCCESS:
      return {
        ...state,
        directlyCreatedObject: action.directlyCreatedObject,
      };
    case C.BASE_OBJECT_DID_UPDATE:
      return {
        ...state,
        objectWasUpdated: false,
        updatedBlockId: null,
        lastUpdatedParentBlockId: null,
      };

    case C.SET_OBJECT_STATUS_SUCCESS:
      return {
        ...state,
        objectDatas: $.addOrUpdateObjectWithObjects(state.objectDatas, action.objectDatas),
        pending: false,
        updatedObjectId: action.updatedObjectId,
      };

    case C.DELETE_FILE_SUCCESS:
      return {
        ...state,
        objectDatas: removeFileFromObject(state.objectDatas, action.file),
        fieldDatas: removeFileFromField(state.fieldDatas, action.file),
      };

    case C.SET_CURRENT_OBJECT:
      return {
        ...state,
        currentObject: action.id,
      };


    case C.UNSET_CURRENT_OBJECT:
      return {
        ...state,
        currentObject: null,
      };

    case C.TOGGLE_TOKEN_USER_ACTIVE_SUCCESS:
      updateObjects[action.objectData.id] = { ...action.objectData, token_users: $.addOrUpdateObjectInArrayById(state.objectDatas[action.objectData.id].token_users, action.objectData.token_users[0]) };
      return {
        ...state,
        objectDatas: $.addOrUpdateObjectWithObjects(state.objectDatas, updateObjects),
      };

    case C.FETCH_CALENDAR_SCHEDULE_SUCCESS:
    case C.FETCH_GLOBAL_SCHEDULE_SUCCESS:
    case C.FETCH_TASKS_SUCCESS:
    case C.CREATE_SCHEDULE_SUCCESS:
    case C.FETCH_SCHEDULE_SUCCESS:
    case C.UPDATE_SCHEDULE_SUCCESS:
    case C.FETCH_FILES_SUCCESS:
    case C.SET_PRIMARY_FILE_SUCCESS:
    case C.FETCH_TASK_SUCCESS:
    case C.FETCH_PROJECTS_SUCCESS:
    case C.BEGIN_VIEWING_PERSON:
    case C.FETCH_STAFF_SUCCESS:
      return {
        ...state,
        objectDatas: $.addOrUpdateObjectWithObjects(state.objectDatas, action.objectDatas),
        blockDatas: $.addOrUpdateObjectWithObjects(state.blockDatas, action.blockDatas),
        fieldDatas: $.addOrUpdateObjectWithObjects(state.fieldDatas, action.fieldDatas),
        selectOptions: $.addOrUpdateObjectWithObjects(state.selectOptions, action.selectOptions),
        jobRoles: $.addOrUpdateObjectWithObjects(state.jobRoles, action.jobRoles),
      };

    case C.FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        objectDatas: $.addOrUpdateObjectWithObjects(state.objectDatas, action.objectDatas),
        blockDatas: $.addOrUpdateObjectWithObjects(state.blockDatas, action.blockDatas),
        fieldDatas: $.addOrUpdateObjectWithObjects(state.fieldDatas, action.fieldDatas),
        selectOptions: $.addOrUpdateObjectWithObjects(state.selectOptions, action.selectOptions),
        jobRoles: $.addOrUpdateObjectWithObjects(state.jobRoles, action.jobRoles),
      };

    case C.FETCH_OBJECTS_LOCAL:
      return {
        ...state,
        nextPage: action.reset ? 1 : state.nextPage + 1,
        currentPage: state.nextPage,
        paginationReachedBottom: didPaginationReachedBottom(
          state.paginationReachedBottom,
          action.objectType,
          Object.values(state.objectDatas) ? Object.values(state.objectDatas).slice(state.nextPage * state.pageSize, (state.nextPage + 1) * state.pageSize) : [],
          action.reset,
        ),
      };
    case C.PREPARE_OBJECT:
    case C.PREPARE_TOKEN_USER_OBJECT:
      prepObject = action.object;
      return {
        ...state,
        pending: false,
        preparedObjects: $.addObjectToObject(state.preparedObjects, prepObject),
      };
    case C.PREPARE_OBJECT_DUPLICATE:
      prepObject = action.object;
      return {
        ...state,
        pending: false,
        preparedObjects: $.addObjectToObject(state.preparedObjects, prepObject),
        duplicateDateTimeChanged: action.duplicateDateTimeChanged,
        initalTimes: action.initalTimes,
        duplicateTimeDiff: action.duplicateTimeDiff,
      };
    case C.ADD_SUB_OBJECT_PRIMARY:
      prepObject = { ...action.subObject };
      prepMainObject = state.preparedObjects[action.mainObjectId];
      prepMainObject.category_objects = $.addToArray((prepMainObject && prepMainObject.category_objects) || [], { id: prepObject.id, is_being_created: true });
      prepObjects.push(prepObject);
      prepObjects.push(prepMainObject);
      prevObject = { ...(Object.values(state.preparedObjects).find(prepObj => prepObj.is_being_edited) || {}) };
      if(prevObject.id) {
        prevObject.is_being_edited = false;
        prepObjects.push(prevObject);
      }
      return {
        ...state,
        preparedObjects: $.addArrayOfObjectsToObject(state.preparedObjects, prepObjects),
      };

    case C.SET_OBJECT_PUBLISHED_START:
    case C.SET_OBJECT_PUBLISHED_SUCCESS:
      return {
        ...state,
        objectDatas: $.addOrUpdateObjectWithObjects(state.objectDatas, action.objectDatas),
      };

    case C.FETCH_OBJECT_COUNTS_START:
      return {
        ...state,
        objectCounts: null,
      };

    case C.FETCH_OBJECT_COUNTS_SUCCESS:
      return {
        ...state,
        objectCounts: {
          objectDatasCount: action.objectCounts.object_datas_count,
          primaryCategoryObjectsCount: action.objectCounts.primary_category_objects_count,
        },
      };

    case C.PRIMARY_CATEGORY_CONFLICT_FOUND:
      return {
        ...state,
        primaryCategoryConflictExists: true,
        primaryCategoryConflictPending: false,
        conflictingObjects: action.conflictingObjects ? $.addOrUpdateListWithList(state.conflictingObjects, action.conflictingObjects) : state.conflictingObjects,
        overrideConflictFunction: action.overrideConflictFunction,
        conflictingObjectType: action.conflictingObjectType,
      };

    case C.RESET_PRIMARY_CATEGORY_CONFLICTS:
      return {
        ...state,
        primaryCategoryConflictExists: false,
        primaryCategoryConflictPending: false,
        conflictingObjects: [],
        overrideConflictFunction: null,
        conflictingObjectType: null,
      };

    case C.PRIMARY_CATEGORY_CONFLICT_PENDING:
      return {
        ...state,
        primaryCategoryConflictPending: true,
      };

    case C.PREPARE_JOB_ROLE:
      return {
        ...state,
        preparedJobRoles: $.addObjectToObject(state.preparedJobRoles, action.jobRole),
      };

    case C.UNPREPARE_JOB_ROLES:
      return {
        ...state,
        preparedJobRoles: {},
      };

    case C.UPDATE_JOB_ROLES_SUCCESS:
      return {
        ...state,
        jobRoles: $.addOrUpdateObjectWithObjects(state.jobRoles, action.jobRoles),
      };


    case C.GENERATE_CONTRACT_PDF_START:
      return {
        ...state,
        isGeneratingPdf: true,
      };

    case C.GENERATE_CONTRACT_PDF_SUCCESS:
      return {
        ...state,
        isGeneratingPdf: false,
        preparedFields: appendContractPdf(state.preparedFields, action.generatedPdf, action.contractId),
      };

    case C.GENERATE_CONTRACT_PDF_FAIL:
      return {
        ...state,
        isGeneratingPdf: false,
      };

    case C.START_CONTRACT_DOCUMENT_SIGNING_SUCCESS:
      return {
        ...state,
        preparedFields: updateContractPdf(state.preparedFields, action.contractPdf, action.contractId),
      };

    case C.FETCH_OBJECT_DUPLICATION_SUCCESS:
      return {
        ...state,
        duplicatedObject: action.object,
        pending: false,
        showLoader: false,
      };

    case C.FETCH_OBJECT_DUPLICATION_FAIL:
      return {
        ...state,
        pending: false,
        error: action.error,
        showLoader: false,
      };

    case C.UPDATE_DUPLICATION_OBJECT:
      return {
        ...state,
        duplicatedObject: action.object,
      };

    case C.DUPLICATE_OBJECT_READY:
      return {
        ...state,
        isDuplicatingObject: true,
      };

    case C.IMPORT_FIELDS_CREATED:
      return {
        ...state,
        importFields: action.fields,
        pending: false,
        showLoader: false,
      };

    case C.PREPARE_IMPORT_FIELDS:
      return {
        ...state,
        importFields: action.preparedImportFields,
      };

    case C.PREPARE_IMPORT_EXCEL_DATA:
      return {
        ...state,
        importExcelData: action.importExcelData,
      };

    case C.IMPORT_EXCEL_DATA_START:
    case C.IMPORT_EXCEL_START:
      return {
        ...state,
        pending: true,
        showLoader: true,
      };

    case C.IMPORT_EXCEL_FINISHED:
      return {
        ...state,
        importExcelData: null,
        importFields: null,
        pending: false,
        showLoader: false,
      };

    case C.IMPORT_FIELDS_BLOCK_CREATED:
      return {
        ...state,
        importFieldsBlock: action.fields,
        importBlockHeaders: action.headerFieldTypes,
        pending: false,
        showLoader: false,
      };

    case C.PREPARE_IMPORT_BLOCK_FIELDS:
      return {
        ...state,
        importFieldsBlock: action.preparedImportFields,
      };

    case C.PREPARE_IMPORT_EXCEL_BLOCK_DATA:
      return {
        ...state,
        importExcelBlock: action.importExcelData,
      };

    case C.IMPORT_EXCEL_BLOCK_FINISHED:
      return {
        ...state,
        importExcelBlock: null,
        importFieldsBlock: null,
      };

    case C.PREPARE_REOCCURING_OBJECT:
      return {
        ...state,
        preparedReoccuringObject: action.reoccuringObject,
      };

    case C.OPEN_REOCCURING_MODAL:
      return {
        ...state,
        reoccuringModalOpen: true,
      };

    case C.CLOSE_REOCCURING_MODAL:
      return {
        ...state,
        reoccuringModalOpen: false,
      };

    case C.CLEAR_OBJECT_SELETION:
      return {
        ...state,
        selectedObjects: [],
      };


    default:
      return state;
  }
}
