import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import * as authActions from '../actions/authActions';
import Login from '../components/Login';

const mapStateToProps = (state) => {
  const { theme } = state.environmentState;
  return { ...state.authStore, theme };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...authActions }, dispatch);

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.handleSubmitPassword = this.handleSubmitPassword.bind(this);
    this.handleSubmitUsername = this.handleSubmitUsername.bind(this);
    this.handleChangeUsername = this.handleChangeUsername.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.state = { username: '', password: '' };
  }

  handleChangeUsername(e) {
    this.setState({ username: e.target.value });
  }

  handleChangePassword(e) {
    this.setState({ password: e.target.value });
  }

  handleSubmitUsername(e) {
    e.preventDefault();
    const { getLastClient } = this.props;
    const { username } = this.state;
    getLastClient(username);
  }

  handleSubmitPassword(e) {
    e.preventDefault();
    const { username, password } = this.state;
    const { login } = this.props;
    login(username, password);
  }

  render() {
    const {
      loggedIn,
      user,
      failedLoginAttempt,
      projects,
      theme,
      sendResetPasswordLink,
    } = this.props;
    const { username } = this.state;
    if (loggedIn) return <Redirect to="/dashboard" />;

    return user ? (
      <Login
        key={1}
        autoFocus
        logotype={
          projects && projects.length ? projects[user.last_project].logo1 : null
        }
        title="Oh, it's you!"
        text="We're glad to see you here again."
        formTitle={
          <span>
            Please enter your <b>password</b>:
          </span>
        }
        buttonTitle="Login"
        handleSubmit={this.handleSubmitPassword}
        handleChange={this.handleChangePassword}
        inputType="password"
        showError={failedLoginAttempt}
        error="E-mail and password do not match."
        username={username}
        theme={theme}
        sendResetPasswordLink={() => sendResetPasswordLink(username)}
      />
    ) : (
      <Login
        key={2}
        promoteApp={false}
        title="Welcome to Jetty."
        text={
          <span>
            The only way to keep track <br /> of everything.
          </span>
        }
        formTitle={
          <span>
            Enter your <b>email address</b>:
          </span>
        }
        buttonTitle={
          <span>
            Next <i className="fa fa-login-next-arrow" />
          </span>
        }
        handleSubmit={this.handleSubmitUsername}
        handleChange={this.handleChangeUsername}
        inputType="email"
        // inputPlaceholder="Forgot?"
        error="No account with this e-mail can be found."
        showError={user && user.client === -1}
        theme={theme}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
