// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import * as authActions from '../actions/authActions';
import type { AuthState, Id } from '../entities/FlowTypes';
import InputText from '../components/shared/InputText';
import Title from '../components/shared/Title';
import Button from '../components/shared/Button';

import s from './ResetPasswordContainer.module.scss';

const mapStateToProps = (state) => {
  const { theme } = state.environmentState;
  return { ...state.authStore, theme };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...authActions }, dispatch);

type PropTypes = AuthState & {
  match: { params: { resetToken: string } },
  fetchUserFromResetToken: (string) => void,
  resetPassword: (Id, string, string) => void,
};

type StateTypes = {
  password1: string,
  password2: string,
}

class ResetPasswordContainer extends Component<PropTypes, StateTypes> {
  constructor(props: PropTypes) {
    super(props);
    this.state = {
      password1: '',
      password2: '',
    };
  }
  componentDidMount() {
    const { fetchUserFromResetToken } = this.props;
    const { resetToken } = this.props.match.params;
    fetchUserFromResetToken(resetToken);
  }

  resetPassword = () => {
    const { user, resetPassword } = this.props;
    const { resetToken } = this.props.match.params;
    const { password1 } = this.state;
    if(user && user.id) resetPassword(user.id, password1, resetToken);
  }

  comparePasswords = () => {
    const { password1, password2 } = this.state;
    return password1 === password2;
  }


  render() {
    const { user, passwordResetOpen, pending } = this.props;
    const { password1, password2 } = this.state;

    const background = () => (
      <div
        style={{ backgroundImage: 'url("https://s3.eu-central-1.amazonaws.com/jetty2-dev/web/splash_screen_image.jpg")' }}
        className={s.background}
      >
        <div className={s.overlay} style={{ backgroundColor: 'white', opacity: '0.85' }} />
      </div>
    );
    const passwordsEqual = this.comparePasswords();
    if(!user) {
      return (
        <div>
          {background()}
          <div className={s.wrapper}>
            <div className="row">
              <div className={`col-12 ${s.header}`}>
                <Title h2>{passwordResetOpen ? 'Password changed' : 'Link Expired'}</Title>
              </div>
            </div>
            <div className="row">
              <div className={`col-12 ${s.footer}`}>
                <Link to="/dashboard" style={{ textDecoration: 'none' }}>
                  <Button
                    type="primary"
                    title="Proceed to login"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        {background()}
        <div className={s.wrapper}>
          <div className="row">
            <div className={`col-12 ${s.header}`}>
              <Title h2>Reset Password</Title>
            </div>
          </div>
          <div className="row">
            <div className={`col-12 ${s.field}`}>
              <InputText
                value={password1}
                onChange={e => this.setState({ password1: e.target.value })}
                placeholder="Enter password"
                edit
                type="password"
              />
            </div>
          </div>
          <div className="row">
            <div className={`col-12 ${s.field}`}>
              <div className={password2 && !passwordsEqual ? s.invalidField : ''}>
                <InputText
                  value={password2}
                  onChange={e => this.setState({ password2: e.target.value })}
                  placeholder="Re-enter password"
                  edit
                  type="password"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className={`col-12 ${s.footer}`}>
              <Button
                onClick={this.resetPassword}
                type="primary"
                title="Save"
                pending={pending}
                disabled={!(password1 && password2 && passwordsEqual)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer);
