// @flow
/* eslint no-use-before-define: 0 */

export type Id = number;
export type IdMap<T> = { [Id]: T | void };
export type StringMap<T> = { [string]: T | void };
export type CompleteIdMap<T> = { [Id]: T };
export type CompleteStringMap<T> = { [string]: T };
export type Uuid = string;

// Prepared-ing a type T will make
// all fields in T nullable
export type Prepared<T> = $Shape<T>;
opaque type EditorState = void;

// Dont use this one, thanks Flow
export type ReduxProps<Props, Actions> = $Exact<
  $Call<Props, ReduxState> & Actions,
>;

export type TokenUser = {
  id: number,
  api_key: string,
  full_name: string,
  email: string,
  is_active: boolean,
  project: Project,
  permissions: Array<Permission>,
  object_data: ?ObjectData,
  object_type: ?ObjectType,
  object_type_id: ?number,
  client_id: ?number,
};

export type Language = {
  id: number,
  name?: string,
  value?: string,
  language_code?: string,
};

export type ProjectInformation = {
  id: number,
  primary_language?: Language,
  order_index?: number,
  title?: string,
  value?: string | EditorState,
};

export type Client = {
  id: Id,
};

export type Project = {
  id: number,
  project_information?: Array<ProjectInformation>,
  primary_language?: Language,
  languages?: Array<Id>,
  primary_people?: Person[],
  name?: string,
  users?: Array<User>,
  menuItems?: IdMap<MenuItem>,
  start_at?: string,
  end_at?: string,
  prod_start_at?: string,
  prod_end_at?: string,
  logotype?: File,
  address?: string,
  gps?: string,
  setting?: Setting,
  economy_setting?: EconomySetting,
  exchange_rates?: ExchangeRate[],
  created_at?: string,
  image_ratios?: ImageRatio[],
  currency?: Currency,
};

export type Setting = {
  id: Id,
  block_printing?: boolean,
  departments: Department[],
  file_tag_group: TagGroup,
  global_scheduling_is_active?: boolean,
  people_object_type: ObjectType,
  people_tag_group: ?Id,
  printing?: boolean,
  roles?: Role[],
  schedule_tag_group?: TagGroup,
  schedule_title_tag_group?: TagGroup,
  schedule_type_option_group?: OptionGroup,
  task_completed_status?: any,
  task_default_status?: any,
  task_list_object_type_id?: number,
  task_object_types?: ObjectType[],
  task_statuses?: Status[],
  task_tag_group: TagGroup,
  tasks_is_active?: boolean,
  timezone?: string,
  person_title_is_multiselect?: boolean,
  person_title_tag_group?: ?Id,
  has_two_letter_country_code?: boolean,
  contracts_is_active?: boolean,
  contract_pdf_dimension?: string,
  contract_pdf_is_hole_punched?: boolean,
  digital_signing_is_active?: boolean,
  has_am_pm_format?: boolean,
  project_is_active?: boolean,
  has_reoccuring?: boolean,
  has_bulk_email_sending?: boolean,
  has_dynamic_bulk_email_footer?: boolean,
};

export type Status = {
  id: any,
  order?: number,
  text?: string,
  color?: string,
  setting?: any,
  object_type?: Id,
};

export type Permission = {
  id?: number,
  type: string,
  department?: ?Department | Id,
  object_type?: { id: number } | number,
  block_type?: { id: number } | number,
  object_data?: { id: number } | number,
  menu_item?: { id: number } | number,
  status?: { id: number } | number,
  post?: CmsPost,
};

export type Role = {
  id?: number,
  text: string,
  hidden: boolean,
  permissions: Permission[],
  color?: string,
  linked_roles?: Role[],
  is_template?: boolean,
};

export type OptionGroup = {
  id: Id,
  select_options?: SelectOption[],
};

export type Person = {
  id?: number,
  project?: any,
  title?: string,
  title_tags?: Tag[],
  tags?: Tag[],
  phone?: string,
  full_name?: string,
  job_roles?: Id[],
  user?: ?Id | ?User,
  email?: string,
  image?: File,
  user_id?: number,
  birth_date?: any,
  street_name?: any,
  companies?: ?(Company[]),
  is_fake?: boolean,
  contacts?: Contact[],
  city?: string,
  state?: string,
  zip_code?: string,
  country?: string,
  global_notes?: string,
  all_title_tags?: Tag[],
  connected_objects?: ObjectData[],
};

export type Company = {
  id?: number,
  project?: any,
  name?: string,
  organization_number?: string,
  image?: File,
  website?: string,
  global_notes?: string,
  tags?: Tag[],
  addresses?: Address[],
  type_tag?: Tag,
  industry_tag?: Tag,
  people?: Person[],
  contact_logs?: ContactLog[],
  is_fake?: boolean,
};

export type Address = {
  id?: number,
  street_name?: string,
  city?: string,
  zip_code?: string,
  phone?: string,
  email?: string,
  country?: string,
  type_tag?: Tag,
};

export type ContactLog = {
  id?: number,
  title?: string,
  schedule?: Schedule,
  person?: Person,
  note?: string,
  type_tag?: Tag,
  added_by?: User,
};

export type Account = {
  id?: number,
  username: string,
  full_name: string,
  first_name: ?string,
  last_name: ?string,
  has_accepted_terms?: boolean,
  super?: boolean,
  client_last?: number,
};

export type Tag = {
  id: Id | Uuid,
  name?: string,
  is_used_by_task?: boolean,
  tag_group_id?: number,
  tag_group?: Id,
  is_schedule_related_tag?: boolean,
  project?: Id,
  color?: string,
};

export type PreparedTag = Prepared<Tag> & {
  disabled?: boolean,
  mergedFrom?: Id[],
};

export type TagGroup = {
  id: Id,
  tags?: Id[],
  name?: Translation,
  field_types?: FieldType[],
};

export type Schedule = {
  id: Id,
  start_time: string,
  end_time?: string,
  project?: any,
  is_external?: boolean,
  object_datas?: {
    id: Id,
    object_type: {
      id: Id,
    },
    title: string,
  }[],
  schedule_title?: string,
  time_set?: boolean,
  date_set?: boolean,
};

export type ProjectSettings = {
  file_tag_group?: number,
  allow_file_tag_create?: boolean,
  project: Project,
  company_type_tag_group?: ?Id,
  company_industry_tag_group?: ?Id,
  company_tag_group?: ?Id,
  contact_log_type_tag_group?: ?Id,
  address_type_tag_group?: ?Id,
  person_title_tag_group?: ?Id,
  people_tag_group?: ?Id,
  contact_log_type_tag_group?: ?Id,
  person_title_is_multiselect?: boolean,
  has_two_letter_country_code?: boolean,
  task_tag_group?: number,
  contract_tag_group?: TagGroup,
  digital_signing_is_active?: boolean,
  contract_pdf_dimension?: string,
  task_object_types?: Id[],
  task_invites?: boolean,
  confirm_task_assignment?: boolean,
  task_filter_availability_default: boolean,
  task_reports: boolean,
  has_am_pm_format?: boolean,
  project_is_active?: boolean,
  has_reoccuring?: boolean,
  timezone?: any,
  max_users?: number,
};

export type EconomySetting = {
  id: Id,
  project: Project,
  vat: number,
  currencies: Currency[],
  primary_currency: Currency,
  categories: EconomyCategory[],
  account_tag_group: TagGroup,
  costcenter_tag_group: TagGroup,
  counterpart_tag_group: TagGroup,
  account_tags: Tag[],
  costcenter_tags: Tag[],
  counterpart_tags: Tag[],
};

export type EconomyCategory = {
  id: Id,
  name?: string,
  class?: string,
  type?: string,
  settlement_class?: string,
};

export type Economy = {
  id: Id,
  tempId?: number | string,
  is_being_edited?: boolean,
  project?: Project,
  name?: string,
  class?: string,
  type?: string,
  settlement_class?: string,
  vat?: number,
  amount?: number,
  cost_carried_by?: string,
  currency?: Currency,
  date?: Schedule,
  account_number_tag?: Tag,
  counter_part_tag?: Tag,
  cost_center_tag?: Tag,
  added_date?: Date,
  field_datas?: Prepared<FieldData>[],
  object_data?: Prepared<ObjectData>,
  object_data_id?: Id,
  approved?: boolean,
  approved_by?: Person,
  approved_schedule?: Schedule,
  rate?: number,
  connected_to: ?Tag,
};

export type EconomyState = {|
  economies: IdMap<Economy>,
  preparedEconomies: Prepared<Economy>[],
  preparedEconomy: Prepared<Economy>,
  isEditingEconomy: boolean,
|};

export type Currency = {
  id: Id,
  cur_code?: string,
  name?: string,
  symbol?: string,
  format?: string,
  active?: boolean,
};

export type ExchangeRate = {
  id?: Id,
  from_currency?: Currency,
  to_currency?: Currency,
  rate?: number,
};

export type User = {
  id?: number,
  account_username?: string,
  account_name?: string,
  hidden?: boolean,
  people?: any,
  person?: ?Person,
  account?: Account,
  start?: Date,
  end?: Date,
  roles?: Array<Role>,
  permissions?: Array<Permission>,
  active?: boolean,
  full_name?: string,
  last_project?: Id,
  phone?: string,
  client?: ?number,
};

export type JobRole = {
  id: number,
  name: string,
};

export type CountedJobRole = {
  id: number,
  job_role: Id,
  count: number,
};

export type ObjectType = {
  active: boolean,
  block_types?: Id[],
  children?: Id[],
  color: string,
  external_block_types?: Id[],
  external_status?: any,
  has_calendar: boolean,
  id: Id,
  is_sub: boolean,
  list_field_types?: Id[],
  menu: boolean,
  object_type_id?: Id,
  order_index: number,
  parent?: Id,
  show_data_counts?: boolean,
  primary_category_conflict_message?: string,
  symbol: string,
  text: string,
  type: string,
  url: string,
  token_user_description?: Translation,
  token_user_description_translation_id?: Id,
  project?: Prepared<Project>,
  publishable_status: any,
  show_contracts?: boolean,
  hide_past?: boolean,
  statuses: ?(any[]),
  default_status: ?any,
  disabled_status: ?any,
  primary_field_id?: ?any,
  should_mail_on_submit?: boolean,
  has_special_list_layout?: boolean,
  show_forms_footer?: boolean,
  primary_tag_group: Id,
  mail_submit_subject_translation?: Translation,
  mail_submit_subject_translation_id?: Id,
  mail_submit_title_translation?: Translation,
  mail_submit_title_translation_id?: Id,
  mail_submit_content_translation?: Translation,
  mail_submit_content_translation_id?: Id,
  has_custom_submit_email?: boolean,
  mail_bulk_subject_translation?: Translation,
  mail_bulk_subject_translation_id?: Id,
  mail_bulk_title_translation?: Translation,
  mail_bulk_title_translation_id?: Id,
  mail_bulk_content_translation?: Translation,
  mail_bulk_content_translation_id?: Id,
  mail_bulk_footer_translation?: Translation,
  mail_bulk_footer_translation_id?: Id,
  has_bulk_email_sending?: boolean,
  language?: Language,
  has_badges?: boolean,
  accepts_submissions?: boolean,
  max_submissions?: ?number,
  badge_types?: BadgeType[],
  badge_object_type?: ObjectType,
};

export type FieldType = {
  active: boolean,
  block_type_id: Id,
  block_type?: Id,
  date_only: boolean,
  explicitly_set_scheduling_info: boolean,
  external_block_type_id?: Id,
  field_type_childs: [],
  field_type_id: Id,
  field_type_parent?: ?Id,
  id: Id,
  input_type: string,
  is_primary_category: boolean,
  is_primary_tag: boolean,
  is_single_tag: boolean,
  is_single_file?: boolean,
  list: boolean,
  object_type?: ?Id,
  order_index: number,
  primary_field: boolean,
  select_options: SelectOption[],
  sub_object_block_type?: ?PartialBlockType,
  text: string,
  help_text: string,
  deleted?: boolean,
  number_min?: number,
  number_max?: number,
  multi_date?: boolean,
  multi_time?: boolean,
  size?: number,
  list_nested?: boolean,
  description?: string,
  required?: boolean,
  placeholder?: string,
  placeholder_translation?: { text: string },
  tag_group?: TagGroup,
  visible?: boolean,
  contract_block_type?: ?Id,
  conditional_field_type?: ?FieldType,
  conditional_select_option?: ?SelectOption,
  has_conditional_logic: boolean,
  hide?: boolean,
  file?: File,
  pendingFiles?: ?(File[]),
  economy_type?: ?string,
  list_size?: number,
  nested_list_size?: number,
  is_publish_date?: boolean,
  max_length?: ?number,
  accordion_id?: ?number,
  accordionFields?: ?(FieldType[]),
  accordion_title?: string,
  has_autocomplete?: boolean,
  parent_object_field_type?: number,
  attach_to_linked_object?: boolean,
  attach_to_linked_object_scheduling?: boolean,
  can_create?: boolean,
  disable_on_self_select?: boolean,
  can_cause_conflict?: boolean,
  has_tag_filter?: boolean,
  read_only?: boolean,
  tag_filter_field_type?: ?FieldType,
  is_being_created?: boolean,
  is_filterable?: boolean,
};

export type Contact = {
  id?: Id,
  tempId?: Id,
  person?: ?Person,
  address?: ?Address,
  company?: ?Company,
  title_tags?: Array<Tag>,
  is_advance?: boolean,
  is_showday?: boolean,
  is_external?: boolean,
  is_being_edited?: boolean,
  is_fake?: boolean,
  primary_field_value?: string,
  object_type_id?: Id,
};

export type FieldData = {
  active: boolean,
  block_data_id: Id,
  contacts: ?(Contact[]),
  people?: Person[],
  companies?: Company[],
  contracts?: any[],
  field_type_id: Id,
  field_type: Id,
  has_aggregate_fields: boolean,
  holds_type: boolean,
  id: Id,
  input_type: string,
  is_aggregate: boolean,
  language: { id: Id },
  list: boolean,
  object_datas: any[],
  order_index: number,
  primary_field: boolean,
  schedule?: Schedule,
  select_options: any[],
  selected_options: any[],
  text: string,
  value: string,
  economies: Economy[],
  invalid?: boolean,
  files?: ?(File[]),
  pendingFiles?: ?(File[]),
  tags?: ?(Tag[]),
  parent_field_value?: any,
  badge_framework?: BadgeFramework,
  connected_field_type?: FieldType,
};

export type BlockType = {
  block_type_id: Id,
  object_type_id: Id,
  is_external: boolean,
  id: Id,
  field_types?: Id[],
  external_field_types?: Id[],
  text: string,
  departments: Id[],
  permissions: Permission[],
  order_index: number,
  active: boolean,
  required: boolean,
  translation_id?: Id,
  object_type?: Prepared<ObjectType>,
  external_object_type?: Prepared<ObjectType>,
  parentBlockTypeId?: Id,
  visible?: boolean,
  is_sorting?: boolean,
  special_type?: ?string,
  is_import_active?: boolean,
  trans_short?: any,
};

export type PartialBlockType = {
  id: Id,
  is_default: boolean,
  type_field?: FieldType,
};

export type BlockData = {
  id: Id,
  block_type_id: Id,
  block_type: Id,
  object_data_id: Id,
  object_data?: ObjectData,
  field_datas: Id[],
  ghosted: boolean,
  done: boolean,
  blockType?: BlockType,
  has_external_access?: boolean,
  fieldDatas?: FieldData[],
  order_index?: number,
  text: string,
  departments: Department[],
  comments?: Comment[],
  permissions: Permission[], // ???
  special_data?: string | Object,
  status?: 'EMPTY' | 'EDITED' | 'DONE' | 'N/A',
};

export type ObjectData = {
  id: Id,
  object_type: Id,
  object_type_id: Id,
  block_datas?: Id[],
  color: string,
  external_field_datas?: Id[],
  files?: Id[],
  primary_category_object_has_been_filtered?: boolean,
  primary_category_objects?: any,
  primary_date_fields?: Id[],
  primary_field?: Id | FieldData,
  status: Id,
  summary_fields?: Id[],
  token_users?: TokenUser[],
  updated_at: string,
  url: string,
  gps?: string,
  added_by: {
    account_name: string,
    account: Account,
    people: any[],
  },
  blockDatas?: BlockData[],
  summaryFields?: FieldData[],
  primaryField?: ?FieldData,
  primary_file?: File,
  primary_field_value: string,
  category_objects?: ObjectData[],
  date_fields?: FieldData[],
  primary_tags?: Id[],
  is_used_by_task: boolean,
  is_primary_category_for_objects: ObjectData[],
  is_published: boolean,
  parent_object_data_ids?: Id[],
  inner_primary_category_objects?: ObjectData[],
  economies: Economy[],
  publish_date_field?: FieldData,
  list_field_datas?: Id[],
  index_field_datas?: Id[],
  select_field_datas?: Id[],
  badges?: Badge[],
};

export type Translation = {
  id?: Id,
  text?: string,
};

export type SelectOption = {
  id: any,
  value?: string,
  order_index?: number,
  deleted?: boolean,
  default?: boolean,
};

export type Department = {
  id: Id,
  text: string,
  translation_id?: Id,
  hidden?: boolean,
};

export type MenuItem = {
  id: Id,
  icon: string,
  title: { text: string },
  parentId: Id,
  object_type_id: Id,
  slug: string,
  order_index: number,
  permissions: Permission[],
  shortcut?: number,
  shortcut_symbol?: string,
  object_type?: ObjectType,
  special_menu?: boolean,
};

export type Notification = {
  id: Id,
  project: Project,
  message: NotificationMessage,
  title: NotificationTitle,
  slug: string,
  seen: ?boolean,
  block_data?: ?BlockData,
  object_data?: ?ObjectData,
  task?: ?Task,
};

export type NotificationMessage = {
  id: Id,
  message: string,
};

export type NotificationTitle = {
  id: Id,
  title: string,
};

export type File = {
  id?: number,
  name?: string,
  url?: string,
  name?: string,
  presigned_url?: string,
  sizes?: any,
  external?: boolean,
  global_on_project?: boolean,
  object_datas?: Array<{ id?: number }>,
  width?: number,
  height?: number,
  size?: number,
  financial?: boolean,
  tags?: Array<Tag>,
  copyright?: ?string,
  restrictions?: ?string,
  alt_text?: ?string,
  user?: Id,
  created_at?: string,
  base64?: any,
  type?: string,
  key?: ?number,
  tempId?: ?Uuid | ?number,
  externally_uploaded?: boolean,
  deletedAt?: string,
  crop_x?: number,
  crop_y?: number,
  crop_width?: number,
  crop_height?: number,
  file_size?: number,
  cropped_sizes?: any,
  project?: Project,
};

export type CmsPostType = {
  id: Id,
  type: string,
  created_at?: any,
  updated_at?: any,
  slug?: string,
  title?: string,
  has_content?: boolean,
  has_images?: boolean,
  has_post_link?: boolean,
  has_url_link?: boolean,
};

export type ReportFilterType =
  | 'DATE_FILTER'
  | 'UNIQUE_LIST_FILTER'
  | 'TIME_FILTER'
  | 'SELECT_SINGLE_FILTER';

export type ReportColumn = {
  id: Id,
  name: string,
  order_index: number,
  column_size: ?number,
  hidden: ?boolean,
  formatter_key: ?string,
  sortable: ?boolean,
  filter_type: ?ReportFilterType,
  skip_empty_row_check: ?boolean,
};

export type ReportType = {
  id: Id,
  name: string,
  columns: ReportColumn[],
  default_sorting_column: ?Id,
  type?: string,
};

export type ReportRow = IdMap<string> & {
  object_id: Id,
  object_type_id: Id,
};

type ReportFilter = {
  name: string,
  id: Id,
  filterType: ReportFilterType,
  formatter?: any,
};

export type UniqueListSelectFilter = ReportFilter & {
  values: string[],
  selected: string[],
};

export type SelectSingleFilter = ReportFilter & {
  values: string[],
  selected: string[],
};

export type DateFilter = ReportFilter & {
  fromDate: ?Date,
  toDate: ?Date,
  allDaysWithRows: Date[],
};

export type TimeFilter = ReportFilter & {
  fromTime: ?Date,
  toTime: ?Date,
};

export type ColumnFilter =
  | UniqueListSelectFilter
  | DateFilter
  | TimeFilter
  | SelectSingleFilter;

export type Report = {
  report_type_id: Id,
  created_at: Date,
  rows: ReportRow[],
};

export type Comment = {
  id: ?Id,
  body: string,
  user: Id,
  created_at: Date,
  files: File[],
  block_data: BlockData,
  task: Task,
};

export type Task = {
  id: Id,
  project: ?Project,
  user: ?User,
  object_datas: ?(any[]),
  assignments: ?(Assignment[]),
  schedule: ?any,
  required_job_roles?: ?(CountedJobRole[]),
  subtasks: ?Subtask,
  person: ?Person,
  files: ?(File[]),
  title: ?string,
  description: ?string,
  note: ?string,
  active: ?boolean,
  added_by: ?User,
  priority: ?string,
  tags: ?(Tag[]),
  status: ?any,
  completed: ?boolean,
  comments: ?(Comment[]),
  invitations?: ?(Invitation[]),
  contacts?: ?(Person[]),
};

export type Invitation = {
  id?: Id,
  user?: Id,
  status?: string,
};

export type Assignment = {
  id?: Id,
  user?: Id,
  is_supervisor?: ?boolean,
  task_id?: Id,
  schedule?: Schedule,
};

/**
 Invitation / Assignment / Supervisor of Task
*/
export type TaskElementUserType = {
  id?: Id,
  user?: ?Id,
  person?: ?Person,
  status?: ?string,
  type: string,
};

export type PreparedTaskBasePropType = {
  preparedTask: Prepared<Task>,
  prepareTask: (Prepared<Task>) => void,
  user?: User,
};

export type Subtask = {
  id: Id,
  text: ?String,
  completed: ?boolean,
};

// REDUCER TYPES

export type ObjectsState = {
  selectedObjects: any[],
  objectsByType: any,
  preparedFields: any,
  preparedBlocks: any,
  preparedObjects: any,
  isCreatingObject: boolean,
  pending: boolean,
  nextPageByType: any,
  paginationReachedBottom: any[],
  objectsAutocompleteResults: any[],
  currentRequest?: any,
  showLoader: boolean,
  currentObject?: any,
  lastUpdatedBlockId?: any,
  latestActivatedBlockId?: any,
  ...Entities,
  objectDatas: IdMap<ObjectData>,
  blockDatas: IdMap<BlockData>,
  fieldDatas: IdMap<FieldData>,
  selectOptions: any,
  statuses: any,
  projects: any,
  departments: IdMap<Department>,
  jobRoles: IdMap<JobRole>,
  preparedJobRoles: IdMap<Prepared<JobRole>>,
  newlyCreatedObjectType: ?number,
  objectWasUpdated: boolean,
  updatedBlockId: ?any,
  lastUpdatedParentBlockId: ?any,
};

export type AuthState = {|
  +pending: boolean,
  +failedLoginAttempt: boolean,
  +showLoader: boolean,
  +termsAccepted: boolean,
  +error?: any,
  loggedIn?: boolean,
  user?: ?User,
  account?: ?Account,
  termsPending?: boolean,
  currentRequest?: ?string,
  clients?: IdMap<Client>,
  projects?: IdMap<Project>,
  departments?: IdMap<Department>,
  preparedDepartments: IdMap<Prepared<Department>>,
  languages?: IdMap<Language>,
  preparedProject?: Prepared<Project>,
  notifications?: Array<Notification>,
  notificationsCount: number,
  menuItems?: IdMap<MenuItem>,
  preparedProject?: Prepared<Project>,
  passwordResetOpen?: boolean,
  freshUser?: boolean,
  isLoggingIn?: boolean,
  loginError?: boolean,
|};

export type CmsPost = {
  id?: Id,
  title?: string,
  content?: ?string,
  created_at: any,
  updated_at: any,
  published_at?: any,
  slug?: string,
  post_type: Id,
  staging?: number,
  list_post_type?: Id,
  api_id?: string,
  post_link?: { id: Id },
  url_link?: string,
  children?: Id[],
  post_settings?: any,
  description?: string,
  files?: Id[],
  active?: boolean,
  has_content?: boolean,
  has_images?: boolean,
  has_post_link?: boolean,
  has_url_link?: boolean,
  order_index?: number,

  parent?: { id: Id },
  menus?: any,
  categories?: any,
  translations?: any,
};

export type CmsUser = {
  id: Id,
  name: string,
  permissions: Array<Permission>,
  is_my_program_active: boolean,
  is_objects_active: boolean,
  cache_cleared_at: string,
};

export type CmsSite = {
  id: Id,
  title: string,
  logotype: ?File,
  users: Array<CmsUser>,
  menus: Array<any>,
  footer: ?CmsPost,
  settings: Array<CmsSetting>,
  post_type_settings: Array<CmsPostTypeSetting>,
};

export type CmsPostTypeSetting = {
  id: String,
  api_id: String,
  post_type: CmsPostType,
  deleted?: boolean,
};

export type CmsSetting = {
  id: any,
  key: string,
  title: string,
  description: ?string,
  value: ?string,
  file: ?any,
  is_file: boolean,
  deleted?: boolean,
  editing?: boolean,
};

export type CmsState = {
  posts: IdMap<CmsPost>,
  postTypes: IdMap<CmsPostType>,
  pending: boolean,
  preparedPosts: Prepared<CmsPost>[],
  preparedMenus: any[],
  postLinks: any[],
  site: ?CmsSite,
  preparedSite: ?Prepared<CmsSite>,
};

export type CmsBlockPropTypes = {
  block: CmsPost,
  files: any,
  postLinks: any,
  posts: IdMap<CmsPost>,
  postTypes: IdMap<CmsPostType>,
  preparedPosts: Prepared<CmsPost>[],
  selectablePostTypes: Array<{ id: number, value: string, type: ?string }>,
  postTypeSettings: Array<CmsPostTypeSetting>,
};

export type ReportsState = {|
  reportTypes: IdMap<ReportType>,
  selectedReportType: ?Id,
  isLoadingReport: boolean,
  report: ?Report,
  subReport?: ?Report,
  columnFilters: ColumnFilter[],
  sortColumnId: ?Id,
  sortDirection: 'ASC' | 'DESC',
  hideEmptyRows: boolean,
|};

export type UnpreparedTagsState = {
  tagAutocompleteResults: any[],
  tag: Prepared<Tag>,
  tagname: ?any,
  tagGroups: IdMap<TagGroup>,
};

export type TagsState = {
  tagAutocompleteResults: any[],
  tag: Prepared<Tag>,
  tagname: ?any,
  tags: IdMap<Tag>,
  tagGroups: IdMap<TagGroup>,
  preparedTags: IdMap<PreparedTag>,
  preparedTagGroups: IdMap<TagGroup>,
};

export type SetupState = {
  blockEditor: PreparedEntities & { isEditingExternal: boolean },
};

export type SignupState = {
  account: Account | any,
  client: Client | any,
  project: Project | any,
};

export type BadgeState = {
  badgeFrameworkId?: Id,
  badgeFramework?: ?BadgeFramework,
  badgeFrameworks?: ?IdMap<BadgeFramework>,
  badgeTypes?: ?IdMap<BadgeType>,
  badgeFrameworkSections?: ?IdMap<BadgeFrameworkSection>,
  isCreatingBadgeFramework?: boolean,
  isUpdatingBadgeFramework?: boolean,
  fetchBadgeFrameworksPending?: boolean,
  badges?: ?IdMap<Badge>,
  isCreatingBadge?: boolean,
  preparedBadge?: Prepared<Badge>,
  isUpdatingBadge?: boolean,
};

export type PreparedPermission = {
  type: string,
  block_type_id?: number,
  object_type_id?: number,
  object_data_id?: number,
  status_id?: number,
  menu_item_id?: number,
  delete?: boolean,
};

export type ManageUsersState = {
  preparedPermissions: PreparedPermission[],
  users: User[],
  roles: Role[],
  departments: Department[],
  permissions: Permission[],
  people: Person[],
  accounts: Account[],
  preparedUser: ?Prepared<User>,
  preparedPerson: ?Prepared<Person>,
  preparedAccount: ?Prepared<Account>,
  preparedRole: ?Prepared<Role>,
  preparedSetting: ?Prepared<ProjectSettings>,
  passwordGenerated: boolean,
  createUserModalIsOpen: boolean,
  createSettingsModalIsOpen: boolean,
  updatingRole: boolean,
  currentRequest: any,
  updateUserPending: boolean,
};

export type FileState = {
  files: IdMap<File>,
};

export type TokenUserState = {
  tokenUser: ?TokenUser,
  tokenUserSubmitPending: boolean,
  fetchingSchedulesOnObjectData: boolean,
  calendarSchedules: Schedule[],
  latestCreatedObjectId: ?string,
  currentTokenUserRequest: ?string,
  isExternallyAccessed: boolean,
};

export type SubscriptionPeriod = {
  id: Id,
  period: string,
  interval: string,
  interval_count: number,
};

export type StripePricingPlan = {
  amount?: number,
  currency?: string,
  id: string,
  period?: string,
  interval?: string,
  interval_count?: number,
  nickname?: string,
  product?: string,
};

export type StripeProductInfo = {
  active: boolean,
  id: string,
  name: string,
};

export type StripeAddress = {
  line1?: string,
  city?: string,
  country?: string,
  postal_code?: string,
  state?: string,
};

export type SubscriptionInfo = {
  id: string,
  status: string,
  plan: StripePricingPlan,
  latest_invoice: string,
};

export type Subscription = {
  id: Id,
  product: StripeProduct,
  project: Project,
  plan?: StripePricingPlan,
  subscription_info?: SubscriptionInfo,
  pricing_plan?: StripePricingPlan,
};

export type Invoice = {
  id: string,
  amount: number,
  created: number,
  currency: string,
  name: string,
  number: string,
  status: string,
  invoice_pdf: string,
};

export type StripeCustomer = {
  id?: string,
  name?: string,
  email?: string,
  address: StripeAddress,
  description?: string,
  phone?: string,
  source?: string,
  sources?: string,
};

export type StripeBillingDetails = {
  id: Id,
  client?: Client,
  customer: StripeCustomer,
};

export type StripeProduct = {
  id: Id,
  title: string,
  description: string,
  type?: string,
  templateProject?: Project,
  pricing_plans?: StripePricingPlan[],
  product_info?: StripeProductInfo,
};

export type StripeState = {
  paymentIntent?: any,
  billingInformation: ?StripeBillingDetails,
  preparedBillingInformation: ?Prepared<StripeBillingDetails>,
  stripeProducts: ?IdMap<StripeProduct>,
  subscriptionPeriods: ?IdMap<SubscriptionPeriod>,
  subscriptions: IdMap<Subscription>,
  preparedPricingPlan: ?Prepared<StripePricingPlan>,
  stripe?: any,
  isEditingBillingInfo: boolean,
  isEditingProduct: boolean,
};

export type ReduxState = {
  authStore: AuthState,
  objectsState: ObjectsState,
  environmentState: any,
  tagsState: TagsState,
  peopleState: any,
  tasksState: any,
  scheduleState: any,
  fileState: FileState,
  commentsState: any,
  cmsState: CmsState,
  tokenUserState: TokenUserState,
  setupState: SetupState,
  signupState: SignupState,
  searchState: any,
  reportsState: ReportsState,
  manageUsersState: ManageUsersState,
  stripeState: StripeState,
  badgeState: BadgeState,
};

export type GetState = () => ReduxState;

export type AxiosError = {
  response?: {
    status: number,
  },
};

export type Entities = {
  objectTypes: IdMap<ObjectType>,
  blockTypes: IdMap<BlockType>,
  fieldTypes: IdMap<FieldType>,
};

export type PreparedEntities = {
  preparedObjectTypes: IdMap<Prepared<ObjectType>>,
  preparedBlockTypes: IdMap<Prepared<BlockType>>,
  preparedFieldTypes: IdMap<Prepared<FieldType>>,
  preparedStatuses: IdMap<Prepared<Status>>,
};

export type GroupByItem = {
  id: number,
  orderIndex: number,
  sortingKey: string,
  text: string,
  width: number,
  order: string,
  ignoreGroupBy?: boolean,
};
export type ContractEdit = {
  tempId?: number | string,
  is_being_edited?: ?boolean,
  contract_data?: ContractTemplate,
};

export type Contract = ContractEdit & {
  id: Id,
  organizer: ContractCompany,
  engager: ContractCompany,
  blocks?: ContractBlock[],
  fields?: ContractField[],
  object_data?: ObjectData,
  contract_pdfs?: ContractPdf[],
};

export type ContractCompany = {
  id?: Id,
  project?: Project,
  company_name?: string,
  street_name?: string,
  company_name?: string,
  zip_code?: string,
  city?: string,
  country?: string,
  organization_number?: string,
  contact_name?: string,
  contact_phone?: string,
  contact_email?: string,
  contact_title?: string,
};

export type ContractBlockType = {
  blockTypes: BlockType[],
  objectType: ObjectType,
};

export type ContractBlock = {
  id?: Id,
  block_type: BlockType,
  order: number,
  title_override?: string,
};

export type ContractEconomyField = {
  text?: string,
  title_override?: string,
};

export type ContractField = {
  id?: Id,
  field_type: FieldType,
  block_type: BlockType,
  title_override?: string,
  order: number,
  economies: ContractEconomyField[],
  is_internal_list?: ?Id,
};

export type ContractTemplate = {
  id?: Id,
  title?: string,
  type?: string,
  language?: Language,
  files?: File[],
  tags?: Tag[],
  header_field?: string,
  intro_field?: string,
  primary_field?: string,
  secondary_field?: string,
  footer_field?: string,
  blocks?: ContractBlock[],
  fields?: ContractField[],
  project?: Project,
};

export type ContractPdfData = {
  organizer?: ContractCompany,
  engager?: ContractCompany,
  language?: Language,
  header_field?: string,
  intro_field?: string,
  primary_field?: string,
  secondary_field?: string,
  footer_field?: string,
  object_primary_field?: string,
  title?: string,
  type?: string,
  data?: any[],
  primary_cat_data?: any[],
  files: File[],
};

export type ContractState = {|
  contracts: IdMap<Contract>,
  contractTemplates: IdMap<ContractTemplate>,
  selectedTemplate?: ContractTemplate,
  isLoadingContracts?: boolean,
  preparedContractTemplate: Prepared<ContractTemplate>,
  preparedContract: Prepared<Contract>,
  latestCreatedContractTemplateId?: ?string,
  tagAutocompleteResults?: Tag[],
  templates?: ContractTemplate[],
  isEditingContractTemplate: boolean,
  contractBlocks?: ContractBlockType[],
  pendingPdfs?: ContractPdf[],
  generatedPdf?: ContractPdf,
  isUploadingPdf?: boolean,
  currentContract?: Prepared<Contract>,
|};

export type ContractTemplateCreateUpdateState = {
  openDeleteNotice: () => void,
  prepareContractTemplate: (?Prepared<ContractTemplate>) => void,
  project: Project,
  languages: Language[],
  tag: Tag,
  tagname: string,
  tagGroups: TagGroup[],
  projectSettings: ProjectSettings,
};

export type ContractPdf = {
  id?: Id,
  tempId?: string | number,
  file?: File,
  signed?: boolean,
  started?: boolean,
  digitally_signed?: boolean,
  status?: string,
};

export type TaskReport = {
  id: Id | Uuid,
  user: User,
  supervisor?: User,
  amount: number,
  type: TaskReportType,
  date: Date,
  description: string,
  editing?: boolean,
  task?: Task,
};

export type TaskReportType = {
  id: Id,
  title: string,
  unit: string,
};

export type ImageRatio = {
  id?: Id,
  width: number,
  height: number,
};

export type BillingDetails = {
  name: string,
  email: string,
  phone?: string,
};

export type Legend = {
  id: Id,
  text: string,
  width: number,
};

export type ImportJob = {
  id: Id,
  created_at?: Date,
  updated_at?: Date,
  created_By?: User,
  job_status?: string,
};

export type BadgeFrameworkSection = {
  id?: Id,
  temp_id?: string | number,
  width: number,
  height: number,
  x_position: number,
  y_position: number,
  background_color: string,
  background_image?: ?File,
  badge_framwork_id?: Id,
  pendingFiles?: ?(File[]),
  type: string,
  name?: string,
  font_family?: string,
  font_size?: number,
  font_bold?: boolean,
  font_italic?: boolean,
  font_underline?: boolean,
  font_color?: string,
  text_align?: string,
};

export type BadgeFramework = {
  id?: Id,
  name?: string,
  width?: number,
  height?: number,
  background_color?: string,
  background_image?: File,
  logo?: File,
  badge_id?: string,
  badge_framework_sections?: BadgeFrameworkSection[],
  pendingFiles?: ?(File[]),
  pendingBackgroundImage?: ?(File[]),
  pendingLogo?: ?(File[]),
};

export type BadgeType = {
  id?: Id,
  name?: string,
  field_types?: FieldType[],
  object_type?: ObjectType,
  badge_framework?: any,
  connected_field_types?: any,
};

export type Badge = {
  Id?: Id,
  file?: File,
  badge_type?: BadgeType,
  object_data?: ObjectData,
  pendingFile?: ?File,
};

export type PreparedBadgeFramework = Prepared<BadgeFramework>;

// UTIL FUNCTIONS FOR IDMAP

export function IdMapValues<T>(map: IdMap<T> | CompleteIdMap<T>): T[] {
  if (!(map instanceof Object)) return [];
  return (Object.values(map): any);
}

export function StringMapValues<T>(
  map: StringMap<T> | CompleteStringMap<T>,
): T[] {
  if (!(map instanceof Object)) return [];
  return (Object.values(map): any);
}

export function IdMapEntries<T>(map: IdMap<T>): [Id, T][] {
  if (!(map instanceof Object)) return [];
  return (Object.entries(map): any);
}

function IdMapKeys(map: IdMap<any>): Id[] {
  if (!(map instanceof Object)) return [];
  return (Object.keys(map): any);
}

export function mapOverValues<T, U>(
  map: IdMap<T>,
  transform: (T) => U,
): IdMap<U> {
  if (!(map instanceof Object)) return {};
  const newIdMap: IdMap<U> = {};
  IdMapKeys(map).forEach((key) => {
    newIdMap[key] = transform((map[key]: any));
  });
  return newIdMap;
}

export function filterOverValues<T>(
  map: IdMap<T>,
  filter: (T) => any,
): IdMap<T> {
  if (!(map instanceof Object)) return {};
  const newIdMap: IdMap<T> = {};
  IdMapKeys(map)
    .filter((key) => filter((map[key]: any)))
    .forEach((key) => {
      newIdMap[key] = (map[key]: any);
    });
  return newIdMap;
}

export function mergeMaps<T>(a: IdMap<T>, b: IdMap<T>): IdMap<T> {
  const merged = {};
  IdMapKeys(a).forEach((id) => {
    merged[id] = { ...a[id], ...b[id] };
  });
  return merged;
}
