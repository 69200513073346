// @flow
import $ from '../../libs/reduxOperations';
import C from '../constants/constants';

import type {
  Account,
  User,
  AxiosError,
  Client,
  Department,
  IdMap,
  Project,
  Language,
  AuthState,
  Prepared,
  ProjectInformation,
  Notification,
} from '../entities/FlowTypes';

export const initialState: AuthState = {
  pending: false,
  failedLoginAttempt: false,
  showLoader: false,
  termsAccepted: false,
  preparedProject: {},
  preparedDepartments: {},
  notifications: [],
  notificationsCount: 0,
  passwordResetOpen: false,
  freshUser: false,
};

type Action = {
  type: string,
  error?: AxiosError,
  email: string,
  user?: User,
  account?: Account,
  data?: any,
  clients?: IdMap<Client>,
  projects?: IdMap<Project>,
  departments?: IdMap<Department>,
  languages?: IdMap<Language>,
  project?: Prepared<Project>,
  department?: Prepared<Department>,
  projectInfo?: Prepared<ProjectInformation>,
  projects?: IdMap<Project>,
  notifications: Array<Notification>,
  notificationsCount: number,
  project?: Prepared<Project>,
  department?: Prepared<Department>,
  projectInfo?: Prepared<ProjectInformation>,
  projects?: IdMap<Project>,
  passwordResetOpen?: boolean,
};

export default function authReducer(
  state: AuthState = initialState,
  action: Action,
): AuthState {
  switch (action.type) {
    case C.LOGIN_START:
      return {
        ...state,
        isLoggingIn: true,
        loginError: false,
      };

    case C.FETCH_MY_USER_FAIL:
    case C.FETCH_ACCOUNT_FAIL:
      return {
        ...state,
        pending: false,
        error: action.error,
        showLoader: false,
      };

    case C.LOGIN_FAIL:
      return {
        ...state,
        pending: false,
        showLoader: false,
        loggedIn: false,
        error: action.error,
        failedLoginAttempt: true,
        isLoggingIn: false,
        loginError: true,
      };

    // case C.VALIDATE_TOKEN_FAIL:
    //   return {
    //     ...state, pending: false, showLoader: false, loggedIn: false, error: action.error,
    //   };

    case C.LOGIN_SUCCESS:
      return {
        ...state,
        pending: false,
        showLoader: false,
        loggedIn: true,
        failedLoginAttempt: false,
        freshUser: false,
        isLoggingIn: false,
        loginError: false,
      };

    case C.CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        freshUser: true,
      };

    case C.FETCH_STRIPE_PRODUCTS_START:
      return {
        ...state,
        freshUser: false,
      };

    case C.SET_EMAIL:
      return { ...state, user: { email: action.email } };

    case C.FETCH_MY_USER_SUCCESS:
      return { ...state, user: action.user, showLoader: false };

    case C.FETCH_ACCOUNT_SUCCESS:
      return { ...state, account: action.account, showLoader: false };

    case C.FETCH_USER_CLIENT_SUCCESS:
      return {
        ...state,
        user: { client: action.data },
        failedLoginAttempt: false,
      };

    case C.FETCH_USER_CLIENT_FAIL:
      return { ...state, user: { client: null } };

    case C.LOGOUT:
      return { ...state, loggedIn: false, user: null };

    case C.SET_PROJECT_FAIL:
    case C.SET_CLIENT_FAIL:
      return { ...state, showLoader: false, pending: false };

    case C.SET_PROJECT_SUCCESS:
      return {
        ...state,
        user: action.user,
        showLoader: false,
        pending: false,
      };

    case C.SET_CLIENT_SUCCESS:
      return {
        ...state,
        account: action.account,
        showLoader: false,
        pending: false,
      };

    case C.FETCH_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.clients,
      };

    case C.FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.projects,
        languages: action.languages,
      };

    case C.FETCH_USER_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departments: action.departments,
      };

    case C.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.notifications,
      };

    case C.CLEAR_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications:
          state.notifications && state.notifications.length
            ? state.notifications.map((n) => ({ ...n, seen: true }))
            : state.notifications,
      };

    case C.FETCH_NOTIFICATIONS_COUNT_SUCCESS:
      return {
        ...state,
        notificationsCount: action.notificationsCount,
      };

    case C.FETCH_USER_FROM_RESET_TOKEN_SUCCESS:
      return {
        ...state,
        user: action.user,
        passwordResetOpen: action.passwordResetOpen,
      };

    case C.FETCH_USER_FROM_RESET_TOKEN_FAIL:
      return {
        ...state,
        passwordResetOpen: false,
      };

    case C.RESET_PASSWORD_START:
      return {
        ...state,
        pending: true,
      };

    case C.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        user: null,
        pending: false,
      };

    case C.RESET_PASSWORD_FAIL:
      return {
        ...state,
        pending: false,
      };
    // $FlowFixMe
    case (action.type.match(/\w*_FAIL\b/) || {}).input:
      if (
        action.error &&
        action.error.response &&
        action.error.response.status === 401
      ) {
        return {
          ...state,
          loggedIn: false,
          user: null,
          account: null,
        };
      }
      return state;

    case C.ACCEPT_TERMS_START:
      return {
        ...state,
        termsPending: true,
        currentRequest: C.ACCEPT_TERMS,
      };

    case C.ACCEPT_TERMS_FAIL:
      return {
        ...state,
        termsPending: false,
        currentRequest: null,
      };

    case C.ACCEPT_TERMS_SUCCESS:
      return {
        ...state,
        termsPending: false,
        account: action.account,
        currentRequest: null,
      };

    case C.TOGGLE_TERMS_ACCEPT:
      return {
        ...state,
        termsAccepted: !state.termsAccepted,
      };

    case C.PREPARE_PROJECT:
      return {
        ...state,
        preparedProject: action.project,
      };

    case C.UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        projects: $.addOrUpdateObjectWithObjects(
          state.projects || {},
          action.projects || {},
        ),
      };

    case C.PREPARE_DEPARTMENT:
      return {
        ...state,
        // $FlowFixMe
        preparedDepartments: $.addObjectToObject(
          state.preparedDepartments,
          action.department,
        ),
      };

    case C.UNPREPARE_DEPARTMENTS:
      return {
        ...state,
        preparedDepartments: {},
      };

    case C.UPDATE_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departments: $.addOrUpdateObjectWithObjects(
          state.departments || {},
          action.departments || {},
        ),
      };

    default:
      return state;
  }
}
