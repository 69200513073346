import C from '../constants/constants';
import $ from '../../libs/reduxOperations';

export const initialState = {
  tasks: {},
  task: {},
  assignments: {},
  invitations: {},
  contacts: {},
  taskPending: false,
  updateTaskPending: false,
  isEditingTask: false,
  isEditingSubtask: false,
  isCreating: false,
  assignMode: null,
  preparedTask: {},
  preparedSubtask: {},
  selectedTasks: [],
  nextPage: 1,
  currentPage: 1,
  paginationReachedBottom: false,
  isEditingTaskReports: false,
  taskReports: {},
  preparedTaskReports: {},
  updateTaskReportsPending: false,
  isDuplicatingTask: false,
  duplicatedTask: null,
};

const didPaginationReachedBottom = (paginationReachedBottom, tasks, reset) => {
  if(reset) return false;
  if(!tasks.length) return true;
  return paginationReachedBottom;
};

export default function taskReducer(state = initialState, action) {
  switch (action.type) {
    case C.ASSIGN_TASKS_SUCCESS:
    case C.FETCH_TASKS_SUCCESS: {
      const tempNextPage = action.nextPage !== undefined && action.nextPage !== null ? action.nextPage : state.nextPage;
      return {
        ...state,
        tasks: $.addOrUpdateObjectWithObjects(state.tasks, action.tasks),
        assignments: $.addOrUpdateObjectWithObjects(state.assignments, action.assignments),
        invitations: $.addOrUpdateObjectWithObjects(state.invitations, action.invitations),
        taskPending: false,
        updateTaskPending: false,
        nextPage: action.reset ? 2 : tempNextPage + 1,
        paginationReachedBottom: didPaginationReachedBottom(state.paginationReachedBottom, action.tasks ? Object.values(action.tasks) : [], action.reset),
      };
    }

    case C.FETCH_TASK_SUCCESS:
      return {
        ...state,
        tasks: $.addOrUpdateObjectWithObjects(state.tasks, action.tasks),
        assignments: $.addOrUpdateObjectWithObjects(state.assignments, action.assignments),
        invitations: $.addOrUpdateObjectWithObjects(state.invitations, action.invitations),
        contacts: $.addOrUpdateObjectWithObjects(state.contacts, action.contacts),
        taskReports: action.taskReports,
        taskPending: false,
        currentPage: action.reset || action.withFilter ? 1 : state.nextPage,
        nextPage: action.reset || action.withFilter ? 1 : state.nextPage + 1,
      };

    case C.FETCH_TASK_SUCCESS_DUPLICATION:
      return {
        ...state,
        duplicatedTask: action.task,
        taskPending: false,
      };

    case C.CREATE_TASK_SUCCESS:
      return {
        ...state,
        tasks: $.addOrUpdateObjectWithObjects(state.tasks, action.tasks),
        assignments: $.addOrUpdateObjectWithObjects(state.assignments, action.assignments),
        invitations: $.addOrUpdateObjectWithObjects(state.invitations, action.invitations),
        contacts: $.addOrUpdateObjectWithObjects(state.contacts, action.contacts),
        isEditingTask: false,
        isEditingSubtask: false,
        updateTaskPending: false,
        isCreating: false,
        task: {},
        preparedTask: {},
        preparedSubtask: {},
        isDuplicatingTask: false,
      };

    case C.UPDATE_TASK_SUCCESS:
      return {
        ...state,
        tasks: $.addOrUpdateObjectWithObjects(state.tasks, action.tasks),
        assignments: $.addOrUpdateObjectWithObjects(state.assignments, action.assignments),
        invitations: $.addOrUpdateObjectWithObjects(state.invitations, action.invitations),
        contacts: $.addOrUpdateObjectWithObjects(state.contacts, action.contacts),
        updateTaskPending: false,
        isEditingTask: false,
        isEditingSubtask: false,
        isCompletingTask: false,
        assignMode: null,
        selectedTasks: [],
        task: {},
        preparedTask: {},
        preparedSubtask: {},
      };

    case C.CREATE_COMMENT_SUCCESS:
      return {
        ...state,
        tasks: $.addOrUpdateObjectWithObjects(state.tasks, action.tasks),
      };

    case C.DELETE_TASK_SUCCESS:
      return {
        ...state,
        updateTaskPending: false,
        tasks: $.removeObjectFromObject(state.tasks, action.task),
        comments: action.comment ? $.removeObjectFromObject(state.comments, action.comment) : state.comments,
      };

    case C.FETCH_TASKS_START:
    case C.FETCH_TASK_START:
    case C.FETCH_TASK_START_DUPLICATION:
      return {
        ...state,
        taskPending: true,
      };

    case C.CREATE_TASK_START:
    case C.UPDATE_TASK_START:
    case C.DELETE_TASK_START:
    case C.ASSIGN_TASKS_START:
      return {
        ...state,
        updateTaskPending: true,
      };

    case C.FETCH_TASKS_FAIL:
    case C.FETCH_TASK_FAIL:
      return {
        ...state,
        taskPending: false,
        error: action.error,
      };

    case C.ASSIGN_TASKS_FAIL:
      return {
        ...state,
        updateTaskPending: false,
      };

    case C.CREATE_TASK_FAIL:
    case C.UPDATE_TASK_FAIL:
    case C.DELETE_TASK_FAIL:
      return {
        ...state,
        updateTaskPending: false,
        isEditingTask: false,
        isEditingSubtask: false,
        isCreating: false,
        task: {},
        preparedTask: {},
        preparedSubtask: {},
        error: action.error,
        isDuplicatingTask: false,
      };

    case C.BEGIN_EDIT_TASK:
      return {
        ...state,
        isEditingTask: true,
        isEditingSubtask: false,
        isCreating: action.isCreating,
        task: action.task,
        preparedTask: {},
        preparedSubtask: {},
      };

    case C.BEGIN_DUPLICATE_TASK:
      return {
        ...state,
        isEditingTask: true,
        isEditingSubtask: false,
        isCreating: action.isDuplicating,
        isDuplicatingTask: action.isDuplicating,
        task: {},
        preparedTask: action.task,
        preparedSubtask: action.newSubTasks,
        duplicatedTask: null,
      };

    case C.ABORT_EDIT_TASK:
      return {
        ...state,
        isEditingTask: false,
        isEditingSubtask: false,
        isCreating: false,
        preparedTask: {},
        preparedSubtask: {},
        task: {},
        duplicatedTask: null,
        isDuplicatingTask: false,
      };

    case C.BEGIN_VIEWING_TASK:
      return {
        ...state,
        isViewingTask: true,
        isCreating: false,
        isDuplicatingTask: false,
        task: action.task,
      };

    case C.ABORT_VIEWING_TASK:
      return {
        ...state,
        isViewingTask: false,
        isEditingSubtask: false,
        isCreating: false,
        isDuplicatingTask: false,
        preparedTask: {},
        preparedSubtask: {},
        task: {},
      };

    case C.BEGIN_COMPLETING_TASK:
      return {
        ...state,
        isCompletingTask: true,
        isCreating: false,
        isDuplicatingTask: false,
        preparedTask: {
          ...action.task,
          completed: true,
        },
      };

    case C.ABORT_COMPLETING_TASK:
      return {
        ...state,
        isCompletingTask: false,
        isEditingSubtask: false,
        isCreating: false,
        isDuplicatingTask: false,
        preparedTask: {},
        preparedSubtask: {},
        task: {},
      };

    case C.PREPARE_TASK:
      return {
        ...state,
        preparedTask: action.task,
      };

    case C.PREPARE_SUBTASK:
      return {
        ...state,
        preparedSubtask: action.subtask ? action.subtask : {},
        isEditingSubtask: action.startEditSubtask ? true : state.isEditingSubtask,
      };

    case C.BEGIN_EDIT_SUBTASK:
      return {
        ...state,
        isEditingSubtask: true,
        preparedSubtask: {},
      };

    case C.CONFIRM_EDIT_SUBTASK:
    case C.ABORT_EDIT_SUBTASK:
      return {
        ...state,
        isEditingSubtask: false,
        preparedSubtask: {},
      };

    case C.CLEAR_SELECT_TASK:
      return {
        ...state,
        selectedTasks: [],
      };

    case C.SELECT_TASK:
      return {
        ...state,
        selectedTasks: $.addToArrayById(state.selectedTasks, action.task),
      };

    case C.DESELECT_TASK:
      return {
        ...state,
        selectedTasks: $.removeFromArrayById(state.selectedTasks, action.task),
      };

    case C.TOGGLE_SELECT_TASK:
      return {
        ...state,
        selectedTasks: $.addOrRemoveFromArrayById(state.selectedTasks, action.task),
      };

    case C.TOGGLE_ASSIGN_MODE:
      return action.assignMode ? {
        ...state,
        assignMode: action.assignMode,
      } : {
        ...state,
        assignMode: action.assignMode,
        selectedTasks: [],
      };

    case C.DELETE_TASK_REPORT_START:
    case C.DELETE_TASK_REPORT_FAIL:
    case C.FETCH_TASK_REPORTS_START:
    case C.FETCH_TASK_REPORTS_FAIL:
      return {
        ...state,
      };

    case C.UPDATE_OR_CREATE_TASK_REPORTS_START:
      return {
        ...state,
        updateTaskReportsPending: true,
      };

    case C.DELETE_TASK_REPORT_SUCCESS:
      return {
        ...state,
        taskReports: $.removeObjectFromObject(state.taskReports, action.taskReport),
      };

    case C.UPDATE_OR_DELETE_TASK_REPORTS_START:
      return {
        ...state,
        updateTaskReportsPending: true,
      };

    case C.UPDATE_OR_DELETE_TASK_REPORTS_FAIL:
      return {
        ...state,
        updateTaskReportsPending: false,
      };

    case C.UPDATE_TASK_REPORT_SUCCESS:
    case C.CREATE_TASK_REPORT_SUCCESS:
      return {
        ...state,
        taskReports: $.addObjectToObject(state.taskReports, action.taskReport),
        preparedTaskReports: $.removeObjectFromObject(state.preparedTaskReports, action.previousReport),
        updateTaskReportsPending: false,
      };

    case C.UPDATE_OR_CREATE_TASK_REPORTS_SUCCESS:
      return {
        ...state,
        preparedTaskReports: action.clear ? {} : state.preparedTaskReports,
        updateTaskReportsPending: false,
        isEditingTaskReports: !!Object.values(state.preparedTaskReports).length,
      };

    case C.PREPARE_TASK_REPORT:
      return {
        ...state,
        preparedTaskReports: $.addObjectToObject(state.preparedTaskReports, action.taskReport),
        isEditingTaskReports: true,
      };

    case C.UNPREPARE_TASK_REPORT:
      return {
        ...state,
        preparedTaskReports: $.removeObjectFromObject(state.preparedTaskReports, action.taskReport),
        isEditingTaskReports: !!$.removeObjectFromObject(state.preparedTaskReports, action.taskReport).length,
      };

    case C.BEGIN_EDIT_TASK_REPORTS:
      return {
        ...state,
        isEditingTaskReports: true,
      };

    case C.ABORT_EDIT_TASK_REPORTS:
      return {
        ...state,
        isEditingTaskReports: false,
        preparedTaskReports: {},
      };


    case C.FETCH_OBJECT_DUPLICATION_SUCCESS:
      return {
        ...state,
        duplicatedTask: action.task,
        pending: false,
        showLoader: false,
      };

    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        preparedTaskReports: {},
        isEditingTaskReports: false,
        taskReports: {},
      };

    default:
      return state;
  }
}
