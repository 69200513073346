// @flow
import React, { Component } from 'react';
import s from './Notice.module.scss';

type PropTypes = {|
  text: any,
  type?: 'warning' | 'error' | 'success',
  closeAction: any => any,
  autoClose?: boolean,
|};

class Notice extends Component<PropTypes> {
  componentDidMount() {
    const { closeAction, autoClose = true } = this.props;
    if (autoClose) setTimeout(() => { closeAction(); }, 10000);
  }

  render() {
    const {
      text,
      type = 'success',
      closeAction,
    } = this.props;
    return (
      <div className={`${s.wrapper} ${s[type]}`}>
        <div className={s.controls}>
          <button className={s.button} onClick={closeAction}>Close</button>
        </div>
        <div className={s.icon}>
          <i className="fa fa-amps" />
        </div>
        <div className={s.text}>
          {text}
        </div>
      </div>
    );
  }
}

export default Notice;
