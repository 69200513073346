import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { history } from '../store/rootStore';
import Loader from '../components/shared/Loader';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

import App from '../containers/App';

import { configureStore } from '../store/rootStore';

if (process.env.NODE_ENV !== 'development') {
  let dsn;

  if (process.env.REACT_APP_SENTRY_DSN) {
    dsn = process.env.REACT_APP_SENTRY_DSN;
  } else {
    if (
      process.env.SENTRY_ENV === 'j2_beta' ||
      process.env.SENTRY_ENV === 'j2_alpha' ||
      process.env.SENTRY_ENV === 'j2_gamma'
    ) {
      dsn =
        'https://630ce9a5a3be4750909d2c156fd9ed74@o1173492.ingest.sentry.io/6269020';
    } else {
      dsn =
        'https://77131dcf512840038fe62ce0d7fe8541@o1173492.ingest.sentry.io/6268698';
    }
  }

  Sentry.init({
    dsn,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
  });
}

export default () => {
  const store = configureStore();
  const persistor = persistStore(store);

  return (
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};
