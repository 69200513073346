/* eslint-disable */

import { compose, createStore, applyMiddleware, combineReducers } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunkMiddleware from "redux-thunk";
import { persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import reducers from "../reducers";
import { initialStates } from "../reducers";
import loggerMiddleware from "../../libs/loggerMiddleware";
import C from "../constants/constants";
import createHistory from "history/createBrowserHistory";

export const history = createHistory();

// const persistConfig = {
//   key: 'root',
//   storage,
//   stateReconciler: autoMergeLevel2,
//   blacklist: ['auth'],
// };

// const persistedReducer = persistReducer(persistConfig, reducers);

export const configureStore = (props, context) => {
  const initialState = {};

  // const { base, location } = context;
  const { authState } = initialStates;

  const composeEnhancers =
    (typeof window !== "undefined" &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
  const appReducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
  });

  const reducer = (state, action) => {
    state = { ...state };

    switch (action.type) {
      case C.SET_CLIENT_START:
      case C.SET_PROJECT_START:
      case C.LOGOUT:
        state = undefined;

      default:
        state = state;
    }
    return appReducer(state, action);
  };

  const rootReducer = reducer; // persistReducer(persistConfig, reducer);

  const composedStore = composeEnhancers(
    process.env.NODE_ENV === "development"
      ? applyMiddleware(
          thunkMiddleware,
          routerMiddleware(history),
          loggerMiddleware
        )
      : applyMiddleware(thunkMiddleware, routerMiddleware(history))
  );

  return composedStore(createStore)(rootReducer, initialState);
};
