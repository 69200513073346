// @flow
import type { SignupState } from 'App/entities/FlowTypes';
import C from '../constants/constants';

export const initialState: SignupState = {
  account: {},
  client: {},
  project: {},
  isCreatingProject: false,
};

export default function reducer(
  state: SignupState = initialState,
  action: any,
) {
  switch (action.type) {
    case C.CREATE_ACCOUNT_START:
      return {
        ...state,
        creatingAccountError: false,
        isCreatingAccount: true,
      };
    case C.CREATE_ACCOUNT_FAIL:
      return {
        ...state,
        creatingAccountError: true,
        isCreatingAccount: false,
      };
    case C.CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        creatingAccountError: false,
        isCreatingAccount: false,
        account: action.account,
      };

    case C.CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        client: action.client,
      };

    case C.CREATE_PROJECT_START:
      return {
        ...state,
        isCreatingProject: true,
      };

    case C.CREATE_PROJECT_FAIL:
      return {
        ...state,
        isCreatingProject: false,
      };

    case C.CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        project: action.project,
        isCreatingProject: false,
      };

    default:
      return state;
  }
}
