import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import Button from './shared/Button';
import s from './DeleteNotice.module.scss';
import bs from './Block.module.scss';

import * as environmentActions from '../actions/environmentActions';

const mapStateToProps = state => ({
  ...state.environmentState,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    ...environmentActions,
  }, dispatch);

class DeleteNotice extends Component {
  constructor(props) {
    super(props);
    this.closeDeleteNotice = this.closeDeleteNotice.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
  }

  closeDeleteNotice() {
    const { closeDeleteNotice } = this.props;
    closeDeleteNotice();
  }

  confirmDelete() {
    const { closeDeleteNotice, deleteCB } = this.props;
    if(deleteCB !== null) deleteCB();
    closeDeleteNotice();
  }

  render() {
    const {
      showDeleteNotice = false,
    } = this.props;
    return (
      <div className={`${s.wrapper} ${s.popup} ${showDeleteNotice ? s.open : null}`}>
        { showDeleteNotice &&
          <div className={s.overlay}>
            <div className={`${s.modal}`}>
              <div className="container">
                <div className={`${s.centerRow} row`}>
                  <div className={`${s.modalContent} ${bs.wrapper}`}>
                    <h5>Confirm delete?</h5>
                    <span className={s.buttonsWrapper}>
                      <span className={s.button}>
                        <Button
                          title="Cancel"
                          type="secondary"
                          onClick={this.closeDeleteNotice}
                        />
                      </span>
                      <span className={s.button}>
                        <Button
                          title="Delete"
                          color="EB5757"
                          onClick={this.confirmDelete}
                        />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteNotice));
