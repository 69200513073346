import { normalize } from 'normalizr';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import moment from 'moment-timezone';
import C from '../constants/constants';
import O from '../constants/objectTypes';
import I from '../constants/inputTypes';
import requestManager from '../../libs/requestManager';
import { filterFields, addTypeObjectToData, hasValue, getDateTimesFromObject } from '../../libs/objectUtils';
import { getIds, getTargetListFieldType, prepareFiles } from './actionUtils';
import { hashString, uuid, richTextToString, isUuid, sanitizeHTML } from '../../libs/utils';
import { YMD, isOverlapping } from '../components/calendar/DateUtils';
import objectData from '../entities/ObjectData';
import blockData from '../entities/BlockData';
import objectType from '../entities/ObjectType';
import $ from '../../libs/reduxOperations';
import { peopleImportHeaders } from '../constants/importHeaders';
import { adaptTag } from '../../libs/adapters';

const flattenObjects = normalizedObject => ({
  objectDatas: normalizedObject.entities.object_data,
  blockDatas: normalizedObject.entities.block_data,
  blockTypes: normalizedObject.entities.block_type,
  fieldDatas: normalizedObject.entities.field_data,
  fieldTypes: normalizedObject.entities.field_type,
  people: normalizedObject.entities.person,
  projects: normalizedObject.entities.project,
  selectOptions: normalizedObject.entities.select_option,
  statuses: normalizedObject.entities.status,
  tags: normalizedObject.entities.tag,
  tagGroups: normalizedObject.entities.tag_group,
  files: normalizedObject.entities.file,
  comments: normalizedObject.entities.comment,
  users: normalizedObject.entities.user,
  currencies: normalizedObject.entities.currency,
  badgeTypes: normalizedObject.entities.badge_type,
  badgeFrameworks: normalizedObject.entities.badge_framework,
  badgeFrameworkSections: normalizedObject.entities.badge_framework_section,
});

export function fetchObjects(props) {
  const {
    type,
    page,
    reset = false,
    categoryTypeId,
    useFilter = true,
    parentId = null,
    isList = false,
    filterOverride = {},
    listType,
    multiOrderBy = [],
    multiOrder = [],
  } = props;
  return (dispatch, getState) => {
    const { pending, nextPageByType, paginationReachedBottom } = getState().objectsState;
    const { filtersObject, filter: initialFilter, pendingRequests = [] } = getState().environmentState;
    let filter = filtersObject[listType] || initialFilter;
    if(filter.selectOptions) {
      let selectedOptions = [];
      Object.values(filter.selectOptions).forEach((options) => {
        selectedOptions = $.addToArray(selectedOptions, Object.values(options));
      });
      filter = { ...filter, selectOptions: selectedOptions };
    }
    filter = { ...filter, multiOrderBy, multiOrder, listType: listType === C.BASE_FIELD ? 1 : 0 };
    const ids = getIds(getState);
    const hashedArgs = hashString(C.FETCH_OBJECTS_START + Object.values(props).toString()); // eslint-disable-line
    if(pending && pendingRequests.find(req => req === hashedArgs)) return;
    if(paginationReachedBottom.find(id => id === type) && !reset) return;
    dispatch({ type: C.FETCH_OBJECTS_START, requestHash: hashedArgs });

    const sendFilter = Object.values(filterOverride) && Object.values(filterOverride).length ? filterOverride : filter;

    (isList ? requestManager.getListObjects(ids.clientId, ids.projectId, type, -1, 100, useFilter ? sendFilter : null, parentId)
      : requestManager.getObjects(ids.clientId, ids.projectId, type, page || nextPageByType[type], 30, useFilter ? sendFilter : null)).then((data) => {
      const nd = normalize(data.data, [objectData]);
      const reduxEntities = flattenObjects(nd);

      dispatch({
        type: C.FETCH_OBJECTS_SUCCESS,
        reset,
        objectType: type,
        requestHash: hashedArgs,
        ...reduxEntities,
      });
      if(categoryTypeId) {
        const newProps = {
          type: categoryTypeId,
          page: -1,
          reset: false,
          categoryTypeId: false,
          useFilter: false,
          listType,
        };
        dispatch(fetchObjects(newProps));
      }
    })
      .catch(error => dispatch({ type: C.FETCH_OBJECTS_FAIL, error }));
  };
}

export function fetchObjectCategories() {
  return (dispatch, getState) => {
    const { id: projectId } = getState().environmentState.project;
    requestManager
      .getObjectCategories(projectId).then((data) => {
        const nd = normalize(data.data, [objectData]);
        const reduxEntities = flattenObjects(nd);

        dispatch({
          type: C.FETCH_OBJECTS_SUCCESS,
          ...reduxEntities,
        });
      })
      .catch(error => dispatch({ type: C.FETCH_OBJECTS_FAIL, error }));
  };
}

export function fetchObjectCounts(type, listType, props = null) {
  let tempMultiOrderBy = [];
  let tempMultiOrder = [];
  if (props) {
    ({
      tempMultiOrderBy = [],
      tempMultiOrder = [],
    } = props);
  }
  return (dispatch, getState) => {
    const { filtersObject, filter: initialFilter } = getState().environmentState;
    let filter = filtersObject[listType] || initialFilter;
    if(filter.selectOptions) {
      let selectedOptions = [];
      Object.values(filter.selectOptions).forEach((options) => {
        selectedOptions = $.addToArray(selectedOptions, Object.values(options));
      });
      filter = { ...filter, selectOptions: selectedOptions };
    }
    filter = { ...filter, multiOrderBy: tempMultiOrderBy, multiOrder: tempMultiOrder, listType: listType === C.BASE_FIELD ? 1 : 0 };
    const ids = getIds(getState);
    dispatch({ type: C.FETCH_OBJECT_COUNTS_START });
    requestManager.getObjectCounts(ids.clientId, ids.projectId, type, filter).then((response) => {
      dispatch({
        type: C.FETCH_OBJECT_COUNTS_SUCCESS,
        objectCounts: response.data,
      });
    });
  };
}

export function fetchObject(id, resolve = null, reject = null, noLoader = false, external = false) {
  return (dispatch, getState) => {
    const { pendingRequests } = getState().environmentState;
    const hashedArgs = hashString(C.FETCH_OBJECT_START + id); // eslint-disable-line
    if (pendingRequests.find(req => req === hashedArgs)) return;
    dispatch({ type: C.FETCH_OBJECT_START, requestHash: hashedArgs });
    if (!noLoader) dispatch({ type: C.SHOW_LOADER });
    const ids = getIds(getState);

    let request;
    if (external) {
      request = requestManager.getTokenApiObject(ids.clientId, ids.projectId, id);
    } else {
      request = requestManager.getObject(ids.clientId, ids.projectId, id);
    }

    request.then((data) => {
      const nd = normalize(data.data, objectData);
      const reduxEntities = flattenObjects(nd);
      dispatch({
        type: C.FETCH_OBJECT_SUCCESS,
        requestHash: hashedArgs,
        ...reduxEntities,
      });
      dispatch({ type: C.HIDE_LOADER });
      if(resolve) resolve();
    })
      .catch((error) => {
        if(reject) reject(error);
        dispatch({ type: C.FETCH_OBJECT_FAIL, error, objectId: id, requestHash: hashedArgs });
        if (!noLoader) dispatch({ type: C.HIDE_LOADER });
      });
  };
}

export function createObjectDirectly(type, block, fields) {
  return (dispatch, getState) => {
    dispatch({ type: C.CREATE_OBJECT_DIRECTLY_START });
    const ids = getIds(getState);
    const uploadFields = filterFields(fields);
    requestManager
      .postObject(ids.clientId, ids.projectId, type, [{ ...block, field_datas: uploadFields }]).then((data) => {
        dispatch({
          type: C.CREATE_OBJECT_DIRECTLY_SUCCESS,
          directlyCreatedObject: data.data,
        });
      })
      .catch(error => dispatch({ type: C.CREATE_OBJECT_DIRECTLY_FAIL, error }));
  };
}

export function updateObjectDirectly(object) {
  return (dispatch, getState) => {
    const ids = getIds(getState);
    dispatch({ type: C.UPDATE_OBJECT_START });
    requestManager
      .putObject(ids.clientId, ids.projectId, object.id, { ...object }).then((data) => {
        const nd = normalize(data.data, objectData);
        dispatch({
          type: C.UPDATE_OBJECT_SUCCESS,
          objectDatas: nd.entities.object_data,
        });
      })
      .catch(error => dispatch({ type: C.UPDATE_OBJECT_FAIL, error }));
  };
}

export function setPrimaryFile(objectId, fileData) {
  return (dispatch, getState) => {
    dispatch({ type: C.SET_PRIMARY_FILE_START });
    const ids = getIds(getState);
    requestManager
      .putObject(ids.clientId, ids.projectId, objectId, { primary_file: { id: fileData.id } }).then((data) => {
        const nd = normalize(data.data, objectData);
        dispatch({
          type: C.SET_PRIMARY_FILE_SUCCESS,
          objectDatas: nd.entities.object_data,
        });
      })
      .catch(error => dispatch({ type: C.SET_PRIMARY_FILE_FAIL, error }));
  };
}

export function createObject(object, block = null, form = false, resolve = null, reject = null, isDuplicatingObject = false) {
  return (dispatch, getState) => {
    dispatch({ type: C.CREATE_OBJECT_START });
    const ids = getIds(getState);
    const preparedBlock = block || Object.values(getState().objectsState.preparedBlocks).find(prepBlock => prepBlock.block_type_id === (object.block_types && object.block_types[0]));
    const preparedFields = Object.values(getState().objectsState.preparedFields).filter(field => field.id && preparedBlock && preparedBlock.field_datas && preparedBlock.field_datas.find(innerField => innerField === field.id));
    const uploadFields = [].concat(filterFields(preparedFields)).map(field => addTypeObjectToData(field, 'field_type'));
    const { id, ...uploadBlock } = preparedBlock;
    requestManager
      .postObject(ids.clientId, ids.projectId, object.object_type_id, [{ ...addTypeObjectToData(uploadBlock, 'block_type'), field_datas: uploadFields }]).then((data) => {
        const nd = normalize(data.data, objectData);
        const reduxEntities = flattenObjects(nd);
        dispatch({
          type: C.CREATE_OBJECT_SUCCESS,
          objectId: data.data.id,
          ...reduxEntities,
          isDuplicatingObject,
        });
        if (form) {
          dispatch({
            type: C.BEGIN_CREATE_OBJECT,
            block: data.data.block_datas[0],
            object: { id: data.data.id, object_type_id: object.object_type_id },
            isDuplicatingObject,
          });
        }
        if(resolve) resolve();
      })
      .catch((error) => {
        if(reject) reject(error);
        dispatch({ type: C.CREATE_OBJECT_FAIL, error });
      });
  };
}

export async function checkForCategoryObjectConflicts(ids, obj) {
  const category = obj.category_objects[0] && obj.category_objects[0].id;
  const startDate = obj.date_fields[0] && obj.date_fields[0].schedule && obj.date_fields[0].schedule.start_time && moment(obj.date_fields[0].schedule.start_time);
  const endDate = obj.date_fields[0] && obj.date_fields[0].schedule && obj.date_fields[0].schedule.end_time && moment(obj.date_fields[0].schedule.end_time);
  const conflicts = await requestManager.getSubObjects(ids.clientId, ids.projectId, obj.object_type, -1, 100, { categories: [category], startDate: YMD(startDate), endDate: YMD(endDate) }).then(data => data.data);
  const timeFilteredConflicts = conflicts.filter(conObj => (conObj.primary_date_fields &&
    conObj.primary_date_fields[0] &&
    conObj.primary_date_fields[0].schedule &&
    (!conObj.primary_date_fields[0].schedule.start_time ||
      (conObj.primary_date_fields[0].schedule.start_time &&
      isOverlapping(moment(conObj.primary_date_fields[0].schedule.start_time), moment(conObj.primary_date_fields[0].schedule.end_time), moment(startDate), moment(endDate))))));
  return timeFilteredConflicts;
}

export function updateObject(skipConflictCheck = false, external = false, duplicateResolve = false, duplicateReject = false) {
  return (dispatch, getState) => {
    const ids = getIds(getState);
    const { preparedObjects, objectTypes } = getState().objectsState;
    const makePromise = (prepObj) => {
      const { id } = prepObj;
      return new Promise((resolve, reject) => {
        dispatch({ type: C.UPDATE_OBJECT_START });
        let preProcessedObject = { ...addTypeObjectToData(preparedObjects[id], 'object_type') };
        // When creating user in a special block, added by is kept. Remove if normal case.
        if(preProcessedObject.added_by && preProcessedObject.added_by.account && preProcessedObject.added_by.account.id) {
          delete preProcessedObject.added_by;
        }
        delete preProcessedObject.object_type;
        if(preProcessedObject.status && !preProcessedObject.status.id) preProcessedObject.status = { id: preProcessedObject.status };
        if (preProcessedObject.primary_field && !preProcessedObject.primary_field.field_type.id) {
          preProcessedObject = { ...preProcessedObject, primary_field: addTypeObjectToData(preProcessedObject.primary_field, 'field_type') };
        }
        preProcessedObject = preProcessedObject.category_objects ? { ...preProcessedObject, category_objects: preProcessedObject.category_objects.map(co => (co.primary_field && !co.primary_field.id ? { ...co, primary_field: { id: co.primary_field } } : co)) } : preProcessedObject;
        preProcessedObject = preProcessedObject.primary_tags ? { ...preProcessedObject, primary_tags: preProcessedObject.primary_tags.map(pt => ({ ...pt, project: { id: ids.projectId } })) } : preProcessedObject;
        preProcessedObject = preProcessedObject.block_datas ? { ...preProcessedObject, block_datas: preProcessedObject.block_datas.map(bd => ({ id: bd.id || bd })) } : preProcessedObject;

        let request;
        if (external) {
          request = requestManager
            .putTokenApiObject(ids.clientId, ids.projectId, id, preProcessedObject);
        } else {
          request = requestManager
            .putObject(ids.clientId, ids.projectId, id, preProcessedObject);
        }
        request.then((data) => {
          const nd = normalize(data.data, objectData);
          const reduxEntities = flattenObjects(nd);
          dispatch({
            type: C.UPDATE_OBJECT_SUCCESS,
            updatedObjectId: data.data.id,
            ...reduxEntities,
          });
          resolve();
        })
          .catch((error) => {
            dispatch({ type: C.UPDATE_OBJECT_FAIL, error });
            reject(error);
            if(duplicateReject) duplicateReject(error);
          });
      });
    };

    const subObjects = Object.values(preparedObjects).filter(prepObj => prepObj.object_type && objectTypes[prepObj.object_type] &&
      objectTypes[prepObj.object_type].type === O.SUBOBJ);
    const mainObjects = Object.values(preparedObjects).filter(prepObj => prepObj.object_type && objectTypes[prepObj.object_type] &&
      (objectTypes[prepObj.object_type].type === O.COREOBJ || objectTypes[prepObj.object_type].type === O.FORMOBJ));
    const allObjects = subObjects.concat(mainObjects);
    const changedCategoryObjects = preparedObjects && Object.values(preparedObjects).filter(prepObj => prepObj.category_objects && prepObj.category_objects.length && prepObj.date_fields && prepObj.date_fields.length);
    const checkForConflicts = !!Object.values(objectTypes).find(typeObject => typeObject.check_for_linked_object_conflict && changedCategoryObjects && changedCategoryObjects.find(catObj => catObj.object_type_id === typeObject.id));

    const submitFunction = () => {
      const promiseSerial = objs =>
        objs.reduce(
          (promise, obj) =>
            promise.then(result => makePromise(obj).then(Array.prototype.concat.bind(result))),
          Promise.resolve([]),
        );
      promiseSerial(allObjects).then(() => {
        dispatch({ type: C.DEACTIVATE_FIELDS_EDIT_MODE });
        dispatch({ type: C.RESET_PRIMARY_CATEGORY_CONFLICTS });
        if(duplicateResolve) duplicateResolve();
      });
    };

    if (checkForConflicts && !skipConflictCheck && changedCategoryObjects && changedCategoryObjects.length) {
      dispatch({ type: C.PRIMARY_CATEGORY_CONFLICT_PENDING });
      changedCategoryObjects.forEach(async (catObj) => {
        const conflicts = await checkForCategoryObjectConflicts(ids, catObj);
        if (conflicts && conflicts.length) {
          dispatch({ type: C.PRIMARY_CATEGORY_CONFLICT_FOUND, conflictingObjects: conflicts });
        } else {
          submitFunction();
        }
      });
    } else {
      submitFunction();
    }
  };
}

export function prepareSubObject(object, block, field) {
  return (dispatch) => {
    const obj = { ...object, id: uuid(), is_list_object: true };
    const innerField = { ...field, id: uuid(), block_type_id: field.sub_object_block_type.id };
    delete innerField.sub_object_block_type;
    const blo = { id: uuid(), block_type: field.sub_object_block_type.id };
    dispatch({ type: C.ADD_SUB_OBJECT, subObject: { ...obj, is_being_edited: true, block_datas: [blo.id] }, subBlock: blo, fieldTypeId: field.field_type_id });
  };
}

export function createSubObject(object, block, field, primaryCategory = false, mainObjectId = null, external = false, resolve = null, reject = null) {
  return (dispatch, getState) => {
    dispatch({ type: C.CREATE_SUB_OBJECT_START });
    const ids = getIds(getState);
    let request;
    if (external) {
      request = requestManager
        .postTokenApiObject(ids.clientId, ids.projectId, object.object_type, block.block_type ? [{ block_type: { id: block.block_type } }] : []);
    } else {
      request = requestManager
        .postObject(ids.clientId, ids.projectId, object.object_type, block.block_type ? [{ block_type: { id: block.block_type } }] : []);
    }
    request.then((data) => {
      const nd = normalize(data.data, objectData);
      const reduxEntities = flattenObjects(nd);
      dispatch({
        type: C.CREATE_SUB_OBJECT_SUCCESS,
        ...reduxEntities,
      });
      if(primaryCategory) {
        dispatch({ type: C.ADD_SUB_OBJECT_PRIMARY, subObject: { ...Object.values(reduxEntities.objectDatas)[0], is_being_edited: true }, mainObjectId });
      } else {
        const subBlock = Object.values(reduxEntities.blockDatas).find(innerBlock => innerBlock.block_type === block.block_type);
        dispatch({ type: C.ADD_SUB_OBJECT, subObject: { ...Object.values(reduxEntities.objectDatas)[0], is_being_edited: true }, subBlock, fieldId: field.id, fieldTypeId: field.field_type_id });
      }
      if(resolve) resolve();
    })
      .catch((error) => {
        if(reject) reject(error);
        dispatch({ type: C.CREATE_SUB_OBJECT_FAIL, error });
      });
  };
}

function maybeCreatePreparedSublistObjects(objectDatas) {
  return (dispatch, getState) => {
    const { preparedBlocks, preparedObjects, preparedFields } = getState().objectsState;
    const ids = getIds(getState);
    return objectDatas.map((obj) => {
      if (!isUuid(obj.id)) return Promise.resolve(obj.id ? { id: obj.id } : obj);
      const object = { ...preparedObjects[obj.id] };
      object.block_datas = object.block_datas.map((bId) => {
        const newObj = { ...preparedBlocks[bId] };
        newObj.block_type = !newObj.block_type.id ? { id: newObj.block_type } : newObj.block_type;
        delete newObj.id;
        newObj.field_datas = newObj.field_datas && newObj.field_datas.map((fId) => {
          const newField = { ...preparedFields[fId] };
          delete newField.id;
          newField.field_type = { id: newField.field_type_id };
          return newField;
        });
        return newObj;
      });
      return new Promise((res, rej) => {
        requestManager
          .postTokenApiObject(ids.clientId, ids.projectId, object.object_type, object.block_datas)
          .then((resp) => {
            res({ id: resp.data.id });
          })
          .catch(error => rej(error));
      });
    });
  };
}

export function upsertBlock(uploadBlock, uploadFields, objectId = null, resolve = null, reject = null, external) {
  const maybeInsertSubObject = (selectedObjects, otherFields, listObjectId, uploadBlockTypeId, resolveInsert, externalInsert, normal = false, scheduling = false) => (dispatch, getState) => {
    const subObjectId = selectedObjects && selectedObjects[0].id;
    const subObject = getState().objectsState.objectDatas[subObjectId];
    // const ids = getIds(getState);
    const primaryLanguage = getState().environmentState.projectSettings.project && getState().environmentState.projectSettings.project.primary_language;

    const submitFunction = (objectDatas, objectTypes, blockDatas, fieldDatas, targetFieldType, completeSubObject) => {
      const targetBlockData = (completeSubObject.block_datas && Object.values(blockDatas).find(block => completeSubObject.block_datas.includes(block.id) && block.block_type === (targetFieldType && targetFieldType.block_type_id)))
        || { block_type: targetFieldType && targetFieldType.block_type_id, is_being_created: true };
      const targetFieldData = (Object.values(fieldDatas) && targetFieldType && targetBlockData && Object.values(fieldDatas).find(fieldData => fieldData.field_type === targetFieldType.id && fieldData.block_data_id === targetBlockData.id))
        || { field_type: targetFieldType && targetFieldType.id, is_being_created: true };
      if (targetFieldType &&
        targetFieldData &&
        targetBlockData &&
        (!targetFieldData.object_datas ||
          (targetFieldData.object_datas &&
          !targetFieldData.object_datas.includes(listObjectId)))) {
        const minifiedListObject = { id: listObjectId };
        const newFieldData = [{ ...targetFieldData, object_datas: ((targetFieldData.object_datas && targetFieldData.object_datas.map(obj => ({ id: obj }))) || []).concat(minifiedListObject), language: { id: (primaryLanguage && primaryLanguage.id) } }];
        const upsertBlockPromise = new Promise((res) => { dispatch(upsertBlock(addTypeObjectToData(targetBlockData, 'block_type'), newFieldData.map(fieldData => addTypeObjectToData(fieldData, 'field_type')), subObjectId, res)); });
        upsertBlockPromise.then(() => {
          dispatch(fetchObject(listObjectId, null, null, true, externalInsert));
          resolveInsert();
        });
      }
    };

    if (subObject && Object.values(subObject)) {
      const getSubObjectPromise = new Promise((res) => { dispatch(fetchObject(subObject.id, res, null, true)); });
      getSubObjectPromise.then(async () => {
        const { objectDatas, objectTypes, blockDatas, blockTypes, fieldTypes, fieldDatas } = getState().objectsState;
        const completeSubObject = objectDatas[subObjectId];
        const targetBlockTypes = Object.values(blockTypes).filter(blockType => completeSubObject.object_type && objectTypes[completeSubObject.object_type] && objectTypes[completeSubObject.object_type].block_types && objectTypes[completeSubObject.object_type].block_types.includes(blockType.id));
        const targetFieldTypes = Object.values(fieldTypes).filter(fieldType => targetBlockTypes && targetBlockTypes.find(blockType => blockType.id === fieldType.block_type_id));
        const targetFieldType = getTargetListFieldType(targetFieldTypes, uploadBlockTypeId, false);
        const schedulingListFieldType = otherFields.find(field => field.input_type === I.SCHEDULING) ? targetFieldTypes.find(fieldType => fieldType.input_type === 'LISTSCHEDULING') : null;
        if(targetFieldType && normal) submitFunction(objectDatas, objectTypes, blockDatas, fieldDatas, targetFieldType, completeSubObject);
        if(schedulingListFieldType && scheduling) submitFunction(objectDatas, objectTypes, blockDatas, fieldDatas, schedulingListFieldType, completeSubObject);
      });
    }
  };

  return async (dispatch, getState) => {
    const ids = getIds(getState);
    dispatch({ type: C.UPDATE_OR_CREATE_BLOCK_START, blockId: uploadBlock.block_type_id, objectTypeId: uploadBlock.object_type_id });
    const uniqueFields = Array.from(new Set(uploadFields.map(f => f.field_type_id))).map(f => uploadFields.find(uf => uf.field_type_id === f)); // Validate field type uniqueness
    const objectSelectFields = uniqueFields.filter(field => field.input_type === I.SELECTOBJECT);

    const newBlock = {
      block_type: {
        id: uploadBlock.block_type.id,
      },
      ghosted: uploadBlock.ghosted,
      done: uploadBlock.done,
      status: uploadBlock.status || "EDITED", // sets status to edited when saving
      comments: uploadBlock.comments,
    };

    const adaptPerson = (p) => {
      if(!(p && p.project)) return null;
      const adaptedPerson = {};
      if(p.id) adaptedPerson.id = p.id;
      if(p.companies) adaptedPerson.companies = p.companies && p.companies.filter(c => c.id || c.project);
      if(p.city) adaptedPerson.city = p.city;
      if(p.company) adaptedPerson.company = p.company;
      if(p.company_name) adaptedPerson.company_name = p.company_name;
      if(p.email) adaptedPerson.email = p.email;
      if(p.full_name) adaptedPerson.full_name = p.full_name;
      if(p.organization_number) adaptedPerson.organization_number = p.organization_number;
      if(p.phone) adaptedPerson.phone = p.phone;
      if(p.street_name) adaptedPerson.street_name = p.street_name;
      if(p.title) adaptedPerson.title = p.title;
      if(p.zip_code) adaptedPerson.zip_code = p.zip_code;
      if(p.country) adaptedPerson.country = p.country;
      if(p.state) adaptedPerson.state = p.state;
      if(p.birth_date) adaptedPerson.birth_date = p.birth_date;
      if(p.project && p.project.id) adaptedPerson.project = p.project;
      if(p.tags && p.tags.length) adaptedPerson.tags = p.tags.filter(tag => Object.values(tag).length);
      if(p.global_notes) {
        if (p.global_notes instanceof EditorState) {
          let val;
          const currentContent = p.global_notes.getCurrentContent();
          if (!currentContent.hasText()) val = '';
          else val = sanitizeHTML(stateToHTML(currentContent));
          adaptedPerson.global_notes = val;
        }
      }
      if(!Object.values(adaptedPerson).length) return null;
      return adaptedPerson;
    };

    const adaptCompany = (c) => {
      if(!(c && c.project)) return null;
      const adaptedCompany = {};
      if(c && c.id) adaptedCompany.id = c.id;
      if(c && c.name) adaptedCompany.name = c.name;
      if(c.project && c.project.id) adaptedCompany.project = c.project;
      return adaptedCompany;
    };

    const adaptAddress = (a) => {
      if (a && a.id) {
        return {
          id: a.id,
        };
      } return null;
    };

    const adaptContact = (c) => {
      const contact = {
        id: c.id,
        is_advance: c.is_advance,
        is_external: c.is_external,
        is_showday: c.is_showday,
        person: adaptPerson(c.person),
        company: adaptCompany(c.company),
        address: c.project ? adaptAddress(c.address) : null,
      };
      if(!contact.person) contact.person = {};
      if (c.title_tags) contact.title_tags = c.title_tags.map(t => adaptTag(t, ids.projectId));
      return contact;
    };

    const adaptSchedule = (s) => {
      const schedule = {
        start_time: s.start_time,
        project: { id: ids.projectId },
      };
      if (s.end_time) schedule.end_time = s.end_time;
      if (s.id) schedule.id = s.id;
      if (s.departments) schedule.departments = s.departments;
      if (s.tag) schedule.tag = adaptTag(s.tag, ids.projectId);
      if (s.is_external !== undefined && s.is_external !== null) schedule.is_external = s.is_external;
      if (s.date_set !== undefined && s.date_set !== null) schedule.date_set = s.date_set;
      if (s.time_set !== undefined && s.time_set !== null) schedule.time_set = s.time_set;
      return schedule;
    };

    const adaptContractData = async (contractData) => {
      const adaptedContractData = {};
      const fileIds = await prepareFiles(ids, contractData.files);
      if (contractData.id) adaptedContractData.id = contractData.id;
      if (contractData.title) adaptedContractData.title = contractData.title;
      if (contractData.type) adaptedContractData.type = contractData.type;
      if (contractData.project) adaptedContractData.project = contractData.project;
      if (contractData.language && contractData.language.id) adaptedContractData.language = { id: contractData.language && contractData.language.id };
      if (contractData.tags && contractData.tags.length) adaptedContractData.tags = contractData.tags.map(t => adaptTag(t, ids.projectId));
      if (contractData.header_field) adaptedContractData.header_field = contractData.header_field instanceof EditorState ? richTextToString(contractData.header_field) : (contractData.header_field || '');
      if (contractData.intro_field) adaptedContractData.intro_field = contractData.intro_field instanceof EditorState ? richTextToString(contractData.intro_field) : (contractData.intro_field || '');
      if (contractData.primary_field) adaptedContractData.primary_field = contractData.primary_field instanceof EditorState ? richTextToString(contractData.primary_field) : (contractData.primary_field || '');
      if (contractData.secondary_field) adaptedContractData.secondary_field = contractData.secondary_field instanceof EditorState ? richTextToString(contractData.secondary_field) : (contractData.secondary_field || '');
      if (contractData.footer_field) adaptedContractData.footer_field = contractData.footer_field instanceof EditorState ? richTextToString(contractData.footer_field) : (contractData.footer_field || '');
      if (contractData.files) adaptedContractData.files = fileIds.map(fid => ({ id: fid }));
      return adaptedContractData;
    };

    const adaptContractPdfs = async (contractPdf) => {
      const adaptedContractPdf = {};
      const fileIds = await prepareFiles(ids, [contractPdf.file]);
      if (contractPdf.id) adaptedContractPdf.id = contractPdf.id;
      if (contractPdf.file) adaptedContractPdf.file = fileIds && fileIds.length ? { id: fileIds[0] } : null;
      if (contractPdf.signed) adaptedContractPdf.status = 'SIGNED';
      return adaptedContractPdf;
    };

    const adaptContractField = (contractField) => {
      const adaptedContractField = {};
      if(contractField.id) adaptedContractField.id = contractField.id;
      if(contractField.field_type) adaptedContractField.field_type = { id: contractField.field_type.id };
      if(contractField.block_type) adaptedContractField.block_type = { id: contractField.block_type.id };
      if(contractField.title_override) adaptedContractField.title_override = contractField.title_override;
      if(contractField.order) adaptedContractField.order = contractField.order;
      if(contractField.is_internal_list) adaptedContractField.is_internal_list = contractField.is_internal_list;
      if(contractField.economies && contractField.economies.length) {
        adaptedContractField.economies = contractField.economies.map(eco =>
          `${eco.text}${eco.title_override ? `|${eco.title_override}` : ''}`).join(',');
      }
      return adaptedContractField;
    };

    const adaptContract = async (contract) => {
      const adaptedContract = {};
      if (contract.id) adaptedContract.id = contract.id;
      if (contract.engager) adaptedContract.engager = contract.engager;
      if (contract.organizer) adaptedContract.organizer = contract.organizer;
      if (contract.blocks) adaptedContract.blocks = contract.blocks.map(block => ({ ...block, block_type: { id: block.block_type.id } }));
      if (contract.fields && contract.fields.length) adaptedContract.fields = contract.fields.map(contractField => adaptContractField(contractField));
      if (contract.contract_data) adaptedContract.contract_data = await adaptContractData(contract.contract_data);
      if (contract.contract_pdfs) adaptedContract.contract_pdfs = await Promise.all(contract.contract_pdfs.map(pdf => adaptContractPdfs(pdf)));
      return adaptedContract;
    };

    const adaptEconomy = (economy) => {
      const adaptedEconomy = {
        project: { id: ids.projectId },
      };
      if (economy.id) adaptedEconomy.id = economy.id;
      if (economy.name) adaptedEconomy.name = economy.name;
      if (economy.class) adaptedEconomy.class = economy.class;
      if (economy.type) adaptedEconomy.type = economy.type;
      if (economy.settlement_class) adaptedEconomy.settlement_class = economy.settlement_class;
      if (economy.vat) adaptedEconomy.vat = economy.vat;
      if (economy.amount) adaptedEconomy.amount = economy.amount;
      if (economy.cost_carried_by) adaptedEconomy.cost_carried_by = economy.cost_carried_by;
      if (economy.currency) adaptedEconomy.currency = { id: economy.currency.id };
      if (economy.date) adaptedEconomy.date = adaptSchedule(economy.date);
      if (economy.account_number_tag) adaptedEconomy.account_number_tag = adaptTag(economy.account_number_tag, ids.projectId);
      if (economy.counter_part_tag) adaptedEconomy.counter_part_tag = adaptTag(economy.counter_part_tag, ids.projectId);
      if (economy.cost_center_tag) adaptedEconomy.cost_center_tag = adaptTag(economy.cost_center_tag, ids.projectId);
      if (economy.approved) adaptedEconomy.approved = economy.approved;
      if (economy.approved_by) adaptedEconomy.approved_by = economy.approved_by;
      if (economy.approved_schedule) adaptedEconomy.approved_schedule = adaptSchedule(economy.approved_schedule);
      if (economy.object_data_id) adaptedEconomy.object_data = { id: economy.object_data_id };
      if (economy.rate) adaptedEconomy.rate = economy.rate;
      if (economy.connected_to) adaptedEconomy.connected_to = adaptTag(economy.connected_to, ids.projectId);
      return adaptedEconomy;
    };

    const adaptField = async (f) => {
      const field = {
        value: f.value,
      };
      if(f.id) field.id = f.id;
      if(f.field_type && f.field_type.id) field.field_type = { id: f.field_type.id };
      if(f.language && f.language.id) field.language = { id: f.language && f.language.id };
      if(f.contacts) {
        field.contacts = f.contacts.map(c => adaptContact(c));
        field.contacts = field.contacts.filter(c => !!c);
      }
      if(f.contracts) field.contracts = f.contracts.length ? await Promise.all(f.contracts.map(c => adaptContract(c))) : [];
      if(f.economies && f.economies.length) field.economies = f.economies.map(e => adaptEconomy(e));
      if(f.object_datas && f.object_datas.length) field.object_datas = await Promise.all(dispatch(maybeCreatePreparedSublistObjects(f.object_datas)));
      if(f.tags && f.tags.length) field.tags = f.tags.map(t => adaptTag(t, ids.projectId));
      if(f.schedule) field.schedule = adaptSchedule(f.schedule);
      if(f.currency) field.currency = { id: f.currency.id };
      if(f.selected_options && f.selected_options.length) field.selected_options = f.selected_options.map(o => ({ id: o.id }));
      if(f.people && f.people.length) {
        field.people = f.people.map(p => adaptPerson(p));
        field.people = field.people.filter(p => !!p);
      }
      if(f.companies && f.companies.length && f.companies.filter(comp => !(comp === undefined || comp === null)).length &&
        !!(f.can_create || f.companies.find(comp => comp && !!comp.id))) field.companies = f.companies.map(c => adaptCompany(c));
      if(f.pendingFiles && f.pendingFiles.length) field.files = (await prepareFiles(ids, f.pendingFiles, external)).map(fid => ({ id: fid }));
      if(f.connected_field_type && f.connected_field_type.id) field.connected_field_type = { id: f.connected_field_type.id };
      if(f.badge_framework && f.badge_framework.id) field.badge_framework = { id: f.badge_framework.id };
      return field;
    };

    const preProcessedFields = uniqueFields.map((f) => {
      let val = f.value;
      // Should not be needed now. Will keep it just in case.
      if (f.input_type === 'RICHTEXT' && f.value instanceof EditorState) {
        const currentContent = f.value.getCurrentContent();
        if (!currentContent.hasText()) val = '';
        else val = richTextToString(currentContent);
      }
      return { ...f, value: val };
    });
    if(uploadFields) newBlock.field_datas = (await Promise.all(preProcessedFields.map(f => adaptField(f)))).filter(f => f.field_type);
    if(uploadBlock.id) newBlock.id = uploadBlock.id;
    if(uploadBlock.special_data) newBlock.special_data = JSON.stringify(uploadBlock.special_data);
    if(objectSelectFields && objectSelectFields.length) {
      await objectSelectFields.forEach((objectSelectField) => {
        if(objectSelectField.attach_to_linked_object || objectSelectField.attach_to_linked_object_scheduling) {
          dispatch(maybeInsertSubObject(
            objectSelectField.object_datas,
            uniqueFields,
            uploadBlock.object_data_id || objectId,
            uploadBlock.block_type_id,
            resolve,
            external,
            !!objectSelectField.attach_to_linked_object,
            !!objectSelectField.attach_to_linked_object_scheduling,
          )); // eslint-disable-line
        }
      });
    }

    if(uploadBlock.is_being_created || typeof newBlock.id === 'string') {
      const { id, ...filteredBlock } = newBlock;
      if(objectId) filteredBlock.object_data = { id: objectId };
      if(!filteredBlock.ghosted && !filteredBlock.done && !filteredBlock.comments && !filteredBlock.status && (!filteredBlock.special_data &&
        (!filteredBlock.field_datas || filteredBlock.field_datas.length === 0))) {
        if(reject) reject(new Error('Invalid data'));
        return;
      }
      let request;
      if (external) {
        request = requestManager.postTokenUserBlock(ids.clientId, ids.projectId, { ...filteredBlock });
      } else {
        request = requestManager.postBlock(ids.clientId, ids.projectId, { ...filteredBlock });
      }
      request.then((data) => {
        const nd = normalize(data.data, blockData);
        const reduxEntities = flattenObjects(nd);
        dispatch({
          type: C.CREATE_BLOCK_SUCCESS,
          ...reduxEntities,
          updatedBlockId: data.data.id,
        });
        if(resolve) resolve();
        // dispatch(checkIfSubObjectInsert(id, fields, data.data));
      })
        .catch((error) => {
          if(reject) reject(error);
          dispatch({ type: C.CREATE_BLOCK_FAIL, error });
        });
    } else {
      delete newBlock.comments;

      let request;
      if (external) {
        request = requestManager
          .putTokenApiBlock(
            ids.clientId,
            ids.projectId,
            uploadBlock.id,
            newBlock,
          );
      } else {
        request = requestManager
          .putBlock(
            ids.clientId,
            ids.projectId,
            uploadBlock.id,
            newBlock,
          );
      }
      request.then((data) => {
        const nd = normalize(data.data, blockData);
        const reduxEntities = flattenObjects(nd);
        dispatch({
          type: C.UPDATE_BLOCK_SUCCESS,
          ...reduxEntities,
          updatedBlockId: data.data.id,
        });
        if(resolve) resolve();
        // dispatch(checkIfSubObjectInsert(id, fields, data.data));
      })
        .catch((error) => {
          if(reject) reject(error);
          dispatch({ type: C.UPDATE_BLOCK_FAIL, error });
        });
    }
  };
}

export function updateOrCreateBlock(objectId = null, external = false, isDeleting = false, resolve = null, reject = null) {
  return async (dispatch, getState) => {
    const { preparedBlocks, preparedFields } = getState().objectsState;
    // throwing in a prepared block in a sublist, the block will be created later with maybecreatesublistobject
    // when updating a block, you still wont have a obejct_type_id but you will have an id on your block.
    const promises = Object.values(preparedBlocks).filter(b => (!!b.object_type_id) || !isUuid(b.id)).map((prepBlock) => {
      const prepFields = Object.values(preparedFields).filter(field => field.id && prepBlock.field_datas && prepBlock.field_datas.find(innerField => innerField === field.id));
      const preparedBlock = preparedBlocks[prepBlock.id];
      const uploadBlock = addTypeObjectToData(preparedBlock, 'block_type');
      const uploadFields = [].concat(filterFields(prepFields, isDeleting))
        .map(field => addTypeObjectToData(field, 'field_type'))
        .map(field => (field.object_datas ? { ...field, object_datas: field.object_datas.map(obj => (!obj.id ? { id: obj } : obj)) } : field));
      dispatch({ type: C.UPDATE_OR_CREATE_BLOCK });
      return new Promise((res, rej) => { dispatch(upsertBlock(uploadBlock, uploadFields, objectId, res, rej, external)); });
    });
    if (promises && promises.length) {
      Promise.all(promises).then(() => {
        const { primaryCategoryConflictExists, primaryCategoryConflictPending } = getState().objectsState;
        if(!primaryCategoryConflictExists && !primaryCategoryConflictPending) dispatch({ type: C.DEACTIVATE_FIELDS_EDIT_MODE });
        if (resolve) resolve();
      })
        .catch(error => reject && reject(error));
    }
  };
}

export function createGlobalSchedule(preparedSchedule) {
  return (dispatch, getState) => {
    const objects = preparedSchedule.object_datas;
    const { tag } = preparedSchedule;
    dispatch({ type: C.CREATE_SCHEDULE_START });
    objects.forEach((objData) => {
      if (objData && tag) {
        const getSubObjectPromise = new Promise((resolve) => { dispatch(fetchObject(objData.id, resolve, null, true)); });
        getSubObjectPromise.then(() => {
          const { objectDatas, objectTypes, blockDatas, blockTypes, fieldTypes, fieldDatas } = getState().objectsState;
          const completeSubObject = objectDatas[objData.id];
          const targetBlockTypes = Object.values(blockTypes).filter(blockType => completeSubObject.object_type && objectTypes[completeSubObject.object_type] && objectTypes[completeSubObject.object_type].block_types && objectTypes[completeSubObject.object_type].block_types.includes(blockType.id));
          const targetFieldTypes = Object.values(fieldTypes).filter(fieldType => targetBlockTypes && targetBlockTypes.find(blockType => blockType.id === fieldType.block_type_id));
          const targetListFieldType =
            targetFieldTypes.find(fieldType => fieldType.sub_object_block_type && targetBlockTypes.find(targetBlockType => targetBlockType.id === fieldType.sub_object_block_type)
              && Object.values(fieldTypes).find(subFieldType => blockTypes[fieldType.sub_object_block_type.id].field_types.includes(subFieldType.id) && subFieldType.default_tag && subFieldType.default_tag.id === tag.id)) ||
            targetFieldTypes.find(fieldType => fieldType.input_type === 'LISTSCHEDULING');
          const targetListBlockData = (completeSubObject.block_datas && Object.values(blockDatas).find(block => completeSubObject.block_datas.includes(block.id) && block.block_type === (targetListFieldType && targetListFieldType.block_type_id)))
            || { object_type_id: (targetListFieldType && targetListFieldType.object_type && targetListFieldType.object_type.id), block_type: targetListFieldType && targetListFieldType.block_type_id, is_being_created: true };
          const targetListFieldData = (Object.values(fieldDatas) && Object.values(fieldDatas).find(fieldData => fieldData.field_type === (targetListFieldType && targetListFieldType.id) && fieldData.block_data_id === targetListBlockData.id))
            || { field_type: targetListFieldType && targetListFieldType.id, is_being_created: true };
          if (targetListFieldType && targetListFieldData) {
            const minifiedListObject = {
              object_type: {
                id: targetListFieldType.object_type,
              },
              block_datas: [].concat({
                block_type: {
                  id: targetListFieldType.sub_object_block_type.block_type_id,
                },
                field_datas: [].concat({
                  field_type: {
                    id: (targetListFieldType.sub_object_block_type.type_field && targetListFieldType.sub_object_block_type.type_field.field_type_id)
                    || Object.values(fieldTypes).find(fieldType => fieldType.block_type_id === targetListFieldType.sub_object_block_type.id && fieldType.input_type === 'SCHEDULING').id,
                  },
                  schedule: {
                    ...preparedSchedule,
                    object_datas: [].concat(
                      preparedSchedule.object_datas.map(od => ({ id: od.id })),
                    ),
                  },
                }),
              }),
            };
            const newFieldData = [{ ...targetListFieldData, object_datas: ((targetListFieldData.object_datas && targetListFieldData.object_datas.map(obj => ({ id: obj }))) || []).concat(minifiedListObject) }];
            dispatch(upsertBlock(addTypeObjectToData(targetListBlockData, 'block_type'), newFieldData.map(fieldData => addTypeObjectToData(fieldData, 'field_type')), objData.id));
          }
        });
      }
    });
  };
}

export function deleteObject(object) {
  return (dispatch, getState) => {
    dispatch({ type: C.DELETE_OBJECT_START });
    const ids = getIds(getState);
    const objectId = object.id;
    requestManager
      .deleteObject(ids.clientId, ids.projectId, objectId).then(() => {
        dispatch({
          type: C.DELETE_OBJECT_SUCCESS,
          objectId,
        });
      })
      .catch(error => dispatch({ type: C.DELETE_OBJECT_FAIL, error }));
  };
}

export function prepareField(field, blockId) {
  return (dispatch) => {
    dispatch({ type: C.PREPARE_FIELD, field, blockId });
  };
}

export function prepareBlock(blockId, blockExtra) {
  return (dispatch) => {
    dispatch({ type: C.PREPARE_BLOCK, blockId, blockExtra });
  };
}

export function addSubObject(subObject, block, fieldId) {
  return (dispatch) => {
    dispatch({ type: C.ADD_SUB_OBJECT, subObject, block, fieldId });
  };
}

export function beginCreateObject(block, object) {
  return (dispatch) => {
    dispatch(createObject(object, block, true));
  };
}

export function beginDuplicatingObject(block, object, resolve = null, reject = null) {
  return (dispatch) => {
    dispatch(createObject(object, block, true, resolve, reject, true));
  };
}

export function closeCreateForm() {
  return (dispatch) => {
    dispatch({ type: C.CLOSE_CREATE_FORM });
  };
}

export function abortCreateObject() {
  return (dispatch, getState) => {
    dispatch({ type: C.ABORT_CREATE_OBJECT });
    const latestCreatedObject = getState().objectsState.objectDatas[getState().objectsState.latestCreatedObjectId];
    dispatch(deleteObject(latestCreatedObject));
  };
}

export function selectObject(object) {
  return (dispatch) => {
    dispatch({ type: C.SELECT_OBJECT, object });
  };
}


export function deselectObject(object) {
  return (dispatch) => {
    dispatch({ type: C.DESELECT_OBJECT, object });
  };
}


export function toggleSelectObject(objectId) {
  return (dispatch) => {
    dispatch({ type: C.TOGGLE_SELECT_OBJECT, objectId });
  };
}

export function activateFieldsEditMode(block, blockId = null, objectId = null, prepareFieldCallBack = null, resetLatestCreatedObject = true) {
  return (dispatch) => {
    const newBlock = { ...block };
    if (!blockId && block && !block.id) {
      newBlock.id = uuid();
      newBlock.is_being_created = true;
    }
    dispatch({ type: C.ACTIVATE_FIELDS_EDIT_MODE, block: newBlock, blockId: (newBlock && newBlock.id) || blockId, objectId, resetLatestCreatedObject });
    if (((newBlock && newBlock.id) || blockId) && prepareFieldCallBack) { prepareFieldCallBack(newBlock.id || blockId); }
  };
}

export function deactivateFieldsEditMode(block) {
  return (dispatch, getState) => {
    const { preparedObjects = {} } = getState().objectsState;
    Object.values(preparedObjects).forEach((obj) => {
      if (obj.id && obj.is_being_created) dispatch(deleteObject(obj));
    });
    dispatch({ type: C.DEACTIVATE_FIELDS_EDIT_MODE, block });
  };
}

export function increaseCurrentPage() {
  return (dispatch) => {
    dispatch({ type: C.INCREASE_CURRENT_OBJECT_PAGE });
  };
}

export function objectDidUpdate() {
  return (dispatch) => {
    dispatch({ type: C.BASE_OBJECT_DID_UPDATE });
  };
}

export function autocompleteObjects(query, objectTypeId) {
  return (dispatch, getState) => {
    dispatch({ type: C.AUTOCOMPLETE_OBJECTS_START, primaryFieldValue: query });
    const ids = getIds(getState);
    return (requestManager
      .autocompleteObjects(ids.clientId, ids.projectId, query, objectTypeId)
      .then(data =>
        dispatch({
          type: C.AUTOCOMPLETE_OBJECTS_SUCCESS,
          objectsAutocompleteResults: data.data,
        }))
      .catch(error => dispatch({ type: C.AUTOCOMPLETE_OBJECTS_FAIL, error }))
    );
  };
}

export function setStatus(id, object, status) {
  return (dispatch, getState) => {
    const objectDatas = {};
    objectDatas[id] = { ...object, status: status.id };
    dispatch({ type: C.SET_OBJECT_STATUS_START, objectDatas });
    // dispatch({ type: C.SET_OBJECT_STATUS_START, object: { ...object, status } });
    const ids = getIds(getState);
    requestManager
      .setObjectStatus(ids.clientId, ids.projectId, id, status.id).then((data) => {
        const nd = normalize(data.data, objectData);
        dispatch({
          type: C.SET_OBJECT_STATUS_SUCCESS,
          objectDatas: nd.entities.object_data,
          updatedObjectId: id,
        });
      })
      .catch(error => dispatch({ type: C.SET_OBJECT_STATUS_FAIL, error }));
  };
}

export function setCurrentObject(id) {
  return (dispatch) => {
    dispatch({ type: C.SET_CURRENT_OBJECT, id });
  };
}

export function unsetCurrentObject() {
  return (dispatch) => {
    dispatch({ type: C.UNSET_CURRENT_OBJECT });
  };
}

export function fetchObjectsLocal(objType) {
  return (dispatch, getState) => {
    const { pending, paginationReachedBottom } = getState().objectsState;
    if (pending) return;
    if (paginationReachedBottom.find(id => id === objType)) return;
    dispatch({ type: C.FETCH_OBJECTS_LOCAL, objectType: objType });
  };
}

export function prepareObject(object) {
  return (dispatch) => {
    dispatch({ type: C.PREPARE_OBJECT, object });
  };
}
export function prepareObjectDuplicate(object, duplicateDateTimeChanged = false, times = null) {
  return (dispatch, getState) => {
    const { initalTimes, duplicateTimeDiff } = getState().objectsState;
    let newInitalTime;
    let newDuplicateTimeDiff = duplicateTimeDiff;
    if(!times) {
      newInitalTime = initalTimes;
    } else if (times && initalTimes) {
      const startTimeDiff = times.startTime && initalTimes.startTime ? times.startTime.diff(initalTimes.startTime) : duplicateTimeDiff.startTime;
      const endTimeDiff = times.endTime && initalTimes.endTime ? times.endTime.diff(initalTimes.endTime) : duplicateTimeDiff.endTime;
      newDuplicateTimeDiff = { startTime: startTimeDiff, endTime: endTimeDiff };
      newInitalTime = initalTimes;
    } else if(!duplicateDateTimeChanged) {
      newInitalTime = times;
    }
    dispatch({ type: C.PREPARE_OBJECT_DUPLICATE, object, duplicateDateTimeChanged, initalTimes: newInitalTime, duplicateTimeDiff: newDuplicateTimeDiff });
  };
}

export function toggleExternal(id) {
  return (dispatch, getState) => {
    if(id && !isUuid(id)) {
      dispatch({ type: C.TOGGLE_EXTERNAL_START });
      const ids = getIds(getState);

      requestManager
        .toggleBlockDataTokenUser(ids.clientId, ids.projectId, id).then((data) => {
          const nd = normalize(data.data, blockData);
          const reduxEntities = flattenObjects(nd);
          dispatch({
            type: C.TOGGLE_EXTERNAL_SUCCESS,
            ...reduxEntities,
            updatedBlockId: data.data.id,
          });
        })
        .catch((error) => {
          dispatch({ type: C.TOGGLE_EXTERNAL_FAIL, error });
        });
    }
  };
}

export function setPublished(id, object, published) {
  return (dispatch, getState) => {
    const objectDatas = {};
    objectDatas[id] = { ...object, is_published: published };
    dispatch({ type: C.SET_OBJECT_PUBLISHED_START, objectDatas });
    const ids = getIds(getState);
    requestManager
      .setObjectPublished(ids.clientId, ids.projectId, id, published).then((data) => {
        const nd = normalize(data.data, objectData);
        dispatch({
          type: C.SET_OBJECT_PUBLISHED_SUCCESS,
          objectDatas: nd.entities.object_data,
        });
      })
      .catch(error => dispatch({ type: C.SET_OBJECT_PUBLISHED_FAIL, error }));
  };
}

export function fetchObjectTypes() {
  return (dispatch, getState) => {
    dispatch({ type: C.FETCH_OBJECT_TYPES_START });
    dispatch({ type: C.SHOW_LOADER });
    const ids = getIds(getState);
    requestManager
      .getObjectTypes(ids.clientId, ids.projectId).then((data) => {
        const nd = normalize(data.data, [objectType]);
        dispatch({
          type: C.FETCH_OBJECT_TYPES_SUCCESS,
          objectTypes: nd.entities.object_type,
          fieldTypes: nd.entities.field_type,
          blockTypes: nd.entities.block_type,
          externalBlockTypes: nd.entities.external_block_type,
          departments: nd.entities.department,
          statuses: nd.entities.status,
          tags: nd.entities.tag,
          tagGroups: nd.entities.tag_group,
          selectOptions: nd.entities.select_option,
          badgeTypes: nd.entities.badge_type,
          badgeFrameworks: nd.entities.badge_framework,
          badgeFrameworkSections: nd.entities.badge_framework_section,
        });
        dispatch({ type: C.HIDE_LOADER });

        // dispatch({
        //   type: C.FETCH_PROJECT_SUCCESS,
        //   projects: Object.values(nd.entities.project),
        // });
      })
      .catch((error) => {
        dispatch({ type: C.FETCH_OBJECT_TYPES_FAIL, error });
        dispatch({ type: C.HIDE_LOADER });
      });
  };
}

export function raisePrimaryCategoryConflicts(overrideConflictFunction, conflictingObjects = [], conflictingObjectType = null) {
  return (dispatch) => {
    dispatch({ type: C.PRIMARY_CATEGORY_CONFLICT_FOUND, conflictingObjects, overrideConflictFunction, conflictingObjectType });
  };
}

export function resetPrimaryCategoryConflicts() {
  return (dispatch) => {
    dispatch({ type: C.RESET_PRIMARY_CATEGORY_CONFLICTS });
  };
}

function innerPrepareField(field, invalid, dispatch, getState, language, object, blockType) {
  const { preparedFields, preparedBlocks } = getState().objectsState;
  const preparedField = Object.values(preparedFields).find(f => f.field_type_id === field.field_type_id);
  // const prepBlockData = Object.values(preparedBlocks).find(b => (field.sub_object_block_type ? b.block_type === field.sub_object_block_type.id : b.block_type_id === field.block_type_id));
  const prepBlockData = Object.values(preparedBlocks).find(b => b.block_type_id === field.block_type_id);
  if (!prepBlockData) {
    dispatch(activateFieldsEditMode({ ...blockType, id: null }, null, object.id, (newBlockId) => {
      dispatch(prepareField({ ...field, ...preparedField, invalid, language }, newBlockId));
    }));
  } else {
    dispatch(prepareField({ ...field, ...preparedField, invalid, language }, prepBlockData.id));
  }
}

export function validateDataSubmit(objectTypeId, externalBlocksOnly = true) {
  return (dispatch, getState) => new Promise((resolve) => {
    const { preparedFields, blockTypes, fieldTypes, preparedObjects } = getState().objectsState;
    const primaryLanguage = getState().environmentState.project && getState().environmentState.project.primary_language;
    const requiredFieldTypes = Object.values(blockTypes)
      .filter(b => (externalBlocksOnly ? b.is_external : true) && b.object_type_id === objectTypeId)
      .flatMap(b => b.field_types.map(id => fieldTypes[id])).filter(f => f.required);
    const constainsInvalidValues = [];
    Object.values(requiredFieldTypes).forEach((field) => {
      const blockType = blockTypes[field.block_type_id];
      const object = Object.values(preparedObjects).find(o => o.object_type === blockType.object_type_id);
      const preparedField = Object.values(preparedFields).find(f => f.field_type_id === field.id);
      const preparePrimaryField = object && object.primary_field && object.primary_field.field_type.id === field.id;
      const invalid = (!preparedField || (preparedField && !hasValue(preparedField))) && !preparePrimaryField;
      if(!preparePrimaryField) innerPrepareField({ ...field, id: null }, invalid, dispatch, getState, { id: primaryLanguage.id }, object, blockType);
      if (invalid) constainsInvalidValues.push(field);
    });
    if (constainsInvalidValues.length) {
      dispatch({ type: C.VALIDATE_DATA_FAIL, error: new Error('You have to fill in all the required fields!') });
    } else {
      resolve();
    }
  });
}

export function generateContractPdf(pdfData, contractId) {
  return (dispatch) => {
    try {
      dispatch({ type: C.GENERATE_CONTRACT_PDF_START });
      requestManager.generateContractPdf(pdfData, contractId).then(data => dispatch({ type: C.GENERATE_CONTRACT_PDF_SUCCESS, generatedPdf: data.data, contractId }));
    } catch (error) {
      dispatch({ type: C.GENERATE_CONTRACT_PDF_FAIL, error, message: 'Could not create contract pdf.' });
    }
  };
}

export function startContractDocumentSigning(contractId, contractPdfId) {
  return (dispatch) => {
    try {
      dispatch({ type: C.START_CONTRACT_DOCUMENT_SIGNING_START });
      requestManager.startContractDocumentSigning(contractId, contractPdfId).then(data =>
        dispatch({ type: C.START_CONTRACT_DOCUMENT_SIGNING_SUCCESS, contractPdf: data.data, contractId }));
    } catch (error) {
      dispatch({ type: C.START_CONTRACT_DOCUMENT_SIGNING_FAIL, error, message: 'Could not start contract document signing.' });
    }
  };
}

export function exportFormsList(object, filter, listField = null, listType) {
  return(dispatch) => {
    try {
      dispatch({ type: C.EXPORT_FORMS_START });
      const newFilter = { ...filter, listType: listType === C.BASE_FIELD ? 1 : 0 };
      newFilter.selectOptions = Object.values(newFilter.selectOptions)
        && Object.values(newFilter.selectOptions).reduce((acc, opt) => acc.concat(opt), []);
      requestManager.exportFormsList(object.text, object.id, newFilter, listField && listField.id).then(async (data) => {
        let firstRow = data && data.data && data.data.rows && Object.values(data.data.rows) && Object.values(data.data.rows)[0];
        firstRow = firstRow instanceof Array ? firstRow : Object.values(firstRow);
        const extraIncrements = [];
        firstRow = Object.values(firstRow).map((item, i) => {
          let newItem = item;
          if(firstRow.find((it, k) => it === item && i !== k)) {
            if(!extraIncrements[item]) extraIncrements[item] = 1;
            else extraIncrements[item] += 1;
            newItem = `${item} ${extraIncrements[item]}`;
          }
          return newItem;
        });
        const wscols = [];
        if(firstRow && firstRow.length) {
          firstRow.forEach((column, i) => {
            wscols[i] = { wch: column.length };
          });
        }
        const rows = data && data.data && data.data.rows && data.data.rows.filter((row, i) => i !== 0).map((row) => {
          const newRow = {};
          firstRow.forEach((c, i) => { newRow[c] = row[i]; });
          return newRow;
        });
        // Adaptive column width depending on number of characters.
        rows.forEach((row) => {
          Object.values(row).forEach((column, i) => {
            let columnChars = column && column.length;
            let columnValue = column;
            if(typeof column !== 'string') {
              columnValue = column && column.toString();
              columnChars = columnValue && columnValue.length;
            }
            if(columnChars && columnChars + 1 > wscols[i].wch) wscols[i].wch = columnChars + 1;
          });
        });
        const XLSX = await import('xlsx');
        const ws = rows && XLSX.utils.json_to_sheet(rows);
        const wb = XLSX.utils.book_new();
        ws['!cols'] = wscols;
        XLSX.utils.book_append_sheet(wb, ws, data.data.title.substring(0, 30));
        XLSX.writeFile(wb, `${data.data.title.trim()}.xlsx`);
        dispatch({ type: C.EXPORT_FORMS_SUCCESS, formsExported: data.data });
      });
    } catch (error) {
      dispatch({ type: C.EXPORT_FORMS_FAIL, error });
    }
  };
}

/* Fetch the object selected for duplication as well as all related blockdatas and fielddatas- */

export function getObjectForDuplication(id, resolve = null, reject = null) {
  return (dispatch, getState) => {
    const hashedArgs = hashString(C.FETCH_OBJECT_DUPLICATION_START + id); // eslint-disable-line
    dispatch({ type: C.FETCH_OBJECT_DUPLICATION_START, requestHash: hashedArgs });
    dispatch({ type: C.SHOW_LOADER });
    const ids = getIds(getState);

    const request = requestManager.getObject(ids.clientId, ids.projectId, id);

    request.then((data) => {
      const nd = normalize(data.data, objectData);

      dispatch({
        type: C.FETCH_OBJECT_DUPLICATION_SUCCESS,
        requestHash: hashedArgs,
        object: nd,
      });
      if(resolve) resolve();
    })
      .catch((error) => {
        if(reject) reject(error);
        dispatch({ type: C.FETCH_OBJECT_DUPLICATION_FAIL, error, objectId: id, requestHash: hashedArgs });
        dispatch({ type: C.HIDE_LOADER });
      });
  };
}

const duplicateData = (dispatch, entities, data, object, block) => {
  const newEntities = entities;
  const newObject = object;
  newObject.block_datas = [{ id: block.id }];
  dispatch(prepareObject(newObject));
  const deleteBlocks = Object.values(newEntities.block_data).filter(bd => bd.block_type_id === block.block_type_id);
  if(deleteBlocks) deleteBlocks.forEach(deleteBlock => delete newEntities.block_data[deleteBlock.id]);
  const newData = { result: data.result, entities: newEntities };
  dispatch({ type: C.UPDATE_DUPLICATION_OBJECT, object: newData });
  dispatch({ type: C.DUPLICATE_OBJECT_READY });
  dispatch({ type: C.HIDE_LOADER });
};

/* This function copies all data directly relating to the object, all showtimes and field datas.
  If there is only one showtime this time will be saved to allow the user to shift all other schedules with the difference
  between the old and the new showtime. If there is more than one showtime a notice will be shown to the user.
*/

/* All ids of field datas, block datas, etc needs to be removed to create new items with the same information. */
export function duplicateObject(data) {
  return (dispatch, getState) => {
    dispatch({ type: C.DUPLICATE_OBJECT_START });
    const { preparedObjects } = getState().objectsState;
    const { entities } = data;

    const newEntities = {
      object_data: {},
      field_data: entities.field_data,
      block_data: entities.block_data,
      file: entities.file,
      tag: entities.tag,
    };

    const duplicatedObject = data.result && entities.object_data[data.result];
    newEntities.object_data[data.result] = duplicatedObject;
    duplicatedObject.id = preparedObjects && Object.values(preparedObjects)[0].id;
    const newObject = {
      id: duplicatedObject.id,
      object_type_id: duplicatedObject.object_type_id,
      object_type: duplicatedObject.object_type_id,
    };
    const primaryField = duplicatedObject && entities.field_data[duplicatedObject.primary_field];
    delete primaryField.id;
    newObject.primary_field = { ...primaryField, value: `${primaryField.value} - copy` };

    const subObjects = duplicatedObject.primary_category_objects && duplicatedObject.primary_category_objects.length &&
      duplicatedObject.primary_category_objects.map(dPco => entities.object_data[dPco]);

    let showDuplicateTimeNoitce = true;

    if(!!subObjects && subObjects.length) {
      /* Loop through each showtime to duplicate the data for each. */
      subObjects.forEach((subObject, index) => {
        const createSubObjectPromise = new Promise((resolve, reject) => {
          dispatch(createSubObject(
            { object_type: subObjects && subObjects.length && subObjects[index].object_type_id },
            {},
            {},
            true,
            newObject && newObject.id,
            false,
            resolve,
            reject,
          ));
        });
        createSubObjectPromise.then(() => {
          const { preparedObjects: newPreparedObjects, preparedBlocks } = getState().objectsState;
          const block = Object.values(preparedBlocks)[0];
          const blockTypeId = block.block_type_id;
          const dupBlock = Object.values(entities.block_data).find(bd => bd.block_type_id === blockTypeId);
          const fieldDatas = dupBlock ? dupBlock.field_datas.map(fd => entities.field_data[fd]) : [];
          fieldDatas.forEach((fd) => {
            const newFd = fd;
            delete newFd.id;
            if(newFd.schedule) delete newFd.schedule.id;
            dispatch(prepareField(newFd, block.id));
          });

          const tempSubObject = Object.values(newPreparedObjects)[index + 1];
          const newSubObject = {
            id: tempSubObject.id,
            object_type: tempSubObject.object_type,
            object_type_id: tempSubObject.object_type_id,
          };

          const categoryObjects = subObject.primary_category_objects.map(pco => entities.object_data[pco]);
          const dateFields = subObject.primary_date_fields.map(pdf => entities.field_data[pdf]);
          newSubObject.category_objects = categoryObjects && categoryObjects.length ?
            categoryObjects.map(catOb => ({
              id: catOb.id,
              object_type_id: catOb.object_type_id,
              primary_field: catOb.primary_field,
            }))
            : [];

          let initalTimes = null;

          newSubObject.date_fields = dateFields && dateFields.length ?
            dateFields.map((dateField) => {
              const { schedule } = dateField;
              delete schedule.id;
              const startTime = schedule.start_time && moment(schedule.start_time);
              const endTime = schedule.end_time && moment(schedule.end_time);

              if(subObjects.length === 1) {
                initalTimes = { startTime, endTime };
                showDuplicateTimeNoitce = false;
              }
              if(schedule.tag) {
                delete schedule.tag.project;
                delete schedule.tag.created_at;
              }
              return {
                field_type: { id: dateField.field_type },
                schedule: {
                  start_time: startTime,
                  end_time: endTime,
                  date_set: true,
                  time_set: true,
                  departments: schedule.departments,
                  project: { id: schedule.project.id },
                  tag: schedule.tag,
                },
              };
            })
            : [];

          dispatch(prepareObjectDuplicate(newSubObject, false, initalTimes));

          block.fieldDatas = fieldDatas.map((fd) => {
            const newFd = fd;
            delete newFd.id;
            return newFd;
          });

          if(showDuplicateTimeNoitce) dispatch({ type: C.SHOW_DUPLICATE_TIME_NOTICE });

          duplicateData(dispatch, newEntities, data, newObject, block);
        })
          .catch(error => dispatch({ type: C.DUPLICATE_OBJECT_FAIL, error }));
      });
    } else {
      const { preparedBlocks } = getState().objectsState;
      const block = Object.values(preparedBlocks)[0];
      const blockTypeId = block.block_type_id;
      const dupBlock = Object.values(entities.block_data).find(bd => bd.block_type_id === blockTypeId);
      const fieldDatas = dupBlock ? dupBlock.field_datas.map(fd => entities.field_data[fd]) : [];

      fieldDatas.forEach((fd) => {
        const newFd = fd;
        delete newFd.id;
        if(newFd.schedule) delete newFd.schedule.id;
        dispatch(prepareField(newFd, block.id));
      });

      block.fieldDatas = fieldDatas.map((fd) => {
        const newFd = fd;
        delete newFd.id;
        return newFd;
      });

      duplicateData(dispatch, newEntities, data, newObject, block);
    }
  };
}

export function updateObjectDuplicate(object) {
  return (dispatch, getState) => {
    const ids = getIds(getState);
    dispatch({ type: C.UPDATE_OBJECT_DUPLICATE_START, blockId: (object.block_datas && object.block_datas.length && object.block_datas[0].block_type_id) });
    requestManager
      .putObjectDuplicate(ids.clientId, ids.projectId, object.id, { block_datas: object.block_datas, files: object.files, primary_tags: object.primary_tags }).then((data) => {
        const nd = normalize(data.data, objectData);
        const reduxEntities = flattenObjects(nd);
        const economies = nd.entities.object_data && nd.result && nd.entities.object_data[nd.result] && nd.entities.object_data[nd.result].economies;
        dispatch({
          type: C.UPDATE_OBJECT_DUPLICATE_SUCCESS,
          updatedObjectId: data.data.id,
          ...reduxEntities,
          economies,
        });
      })
      .catch(error => dispatch({ type: C.UPDATE_OBJECT_FAIL, error }));
  };
}

/* Helpfunction to remove ids of schedules and economies as well as applying the showtime diff if needed. */

const duplicateFieldScheduleAndEconomies = (fieldData, project, duplicateDateTimeChanged, duplicateTimeDiff, objectId) => {
  const newFieldData = fieldData;
  if(newFieldData.schedule) {
    delete newFieldData.schedule.id;
    if (newFieldData.schedule.object_datas && newFieldData.schedule.object_datas.length) {
      newFieldData.schedule.object_datas = newFieldData.schedule.object_datas.map(schOb => ({ id: schOb }));
    }
    newFieldData.schedule.project = { id: project.id };
    if(duplicateDateTimeChanged) {
      if(newFieldData.schedule.end_time && duplicateTimeDiff.endTime) newFieldData.schedule.end_time = moment(newFieldData.schedule.end_time).add(duplicateTimeDiff.endTime, 'ms');
      else if(newFieldData.schedule.end_time && duplicateTimeDiff.startTime) newFieldData.schedule.end_time = moment(newFieldData.schedule.end_time).add(duplicateTimeDiff.startTime, 'ms');
      if(newFieldData.schedule.start_time && duplicateTimeDiff.startTime) newFieldData.schedule.start_time = moment(newFieldData.schedule.start_time).add(duplicateTimeDiff.startTime, 'ms');
    }
  }
  if(newFieldData.economies) {
    newFieldData.economies = newFieldData.economies.map((eco) => {
      const newEco = eco;
      delete newEco.id;
      newEco.object_data = { id: objectId };
      delete newEco.object_data_id;
      newEco.project = { id: project.id };
      return newEco;
    });
  }
  return newFieldData;
};

/* In parameter is a list of which blocks in the object to duplicate. This loops through all block datas all the way down the rabbit hole.
  On the way it removes ids and put the data in the appropiate location.
  If the object only has one showtime and the user has changed it all other schedules will be altered with the same differece.
  All files related to the object will also be linked to the new object.
*/

export function finishDuplication(selectedBlockList = []) {
  return(dispatch, getState) => {
    const { duplicatedObject, latestCreatedObjectId, objectDatas, fieldDatas, blockDatas, duplicateDateTimeChanged, duplicateTimeDiff } = getState().objectsState;
    const { project } = getState().environmentState;
    const { entities } = duplicatedObject;
    const dupData = Object.values(entities.object_data)[0];
    const newObjectData = {
      id: latestCreatedObjectId,
    };
    let entitiesblockDatas = entities.object_data && entities.block_data &&
      Object.values(entities.block_data).filter(bd => dupData.block_datas.includes(bd.id));

    entitiesblockDatas = entitiesblockDatas.filter(entBd => selectedBlockList.find(sbl => sbl.id === entBd.block_type)).map((bd) => {
      const newBd = bd;
      if(bd.field_datas && bd.field_datas.length && entities.field_data) {
        newBd.field_datas = bd.field_datas.map(fieldData => Object.values(entities.field_data).find(dfd => dfd.id === fieldData));
        newBd.field_datas = newBd.field_datas
          .filter(fieldData => fieldData.input_type !== 'LISTCONTRACTS')
          .map((fieldData) => {
            let newFieldData = fieldData;
            delete newFieldData.id;
            delete newFieldData.block_data_id;
            newFieldData.field_type = { id: fieldData.field_type };

            newFieldData.contacts = fieldData.contacts.map((contact) => {
              const newContact = contact;
              delete newContact.id;
              return newContact;
            });

            newFieldData = duplicateFieldScheduleAndEconomies(newFieldData, project, duplicateDateTimeChanged, duplicateTimeDiff, latestCreatedObjectId);

            if(fieldData.object_datas) {
              newFieldData.object_datas = fieldData.object_datas.map((fieldObjectData) => {
                const newFieldObjectData = objectDatas[fieldObjectData];
                delete newFieldObjectData.parent_object_data_ids;
                delete newFieldObjectData.object_type_id;
                delete newFieldObjectData.files;
                newFieldObjectData.object_type = { id: newFieldObjectData.object_type };

                if(!newFieldObjectData.primary_field) {
                  delete newFieldObjectData.primary_field;
                  delete newFieldObjectData.id;
                  newFieldObjectData.block_datas = newFieldObjectData.block_datas.filter(block => blockDatas[block]).map(block => ({ block_type: { id: blockDatas[block].block_type }, field_datas: [] }));
                } else {
                  newFieldObjectData.primary_field = { id: newFieldObjectData.primary_field };
                  if(newFieldObjectData.block_datas && newFieldObjectData.block_datas.length) {
                    newFieldObjectData.block_datas = newFieldObjectData.block_datas.map(block => ({ id: block }));
                  }
                }

                if(newFieldObjectData.external_field_datas) {
                  newFieldObjectData.external_field_datas
                    .filter(ffd => fieldDatas[ffd].input_type !== 'LISTCONTRACTS')
                    .forEach((ffd) => {
                      let newFfd = fieldDatas[ffd];
                      delete newFfd.id;
                      newFfd.object_datas = newFfd.object_datas.map(ffdod => ({ id: ffdod }));
                      newFfd.field_type = { id: newFfd.field_type };
                      newFfd = duplicateFieldScheduleAndEconomies(newFfd, project, duplicateDateTimeChanged, duplicateTimeDiff, latestCreatedObjectId);
                      const blockType = newFfd.block_data_id && blockDatas[newFfd.block_data_id] && blockDatas[newFfd.block_data_id].block_type_id;
                      delete newFfd.block_data_id;
                      delete newFfd.field_type_id;
                      const blockIndex = newFieldObjectData.block_datas && newFieldObjectData.block_datas.length &&
                        newFieldObjectData.block_datas.findIndex(bl => bl.block_type && bl.block_type.id === blockType);

                      if (blockIndex !== undefined && blockIndex !== null && newFieldObjectData.block_datas[blockIndex]) {
                        if(!newFieldObjectData.block_datas[blockIndex].field_datas) {
                          newFieldObjectData.block_datas[blockIndex].field_datas = [];
                        }
                        newFieldObjectData.block_datas[blockIndex].field_datas = newFieldObjectData.block_datas[blockIndex].field_datas.concat(newFfd);
                      }
                    });
                }
                delete newFieldObjectData.external_field_datas;
                return newFieldObjectData;
              });
            }
            return newFieldData;
          });
      }
      newBd.block_type = { id: bd.block_type };
      if(!newBd.field_datas.length) delete newBd.field_datas;
      delete newBd.id;
      delete newBd.comments;
      delete newBd.ghosted;
      delete newBd.done;
      delete newBd.has_external_access;
      return newBd;
    });

    newObjectData.block_datas = entitiesblockDatas;

    newObjectData.files = []; // newFiles;
    if(selectedBlockList.find(sbl => sbl.isPrimaryTag)) {
      newObjectData.primary_tags = dupData.primary_tags && dupData.primary_tags.length && entities.tag ?
        Object.values(entities.tag).filter(tag => dupData.primary_tags.includes(tag.id)).map(tag => ({ ...tag, project: { id: tag.project } }))
        : [];
    } else newObjectData.primary_tags = [];

    dispatch(updateObjectDuplicate(newObjectData));
  };
}

export function handleDuplicateError(error) {
  return(dispatch) => {
    dispatch({ type: C.DUPLICATE_OBJECT_FAIL, error });
  };
}

export function readExcelFile(file, objectTypeId) {
  return(dispatch, getState) => {
    dispatch({ type: C.IMPORT_EXCEL_START });
    dispatch({ type: C.SHOW_LOADER });
    const reader = new FileReader();
    async function onload(e) {
      const ids = getIds(getState);
      const data = e.target.result;
      const XLSX = await import('xlsx');
      const workbook = XLSX.read(data, {
        type: 'binary',
      });

      workbook.SheetNames.forEach((sheetName) => {
        const sheet = workbook.Sheets[sheetName];
        const excelImport = XLSX.utils.sheet_to_row_object_array(sheet);
        dispatch({ type: C.PREPARE_IMPORT_EXCEL_DATA, importExcelData: excelImport });
        /* Extract header fields from the first row in the Excel sheet. */
        const headers = [];
        const range = XLSX.utils.decode_range(sheet['!ref']);
        let index = range.s.r; /* start in the first row */
        const { r } = range.s;
        /* walk every column in the range */
        for(index; index <= range.e.c; index += 1) {
          const cell = sheet[XLSX.utils.encode_cell({ c: index, r })]; /* find the cell in the first row */
          if(cell && cell.t) {
            headers.push(XLSX.utils.format_cell(cell));
          }
        }
        requestManager.matchImportHeadersToFieldTypes(headers, ids.projectId, objectTypeId)
          .then((response) => {
            const fields = response.data;
            dispatch({ type: C.IMPORT_FIELDS_CREATED, fields });
            dispatch({ type: C.HIDE_LOADER });
          }).catch((error) => {
            dispatch({ type: C.HIDE_LOADER });
            dispatch({ type: C.IMPORT_FIELDS_FAIL, error });
          });
      });
    }
    reader.onload = onload;
    reader.readAsBinaryString(file);
  };
}

export function prepareImportFields(preparedImportFields) {
  return (dispatch) => {
    dispatch({ type: C.PREPARE_IMPORT_FIELDS, preparedImportFields });
  };
}

export function prepareReoccuringObject(reoccuringObject) {
  return(dispatch) => {
    dispatch({ type: C.PREPARE_REOCCURING_OBJECT, reoccuringObject });
  };
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function pollForImportObjectsDone(objectImportJob, objectTypeId, categoryTypeId, dispatch) {
  const startLocation = typeof window !== 'undefined' && window.location && window.location.href;
  if(!startLocation) return;
  let userHasChangedPage = false;
  while(!userHasChangedPage) {
    const newLocation = typeof window !== 'undefined' && window.location && window.location.href;
    if(!(newLocation && newLocation === startLocation)) {
      userHasChangedPage = true;
    } else {
      dispatch({ type: C.FETCH_IMPORT_STATE, id: objectImportJob.id });
      requestManager.fetchImportObjectsIfRunDone(objectImportJob.id)
        // eslint-disable-next-line no-loop-func
        .then((data) => {
          if(data.data) {
            if(data.data === 'FAILED') {
              const notificationMessage = 'Import of objects was unsuccessful.';
              dispatch({ type: C.CREATE_IMPORT_DATA_FAIL, notificationMessage });
            } else {
              const nd = normalize(data.data, [objectData]);
              const reduxEntities = flattenObjects(nd);
              dispatch({
                type: C.CREATE_IMPORT_DATA_SUCCESS,
                reset: false,
                objectType: objectTypeId,
                ...reduxEntities,
              });
              if(categoryTypeId) {
                const newProps = {
                  type: categoryTypeId,
                  page: -1,
                  reset: false,
                  categoryTypeId: false,
                  useFilter: false,
                  listType: C.BASE_FIELD,
                };
                dispatch(fetchObjects(newProps));
              }
            }
            userHasChangedPage = true;
          }
        });
      // eslint-disable-next-line no-await-in-loop
      await sleep(10000);
    }
  }
}

async function pollForReoccuringCreateDone(reoccuringGroup, objectTypeId, categoryTypeId, dispatch) {
  const startLocation = typeof window !== 'undefined' && window.location && window.location.href;
  if(!startLocation) return;
  let userHasChangedPage = false;
  while(!userHasChangedPage) {
    const newLocation = typeof window !== 'undefined' && window.location && window.location.href;
    if(!(newLocation && newLocation === startLocation)) {
      userHasChangedPage = true;
    } else {
      dispatch({ type: C.FETCH_REOCCURING_STATE, id: reoccuringGroup.id });
      requestManager.fetchReoccuringIfRunDone(reoccuringGroup.id)
        // eslint-disable-next-line no-loop-func
        .then((data) => {
          if(data.data) {
            if(data.data === 'FAILED') {
              const creationMessage = 'Creation of reoccuring objects was unsuccessful.';
              dispatch({ type: C.CREATE_REOCCURING_FAILED, creationMessage });
            } else {
              const nd = normalize(data.data, [objectData]);
              const reduxEntities = flattenObjects(nd);
              dispatch({
                type: C.CREATE_REOCCURING_SUCCESS,
                reset: false,
                objectType: objectTypeId,
                ...reduxEntities,
              });
              if(categoryTypeId) {
                const newProps = {
                  type: categoryTypeId,
                  page: -1,
                  reset: false,
                  categoryTypeId: false,
                  useFilter: false,
                  listType: C.BASE_FIELD,
                };
                dispatch(fetchObjects(newProps));
              }
            }
            userHasChangedPage = true;
          }
        });
      // eslint-disable-next-line no-await-in-loop
      await sleep(10000);
    }
  }
}

export function importDataFromExcel(objectTypeId, importDateFormat) {
  return (dispatch, getState) => {
    dispatch({ type: C.IMPORT_EXCEL_DATA_START });
    dispatch({ type: C.SHOW_LOADER });
    const ids = getIds(getState);
    const { importExcelData, importFields, objectTypes } = getState().objectsState;
    const categoryTypeId = objectTypeId && objectTypes[objectTypeId] && objectTypes[objectTypeId].primary_category_object;
    requestManager.importDataFromExcel(importExcelData, importFields, ids.projectId, objectTypeId, importDateFormat)
      .then((response) => {
        if(response.data) {
          const objectImportJob = response.data;
          const notificationMessage = `The import of objects will be done in the background, this will take up to
            ${1 + Math.ceil(0.10 * importExcelData.length)} minutes. Please continue using the site as usual.`;
          dispatch({
            type: C.IMPORT_EXCEL_DATA_SUCCESS,
            notificationMessage,
          });
          pollForImportObjectsDone(objectImportJob, objectTypeId, categoryTypeId, dispatch);
        } else dispatch({ type: C.IMPORT_EXCEL_DATA_FAIL });
        dispatch({ type: C.IMPORT_EXCEL_FINISHED });
        dispatch({ type: C.HIDE_LOADER });
      }).catch((error) => {
        dispatch({ type: C.IMPORT_EXCEL_DATA_FAIL, error });
        dispatch({ type: C.HIDE_LOADER });
      });
  };
}

export function readExcelFileBlock(file, blockTypeId) {
  return(dispatch, getState) => {
    dispatch({ type: C.IMPORT_EXCEL_BLOCK_START });
    dispatch({ type: C.SHOW_LOADER });
    const reader = new FileReader();
    async function onload(e) {
      const ids = getIds(getState);
      const data = e.target.result;
      const XLSX = await import('xlsx');
      const workbook = XLSX.read(data, {
        type: 'binary',
      });

      workbook.SheetNames.forEach((sheetName) => {
        const sheet = workbook.Sheets[sheetName];
        const excelImport = XLSX.utils.sheet_to_row_object_array(sheet);
        dispatch({ type: C.PREPARE_IMPORT_EXCEL_BLOCK_DATA, importExcelData: excelImport });
        /* Extract header fields from the first row in the Excel sheet. */
        const headers = [];
        const range = XLSX.utils.decode_range(sheet['!ref']);
        let index = range.s.r; /* start in the first row */
        const { r } = range.s;
        /* walk every column in the range */
        for(index; index <= range.e.c; index += 1) {
          const cell = sheet[XLSX.utils.encode_cell({ c: index, r })]; /* find the cell in the first row */
          if(cell && cell.t) {
            headers.push(XLSX.utils.format_cell(cell));
          }
        }
        requestManager.matchImportHeadersToBlocks(headers, ids.projectId, blockTypeId)
          .then((response) => {
            const { blockTypes, fieldTypes, objectTypes } = getState().objectsState;
            const fields = response.data;
            let headerFieldTypes = [];
            const blockType = blockTypes[blockTypeId];
            if(blockType.field_types && blockType.field_types.length) {
              blockType.field_types.forEach((ft) => {
                const subObject = fieldTypes[ft] && objectTypes[fieldTypes[ft].object_type];
                if(subObject && subObject.block_types) {
                  const subObjectBlockType = fieldTypes[ft].sub_object_block_type;
                  const subBlockTypes = subObjectBlockType ? [subObjectBlockType.id] : subObject.block_types;
                  subBlockTypes.forEach((bt) => {
                    const innerFieldTypes = blockTypes[bt] &&
                      blockTypes[bt].field_types &&
                      blockTypes[bt].field_types.filter(f =>
                        fieldTypes[f] &&
                        fieldTypes[f].input_type !== I.PLACEHOLDER &&
                        fieldTypes[f].input_type !== I.PREVIEW)
                        .map(f =>
                          fieldTypes[f] && {
                            ...fieldTypes[f],
                            text: fieldTypes[f].text ||
                              (fieldTypes[f].placeholder_translation && fieldTypes[f].placeholder_translation.text) ||
                              fieldTypes[f].input_type,
                          });
                    headerFieldTypes = headerFieldTypes.concat(innerFieldTypes);
                  });
                }
                if(!headerFieldTypes.length && fieldTypes[ft].input_type === I.LISTPEOPLE) {
                  headerFieldTypes = peopleImportHeaders;
                }
              });
            }
            dispatch({ type: C.HIDE_LOADER });
            dispatch({ type: C.IMPORT_FIELDS_BLOCK_CREATED, fields, headerFieldTypes });
          }).catch((error) => {
            dispatch({ type: C.HIDE_LOADER });
            dispatch({ type: C.IMPORT_FIELDS_BLOCK_FAIL, error });
          });
      });
    }
    reader.onload = onload;
    reader.readAsBinaryString(file);
  };
}

export function prepareImportFieldsBlock(preparedImportFields) {
  return (dispatch) => {
    dispatch({ type: C.PREPARE_IMPORT_BLOCK_FIELDS, preparedImportFields });
  };
}

export function importBlockFromExcel(blockTypeId, objectId, importDateFormat) {
  return (dispatch, getState) => {
    dispatch({ type: C.IMPORT_EXCEL_BLOCK_START });
    dispatch({ type: C.SHOW_LOADER });
    const ids = getIds(getState);
    const { importExcelBlock, importFieldsBlock } = getState().objectsState;
    requestManager.importBlockFromExcel(importExcelBlock, importFieldsBlock, blockTypeId, objectId, ids.projectId, importDateFormat)
      .then((response) => {
        const nd = normalize(response.data, objectData);
        const reduxEntities = flattenObjects(nd);
        dispatch({
          type: C.IMPORT_EXCEL_BLOCK_SUCCESS,
          ...reduxEntities,
        });
        const props = {
          type: null,
          page: 1,
          reset: false,
          categoryTypeId: null,
          useFilter: null,
          parentId: objectId,
          isList: true,
        };
        dispatch(fetchObjects(props));
        dispatch({ type: C.HIDE_LOADER });
        dispatch({ type: C.IMPORT_EXCEL_BLOCK_FINISHED });
      }).catch(error => dispatch({ type: C.IMPORT_EXCEL_BLOCK_FAIL, error }));
  };
}

export function createReoccuringObject(objectId) {
  return(dispatch, getState) => {
    const { requiredFields } = getState().environmentState;
    if(requiredFields && Object.values(requiredFields) && Object.values(requiredFields).length) {
      dispatch({ type: C.FIELDS_REQUIRED });
    } else {
      dispatch({ type: C.CLOSE_REOCCURING_MODAL });
      const { preparedReoccuringObject, objectDatas, fieldDatas, objectTypes } = getState().objectsState;
      const { repeats, frequency, end_date: endDate, endRepeat } = preparedReoccuringObject;
      const freq = frequency.realValue;
      const object = objectDatas[objectId];
      const objectTypeId = object && object.object_type_id;
      const categoryTypeId = objectTypeId && objectTypes[objectTypeId] && objectTypes[objectTypeId].primary_category_object;
      const times = getDateTimesFromObject(object, objectDatas, fieldDatas);
      const maxNumberOfRepeats = (endRepeat && endRepeat.value === 'After' && repeats) || 52;
      if(times && times.length && times[0] && times[0].startTime) {
        let endTimeReached = false;
        const endTime = endRepeat && endRepeat.value === 'On date' && endDate && moment(endDate);
        let index = 1;
        const reoccuringStartDates = [];
        const reoccuringEndDates = [];
        while(!endTimeReached) {
          const newStartTime = moment(times[0].startTime).add(index, freq);
          if(newStartTime.isSameOrBefore(endTime) || !endTime) {
            reoccuringStartDates.push(newStartTime.toJSON());
            if(times[0].endTime) {
              const newEndTime = moment(times[0].endTime).add(index, freq);
              reoccuringEndDates.push(newEndTime.toJSON());
            }
            index += 1;
            if(index > maxNumberOfRepeats) endTimeReached = true;
          } else endTimeReached = true;
        }
        const reoccurObject = {
          frequency: frequency.realValue && frequency.realValue.charAt(0).toUpperCase(),
        };
        requestManager.createReoccuringObject(objectId, reoccuringStartDates, reoccuringEndDates, reoccurObject)
          .then((data) => {
            const reoccuringGroup = data.data;
            if(reoccuringGroup) {
              const creationMessage = `
                Creation of reoccuring objects have been queued,
                it may between 1 and ${1 + Math.ceil(0.25 * reoccuringStartDates.length)} minutes for it to complete.
                Please continue using Jetty as usual or wait for the action to complete.
              `;
              dispatch({ type: C.CREATE_REOCCURING_STARTED, creationMessage });
              pollForReoccuringCreateDone(reoccuringGroup, objectTypeId, categoryTypeId, dispatch);
            } else {
              const creationMessage = 'Oops something went wrong. Creation of reoccuring objects could not be queued.';
              dispatch({ type: C.CREATE_REOCCURING_FAILED, creationMessage });
            }
          });
      }
    }
  };
}

export function openReoccuringModal() {
  return(dispatch) => {
    dispatch({ type: C.OPEN_REOCCURING_MODAL });
  };
}

export function closeReoccuringModal() {
  return(dispatch) => {
    dispatch({ type: C.CLOSE_REOCCURING_MODAL });
  };
}

export function clearObjectSelection() {
  return(dispatch) => {
    dispatch({ type: C.CLEAR_OBJECT_SELETION });
  };
}
