import C from '../constants/constants';

export const initialState = {
  tokenUser: false,
  tokenUserSubmitPending: false,
  fetchingSchedulesOnObjectData: false,
  calendarSchedules: [],
  latestCreatedObjectId: null,
  isExternallyAccessed: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case C.CREATE_TOKEN_USER_CHILD_SUCCESS:
    case C.VALIDATE_TOKEN_USER_SUCCESS:
      return {
        ...state,
        tokenUser: action.tokenUser,
      };

    case C.SET_EXTERNALLY_ACCESSED:
      return {
        ...state,
        isExternallyAccessed: action.value,
      };

    case C.PREPARE_TOKEN_USER_OBJECT:
      return {
        ...state,
        tokenUser: action.object.is_list_object ? state.tokenUser : { ...state.tokenUser, object_data: action.object },
      };

    case C.GET_TOKEN_USER_OBJECT_DATA_SUCCESS:
      return {
        ...state,
        tokenUser: { ...state.tokenUser, object_data: action.objectId ? action.objectDatas[action.objectId] : state.tokenUser.object_data },
      };

    case C.SUBMIT_TOKEN_USER_OBJECT_DATA_START:
      return {
        ...state,
        tokenUserSubmitPending: true,
        currentTokenUserRequest: C.TOKEN_USER_SUBMIT_OBJECT,
      };

    case C.SUBMIT_TOKEN_USER_OBJECT_DATA_FAIL:
      return {
        ...state,
        tokenUserSubmitPending: false,
        currentTokenUserRequest: null,
      };


    case C.SUBMIT_TOKEN_USER_OBJECT_DATA_SUCCESS:
      return {
        ...state,
        showNotice: true,
        noticeMessage: 'Information sent',
        noticeType: 'success',
        tokenUserSubmitPending: false,
        currentTokenUserRequest: null,
        latestCreatedObjectId: action.objectId,
      };

    case C.FETCH_SCHEDULES_ON_OBJECT_DATA_SUCCESS:
      return {
        ...state,
        calendarSchedules: action.calendarSchedules,
        fetchingSchedulesOnObjectData: false,
      };

    case C.FETCH_SCHEDULES_ON_OBJECT_DATA_START:
      return {
        ...state,
        fetchingSchedulesOnObjectData: true,
      };

    case C.FETCH_SCHEDULES_ON_OBJECT_DATA_FAIL:
      return {
        ...state,
        fetchingSchedulesOnObjectData: false,
      };

    case C.GET_TOKEN_USER_OBJECT_TYPE_FAIL:
      return {
        ...state,
        tokenUserSubmitPending: false,
        currentTokenUserRequest: null,
      };

    default:
      return state;
  }
}
