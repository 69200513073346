// @flow
import type { ManageUsersState } from '../../App/entities/FlowTypes';
import C from '../constants/constants';
import $ from '../../libs/reduxOperations';

const removePermission = (permissions: any, permission) => {
  const newPermissions = permissions.slice();
  const index = permissions.findIndex(p => (
    p.type === permission.type &&
    p.block_type_id === permission.block_type_id &&
    p.object_type_id === permission.object_type_id
  ));

  if(index > -1) {
    newPermissions.splice(index, 1);
    return newPermissions;
  }

  return permissions;
};

const addPermission = (permissions: any, permission: any) => {
  const newPermissions = permissions.slice();
  const index = permissions.findIndex(p => (
    p.type === permission.type &&
    p.block_type_id === permission.block_type_id &&
    p.object_type_id === permission.object_type_id &&
    p.object_data_id === permission.object_data_id &&
    p.status_id === permission.status_id
  ));

  if(index === -1) {
    return newPermissions.concat(permission);
  }

  if(index !== -1) {
    newPermissions.splice(index, 1, permission);
  }

  return newPermissions;
};

const updatePermissionsOnUser = (userId, users: any, permissions) => {
  const newUsers = users.slice();

  const index = users.findIndex(u => u.id === userId);
  const user = users[index];

  if(!user) return users;

  user.permissions = permissions;

  newUsers.splice(index, 1, user);

  return newUsers;
};

const updatePermissionsOnRole = (roleId, roles: any, permissions: any) => {
  const newRoles = roles.slice();

  const index = roles.findIndex(u => u.id === roleId);
  const role = roles[index];

  if(!role) return roles;

  role.permissions = permissions;

  newRoles.splice(index, 1, role);

  return newRoles;
};

export const initialState: ManageUsersState = {
  preparedPermissions: [],
  isEditingOverallRights: false,
  isEditingBlockRights: false,
  isEditingObjectRights: false,
  users: [],
  roles: [],
  departments: [],
  permissions: [],
  people: [],
  accounts: [],
  preparedUser: null,
  preparedPerson: null,
  preparedAccount: null,
  preparedRole: null,
  preparedSetting: null,
  passwordGenerated: false,
  createUserModalIsOpen: false,
  createSettingsModalIsOpen: false,
  updatingRole: false,
  currentRequest: null,
  updateUserPending: false,
};

export default function reducer(state: ManageUsersState = initialState, action: any) {
  switch (action.type) {
    case C.PREPARE_PERMISSION:
      return {
        ...state,
        preparedPermissions: addPermission(state.preparedPermissions, action.permission),
      };

    case C.UNPREPARE_PERMISSION:
      return {
        ...state,
        preparedPermissions: removePermission(state.preparedPermissions, action.permission),
      };

    case C.ACTIVATE_OVERALL_RIGHTS_EDIT_MODE:
      return {
        ...state,
        isEditingOverallRights: true,
        isEditingBlockRights: false,
        isEditingObjectRights: false,
        preparedPermissions: [],
      };

    case C.DEACTIVATE_OVERALL_RIGHTS_EDIT_MODE:
      return {
        ...state,
        isEditingOverallRights: false,
        preparedPermissions: [],
      };

    case C.ACTIVATE_BLOCK_RIGHTS_EDIT_MODE:
      return {
        ...state,
        isEditingBlockRights: true,
        isEditingObjectRights: false,
        isEditingOverallRights: false,
        preparedPermissions: [],
      };

    case C.DEACTIVATE_BLOCK_RIGHTS_EDIT_MODE:
      return {
        ...state,
        isEditingBlockRights: false,
        preparedPermissions: [],
      };

    case C.ACTIVATE_OBJECT_RIGHTS_EDIT_MODE:
      return {
        ...state,
        isEditingObjectRights: true,
        isEditingBlockRights: false,
        isEditingOverallRights: false,
        preparedPermissions: [],
      };

    case C.DEACTIVATE_OBJECT_RIGHTS_EDIT_MODE:
      return {
        ...state,
        isEditingObjectRights: false,
        preparedPermissions: [],
      };

    case C.UPDATE_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isEditingObjectRights: false,
        isEditingBlockRights: false,
        isEditingOverallRights: false,
        preparedPermissions: [],
        users: updatePermissionsOnUser(action.userId, state.users, action.permissions),
        currentRequest: null,
      };

    case C.UPDATE_ROLE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isEditingObjectRights: false,
        isEditingBlockRights: false,
        isEditingOverallRights: false,
        preparedPermissions: [],
        roles: updatePermissionsOnRole(action.roleId, state.roles, action.permissions),
        currentRequest: null,
      };

    case C.FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: $.addOrUpdateListWithList(state.users, action.users),
        updateUserPending: false,
      };

    case C.FETCH_USER_SUCCESS:
      return {
        ...state,
        users: $.addOrUpdateListWithList(state.users, [action.user]),
        preparedUser: null,
        preparedAccount: null,
        preparedPerson: null,
      };

    case C.UPDATE_USER_START:
      return {
        ...state,
        currentRequest: C.UPDATE_USER,
        updateUserPending: true,
      };

    case C.UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: $.addOrUpdateListOfObjectsWithObjectById(state.users, action.user),
        preparedUser: null,
        currentRequest: null,
        updateUserPending: false,
      };

    case C.FETCH_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departments: $.addOrUpdateListWithList(state.departments, action.departments),
      };

    case C.FETCH_ROLES_SUCCESS:
      return {
        ...state,
        roles: $.addOrUpdateListWithList(state.roles, action.roles),
      };

    case C.FETCH_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: $.addOrUpdateListWithList(state.permissions, action.permissions),
      };

    case C.UPDATE_ACCOUNT_START:
      return {
        ...state,
        currentRequest: C.UPDATE_ACCOUNT,
      };

    case C.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        accounts: $.addOrUpdateListWithList(state.accounts, [action.account]),
        preparedAccount: null,
        currentRequest: null,
      };

    case C.UPDATE_SETUP_PERSON_SUCCESS:
    case C.CREATE_SETUP_PERSON_SUCCESS:
      return {
        ...state,
        people: $.addOrUpdateListWithList(state.people, [action.person]),
        preparedPerson: false,
        currentRequest: null,
      };

    case C.PREPARE_ACCOUNT:
      return {
        ...state,
        preparedAccount: action.account,
      };

    case C.PREPARE_USER:
      return {
        ...state,
        preparedUser: action.user,
      };

    case C.UNPREPARE_USER:
      return {
        ...state,
        preparedUser: null,
      };

    case C.PREPARE_SETUP_PERSON:
      return {
        ...state,
        preparedPerson: action.person,
      };

    case C.PREPARE_ROLE:
      return {
        ...state,
        preparedRole: action.role,
      };

    case C.PREPARE_SETTING:
      return {
        ...state,
        preparedSetting: action.setting,
      };

    case C.CREATE_USER_START:
      return {
        ...state,
        currentRequest: C.CREATE_USER,
        updateUserPending: true,
      };

    case C.CREATE_USER_SUCCESS:
      return {
        ...state,
        users: $.addOrUpdateListWithList(state.users, [action.user]),
        preparedUser: null,
        createUserModalIsOpen: false,
        currentRequest: null,
        updateUserPending: false,
      };

    case C.CREATE_USER_FAIL:
      return {
        ...state,
        error: action.error,
        currentRequest: null,
        updateUserPending: false,
      };

    case C.SHOW_CREATE_USER_MODAL:
      return {
        ...state,
        createUserModalIsOpen: true,
      };

    case C.HIDE_CREATE_USER_MODAL:
      return {
        ...state,
        createUserModalIsOpen: false,
      };

    case C.SHOW_CREATE_SETTINGS_MODAL:
      return {
        ...state,
        createSettingsModalIsOpen: true,
      };

    case C.HIDE_CREATE_SETTINGS_MODAL:
      return {
        ...state,
        createSettingsModalIsOpen: false,
      };

    case C.UPDATE_ROLE_START:
    case C.UPDATE_ROLE_PERMISSIONS_START:
    case C.UPDATE_USER_PERMISSIONS_START:
      return {
        ...state,
        updatingRole: true,
        currentRequest: C.UPDATE_ROLE,
      };

    case C.UPDATE_ROLE_FAIL:
    case C.UPDATE_ROLE_PERMISSIONS_FAIL:
    case C.UPDATE_USER_PERMISSIONS_FAIL:
      return {
        ...state,
        updatingRole: false,
        currentRequest: null,
      };

    case C.CREATE_ROLE_START:
      return {
        ...state,
        creatingRole: true,
        currentRequest: C.CREATE_ROLE,
      };

    case C.CREATE_ROLE_FAIL:
      return {
        ...state,
        creatingRole: false,
        currentRequest: null,
      };

    case C.UPDATE_ROLE_SUCCESS:
    case C.CREATE_ROLE_SUCCESS:
      return {
        ...state,
        roles: $.addOrUpdateListWithList(state.roles, [action.role]),
        preparedRole: null,
        updatingRole: false,
        creatingRole: false,
        createSettingsModalIsOpen: false,
        currentRequest: null,
      };

    case C.CREATE_PERSON_SUCCESS:
      return {
        ...state,
        people: $.addOrUpdateListWithList(state.people, action.person ? [action.person] : []),
      };

    case C.UPDATE_USER_FAIL:
    case C.UPDATE_ACCOUNT_FAIL:
      return {
        ...state,
        currentRequest: null,
      };

    default:
      return state;
  }
}
