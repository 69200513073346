// @flow
import C from '../constants/constants';
import $ from '../../libs/reduxOperations';
import type { ActionType } from '../actions/reportsActions';
import type { ReportsState } from '../entities/FlowTypes';

export const initialState: ReportsState = {
  reportTypes: {},
  columnFilters: [],
  selectedReportType: null,
  isLoadingReport: false,
  report: null,
  subReport: null,
  sortColumnId: null,
  sortDirection: 'ASC',
  hideEmptyRows: true,
};

export default function reportsReducer(state: ReportsState = initialState, action: ActionType): ReportsState {
  switch (action.type) {
    case C.FETCH_REPORT_TYPES_SUCCESS:
      return {
        ...state,
        reportTypes: action.reportTypes,
      };

    case C.SELECT_REPORT_TYPE:
      return {
        ...state,
        selectedReportType: action.reportTypeId,
      };

    case C.FETCH_REPORT_START:
      return {
        ...state,
        columnFilters: [],
        isLoadingReport: true,
      };

    case C.FETCH_REPORT_SUCCESS:
      return {
        ...state,
        isLoadingReport: false,
        report: action.report,
        columnFilters: action.filters,
      };

    case C.FETCH_SUB_REPORT_START:
      return {
        ...state,
        isLoadingReport: true,
      };

    case C.FETCH_SUB_REPORT_SUCCESS:
      return {
        ...state,
        isLoadingReport: false,
        subReport: action.report,
      };

    case C.SET_REPORT_SORTING_COLUMN:
      return {
        ...state,
        sortColumnId: action.sortColumnId,
        sortDirection: (state.sortColumnId === action.sortColumnId && state.sortDirection === 'ASC' ? 'DESC' : 'ASC'),
      };

    case C.UPDATE_REPORT_FILTER: {
      const { filterColumnId, updatedFilter } = action;
      return {
        ...state,
        columnFilters: $.addOrUpdateAttributesInObjectInArrayById(state.columnFilters, { id: filterColumnId, ...updatedFilter }),
      };
    }

    case C.CLEAR_REPORT_FILTER: {
      let tempFilters = [];
      action.filters.forEach((filter) => {
        const tempFilter = { ...filter };
        if(filter.filterType === 'UNIQUE_LIST_FILTER' || filter.filterType === 'SELECT_SINGLE_FILTER') tempFilter.selected = [];
        else if(filter.filterType === 'DATE_FILTER') {
          tempFilter.fromDate = null;
          tempFilter.toDate = null;
        } else if(filter.filterType === 'TIME_FILTER') {
          tempFilter.fromTime = null;
          tempFilter.toTime = null;
        }
        tempFilters = $.addToArray(tempFilters, tempFilter);
      });
      return {
        ...state,
        columnFilters: tempFilters,
      };
    }

    case C.SET_HIDDEN_EMPTY_ROWS:
      return {
        ...state,
        hideEmptyRows: action.hideEmptyRows,
      };

    case C.FETCH_REPORT_FAIL:
    case C.FETCH_SUB_REPORT_FAIL:
      // TODO: show error dialog or something
      // console.error(action.message);
      return state;
    default:
      return state;
  }
}
