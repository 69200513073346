import * as Sentry from '@sentry/browser';
import C from '../constants/constants';
import T from '../constants/taskConstants';
import E from '../constants/economy';
import $ from '../../libs/reduxOperations';
import { updateMenuItemsWithObjectType } from '../../libs/objectUtils';

const initialFilterState = {
  departments: [],
  statuses: [],
  published: false,
  startDate: null,
  endDate: null,
  startTime: null,
  endTime: null,
  tags: [],
  roles: [],
  categories: [],
  myTasks: null,
  exactMatch: null,
  users: [],
  orderBy: null,
  order: 'ASC',
  primaryCategories: [],
  secondaryCategories: [],
  tertiaryCategories: [],
  objects: [],
  connectedTo: [],
  titles: [],
  jobRoles: [],
  taskPriorities: [],
  staff: [],
  selectOptions: {},
  listType: null,
  companies: [],
  available: false,
  titleTags: [],
  exactTitlesMatch: null,
  connectedObject: null,
};

export const initialState = {
  theme: '',
  viewMode: C.LIST,
  setting: {},
  projectSettings: null,
  project: null,
  filter: initialFilterState,
  printFilter: {
    printMode: false,
    printBlocks: [],
    printTitle: '',
  },
  pendingRequests: [],
  showLoader: 0,
  showNotice: false,
  noticeMessage: '',
  noticeType: 'warning',
  requiredFields: {},
  fieldsRequired: false,
  solidLoader: false,
  showDeleteNotice: false,
  deleteCB: null,
  menuItems: null,
  menuItemsOpen: [],
  selectedMenuItem: null,
  groupByObjects: {},
  filtersObject: {},
  version: '',
  currencies: {},
  bulkEmailSent: false,
  pendingBulkEmailSend: false,
  videoPlayer: {
    url: null,
    open: false,
    title: null,
    subtitle: null,
  },
};

const toggleOrder = (order) => (order === 'ASC' ? 'DESC' : 'ASC');
let groupList = [];
let selectList = [];
let filterTemp = { ...initialFilterState };
let filterInitial = { ...initialFilterState };

export default function environmentReducer(state = initialState, action) {
  let newState = {};
  switch (action.type) {
    case C.SET_THEME:
      if (action.theme !== 'external')
        localStorage.setItem('theme', action.theme);
      return { ...state, theme: action.theme };

    case C.ACTIVATE_LIST_MODE:
      return { ...state, viewMode: C.LIST };

    case C.ACTIVATE_CALENDAR_MODE:
      return { ...state, viewMode: C.CALENDAR };

    // case C.FETCH_PROJECT_SETTINGS_START:
    //   return { ...state, showLoader: !state.projectSettings ? state.showLoader + 1 : state.showLoader };

    case C.FETCH_PROJECT_SETTINGS_SUCCESS:
      return {
        ...state,
        setting: action.setting,
        projectSettings:
          action.setting &&
          Object.values(action.setting) &&
          Object.values(action.setting)[0],
        project:
          action.project &&
          Object.values(action.project) &&
          Object.values(action.project)[0],
      };

    case C.UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        project:
          action.projects && action.id && action.projects[action.id]
            ? action.projects[action.id]
            : state.project,
      };

    case C.CREATE_OBJECT_TYPE_SUCCESS:
    case C.UPDATE_OBJECT_TYPE_SUCCESS:
      return {
        ...state,
        menuItems: $.addArrayOfObjectsToObject(
          state.menuItems,
          Object.values(action.objectTypes).map((o) => ({
            ...o.menu_item,
            object_type: o,
          })),
        ),
      };

    case C.DELETE_OBJECT_TYPE_SUCCESS:
      return {
        ...state,
        menuItems: $.removeFromObjectById(
          state.menuItems,
          Object.values(state.menuItems).filter(
            (m) => m.object_type && action.objectTypeId === m.object_type.id,
          )[0],
        ),
      };

    case C.PREPARE_OBJECT_TYPE:
      return {
        ...state,
        menuItems: $.addObjectToObject(
          state.menuItems,
          updateMenuItemsWithObjectType(action.objectType, state.menuItems),
        ),
      };

    case C.FETCH_PROJECT_SETTINGS_FAIL:
      return { ...state, error: action.error };

    case C.FETCH_MENU_ITEMS_SUCCESS:
      return {
        ...state,
        menuItems: action.menuItems,
      };

    case C.FETCH_MENU_ITEMS_FAIL:
      return { ...state, error: action.error };

    case C.CLEAR_MENU:
      return {
        ...state,
        menuItemsOpen: [],
        selectedMenuItem: null,
      };

    case C.SET_MENU_ITEMS:
      return {
        ...state,
        menuItemsOpen: action.toggle
          ? $.addOrRemoveFromArray(state.menuItemsOpen || [], action.item.id)
          : $.addToArray(state.menuItemsOpen || [], action.item.id),
        selectedMenuItem: action.toggle ? state.selectedMenuItem : action.item,
      };

    case E.FETCH_CURRENCIES_SUCCESS:
      return {
        ...state,
        currencies: action.currencies,
      };

    case E.FETCH_CURRENCIES_FAIL:
      return { ...state, error: action.error };

    case C.ADD_GROUP_BY_ITEM:
      groupList = [];
      groupList = $.addToArray(
        state.groupByObjects && state.groupByObjects[action.listType]
          ? state.groupByObjects[action.listType]
          : groupList,
        action.item,
      );
      return {
        ...state,
        groupByObjects: {
          ...state.groupByObjects,
          [action.listType]: groupList,
        },
      };

    case C.REMOVE_GROUP_BY_ITEM:
      groupList = $.removeFromArrayById(
        state.groupByObjects[action.listType],
        action.item,
      );
      return {
        ...state,
        groupByObjects: {
          ...state.groupByObjects,
          [action.listType]: groupList,
        },
      };

    case C.UPDATE_GROUP_BY:
      return {
        ...state,
        groupByObjects: {
          ...state.groupByObjects,
          [action.listType]: action.array,
        },
      };

    case C.UPDATE_GROUP_BY_ITEM:
      groupList = $.addOrUpdateObjectInArrayById(
        state.groupByObjects[action.listType],
        action.item,
      );
      return {
        ...state,
        groupByObjects: {
          ...state.groupByObjects,
          [action.listType]: groupList,
        },
      };

    case C.SET_DEFAULT_GROUP_BY:
      return {
        ...state,
        groupByObjects: {
          ...state.groupByObjects,
          [action.listType]: action.list,
        },
      };

    case C.CLEAR_ALL_FILTERS:
      filterTemp = { ...initialFilterState };
      if (action.listType === C.STAFF) {
        filterTemp.startDate =
          state.filtersObject[action.listType] &&
          state.filtersObject[action.listType].startDate;
        filterTemp.endDate =
          state.filtersObject[action.listType] &&
          state.filtersObject[action.listType].endDate;
      }
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_AVAILABILITY:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.available = action.available;
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_DEPARTMENTS:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.departments = $.addOrRemoveFromArray(
        filterTemp.departments,
        action.department.id,
      );
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_STATUS:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.statuses = $.addOrRemoveFromArray(
        filterTemp.statuses,
        action.status.id,
      );
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.TOGGLE_FILTER_PUBLISHED:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.published = !filterTemp.published;
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_DATES:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.startDate = action.schedule.start_time
        ? action.schedule.start_time
        : null;
      filterTemp.endDate = action.schedule.end_time
        ? action.schedule.end_time
        : null;
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_TIME:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.startTime = action.schedule.start_time
        ? action.schedule.start_time
        : null;
      filterTemp.endTime = action.schedule.end_time
        ? action.schedule.end_time
        : null;
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_TAG:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.tags = $.addOrRemoveFromArray(
        filterTemp.tags || [],
        action.tag.id,
      );
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_TITLE_TAG:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.titleTags = $.addOrRemoveFromArray(
        filterTemp.titleTags || [],
        action.tag.id,
      );
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_ROLES:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.roles = action.role
        ? $.addOrRemoveFromArray(filterTemp.roles, action.role.id)
        : [];
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_CATEGORY:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.categories = $.addOrRemoveFromArray(
        filterTemp.categories,
        action.category.id,
      );
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_TASKS:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.myTasks = action.myTasks;
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_EXACT:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.exactMatch = action.exactMatch;
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_TITLES_EXACT:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.exactTitlesMatch = action.exactTitlesMatch;
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_USERS:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.users = $.addOrRemoveFromArray(
        filterTemp.users,
        action.user.id,
      );
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_ORDER_BY:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.order =
        filterTemp.orderBy === action.orderBy
          ? toggleOrder(filterTemp.order)
          : 'ASC';
      filterTemp.orderBy = action.orderBy;
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_PRIMARY_CATEGORY:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.primaryCategories = $.addOrRemoveFromArray(
        filterTemp.primaryCategories,
        action.primaryCategory.id,
      );
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_SECONDARY_CATEGORY:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.secondaryCategories = $.addOrRemoveFromArray(
        filterTemp.secondaryCategories,
        action.secondaryCategory.id,
      );
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_TERTIARY_CATEGORY:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.tertiaryCategories = $.addOrRemoveFromArray(
        filterTemp.tertiaryCategories,
        action.tertiaryCategory.id,
      );
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_OBJECT:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.objects = $.addOrRemoveFromArray(
        filterTemp.objects,
        action.object.id,
      );
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_CONNECTED_TO:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.connectedTo = $.addOrRemoveFromArray(
        filterTemp.connectedTo,
        action.connectedTo.id,
      );
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_STAFF:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.staff = $.addOrRemoveFromArray(
        filterTemp.staff,
        action.staff.user_id,
      );
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };
    case C.SET_FILTER_TASK_PRIORITY:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.taskPriorities = $.addOrRemoveFromArray(
        filterTemp.taskPriorities,
        action.priority,
      );
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_SELECT_OPTION:
      selectList = [];
      filterTemp = {};
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      selectList = $.addOrRemoveFromArrayById(
        filterTemp.selectOptions && filterTemp.selectOptions[action.optionType]
          ? filterTemp.selectOptions[action.optionType]
          : selectList,
        action.option,
      );
      filterTemp.selectOptions = {
        ...filterTemp.selectOptions,
        [action.optionType]: selectList,
      };
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_COMPANY:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.companies = $.addOrRemoveFromArrayByKey(
        filterTemp.companies,
        action.company,
        'company_name',
      );
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_CONNECTED_OBJECT:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.connectedObject = action.connectedObject;
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.RESET_FILTER:
      filterInitial = { ...initialFilterState };
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterInitial,
        },
      };

    case C.CLOSE_ERROR:
      return {
        ...state,
        errorCode: null,
      };

    case C.CLOSE_NOTICE:
      return {
        ...state,
        showNotice: false,
        noticeMessage: '',
        noticeType: 'warning',
      };

    case C.ADD_REQUIRED_FIELD:
      return {
        ...state,
        requiredFields: $.addObjectToObject(
          state.requiredFields,
          action.requiredField,
        ),
      };

    case C.REMOVE_REQUIRED_FIELD:
      return {
        ...state,
        requiredFields: $.removeObjectFromObject(
          state.requiredFields,
          action.requiredField,
        ),
      };

    case C.FIELDS_REQUIRED:
      return {
        ...state,
        fieldsRequired: true,
      };

    case C.SET_FILTER_TITLE:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.titles = $.addOrRemoveFromArray(
        filterTemp.titles || [],
        action.title,
      );
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.SET_FILTER_JOBROLE:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.jobRoles = $.addOrRemoveFromArray(
        filterTemp.jobRoles || [],
        action.jobRole,
      );
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.PREPARE_TASK:
    case C.PREPARE_SUBTASK:
    case C.PREPARE_FIELD:
    case C.BEGIN_EDIT_TASK:
    case C.PREPARE_USER:
    case C.PREPARE_BADGE_FRAMEWORK:
      return {
        ...state,
        fieldsRequired: false,
      };

    case C.SUBMIT_TOKEN_USER_OBJECT_DATA_SUCCESS:
      return {
        ...state,
        showNotice: true,
        noticeMessage: 'Information sent',
        noticeType: 'success',
      };

    case C.SET_PRINT_MODE:
      return {
        ...state,
        printFilter: {
          ...state.printFilter,
          printMode: action.printMode,
        },
      };

    case C.SET_PRINT_BLOCKS:
      return {
        ...state,
        printFilter: {
          ...state.printFilter,
          printBlocks: action.printBlocks,
        },
      };

    case C.SET_PRINT_TITLE:
      return {
        ...state,
        printFilter: {
          ...state.printFilter,
          printTitle: action.printTitle,
        },
      };

    case C.SHOW_LOADER:
      return {
        ...state,
        showLoader: state.showLoader + 1,
        solidLoader: action.solid,
      };

    case C.HIDE_LOADER:
      return {
        ...state,
        showLoader:
          state.showLoader === 0 ? state.showLoader : state.showLoader - 1,
        solidLoader: action.solid,
      };

    case C.CLOSE_DELETE_NOTICE:
      return {
        ...state,
        showDeleteNotice: false,
        deleteCB: null,
      };

    case C.OPEN_DELETE_NOTICE:
      return {
        ...state,
        showDeleteNotice: true,
        deleteCB: action.cb,
      };

    case C.TOGGLE_ASSIGN_MODE:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.jobRoles = [];
      return {
        ...state,
        filtersObject: {
          ...state.filtersObject,
          [action.listType]: filterTemp,
        },
      };

    case C.UPDATE_TASK_SUCCESS:
      filterInitial = { ...initialFilterState };
      filterTemp = state.filtersObject[action.listType]
        ? { ...state.filtersObject[action.listType] }
        : filterInitial;
      filterTemp.jobRoles = [];
      return action.assignmentMode
        ? {
            ...state,
            showNotice: true,
            noticeMessage:
              action.assignmentMode === T.INVITE
                ? 'Invitation successful'
                : 'Assignment successful',
            noticeType: 'success',
            filtersObject: {
              ...state.filtersObject,
              [action.listType]: filterTemp,
            },
          }
        : {
            ...state,
          };

    case C.VALIDATE_DATA_FAIL:
    case C.COPY_TEXT_FAIL:
      return {
        ...state,
        showNotice: true,
        noticeMessage: action.error.message,
        noticeType: 'error',
      };

    case C.COPY_TEXT_SUCCESS:
      return {
        ...state,
        showNotice: true,
        noticeMessage: action.message,
        noticeType: 'success',
      };

    case C.FETCH_VERSION_SUCCESS:
      return {
        ...state,
        version: action.version,
      };

    case C.CLEAR_CACHE_SUCCESS:
      return {
        ...state,
        showNotice: true,
        noticeMessage: 'Cache cleared',
        noticeType: 'success',
      };

    case C.CLEAR_CACHE_FAIL:
      return {
        ...state,
        showNotice: true,
        noticeMessage:
          'Cache could not be cleared. Please wait a few minutes and try again',
        noticeType: 'warning',
      };

    case C.RESET_PASSWORD_LINK_SENT:
    case C.RESET_PASSWORD_LINK_FAIL:
      return {
        ...state,
        showNotice: true,
        noticeMessage: 'A link for resetting the password has been sent.',
        noticeType: 'success',
      };

    case C.LOGIN_FAIL:
    case C.FETCH_USER_CLIENT_FAIL:
      return {
        ...state,
      };

    case C.DELETE_PERSON_AND_CONNECTIONS_SUCCESS:
      return {
        ...state,
        showNotice: true,
        noticeMessage: 'Person deleted',
        noticeType: 'success',
      };

    case C.DELETE_COMPANY_AND_CONNECTIONS_SUCCESS:
      return {
        ...state,
        showNotice: true,
        noticeMessage: 'Company deleted',
        noticeType: 'success',
      };

    case C.FILE_UPDATE_SUCCESS:
      return {
        ...state,
        showNotice: true,
        noticeMessage: 'File has been successfully cropped',
        noticeType: 'success',
      };

    case C.SHOW_DUPLICATE_TIME_NOTICE:
      return {
        ...state,
        showNotice: true,
        noticeMessage:
          'Cannot alter related dates & times due to multiple showtimes.',
        noticeType: 'warning',
      };

    case C.IMPORT_EXCEL_DATA_SUCCESS:
      return {
        ...state,
        showNotice: true,
        noticeMessage: action.notificationMessage,
        noticeType: 'success',
      };

    case C.CREATE_IMPORT_DATA_FAIL:
      return {
        ...state,
        noticeMessage: action.notificationMessage,
        noticeType: 'error',
      };

    case C.CREATE_IMPORT_DATA_SUCCESS:
      return {
        ...state,
        showNotice: true,
        noticeMessage:
          'Import of objects finished, the objects have been loaded.',
      };

    case C.CREATE_REOCCURING_STARTED:
      return {
        ...state,
        showNotice: true,
        noticeMessage: action.creationMessage,
        noticeType: 'success',
      };

    case C.CREATE_REOCCURING_FAILED:
      return {
        ...state,
        showNotice: true,
        noticeMessage: action.creationMessage,
        noticeType: 'error',
      };

    case C.CREATE_REOCCURING_SUCCESS:
      return {
        ...state,
        showNotice: true,
        noticeMessage:
          'Reoccuring objects creation job finished, the objects have been loaded.',
        noticeType: 'success',
      };

    case C.SEND_BULK_EMAIL_START:
      return {
        ...state,
        bulkEmailSent: false,
        pendingBulkEmailSend: true,
      };

    case C.SEND_BULK_EMAIL_SUCCESS:
      return {
        ...state,
        bulkEmailSent: true,
        pendingBulkEmailSend: false,
        showNotice: true,
        noticeMessage: 'Successfully sent email',
        noticeType: 'success',
      };

    case C.SEND_BULK_EMAIL_FAIL:
      return {
        ...state,
        bulkEmailSent: false,
        pendingBulkEmailSend: false,
        showNotice: true,
        noticeMessage: 'Something went wrong. Email not sent',
        noticeType: 'error',
      };

    case C.RESET_BULK_EMAIL_STATUS:
      return {
        ...state,
        bulkEmailSent: false,
      };

    case C.OPEN_VIDEO_PLAYER:
      return {
        ...state,
        videoPlayer: {
          ...state.videoPlayer,
          open: true,
          url: action.url,
          title: action.title,
          subtitle: action.subtitle,
        },
      };

    case C.CLOSE_VIDEO_PLAYER:
      return {
        ...state,
        videoPlayer: {
          ...state.videoPlayer,
          open: false,
        },
      };

    case '@@router/LOCATION_CHANGE':
      document.body.classList.remove('locked');
      return {
        ...state,
        showNotice: false,
      };

    case (action.type.match(/\w*_FAIL\b/) || {}).input:
      newState = {
        ...state,
        pendingRequests: $.removeFromArray(
          state.pendingRequests,
          action.requestHash,
        ),
      };
      if (
        action.error &&
        action.error.response &&
        action.error.response.status !== 401 &&
        action.error.response.status !== 403 &&
        action.error.response.status !== 404
      ) {
        Sentry.setContext('error', { error: action.error });
        Sentry.captureException(
          new Error(
            `[Dispatch *_FAIL] ${action.error ? action.error.message : ''} ${
              action.error && action.error.config ? action.error.config.url : ''
            }`,
          ),
        );
        if (action.error.response && action.error.response.status === 500) {
          if (process.env.NODE_ENV !== 'development') Sentry.showReportDialog();
        }

        newState = {
          ...newState,
          errorCode: true,
        };
      }

      if (
        action.error &&
        action.error.response &&
        action.error.response.status === 404 &&
        !action.type.match(/\w*AUTOCOMPLETE/) &&
        !action.type.match(/\w*SEARCH/) &&
        !action.avoidNotice
      ) {
        newState = {
          ...newState,
          showNotice: true,
          noticeMessage:
            'Content not found. It might have been deleted by someone else',
          noticeType: 'error',
        };
      }

      if (
        action.error &&
        action.error.response &&
        action.error.response.status === 403
      ) {
        newState = {
          ...newState,
          showNotice: true,
          noticeMessage: 'Permission denied',
          noticeType: 'error',
        };
      }

      if (
        action.error &&
        action.error.response &&
        action.error.response.status === 422
      ) {
        newState = {
          ...newState,
          showNotice: true,
          noticeMessage:
            action.error.noticeMessage ||
            'There was a problem with the request. Did you forget something?',
          noticeType: 'error',
        };
      }

      return newState;

    case (action.type.match(/\w*_START\b/) || {}).input:
      return {
        ...state,
        pendingRequests: action.requestHash
          ? $.addToArray(state.pendingRequests || [], action.requestHash)
          : state.pendingRequests,
      };

    case (action.type.match(/\w*_SUCCESS\b/) || {}).input:
      return {
        ...state,
        pendingRequests: action.requestHash
          ? $.removeFromArray(state.pendingRequests || [], action.requestHash)
          : state.pendingRequests,
      };

    default:
      return state;
  }
}
