// @flow
const C = {
  DISPLAYTEXT: { name: 'Display Text', field_type_attribute: null, is_external: true, has_preview: false, has_required: false, has_placeholder: false },
  DISPLAYIMAGE: { name: 'Display Image', field_type_attribute: null, is_external: true, has_preview: false, has_required: false, has_placeholder: false },
  DISPLAYPOPUP: { name: 'Display Popup', field_type_attribute: null, is_external: true, has_preview: false, has_required: true, has_placeholder: false },
  TEXT: { name: 'Text', field_type_attribute: null, is_external: true, has_preview: true, has_required: true, has_placeholder: true },
  LINK: { name: 'Comm Link', field_type_attribute: null, is_external: false, has_preview: false, has_required: true, has_placeholder: false },
  LINK2: { name: 'Info to Artist and Crew', field_type_attribute: null, is_external: false, has_preview: false, has_required: true, has_placeholder: false },
  TEXTAREA: { name: 'Textarea', field_type_attribute: null, is_external: true, has_preview: false, has_required: true, has_placeholder: false },
  SELECTMULTIPLE: { name: 'Multi-select', field_type_attribute: null, is_external: true, has_preview: true, has_required: true, has_placeholder: false, is_filterable: true },
  NUMBER: { name: 'Number', field_type_attribute: null, is_external: true, has_preview: true, has_required: true, has_placeholder: true },
  SELECT: { name: 'Single-select', field_type_attribute: null, is_external: true, has_preview: true, has_required: true, has_placeholder: false, is_filterable: true },
  SELECTOBJECT: { name: 'Core Object Select', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  SELECTPEOPLE: { name: 'Person Select', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  SELECTSTAFF: { name: 'User Select', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  SELECTCOMPANY: { name: 'Company Select', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  SELECTBLOCKTYPETAG: { name: 'Block Type Select', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  LISTOBJECT: { name: 'Object List', field_type_attribute: null, is_external: true, has_preview: false, has_required: true, has_placeholder: false },
  LISTPEOPLE: { name: 'Contact List', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  DATE: { name: 'Date-picker', field_type_attribute: { field: 'multi_date', name: 'Multiple dates' }, is_external: true, has_preview: true, has_required: true, has_placeholder: false },
  TIME: { name: 'Time-picker', field_type_attribute: { field: 'multi_time', name: 'Multiple times' }, is_external: true, has_preview: true, has_required: true, has_placeholder: false },
  DATETIME: { name: 'Datetime-picker', field_type_attribute: null, is_external: true, has_preview: true, has_required: true, has_placeholder: false },
  TAG: { name: 'Tag Select', field_type_attribute: { field: 'is_single_tag', name: 'Is single tag' }, is_external: true, has_preview: true, has_required: true, has_placeholder: false },
  PLACEHOLDER: { name: 'Placeholder', field_type_attribute: null, is_external: true, has_preview: false, has_required: false, has_placeholder: false },
  COUNTRY: { name: 'Country-picker', field_type_attribute: null, is_external: true, has_preview: true, has_required: true, has_placeholder: true },
  INPUTOBJECT: { name: 'Object Input', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  MONEY: { name: 'Money', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  PREVIEW: { name: 'Preview', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  LISTSCHEDULING: { name: 'Scheduling List', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  SCHEDULING: { name: 'Scheduling', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  PROJECTINFO: { name: 'Project Info', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  DATEINFO: { name: 'Date Info', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  FILES: { name: 'File', field_type_attribute: { field: 'is_single_file', name: 'Single file' }, is_external: true, has_preview: false, has_required: true, has_placeholder: false },
  PRIMARY_FILES: { name: 'Primary Files', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  PROJECTGENERAL: { name: 'General Project Info', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  PRIMARYCATEGORIES: { name: 'Primary Category List', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  PRIMARYCATEGORYSCHEDULE: { name: 'Primary Category Schedule', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  SELECTMULTIPLETAG: { name: 'Multiple Tag Select', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  SELECTTAG: { name: 'Single Tag Select', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  PRIMARYTEXT: { name: 'Primary Text', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  EXTERNALCONTACTS: { name: 'External Contacts', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  EXTERNALSCHEDULING: { name: 'External Scheduling', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  RICHTEXT: { name: 'Rich Text Editor', field_type_attribute: null, is_external: true, has_preview: true, has_required: true, has_placeholder: false },
  LISTCONTRACTS: { name: 'Contract List', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  LISTECONOMY: { name: 'Economy List', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false },
  ECONOMY: { name: 'Economy', field_type_attribute: null, is_external: false, has_preview: true, has_required: true, has_placeholder: false, has_economy_type: true },

  CHECKBOX: { name: 'Checkbox', field_type_attribute: null, is_external: true, has_preview: false, has_required: true, has_placeholder: false },
  USER: { name: 'User', field_type_attribute: null, is_external: true, has_preview: true, has_required: true, has_placeholder: false },
  SELECTBADGEOBJECT: { name: 'Badge Object Picker', field_type_attribute: null, is_external: true, has_preview: true, has_required: true, has_placeholder: false },
};

export default C;
