import React, { Component } from 'react';
import moment from 'moment-timezone';
import s from './Login.module.scss';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPlaceholder: true,
    };
  }

  render() {
    const {
      logotype,
      title,
      text,
      buttonTitle,
      formTitle,
      inputType,
      inputPlaceholder,
      handleChange,
      handleSubmit,
      promoteApp = false,
      error,
      showError,
      username,
      theme,
      sendResetPasswordLink,
    } = this.props;

    const { showPlaceholder } = this.state;

    return (
      <div className={s.wrapper}>
        <div className={s.left}>
          <div className={s.formWrapper}>
            { logotype &&
            <img className={s.logotype} alt="" src={logotype} />
            }
            <form onSubmit={handleSubmit}>
              <h2 className={s.title}>{title}</h2>
              <span className={s.text}>{text}</span>
              <span className={s.formTitle}>{formTitle}</span>
              {inputType && inputType === 'password' &&
                <input name="email" type="email" className={s.hiddenInput} value={username} readOnly />
              }
              <div className={s.field}>
                <div className={s.inputWrapper}>
                  <input
                    name={inputType}
                    type={inputType}
                    onChange={handleChange}
                    onFocus={() => this.setState({ showPlaceholder: false })}
                    onBlur={e => this.setState({ showPlaceholder: !e.target.value })}
                    className={s.input}
                  />
                  { showPlaceholder && <div className={s.placeholder}>{inputPlaceholder}</div> }
                  { showError && <div className={s.error}>{error}</div>}
                </div>
                <button name="submit" type="submit" className={s.button}>{buttonTitle}</button>
              </div>
              {inputType && inputType === 'password' &&
                <span className={s.forgottenPassword} onClick={sendResetPasswordLink}>Forgotten password?</span>
              }
            </form>
          </div>
          <div className={s.footer}>
            {theme && theme === 'light' ?
              <img src="https://s3-eu-west-1.amazonaws.com/j2-files/Jetty-logo.png" alt="jetty logo" />
              :
              <h3>Jetty</h3>
            }
            <div className={s.text}>&copy; {`${moment().year()} Jetty - Get your acts together`}</div>
          </div>
        </div>
        <div
          className={s.right}
          style={{ backgroundImage: theme && theme === 'light' ?
            'url(https://s3-eu-west-1.amazonaws.com/j2-files/system/login-background-light.jpg)' :
            'url("https://s3.eu-central-1.amazonaws.com/jetty2-dev/web/splash_screen_image.jpg")' }}
        >
          { promoteApp ?
            <div className={s.app}>
              <h3>Seen our new app?</h3>
              <div className={s.text}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tempor tortor ut quam aliquet, eu bibendum elit porttitor. Praesent feugiat placerat sapien, sed mattis odio tincidunt eu. Donec lorem metus, sodales in egestas at, condimentum quis purus. Morbi quis neque vitae justo aliquet fermentum et nec turpis.
              </div>
            </div> : null }
        </div>
      </div>
    );
  }
}

export default Login;
