import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Title from './Title';
import InputPreview from './InputPreview';
import TextScroll from './TextScroll';

import s from './InputText.module.scss';

class InputText extends Component {
  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.state = {
      value: props.value,
      isFocused: false,
      hasContent: !!props.value,
      timeout: setTimeout(() => {}, 100),
      typing: false,
      invalidPattern: false,
    };
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeout);
  }

  componentWillReceiveProps(nextProps) {
    const { value: nextValue, presetValue, hasParentField, edit } = nextProps;
    const { value, typing } = this.state;
    if (value !== nextValue && !typing) this.setState({ value: nextValue });
    if (edit && value !== presetValue && (!!presetValue || !!value) && !typing && hasParentField) this.onChange({ target: { value: presetValue || '' } });
  }

  handleChange = (e) => {
    const { onChange } = this.props;
    onChange(e);
    this.setState({ typing: false });
  }

  onChange(e) {
    if(typeof e.persist !== 'undefined') e.persist();
    clearTimeout(this.state.timeout);
    this.setState({
      typing: true,
      hasContent: e.target.value.length,
      value: e.target.value,
      timeout: setTimeout(() => this.handleChange(e), 300),
      invalidPattern: (this.props.pattern && !this.props.pattern.test(e.target.value)) || false,
    });
  }

  onKeyPress(e) {
    const { onKeyPress } = this.props;
    if (onKeyPress) onKeyPress(e);
  }

  onKeyDown(e) {
    const { onKeyDown } = this.props;
    if (onKeyDown) onKeyDown(e);
  }

  handleBlur() {
    const { onBlur } = this.props;
    this.setState({ isFocused: false });
    if(onBlur) onBlur();
  }

  handleFocus(e) {
    const { onFocus, moveCursorToEndOnFocus = false } = this.props;
    if (onFocus) onFocus();
    if (moveCursorToEndOnFocus) {
      const val = e.target.value;
      e.target.value = '';
      e.target.value = val;
    }
    this.setState({ isFocused: true });
  }
  static whyDidYouRender = true;
  render() {
    const {
      placeholder,
      title,
      type = 'text',
      autoFocus = false,
      edit = true,
      isNested,
      prioritized,
      disabled,
      icon,
      small = false,
      invalid = false,
      displayValue,
      error = false,
      nomargin = false,
    } = this.props;
    const { isFocused, hasContent, value, invalidPattern } = this.state;
    const val = (displayValue != null && displayValue) || value || '';
    if(edit) {
      return (
        <div className={`${s.wrapper} ${hasContent ? s.hasContent : ''} ${isFocused ? s.active : ''} ${disabled ? s.disabled : ''} ${icon ? s.hasIcon : ''} ${(title && !nomargin) ? s.hasTitle : ''} ${val ? s.hasValue : ''}`}>
          { title && <Title invalid={invalid || invalidPattern} h3 edit>{title}</Title> }
          <div className={s.inputWrapper}>
            { icon && <i className={`${s.icon} fa fa-${icon}`} /> }
            {placeholder && <span className={s.placeholder}>{placeholder}</span>}
            <input
              type={type}
              autoFocus={autoFocus} // eslint-disable-line
              className={`${s.input} ${small ? s.small : ''}  ${error ? s.error : ''}`}
              value={val || ''}
              onChange={this.onChange}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onKeyPress={this.onKeyPress}
              onKeyDown={this.onKeyDown}
            />
          </div>
        </div>
      );
    }

    return (
      prioritized ?
        <div className={`${s.preview} ${isNested ? s.nested : ''} ${icon ? s.hasIcon : ''} ${val ? s.hasValue : ''}`}>
          { icon &&
            <div className={s.iconWrapper}>
              <i className={`${s.icon} fa fa-${icon}`} />
            </div>
          }
          <div className={s.previewContent}>
            <div className={s.title}>{placeholder}</div>
            <TextScroll><div className={s.value}>{val}</div></TextScroll>
          </div>
        </div>
        : <InputPreview title={placeholder} value={val} isNested={isNested} icon={icon} />
    );
  }
}

InputText.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string, // .isRequired,
  type: PropTypes.oneOf(['text', 'password', 'email']),
  edit: PropTypes.bool,
  onChange: PropTypes.func,
};


export default InputText;
