import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import authReducer, { initialState as authState } from './authReducer';
import objectsReducer, { initialState as objectsState } from './objectsReducer';
import environmentReducer, {
  initialState as environmentState,
} from './environmentReducer';
import tagsReducer, { initialState as tagsState } from './tagsReducer';
import peopleReducer, { initialState as peopleState } from './peopleReducer';
import tasksReducer, { initialState as tasksState } from './tasksReducer';
import scheduleReducer, {
  initialState as scheduleState,
} from './scheduleReducer';
import fileReducer, { initialState as fileState } from './fileReducer';
import cmsReducer, { initialState as cmsState } from './cmsReducer';
import tokenUserReducer, {
  initialState as tokenUserState,
} from './tokenUserReducer';
import setupReducer, { initialState as setupState } from './setupReducer';
import searchReducer, { initialState as searchState } from './searchReducer';
import reportsReducer, { initialState as reportsState } from './reportsReducer';
import commentsReducer, {
  initialState as commentsState,
} from './commentsReducer';
import companiesReducer, {
  initialState as companiesState,
} from './companiesReducer';
import manageUsersReducer, {
  initialState as manageUsersState,
} from './manageUsersReducer';
import contractsReducer, {
  initialState as contractsState,
} from './contractsReducer';
import economyReducer, { initialState as economyState } from './economyReducer';
import stripeReducer, { initialState as stripeState } from './stripeReducer';
import badgeReducer, { initialState as badgeState } from './badgeReducer';
import signupReducer, { initialState as signupState } from './signupReducer';

const objectsStatePersistConfig = {
  key: 'objectsState',
  storage,
  blacklist: [
    'paginationReachedBottom',
    'nextPageByType',
    'showLoader',
    'currentObject',
    'currentRequest',
    'lastUpdatedBlockId',
    'latestActivatedBlockId',
    'pending',
    'objectDatas',
    'blockDatas',
    'fieldDatas',
    'currentPage',
    'nextPage',
    'pageSize',
    'pendingCreateObject',
    'error',
    'preparedJobRoles',
    'departments',
    'preparedDepartments',
    'newlyCreatedObjectType',
    'importFields',
    'importExcelData',
    'importFieldsBlock',
    'importExcelBlock',
    'importBlockHeaders',
  ],
};

const authStatePersistConfig = {
  key: 'authState',
  storage,
  blacklist: [
    'showLoader',
    'pending',
    'failedLoginAttempt',
    'loggedIn',
    'user',
    'account',
    'termsAccepted',
    'error',
    'notifications',
    'preparedProject',
  ],
};

const environmentStatePersistConfig = {
  key: 'environmentState',
  storage,
  blacklist: [
    'showLoader',
    'filter',
    'printFilter',
    'pendingRequests',
    'viewMode',
    'requiredFields',
    'fieldsRequired',
    'error',
    'showDeleteNotice',
    'deleteCB',
    'comments',
    'bulkEmailSent',
    'pendingBulkEmailSend',
  ],
};

const peopleStatePersistConfig = {
  key: 'peopleState',
  storage,
  blacklist: [
    'paginationReachedBottom',
    'currentPage',
    'nextPage',
    'pending',
    'isCreating',
    'isEditingPerson',
    'selectedPeople',
    'peopleAutocompleteResults',
    'staffAutocompleteResults',
    'people',
    'error',
    'preparedPerson',
  ],
};

const tasksStatePersistConfig = {
  key: 'tasksState',
  storage,
  blacklist: [
    'taskPending',
    'isEditingTask',
    'isCreating',
    'assignMode',
    'selectedTasks',
    'nextPage',
    'currentPage',
    'paginationReachedBottom',
    'preparedTask',
    'tasks',
    'error',
    'updateTaskPending',
    'taskReports',
    'preparedTaskReports',
    'isEditingTaskReports',
  ],
};

const scheduleStatePersistConfig = {
  key: 'scheduleState',
  storage,
  blacklist: [
    'calendarView',
    'showLocation',
    'showStatus',
    'schedulePending',
    'isCreating',
    'isEditingSchedule',
    'nextPage',
    'currentPage',
    'paginationReachedBottom',
    'preparedSchedule',
    'schedules',
    'globalSchedules',
    'pending',
    'calendarSchedules',
    'error',
  ],
};

const fileStatePersistConfig = {
  key: 'fileState',
  storage,
  blacklist: [
    'pendingFiles',
    'isUploading',
    'selectedFiles',
    'nextPage',
    'paginationReachedBottom',
    'files',
    'pending',
    'isUpdating',
    'error',
  ],
};

const tagsStatePersistConfig = {
  key: 'tagsState',
  storage,
  blacklist: [
    'tagname',
    'tag',
    'tagAutocompleteResults',
    'tags',
    'error',
    'preparedTags',
    'preparedTagGroups',
  ],
};

const companiesStatePersistConfig = {
  key: 'companiesState',
  storage,
  blacklist: [
    'pending',
    'isCreating',
    'isEditingCompany',
    'isEditingAddresses',
    'isEditingPeople',
    'isEditingContactLogs',
    'selectedCompanies',
    'companiesAutocompleteResults',
    'companies',
    'error',
    'preparedCompany',
    'preparedAddresses',
    'preparedPeople',
    'preparedContactLogs',
  ],
};

const commentsStatePersistConfig = {
  key: 'commentsState',
  storage,
  blacklist: ['comments', 'preparedComment'],
};

const stripeStatePersistConfig = {
  key: 'stripeState',
  storage,
};

const signupStatePersistConfig = {
  key: 'signupState',
  storage,
};

export default {
  authStore: persistReducer(authStatePersistConfig, authReducer),
  objectsState: persistReducer(objectsStatePersistConfig, objectsReducer),
  environmentState: persistReducer(
    environmentStatePersistConfig,
    environmentReducer,
  ),
  tagsState: persistReducer(tagsStatePersistConfig, tagsReducer),
  peopleState: persistReducer(peopleStatePersistConfig, peopleReducer),
  tasksState: persistReducer(tasksStatePersistConfig, tasksReducer),
  scheduleState: persistReducer(scheduleStatePersistConfig, scheduleReducer),
  fileState: persistReducer(fileStatePersistConfig, fileReducer),
  companiesState: persistReducer(companiesStatePersistConfig, companiesReducer),
  commentsState: persistReducer(commentsStatePersistConfig, commentsReducer),
  stripeState: persistReducer(stripeStatePersistConfig, stripeReducer),
  cmsState: cmsReducer,
  tokenUserState: tokenUserReducer,
  setupState: setupReducer,
  searchState: searchReducer,
  reportsState: reportsReducer,
  manageUsersState: manageUsersReducer,
  contractsState: contractsReducer,
  economyState: economyReducer,
  badgeState: badgeReducer,
  signupState: persistReducer(signupStatePersistConfig, signupReducer),
};

export const initialStates = {
  authState,
  objectsState,
  environmentState,
  tagsState,
  peopleState,
  tasksState,
  scheduleState,
  fileState,
  cmsState,
  tokenUserState,
  setupState,
  searchState,
  reportsState,
  commentsState,
  companiesState,
  manageUsersState,
  contractsState,
  economyState,
  stripeState,
  badgeState,
  signupState,
};
