// @flow
import C from '../constants/constants';
import $ from '../../libs/reduxOperations';
import type { ActionType } from '../actions/contractsActions';
import type { ContractState } from '../entities/FlowTypes';
import { IdMapValues } from '../entities/FlowTypes';

export const initialState: ContractState = {
  contracts: {},
  contractTemplates: {},
  isLoadingContracts: false,
  preparedContractTemplate: {},
  preparedContract: {},
  latestCreatedContractTemplateId: null,
  isEditingContractTemplate: false,
  contractBlocks: [],
  pendingPdfs: [],
  isUploadingPdf: false,
  currentContract: {},
};

export default function contractsReducer(state: ContractState = initialState, action: ActionType): ContractState {
  switch (action.type) {
    case C.FETCH_CONTRACTS_START:
      return {
        ...state,
      };

    case C.FETCH_CONTRACTS_SUCCESS:
      return {
        ...state,
        contracts: $.addOrUpdateObjectWithObjects(state.contracts, action.contracts),
      };


    case C.FETCH_CONTRACT_TEMPLATES_SUCCESS:
      return {
        ...state,
        contractTemplates: $.addOrUpdateObjectWithObjects(state.contractTemplates, action.contractTemplates),
        isLoadingContracts: false,
        latestCreatedContractTemplateId: null,
      };

    case C.FETCH_CONTRACT_TEMPLATES_START:
      return {
        ...state,
        isLoadingContracts: true,
      };


    case C.PREPARE_CONTRACT_TEMPLATE:
      return {
        ...state,
        preparedContractTemplate: action.preparedContractTemplate,
      };


    case C.PREPARE_CONTRACT:
      return {
        ...state,
        preparedContract: action.preparedContract,
      };

    case C.CREATE_CONTRACT_TEMPLATE_SUCCESS:
      return {
        ...state,
        contractTemplates: $.addOrUpdateObjectWithObjects(state.contractTemplates, action.contractTemplates),
        latestCreatedContractTemplateId: Object.keys(action.contractTemplates)[0],
        preparedContractTemplate: {},
        isEditingContractTemplate: false,
      };

    case C.BEGIN_EDIT_CONTRACT_TEMPLATE:
      return {
        ...state,
        isEditingContractTemplate: true,
      };

    case C.UPDATE_CONTRACT_TEMPLATE_SUCCESS:
      return {
        ...state,
        contractTemplates: $.addOrUpdateObjectWithObjects(state.contractTemplates, action.updatedContractTemplates),
        preparedContractTemplate: IdMapValues(action.updatedContractTemplates) && IdMapValues(action.updatedContractTemplates)[0],
        isEditingContractTemplate: false,
      };

    case C.ABORT_EDIT_CONTRACT_TEMPLATE:
      return {
        ...state,
        isEditingContractTemplate: false,
      };

    case C.SET_CONTRACT_TEMPLATE_BLOCKS:
      return {
        ...state,
        contractBlocks: action.contractBlocks,
      };

    case C.PREPARE_CONTRACT_PDF:
      return {
        ...state,
        pendingPdfs: $.addOrUpdateByTempId(state.pendingPdfs || [], action.contractPdf),
      };

    case C.OPEN_CONTRACT_PDF_UPLOAD:
      return {
        ...state,
        isUploadingPdf: true,
        currentContract: action.contract,
      };

    case C.UPDATE_CONTRACT_SUCCESS:
      return {
        ...state,
        contracts: $.updateObjectById(state.contracts, action.contractId, action.contract),
      };

    case C.CLOSE_CONTRACT_PDF_UPLOAD:
      return {
        ...state,
        isUploadingPdf: false,
        pendingPdfs: [],
        currentContract: {},
      };

    case C.UPDATE_CONTRACT_FAIL:
      return {
        ...state,
        isUploadingPdf: false,
        pendingPdfs: [],
        currentContract: {},
        preparedContract: {},
      };

    case C.FETCH_CONTRACTS_FAIL:
    case C.UPDATE_CONTRACT_TEMPLATE_FAIL:
    case C.CREATE_CONTRACT_TEMPLATE_FAIL:
    case C.FETCH_CONTRACT_TEMPLATES_FAIL:
      return state;
    default:
      return state;
  }
}
