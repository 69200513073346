// @flow

import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { hexToRgbA } from '../../../libs/utils';

import s from './Button.module.scss';

type Props = {
  title: string,
  onClick?: (any) => any,
  type?: 'primary' | 'secondary' | 'tertiary',
  pending?: boolean,
  color?: string,
  borderColor?: string,
  height?: number,
  disabled?: boolean,
  style?: any,
  fluidWidth?: boolean,
  small?: boolean,
  tiny?: boolean,
  wide?: boolean,
  multiButtonActions?: ?Array<{ action: (?MouseEvent) => any, title: string }>,
  hideMainActionIfMulti?: boolean,
  minWidth?: number,
  multiButtonDisableOverride?: boolean,
  icon?: string,
};

type State = {
  isOpen: boolean,
};

class Button extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  wrapperRef: ?HTMLDivElement;

  handleClickOutside = (event: MouseEvent) => {
    const { target } = event;
    if (
      this.wrapperRef &&
      target instanceof Node &&
      !this.wrapperRef.contains(target)
    ) {
      this.setState({ isOpen: false });
    }
  };

  render() {
    const {
      style,
      title,
      onClick,
      type = 'primary',
      pending = false,
      color,
      borderColor,
      height,
      fluidWidth,
      disabled = false,
      small,
      tiny,
      wide,
      multiButtonActions,
      hideMainActionIfMulti,
      minWidth,
      multiButtonDisableOverride,
      icon,
    } = this.props;
    const { isOpen } = this.state;

    let addToStyle = {};
    let colorStyle = {};
    if (color) {
      colorStyle = {
        backgroundColor: `#${color}`,
        boxShadow: `0 5px 10px 0 ${hexToRgbA(`#${color}`, 0.25)}`,
        borderColor: `#${borderColor || color}`,
      };
      addToStyle = {
        ...style,
        ...colorStyle,
      };
    }

    if (height) addToStyle = { ...addToStyle, height: `${height}px` };
    if (fluidWidth)
      addToStyle = { ...addToStyle, width: '100%', minWidth: 'auto' };
    if (minWidth) addToStyle = { ...addToStyle, minWidth: `${minWidth}px` };
    return (
      <div
        className={`${s.button} ${pending ? s.inProgress : ''} ${
          disabled && !multiButtonDisableOverride ? s.disabled : ''
        } ${pending ? s.active : ''} ${s[type]} ${small ? s.small : ''} ${
          tiny ? s.tiny : ''
        } ${wide ? s.wide : ''} ${multiButtonActions ? s.isMulti : ''}`}
        style={addToStyle}
        ref={(r) => {
          this.wrapperRef = r;
        }}
      >
        <button
          className={`${s.innerButton} ${disabled ? s.disabled : ''} `}
          onClick={onClick}
        >
          {!disabled && (
            <TransitionGroup timeout={10000}>
              {pending && (
                <CSSTransition
                  classNames={{
                    appear: s.progressEnter,
                    appearActive: s.progressEnterActive,
                    enter: s.progressEnter,
                    enterActive: s.progressEnterActive,
                    exit: s.progressExit,
                    exitActive: s.progressExitActive,
                  }}
                  timeout={10000}
                >
                  <div className={s.progress} />
                </CSSTransition>
              )}
            </TransitionGroup>
          )}
          <div className={`${s.title} ${icon ? s.hasIcon : ''}`}>{title}</div>
          {icon && <i className={`fa fa-${icon} ${s.icon}`} />}
        </button>
        {multiButtonActions && (
          <button
            className={`${s.arrow} ${
              disabled && !multiButtonDisableOverride ? s.disabled : ''
            } ${isOpen ? s.isOpen : ''}`}
            onClick={() => this.setState({ isOpen: !isOpen })}
          />
        )}
        <TransitionGroup timeout={700} style={{ background: 'inherit' }}>
          {isOpen && (
            <CSSTransition
              classNames={{
                appear: s.animationEnter,
                appearActive: s.animationEnterActive,
                enter: s.animationEnter,
                enterActive: s.animationEnterActive,
                exit: s.animationExit,
                exitActive: s.animationExitActive,
              }}
              timeout={700}
            >
              <div className={s.menu} style={colorStyle}>
                {!hideMainActionIfMulti ? (
                  <button onClick={onClick}>{title}</button>
                ) : null}
                {multiButtonActions &&
                  multiButtonActions.map((button, i) => (
                    <button key={i} onClick={button.action}>
                      {button.title}
                    </button>
                  ))}
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
    );
  }
}

export default Button;
